import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import EventBus from '../../../../core/scripts/vendor/EventBus/dist/EventBus';
import BootstrapUtility from '../../../../core/scripts/util/BootstrapUtility';
const I18n = require('../../../../core/scripts/data/i18nJSON.json');
import UserPetRepository from '../../../../core/scripts/repositories/UserPetRepository';

var PetInputBlock = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PetInputBlock.prototype = new BaseComponent();
var proto = PetInputBlock.prototype;
proto.constructor = PetInputBlock;
proto._super = BaseComponent.prototype;

//////////////////////////////////////////////////////////////////////////////////
// CONSTANTS
//////////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
PetInputBlock.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PetInputBlock.SELECTORS = {};

/**
 * Holds event names that are dispatched/received through the event bus.
 *
 * @property EVENTS
 * @static
 * @final
 * @type {Object}
 */
PetInputBlock.EVENTS = {};

/**
 * Holds event names that are dispatched through the event bus.
 *
 * @property EVENTS.DISPATCH
 * @static
 * @final
 * @type {Object}
 */
PetInputBlock.EVENTS.DISPATCH = {};

/**
 * Holds event names that are eceived through the event bus.
 *
 * @property EVENTS.RECEIVE
 * @static
 * @final
 * @type {Object}
 */
PetInputBlock.EVENTS.RECEIVE = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.SELECTORS.ELEMENT = '.js-petInputBlock';

/**
 * Selector for the pet type select list. Changing this loads in the breeds for the pet type.
 *
 * @property SELECTORS.INPUT_PET_TYPE
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.SELECTORS.INPUT_PET_TYPE =
    PetInputBlock.SELECTORS.ELEMENT + '-inputPetType';

/**
 * The delete button
 *
 * @property SELECTORS.BTN_DELETE
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.SELECTORS.BTN_DELETE =
    PetInputBlock.SELECTORS.ELEMENT + '-btnDelete';

/**
 * Selector for the loading indicator
 *
 * @property SELECTORS.THROBBER
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.SELECTORS.THROBBER =
    PetInputBlock.SELECTORS.ELEMENT + '-throbber';

/**
 * Is hidden class
 *
 * @property CLASSES.IS_HIDDEN
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.CLASSES.IS_HIDDEN = 'u-isHidden';

/**
 * Event dispatched to enable child components within the pet input blocks
 *
 * @property EVENTS.DISPATCH.ENABLE_CHILD_COMPONENTS
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.EVENTS.DISPATCH.ENABLE_CHILD_COMPONENTS = 'enableComponents';

/**
 * Event dispatched when a pet input block has been deleted
 *
 * @property EVENTS.DISPATCH.DELETE_PET_INPUT_BLOCK
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.EVENTS.DISPATCH.DELETE_PET_INPUT_BLOCK = 'petInputBlockDeleted';

/**
 * Event received when getting breeds for the pet type has been initiated
 *
 * @property EVENTS.RECEIVE.GETTING_BREEDS_FOR_PET_TYPE
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.EVENTS.RECEIVE.GETTING_BREEDS_FOR_PET_TYPE =
    'gettingBreedsForPetType';

/**
 * Event received when the get for breeds for the pet type has been successful
 *
 * @property EVENTS.RECEIVE.GET_BREEDS_FOR_PET_TYPE_SUCCESS
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.EVENTS.RECEIVE.GET_BREEDS_FOR_PET_TYPE_SUCCESS =
    'getBreedsForPetTypeSuccess';

/**
 * Event received when the get for breeds for the pet type has failed
 *
 * @property EVENTS.RECEIVE.GET_BREEDS_FOR_PET_TYPE_FAIL
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.EVENTS.RECEIVE.GET_BREEDS_FOR_PET_TYPE_FAIL =
    'getBreedsForPetTypeFailed';

/**
 * Message displayed in the dialog for deleting a pet.
 *
 * @property MESSAGE_DELETE_PET
 * @static
 * @final
 * @type {String}
 */
PetInputBlock.MESSAGE_DELETE_PET = 'Are you sure you want to delete this pet?';

//////////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
//////////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.componentEventBus = new EventBus();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onClickBtnDeleteHandler = this.onClickBtnDelete.bind(this);
    this.onChangeInputPetTypeHandler = this.onChangeInputPetType.bind(this);
    this.onGettingBreedsForPetTypeHandler = this.onGettingBreedsForPetType.bind(
        this
    );
    this.onGetBreedsForPetTypeCompleteHandler = this.onGetBreedsForPetTypeComplete.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputPetType = this.$element.find(
        PetInputBlock.SELECTORS.INPUT_PET_TYPE
    );
    this.$nonPetTypeInputs = this.$element
        .find(':input')
        .not('button')
        .not(PetInputBlock.SELECTORS.INPUT_PET_TYPE);
    this.$inputPetId = this.$element.find('.js-petInputBlock-inputPetId');
    this.$btnDelete = this.$element.find(PetInputBlock.SELECTORS.BTN_DELETE);
    this.$throbber = this.$element.find(PetInputBlock.SELECTORS.THROBBER);
    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);

    this.setupListeners()
        .enableComponents()
        .cachePetId()
        .setIsPetCreated()
        .setInitialInputStates();

    return this;
};

/**
 * Setsup event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$btnDelete.on('click', this.onClickBtnDeleteHandler);
    this.$inputPetType.on('change', this.onChangeInputPetTypeHandler);
    this.componentEventBus
        .on(
            PetInputBlock.EVENTS.RECEIVE.GETTING_BREEDS_FOR_PET_TYPE,
            this.onGettingBreedsForPetTypeHandler
        )
        .on(
            PetInputBlock.EVENTS.RECEIVE.GET_BREEDS_FOR_PET_TYPE_SUCCESS,
            this.onGetBreedsForPetTypeCompleteHandler
        )
        .on(
            PetInputBlock.EVENTS.RECEIVE.GET_BREEDS_FOR_PET_TYPE_FAIL,
            this.onGetBreedsForPetTypeCompleteHandler
        );

    return this;
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$btnDelete.off('click', this.onClickBtnDeleteHandler);
    return this;
};

proto.enableComponents = function enableComponents() {
    this.componentEventBus.trigger(
        PetInputBlock.EVENTS.DISPATCH.ENABLE_CHILD_COMPONENTS
    );
    return this;
};

//////////////////////////////////////////////////////////////////////////////////
// HELPERS
//////////////////////////////////////////////////////////////////////////////////

/**
 * Cache the pet id on the object.
 * Run on enabling of the component.
 *
 * @public
 * @chainable
 */
proto.cachePetId = function cachePetId() {
    this.petId = this.$inputPetId.val();
    return this;
};

/**
 * Sets the flag for whether the pet has been created.
 * This is determined by whether the pet has an ID.
 * This value is used to branch logic for whethere a change to an input should create a new pet record
 * or update an existing one.
 *
 * @public
 * @chainable
 */
proto.setIsPetCreated = function setIsPetCreated() {
    this.isPetCreated = this.petId !== '';
    return this;
};

/**
 * Set initial input states. If the pet doesn't have a type, its a new pet.
 * Run on enabling of the component.
 *
 * @public
 * @chainable
 */
proto.setInitialInputStates = function setInitialInputStates() {
    if (this.isPetCreated) {
        this.enableInputs(this.$nonPetTypeInputs);
    } else {
        this.disableInputs(this.$nonPetTypeInputs);
    }
    return this;
};

/**
 * Enables an input
 *
 * @method enableInput
 * @param input to enable
 * @public
 * @chainable
 */
proto.enableInput = function enableInput(input) {
    input.removeAttribute('disabled');
};

/**
 * Disables an input
 *
 * @method disableInput
 * @param input to disable
 * @public
 * @chainable
 */
proto.disableInput = function disableInput(input) {
    input.setAttribute('disabled', 'disabled');
};

/**
 * Disables a set of inputs
 *
 * @method disableInputs
 * @param $inputs Jquery wrapped
 * @public
 * @chainable
 */
proto.disableInputs = function disableInputs($inputs) {
    $inputs.each(this.disableInput);
    return this;
};

/**
 * Enables a set of inputs
 *
 * @method enableInputs
 * @param $inputs Jquery wrapped
 * @public
 * @chainable
 */
proto.enableInputs = function enableInputs($inputs) {
    $inputs.each(this.enableInput);
    return this;
};

/**
 * Show the loading indicator while interacting with the server
 *
 * @method showThrobber
 * @private
 * @chainable
 */
proto.showThrobber = function showThrobber() {
    this.$throbber.removeClass(PetInputBlock.CLASSES.IS_HIDDEN);
    return this;
};

/**
 * Hide the loading indicator when finished interacting with the server
 *
 * @method hideThrobber
 * @private
 * @chainable
 */
proto.hideThrobber = function hideThrobber() {
    this.$throbber.addClass(PetInputBlock.CLASSES.IS_HIDDEN);
    return this;
};

/**
 * Dispatch deletion events after a pet has been successfully deleted to allow the
 * form to handle communication with the server
 *
 * @method dispatchDeletionEvents
 * @private
 */
proto.dispatchDeletionEvents = function dispatchDeletionEvents() {
    this.eventBus.trigger(PetInputBlock.EVENTS.DISPATCH.DELETE_PET_INPUT_BLOCK);
};

/**
 * Remove this pet input block after a successful deletion
 *
 * @method removeElement
 * @private
 */
proto.removeElement = function removeElement() {
    this.$element.remove();
    return this;
};

//////////////////////////////////////////////////////////////////////////////////
// HANDLERS
//////////////////////////////////////////////////////////////////////////////////

proto.onChangeInputPetType = function onChangeInputPetType(event) {
    var val = event.currentTarget.value;
    if (val == '') {
        this.disableInputs(this.$nonPetTypeInputs);
    } else {
        this.enableInputs(this.$nonPetTypeInputs);
    }
};

/**
 * On click of the delete button, the pet is deleted from the database.
 * If the pet has not been created yet, just wipe out the block of inputs
 *
 * @method onClickBtnDelete
 * @param event Javascript
 * @private
 */
proto.onClickBtnDelete = function onClickBtnDelete(event) {
    event.preventDefault();
    if (window.confirm(PetInputBlock.MESSAGE_DELETE_PET)) {
        this.removeElement().dispatchDeletionEvents();
    }
};

/**
 * Show a throbber while the server is retrieving breeds to inform the user to wait until they are fetched
 *
 * @method onGettingBreedsForPetType
 * @private
 */
proto.onGettingBreedsForPetType = function onGettingBreedsForPetType() {
    this.showThrobber();
};

/**
 * Hide the throbber when the get is complete.
 *
 * @method onGetBreedsForPetTypeComplete
 * @private
 */
proto.onGetBreedsForPetTypeComplete = function onGetBreedForPetTypeComplete() {
    this.hideThrobber();
};

export default PetInputBlock;
