/**
 * @type {number}
 */
export const PADDING_FROM_SIDES = 20;

/**
 * @type {number}
 */
export const POINTER_TOP_OFFSET = 1;

/**
 * @type {number}
 */
export const TOOLTIP_TOP_OFFSET = 12;

/**
 * @type {string}
 */
export const TOOLTIP_UID_PREFIX = 'Tooltip_';

/**
 * @type {Object}
 */
export const TOOLTIP_TYPE_TO_CLASS_MAP = {
    primaryBordered: 'breedTooltip_primaryBordered',
};

/**
 * @type {Object}
 */
export const TOOLTIP_POSITIONS = {
    BOTTOM: 'bottom',
    TOP: 'top',
};

/**
 * @type {Object}
 */
export const TOOLTIP_POSITION_TO_CLASS_MAP = {
    bottom: 'm-breedTooltip_bottom',
    top: 'm-breedTooltip_top',
};

/**
 * @type {string}
 */
export const HIDE_TOOLTIP_OFFSCREEN_PX = '-10000px';
