/**
 * @fileOverview OrganizationCollection
 * @author Noah Blon nblon@nerdery.com
 */
import BaseCollection from './BaseCollection';
import LegacyOrganizationModel from '../models/LegacyOrganizationModel';

/**
 * Organization Collection
 * A list of Organization (pet shelters) Models
 *
 * @class OrganizationCollection
 * @extends {BaseCollection}
 * @constructor
 */
var OrganizationCollection = function OrganizationCollection() {
    this.init();
};

var _super = BaseCollection.prototype;
OrganizationCollection.prototype = Object.create(_super);
OrganizationCollection.prototype.constructor = OrganizationCollection;

var proto = OrganizationCollection.prototype;

/**
 * Initializes the Collection
 * Instantiates object property defaults.
 *
 * @private
 * @method init
 */
proto.init = function() {
    _super.init.call(this);

    /**
     * Array to hold organization models.
     *
     * @for OrganizationCollection
     * @property organizations
     * @type {Array}
     */
    this.organizations = [];

    /**
     * Holds properties related to the pagination data of the response set.
     *
     * @for OrganizationCollection
     * @property pagination
     * @type {Object}
     */
    this.pagination = {};
};

/**
 * An Organization Collection of Organization models is built after an AJAX request for organizations based on
 * search parameters.  Pagination is created based on the response to allow for paging of records.
 *
 *
 * @method fromJSON
 * @param json response containing countries.
 *
 */
proto.fromJSON = function fromJSON(json) {
    this.pagination = {
        countPerPage: json.pagination.count_per_page,
        totalCount: json.pagination.total_count,
        currentPage: json.pagination.current_page,
        totalPages: json.pagination.total_pages,
    };
    for (var i = 0; i < json.organizations.length; i++) {
        var organization = new LegacyOrganizationModel();
        organization.fromJSON(json.organizations[i]);
        this.organizations.push(organization);
    }
};

proto.getOrganizationById = function getOrganizationById(id) {
    const organization = this.organizations.filter(
        organization => organization.id === id
    );
    if (organization.length !== 1) {
        throw new Error('Expected organizations to have unique IDs.');
    }
    return organization[0];
};

export default OrganizationCollection;
