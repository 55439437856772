/**
 * @fileOverview State nCollection
 * @author Noah Blon nblon@nerdery.com
 */
import BaseCollection from './BaseCollection';
import StateModel from '../models/LegacyStateModel';

    /**
     * Organization Collection
     * A list of State Models
     *
     * @class StateCollection
     * @extends {BaseCollection}
     * @constructor
     */
    var StateCollection = function StateCollection () {
        this.init();
    };

    var _super = BaseCollection.prototype;
    StateCollection.prototype = Object.create(_super);
    StateCollection.prototype.constructor = StateCollection;

    var proto = StateCollection.prototype;

    /**
     /**
     * Initializes the Collection
     * Instantiates object property defaults.
     *
     * @private
     * @method init
     */
    proto.init = function init () {
        _super.init.call(this);

        /**
         * Array to hold organization models.
         *
         * @for StateCollection
         * @property organizations
         * @type {Array}
         */
        this.states = [];
    };

    /**
     * A collection of states for a country are built when a response is returned from the server.
     *
     * @method fromJSON
     * @param json response containing countries.
     *
     */
    proto.fromJSON = function fromJSON (json) {
        this.states = [];
        for (var i = 0; i < json.states.length; i++) {
            var state = new StateModel();
            state.fromJSON(json.states[i]);
            this.states.push(state);
        }
    };

export default StateCollection;
