import BaseForm from '../../../../core/scripts/components/BaseForm/BaseForm';
import BootstrapUtility from '../../../../core/scripts/util/BootstrapUtility';
import PetInputBlockCollection from '../PetInputBlocks/PetInputBlocks';
import CountryPostalCodePatternMatcher from '../../../../core/scripts/regexes/CountryZipPatternMatcher.js';
import UserRepository from '../../../../core/scripts/repositories/UserRepository';

import $ from 'jquery';
import { COUNTRY_NOT_LISTED_VALUE } from '../../../../core/scripts/constants/geography';
import { Config } from '../../../../core/scripts/lib/Config';

// Subcomponents of the About Me Form
const componentList = {
    PetInputBlockCollection,
};

/**
 * About Me Form controlling user profile data.
 * Form autosaves on blurring of inputs.
 *
 * @class AboutMeForm
 * @extends {BaseForm}
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus Event Bus shared between components in
 * app to facilitate cross module communication
 * @param {JSON} routeModel config generated from backend
 * @param {JSON} i18nModel translated messages generated from backend
 */
const AboutMeForm = function($element, eventBus, routeModel, i18nModel) {
    BaseForm.call(this, $element, eventBus, routeModel, i18nModel);
    this.init($element, eventBus, routeModel, i18nModel);
};

// Inheritance
AboutMeForm.prototype = Object.create(BaseForm.prototype);
const _super = BaseForm.prototype;

Object.assign(AboutMeForm.prototype, {
    constructor: AboutMeForm,

    /**
     * Initializes the UI Component View
     * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
     *
     * @private
     * @method init
     * @extends {BaseForm.init}
     * @chainable
     * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
     * @param {Object} eventBus Event Bus shared between components in app to
     * facilitate cross module communication
     * @param {JSON} routeModel config generated from backend
     * @param {JSON} i18nModel translated messages generated from backend
     * @returns {AboutMeForm}
     */
    init($element, eventBus, routeModel, i18nModel) {
        _super.init.call(this, $element, eventBus, routeModel, i18nModel);

        // merge base component list with additional components that will be
        // enabled in a factory with an event bustied to this view
        $.extend(this.componentList, componentList);

        /**
         * Class containing regex to test postal code validity by country
         *
         * @property countryPostalCodePatternMatcher
         * @type {CountryPostalCodePatternMatcher}
         */
        this.countryPostalCodePatternMatcher = new CountryPostalCodePatternMatcher();

        /**
         * Class responsible for AJAX interaction with the server related to user data
         *
         * @property userRepository
         * @type {UserRepository}
         */
        this.userRepository = new UserRepository();

        return this.setupHandlers().createChildren();
    },

    /**
     * Binds the scope of any handler functions
     *
     * @method setupHandlers
     * @extends {BaseForm.setupHandlers}
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    setupHandlers() {
        _super.setupHandlers.call(this);
        this.onInputChangeHandler = this.onInputChange.bind(this);
        this.onCountryChangeHandler = this.onCountryChange.bind(this);
        this.onAddMarkupHandler = this.onAddMarkup.bind(this);
        this.onInputPetSaveInitiatedHandler = this.onInputPetSaveInitiated.bind(
            this
        );
        this.onInputPetSaveSuccessHandler = this.onInputPetSaveSuccess.bind(
            this
        );
        this.onInputPetSaveFailedHandler = this.onInputPetSaveFailed.bind(this);
        this.onLinkClickHandler = this.onLinkClick.bind(this);
        this.onPostalCodeChangeHandler = this.onZipChange.bind(this);
        return this;
    },

    /**
     * Create any child objects or references to DOM elements
     * Should only be run on initialization of the view
     *
     * @method createChildren
     * @extends {BaseForm.createChildren}
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    createChildren() {
        _super.createChildren.call(this);
        this.$inputProfile = this.$element.find(CONST.SELECTOR.PROFILE_INPUT);
        this.$locationInputs = this.$element.find(
            CONST.SELECTOR.RELATED_LOCATION_INPUT
        );
        this.$sections = this.$element.find(CONST.SELECTOR.SECTION);
        this.$inputCountry = this.$element.find(CONST.SELECTOR.INPUT_COUNTRY);
        this.$inputState = this.$element.find(CONST.SELECTOR.INPUT_STATE);
        this.$inputPostalCode = this.$element.find(
            CONST.SELECTOR.INPUT_POSTAL_CODE
        );
        this.$inputErrors = this.$element.find(CONST.SELECTOR.ERRORS);
        this.$inputErrorPostalCode = this.$element.find(
            CONST.SELECTOR.ERROR_POSTAL_CODE
        );
        this.$labelPostalCode = this.$element.find(
            CONST.SELECTOR.LABEL_POSTAL_CODE
        );

        // caching all links on the document so that if a link navigating away from the page, a
        // new save cannot be initiated.  This prevents a bug with a race condition in firefox
        // in which a save appears to finish successfully but does not.
        this.$links = $('a');
        return this;
    },

    /**
     * Enables the view
     * Performs any event binding to handlers
     * Exits early if it is already enabled
     * Kicks off the slogan update lifecycle
     *
     * @method enable
     * @extends {BaseForm.enable}
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    enable() {
        _super.enable.call(this);

        // submit button is there is there is a JS error preventing autosave from working.
        this.$submitBtn.hide();

        return this;
    },

    /**
     * Sets up Event Listeners
     * Run during enabling of the component
     *
     * @method setupListeners
     * @extends {BaseForm.setupListeners}
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    setupListeners() {
        _super.setupListeners.call(this);
        this.$inputProfile.on('change', this.onInputChangeHandler);
        this.$inputCountry.on('change', this.onCountryChangeHandler);
        this.$inputPostalCode.on('change', this.onPostalCodeChangeHandler);

        this.$links.on('click', this.onLinkClickHandler);

        this.formEventBus
            .on('addPetBlock', this.onAddMarkupHandler)
            .on('inputPetSaveInitiated', this.onInputPetSaveInitiatedHandler)
            .on('inputPetSaveSuccess', this.onInputPetSaveSuccessHandler)
            .on('inputPetSaveFailed', this.onInputPetSaveFailedHandler);

        return this;
    },

    /**
     * Disable any event listeners for the view
     *
     * @method disable
     * @extends {BaseForm.disable}
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    disable() {
        _super.disable.call(this);
        return this;
    },

    /// ///////////////////////////////////////////////////////////////////////////////
    // HELPERS
    /// ///////////////////////////////////////////////////////////////////////////////

    /**
     * Builds the data object to be sent to the server.
     * Bundles fields to be patched with the token in the data object.
     *
     * @method buildDataToSendToServer
     * @private
     * @chainable
     * @param {jQuery} $inputs list of jQuery wrapped inputs to grab the
     * value from and push into data object
     * @returns {AboutMeForm}
     */
    async buildDataToSendToServer($inputs) {
        const data = {
            'user_extended_profile[_token]': await Config.userToken,
        };

        const $inputsLength = $inputs.length;

        for (let i = 0; i < $inputsLength; i++) {
            const input = $inputs[i];
            data[String(input.name)] = input.value;
        }
        return data;
    },

    /**
     * Builds the data object to be sent to the server.
     * Bundles fields to be patched with the token in the data object.
     *
     * @method buildDataToSendToServer
     * @param {jQuery} $inputs list of jQuery wrapped inputs to grab the
     * value from and push into data object
     * @param {string} sectionId the id of the section within which
     * @param {bool} [allowTokenRefreshRetry] A flag to control whether or not to allow a retry
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    async sendDataToServer($inputs, sectionId, allowTokenRefreshRetry = true) {
        const data = await this.buildDataToSendToServer($inputs);

        this.userRepository
            .saveData(data)
            .done(this.onSaveSuccess.bind(this, sectionId))
            .fail(error => {
                if (
                    allowTokenRefreshRetry &&
                    !error.responseJSON.errors.fields
                ) {
                    Config.refreshUserMe();
                    this.sendDataToServer($inputs, sectionId, false);
                    return;
                }

                this.onSaveFail(sectionId);
            });

        return this;
    },

    showErrorMessage($element, msg) {
        if (Array.isArray(msg)) {
            for (let i = 0; i < msg.length; i++) {
                const $div = $('<div></div>');
                $div.text(msg[i]);
                $element.append($div);
            }
        } else {
            $element.text(msg);
        }
        return this;
    },

    /**
     * Iterates over invalid inputs returned from server and shows appropriate error messages.
     * Run when an error response is returned from the server.
     *
     * @method setInputErrors
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    setInputErrors() {
        let errorMessage = '';
        let $input = null;
        let $inputError = null;
        let $label = null;

        // eslint-disable-next-line
        for (const input in this.invalidInputs) {
            errorMessage = this.invalidInputs[String(input)];
            $input = this.$element.find(`[name*="${input}"]`);
            const inputEscaped = input
                .split('[')
                .join('\\[')
                .split(']')
                .join('\\]');
            $inputError = this.$inputErrors.filter(`#${inputEscaped}_error`);
            $label = this.$element.find(`#${inputEscaped}_label`);

            this.setInvalidStyles($input, $label);

            this.showErrorMessage($inputError, errorMessage);
        }
        return this;
    },

    /**
     * Parses the jqXHR object returned from the server and caches responses on object.
     * Run whenever form submission fails.
     *
     * @method setInputErrors
     * @param {jQueryXHR} jqXHR jquery XHR Object
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    parsejqXHR(jqXHR) {
        const responseTextJSON = JSON.parse(jqXHR.responseText);
        this.invalidInputs = responseTextJSON.errors.fields;
        this.globalFormErrors = responseTextJSON.errors.fields;
        return this;
    },

    /**
     * Clears input errors. Run whenever a change has been made to an input field.
     *
     * @method clearErrors
     * @param {jQuery} $input the input that has been changed
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    clearErrors($input) {
        const name = $input.attr('name');

        // escapes the brackets [] in the name to allow jquery to find them
        const nameEscaped = name
            .split('[')
            .join('\\[')
            .split(']')
            .join('\\]');

        const $label = this.$element.find(`#${nameEscaped}_label`);
        const $error = this.$element.find(`#${nameEscaped}_error`);

        this.clearInvalidStyles($input, $label);
        $error.html('');
        return this;
    },

    /**
     * Tests for whether the postal code is valid for the country
     * run when a related field is changed
     *
     * @method isPostalCodeValidForCountry
     * @param {string} countryCode current country code value
     * @param {string} postalCode current postalcode value
     * @private
     * @returns {bool}
     */
    isPostalCodeValidForCountry(countryCode, postalCode) {
        return this.countryPostalCodePatternMatcher.isPostalCodeValidForCountry(
            countryCode,
            postalCode
        );
    },

    /**
     * Whenever a save has been initiated, show a graphic on that input section
     *
     * @method showSavingInitiatedIndicator
     * @param {string} sectionId selector for the fieldset the input is within
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    showSavingInitiatedIndicator(sectionId) {
        this.formEventBus.trigger(CONST.EVENT.SAVE_INITIATED, sectionId);
        return this;
    },

    /**
     * Special handling for the postal code error due to the change if
     * language based on whether the country selected is US or not
     *
     * @method showSavingInitiatedIndicator
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    setPostalCodeErrorMessage() {
        if (
            this.$inputCountry.val() === 'US' ||
            this.$inputCountry.val() === 'us'
        ) {
            this.postalCodeTxt = this.i18nModel[
                CONST.I18N.ERROR_US_POSTAL_CODE_VALIDATION
            ];
        } else {
            this.postalCodeTxt = this.i18nModel[
                CONST.I18N.ERROR_NON_US_POSTAL_CODE_VALIDATION
            ];
        }
        return this;
    },

    /**
     * Run when a client-side validator or server-side validation
     * fails to indicate top the user an invalid field
     *
     * @method setInvalidStyles
     * @param {jQuery} $input to set the invalid style on
     * @param {jQuery} $label to set the invalid style on
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    setInvalidStyles($input, $label) {
        $input.addClass(CONST.CLASSES.ERROR_INPUT);
        $label.addClass(CONST.CLASSES.ERROR_LABEL);
        return this;
    },

    /**
     * Run when a client-side validator or server-side validation
     * fails to indicate top the user an invalid field
     *
     * @method setInvalidStyles
     * @param {jQuery} $input to set the invalid style on
     * @param {jQuery} $label to set the invalid style on
     * @private
     * @chainable
     * @returns {AboutMeForm}
     */
    clearInvalidStyles($input, $label) {
        $input.removeClass(CONST.CLASSES.ERROR_INPUT);
        $label.removeClass(CONST.CLASSES.ERROR_LABEL);
        return this;
    },

    /// ///////////////////////////////////////////////////////////////////////////////
    // HELPERS
    /// ///////////////////////////////////////////////////////////////////////////////

    /**
     * On change of any input except for related location inputs, gets the input and
     * the section ID and kicks off sending the input value to the server and showing
     * loading indicators.
     *
     * @method onInputChange
     * @param {Event} event
     * @private
     */
    onInputChange(event) {
        const $input = $(event.currentTarget);
        const sectionId = $input.closest(CONST.SELECTOR.SECTION).attr('id');
        this.clearErrors($input)
            .showSavingInitiatedIndicator(sectionId)
            .sendDataToServer($input, sectionId);
    },

    /**
     * On change of the country, save country, state, and zip because
     * country changes clears out state and zip values
     *
     * @method onCountryChange
     * @param {Event} event
     * @private
     */
    onCountryChange(event) {
        const $input = $(event.currentTarget);
        const countryCode = $input.val();

        if (countryCode === COUNTRY_NOT_LISTED_VALUE) {
            event.preventDefault();
            return;
        }

        const postalCode = this.$inputPostalCode.val();

        this.$inputState.val('');
        this.clearErrors($input);
        this.clearErrors(this.$inputPostalCode);
        if (
            !this.isPostalCodeValidForCountry(countryCode, postalCode) ||
            this.$inputPostalCode.val() === ''
        ) {
            this.$inputPostalCode.val('');
        }
        const sectionId = $input.closest(CONST.SELECTOR.SECTION).attr('id');

        this.showSavingInitiatedIndicator(sectionId).sendDataToServer(
            this.$locationInputs,
            sectionId
        );
    },

    /**
     * On change zip, check for validity and send if valid.
     *
     * @method onCountryChange
     * @param {Event} event
     * @private
     */
    onZipChange(event) {
        const $input = $(event.currentTarget);
        const postalCode = this.$inputPostalCode.val();
        const countryCode = this.$inputCountry.val();
        this.clearErrors($input);
        if (
            this.isPostalCodeValidForCountry(countryCode, postalCode) ||
            this.$inputPostalCode.val() === ''
        ) {
            const sectionId = $input.closest(CONST.SELECTOR.SECTION).attr('id');
            this.showSavingInitiatedIndicator(sectionId).sendDataToServer(
                this.$locationInputs,
                sectionId
            );
        } else {
            this.setPostalCodeErrorMessage()
                .showErrorMessage(
                    this.$inputErrorPostalCode,
                    this.postalCodeTxt
                )
                .setInvalidStyles($input, this.$labelPostalCode);
        }
    },

    /**
     * On successful save of profile data, trigger the save
     * complete event to change the saving indicator
     *
     * @method onSaveSuccess
     * @param {string} sectionId the id of the section in which the saved inputs reside
     * @private
     */
    onSaveSuccess(sectionId) {
        this.formEventBus.trigger('saveComplete', sectionId);
    },

    /**
     * On failed save, trigger the event for a failed save.
     * If its not a 400 error, its a catastrophic saving error and the view is shut down.
     * A submit button is shown to allow saving of form data via normal methods.
     *
     * @method onSaveFailed
     * @param {string} sectionId the id of the section in which the saved inputs reside
     * @param {jQueryXHR} jqXHR jquery XHR Object
     * @private
     */
    onSaveFail(sectionId, jqXHR) {
        if (!this.isLinkClicked) {
            this.formEventBus.trigger(CONST.EVENT.SAVE_FAILED, sectionId);
        }
        if (jqXHR.status !== 400) {
            this.$submitBtn.show();
            this.destroy();
        } else {
            this.parsejqXHR(jqXHR).setInputErrors();
        }
    },

    /**
     * Run when a new set of pet inputs is inserted and enables components within it.
     * For example, enable the autosaving pet input block,
     * as well as validation components within it.
     *
     * @method onSaveFailed
     * @param {Event} event triggering the handler
     * @param {jQuery} $element that the markup is being inserted into
     * @private
     */
    onAddMarkup(event, $element) {
        BootstrapUtility.bootstrap(
            this.componentList,
            $element,
            this.formEventBus
        );
        this.enableComponents();
    },

    /**
     * Run when a pet input has been saved successfully via AJAX
     *
     * @method onInputPetSaveSuccess
     * @private
     */
    onInputPetSaveSuccess() {
        this.formEventBus.trigger(CONST.EVENT.SAVE_COMPLETE, 'About_Me_Pets');
    },

    /**
     * Run when an input in a pet block is changed
     *
     * @method onInputPetSaveInitiated
     * @private
     */
    onInputPetSaveInitiated() {
        this.formEventBus.trigger(CONST.EVENT.SAVE_INITIATED, 'About_Me_Pets');
    },

    /**
     * Run when a pet input field has not saved successfully
     *
     * @method onInputPetSaveFailed
     * @private
     */
    onInputPetSaveFailed() {
        this.formEventBus.trigger(CONST.EVENT.SAVE_FAILED, 'About_Me_Pets');
    },

    /**
     * Run when a link is clicked and sets a flag to prevent any further saves from being initiated.
     * This prevents a Firefox bug where a save is indicated successfully but is not.
     *
     * @method onSaveFailed
     * @param {Event} event triggering the handler
     * @private
     */
    onLinkClick(event) {
        this.isLinkClicked = true;
    },
});

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

AboutMeForm.SELECTORS = {};

AboutMeForm.SELECTORS.ELEMENT = '.js-aboutMeForm';

const CONST = {
    EVENT: {
        SAVE_FAILED: 'saveFailed',
        SAVE_COMPLETE: 'saveComplete',
        SAVE_INITIATED: 'saveInitiated',
    },
    I18N: {
        ERROR_US_POSTAL_CODE_VALIDATION: 'formErrorZipCode',
        ERROR_NON_US_POSTAL_CODE_VALIDATION: 'formErrorZipCodeNonUs',
    },
    SELECTOR: {
        RELATED_LOCATION_INPUT: `${
            AboutMeForm.SELECTORS.ELEMENT
        }-inputRelatedLocation`,
        PROFILE_INPUT: `${AboutMeForm.SELECTORS.ELEMENT}-inputProfile`,
        SECTION: `${AboutMeForm.SELECTORS.ELEMENT}-section`,
        INPUT_COUNTRY: `${AboutMeForm.SELECTORS.ELEMENT}-inputCountry`,
        INPUT_STATE: `${AboutMeForm.SELECTORS.ELEMENT}-inputState`,
        INPUT_POSTAL_CODE: `${AboutMeForm.SELECTORS.ELEMENT}-inputPostalCode`,
        ERROR_POSTAL_CODE: `${AboutMeForm.SELECTORS.ELEMENT}-errorPostalCode`,
        LABEL_POSTAL_CODE: `${AboutMeForm.SELECTORS.ELEMENT}-labelPostalCode`,
        ERRORS: `${AboutMeForm.SELECTORS.ELEMENT}-error`,
    },
    CLASSES: {
        ERROR_INPUT: 'm-field_error',
        ERROR_LABEL: 'label_error',
    },
};

export default AboutMeForm;
