/**
 * @fileOverview Base Model File
 * @author Noah Blon nblon@nerdery.com
 */

var uid = 0;

/**
 * Returns a unique id to identify each instance of the model
 *
 * @for BaseModel
 * @method getId
 * @private
 */
function getId() {
    return uid++;
}

/**
 * Abstract model object tp inherit from
 * Stores data about the Game
 *
 * @class BaseModel
 * @constructor
 *
 */
var BaseModel = function() { };

/**
 * Reference to BaseModel prototype
 *
 * @for BaseModel
 * @property proto
 * @type {Object}
 */
var proto = BaseModel.prototype;

proto.init = function() {
    /**
     * Unique identifier of the instance
     * Best used for identifying debugging issues
     *
     * @for BaseModel
     * @property uid
     * @type {number}
     */
    this.uid = getId();

    return this;
};

/**
 * Destroys the model
 * Should be called when the object should be left unused
 *
 * @for BaseModel
 * @method destroy
 * @chainable
 * @returns {BaseModel.prototype}
 */
proto.destroy = function() {
    for (var key in this) {
        if (this.hasOwnProperty(key)) {
            this[key] = null;
        }
    }

    return this;
};

proto.setProperty = function setProperty(key, val) {
    this[key] = val;
};

proto.getProperty = function getProperty(key) {
    return this.key;
};

export default BaseModel;
