import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import UserPetRepository from '../../../../core/scripts/repositories/UserPetRepository';
import BreedRepository from '../../../../core/scripts/repositories/BreedRepository';
const I18n = require('../../../../core/scripts/data/i18nJSON.json');

import $ from 'jquery';

var PetInputBlockAutoSave = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PetInputBlockAutoSave.prototype = new BaseComponent();
var proto = PetInputBlockAutoSave.prototype;
proto.constructor = PetInputBlockAutoSave;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
PetInputBlockAutoSave.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PetInputBlockAutoSave.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.ELEMENT = '.js-petInputBlockAutoSave';

/**
 * Selector for the pet type select list. Changing this loads in the breeds for the pet type.
 *
 * @property SELECTORS.INPUT_PET_TYPE
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_PET_TYPE =
    '.js-petInputBlock-inputPetType';

/**
 * Selector for the hidden pet id input.
 *
 * @property SELECTORS.INPUT_PETID
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_PETID = '.js-petInputBlock-inputPetId';

/**
 * Selector for the breeds select lists.
 *
 * @property SELECTORS.INPUT_BREEDS
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_BREEDS = '.js-petInputBlock-inputBreeds';

/**
 * Selector for the primary breed select list
 *
 * @property SELECTORS.INPUT_PRIMARY_BREED
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_PRIMARY_BREED =
    '.js-petInputBlock-inputPrimaryBreed';

/**
 * Selector for the secondary breed select list.
 *
 * @property SELECTORS.INPUT_SECONDARY_BREED
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_SECONDARY_BREED =
    '.js-petInputBlock-inputSecondaryBreed';

/**
 * Selector for the birth month select list
 *
 * @property SELECTORS.INPUT_BREEDS
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_BIRTH_MONTH =
    '.js-petInputBlock-inputBirthMonth';

/**
 * Selector for the birth year select list
 *
 * @property SELECTORS.INPUT_BIRTH_YEAR
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_BIRTH_YEAR =
    '.js-petInputBlock-inputBirthYear';

/**
 * Selector for the acquisition method select list.
 *
 * @property SELECTORS.INPUT_ACQUISITION_METHOD
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.INPUT_ACQUISITION_METHOD =
    '.js-petInputBlock-inputAcquisitionMethod';

/**
 * The delete button
 *
 * @property SELECTORS.BTN_DELETE
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.BTN_DELETE = '.js-petInputBlock-btnDelete';

/**
 * Selector for the pet token input
 *
 * @property SELECTORS.PET_TOKEN
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.PET_TOKEN = '.js-aboutMeForm-petToken';

/**
 * Selector for the loading indicator
 *
 * @property SELECTORS.THROBBER
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.SELECTORS.THROBBER = '.js-petInputBlock-throbber';

/**
 * Is hidden class
 *
 * @property CLASSES.IS_HIDDEN
 * @static
 * @final
 * @type {String}
 */
PetInputBlockAutoSave.CLASSES.IS_HIDDEN = 'u-isHidden';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.petModel = {};
    this.petId = null;
    this.petToken = '';
    this.isPetCreated = false;
    this.userPetRepository = new UserPetRepository();
    this.breedRepository = new BreedRepository();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangeInputPetTypeHandler = this.onChangeInputPetType.bind(this);
    this.onChangeNonInputPetTypeHandler = this.onChangeNonInputPetType.bind(
        this
    );
    this.onGetBreedsForPetTypeSuccessHandler = this.onGetBreedsForPetTypeSuccess.bind(
        this
    );
    this.onGetBreedsForPetTypeFailedHandler = this.onGetBreedsForPetTypeFailed.bind(
        this
    );
    this.onCreatePetSuccessHandler = this.onCreatePetSuccess.bind(this);
    this.onCreatePetFailedHandler = this.onCreatePetFailed.bind(this);
    this.onEditPetSuccessHandler = this.onEditPetSuccess.bind(this);
    this.onEditPetFailedHandler = this.onEditPetFailed.bind(this);
    this.onDeletePetSuccessHandler = this.onDeletePetSuccess.bind(this);
    this.onDeletePetFailedHandler = this.onDeletePetFailed.bind(this);
    this.onClickBtnDeleteHandler = this.onClickBtnDelete.bind(this);
    this.onUpdatePetCompleteHandler = this.onUpdatePetComplete.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputs = this.$element.find(':input').not('button');
    this.$nonPetTypeInputs = this.$inputs.not(
        PetInputBlockAutoSave.SELECTORS.INPUT_PET_TYPE
    );
    this.$inputBreeds = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_BREEDS
    );
    this.$inputPetType = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_PET_TYPE
    );
    this.$inputPetId = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_PETID
    );
    this.$inputBreedPrimary = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_PRIMARY_BREED
    );
    this.$inputBreedSecondary = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_SECONDARY_BREED
    );
    this.$inputBirthMonth = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_BIRTH_MONTH
    );
    this.$inputBirthYear = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_BIRTH_YEAR
    );
    this.$inputAcquisitionMethod = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.INPUT_ACQUISITION_METHOD
    );
    this.$btnDelete = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.BTN_DELETE
    );
    this.$throbber = this.$element.find(
        PetInputBlockAutoSave.SELECTORS.THROBBER
    );

    // Token is in an input at the form level, not within the component.
    this.$inputPetToken = $(PetInputBlockAutoSave.SELECTORS.PET_TOKEN);
    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.cachePetId()
        .cachePetToken()
        .setIsPetCreated()
        .setInitialInputStates()
        .setupListeners();

    return this;
};

/**
 * Cache the pet id on the object.
 * Run on enabling of the component.
 *
 * @public
 * @chainable
 */
proto.cachePetId = function cachePetId() {
    this.petId = this.$inputPetId.val();
    return this;
};

/**
 * Cache the pet token on the object
 * Run on enabling of the component.
 *
 * @public
 * @chainable
 */
proto.cachePetToken = function cachePetToken() {
    this.petToken = this.$inputPetToken.val();
    return this;
};

/**
 * Sets the flag for whether the pet has been created.
 * This is determined by whether the pet has an ID.
 * This value is used to branch logic for whethere a change to an input should create a new pet record
 * or update an existing one.
 *
 * @public
 * @chainable
 */
proto.setIsPetCreated = function setIsPetCreated() {
    this.isPetCreated = this.petId !== '';
    return this;
};

/**
 * Set initial input states. If the pet doesn't have a type, its a new pet.
 * Run on enabling of the component.
 *
 * @public
 * @chainable
 */
proto.setInitialInputStates = function setInitialInputStates() {
    if (this.isPetCreated) {
        this.enableInputs(this.$nonPetTypeInputs);
    } else {
        this.disableInputs(this.$nonPetTypeInputs);
    }
    return this;
};

/**
 * Setsup event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$nonPetTypeInputs.on('change', this.onChangeNonInputPetTypeHandler);
    this.$inputPetType.on('change', this.onChangeInputPetTypeHandler);
    this.$btnDelete.on('click', this.onClickBtnDeleteHandler);
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$nonPetTypeInputs.off('change', this.onChangeInputHandler);
    this.$inputPetType.off('change', this.onChangeInputPetTypeHandler);
    this.$btnDelete.off('click', this.onClickBtnDeleteHandler);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Enables an input
 *
 * @method enableInput
 * @param input to enable
 * @public
 * @chainable
 */
proto.enableInput = function enableInput(index, input) {
    input.removeAttribute('disabled');
};

/**
 * Disables an input
 *
 * @method disableInput
 * @param input to disable
 * @public
 * @chainable
 */
proto.disableInput = function disableInput(index, input) {
    input.setAttribute('disabled', 'disabled');
};

/**
 * Disables a set of inputs
 *
 * @method disableInputs
 * @param $inputs Jquery wrapped
 * @public
 * @chainable
 */
proto.disableInputs = function disableInputs($inputs) {
    $inputs.each(this.disableInput);
    return this;
};

/**
 * Enables a set of inputs
 *
 * @method enableInputs
 * @param $inputs Jquery wrapped
 * @public
 * @chainable
 */
proto.enableInputs = function enableInputs($inputs) {
    $inputs.each(this.enableInput);
    return this;
};

/**
 * AJAX request for pets breeds according to pet type.
 * Run when the pet type input is changed.
 *
 * @method getBreedForPetType
 * @param petTypeId corresponding to the pet type
 * @private
 */
proto.getBreedForPetType = function getBreedForPetType(petTypeId) {
    return this.breedRepository.getBreedsByPetType(petTypeId);
};

/**
 * Send the petmodel to the server and update the existing petZ
 * Run when an input is changed and the pet has already been created.
 *
 * @method editPet
 * @private
 * @return jqXHR promise object
 */
proto.editPet = function editPet() {
    return this.userPetRepository.editPet(this.petId, this.petModel);
};

/**
 * Send the petmodel to the server and creates the pet if it has not already been created.
 * Run whenever the pet input type has been changed and the pet has not been created yet.
 *
 * @method createPet
 * @private
 */
proto.createPet = function createPet() {
    return this.userPetRepository.createPet(this.petModel);
};

/**
 * Requests a deletion of a user pet from the server
 * Run when the delete button has been clicked and the prompt has been approved.
 *
 * @method deletePet
 * @private
 */
proto.deletePet = function deletePet() {
    return this.userPetRepository.deletePet(this.petId, this.petToken);
};

/**
 * Appends a default select value to the breed list.
 *
 * @method appendDefaultBreedValue
 * @private
 * @chainable
 */
proto.appendDefaultBreedValue = function appendDefaultBreedValue() {
    this.$inputBreeds.append(
        '<option value="">' +
            I18n['user.add_a_pet_block.default_breed_select_txt'] +
            '</option>'
    );
    return this;
};

/**
 * Appends a breed value to the breed select list.
 *
 * @method appendBreedValue
 * @param breed object containing id and name
 * @private
 * @chainable
 */
proto.appendBreedValue = function appendBreedValue(breedModel) {
    this.$inputBreeds.append(
        '<option value="' +
            breedModel.breedId +
            '">' +
            breedModel.name +
            '</option>'
    );
};

/**
 * Appends the breed values for the selected pet type to the select list.
 * Run on a successful get for breeds according to pet type.
 *
 * @method appendBreedValues
 * @param petBreeds for pet type returned from server
 * @private
 * @chainable
 */
proto.appendBreedValues = function appendBreedValues(breedCollection) {
    breedCollection.breeds.forEach(breed => this.appendBreedValue(breed));
    return this;
};

/**
 * Empties the breed select inputs.
 * Run on a successful get for breeds according to pet type to reset the field.
 *
 * @method emptyBreedSelects
 * @private
 * @chainable
 */
proto.emptyBreedSelects = function emptyBreedSelects() {
    this.$inputBreeds.empty();
    return this;
};

/**
 * Gets the pet type id.
 *
 * @method getPetTypeId
 * @private
 * @chainable
 */
proto.getPetTypeId = function getPetTypeId() {
    return this.$inputPetType.val();
};

/**
 * Sets the pet id
 *
 * @method setPetId
 * @private
 * @chainable
 */
proto.setPetId = function setPetId(value) {
    this.$inputPetId.val(value);
};

/**
 * Builds the pet model that will be sent to the server for processing.
 * Run whenever an input has changed.
 *
 * @method buildPetModel
 * @private
 * @chainable
 */
proto.buildPetModel = function buildPetModel() {
    this.petModel = {
        'user_pet[_token]': this.petToken,
        'user_pet[type]': this.$inputPetType.val(),
        'user_pet[acquisitionMethod]': this.$inputAcquisitionMethod.val(),
        'user_pet[primaryBreed]': this.$inputBreedPrimary.val(),
        'user_pet[secondaryBreed]': this.$inputBreedSecondary.val(),
        'user_pet[birthDate][year]': this.$inputBirthYear.val(),
        'user_pet[birthDate][month]': this.$inputBirthMonth.val(),
    };
    return this;
};

/**
 * Show the loading indicator while interacting with the server
 *
 * @method showThrobber
 * @private
 * @chainable
 */
proto.showThrobber = function showThrobber() {
    this.$throbber.removeClass(PetInputBlockAutoSave.CLASSES.IS_HIDDEN);
    return this;
};

/**
 * Hide the loading indicator when finished interacting with the server
 *
 * @method hideThrobber
 * @private
 * @chainable
 */
proto.hideThrobber = function hideThrobber() {
    this.$throbber.addClass(PetInputBlockAutoSave.CLASSES.IS_HIDDEN);
    return this;
};

proto.doesOnlyBirthMonthHaveValue = function doesOnlyBirthMonthHaveValue() {
    return (
        this.$inputBirthMonth.val() == '' && this.$inputBirthYear.val() != ''
    );
};

proto.showPetSavingSectionIndicator = function showPetSavingSectionIndicator() {
    this.eventBus.trigger('inputPetSaveInitiated');
    return this;
};

proto.showInputErrors = function showInputErrors() {
    var errorMessage = '';
    var $input = {};
    var $inputError = {};
    var $label = {};

    for (var input in this.invalidInputs) {
        errorMessage = this.invalidInputs['' + input + ''];
        $input = this.$element.find('[name*="' + input + '"]');
        var inputEscaped = input
            .split('[')
            .join('\\[')
            .split(']')
            .join('\\]');
        $inputError = this.$inputErrors.filter('#' + inputEscaped + '_error');
        $label = this.$element.find('#' + inputEscaped + '_label');

        this.addInvalidInputStyle($input);
        this.addInvalidLabelStyle($label);

        this.showErrorMessage($inputError, errorMessage);
    }
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the pet type input, disables input and kicks off request for pet breeds according to
 * that pet and population of the pet breed inputs.
 *
 * @method onChangeInputPetType
 * @param event to grab the input's value
 * @private
 */
proto.onChangeInputPetType = function onChangeInputPetType(event) {
    var petTypeId = event.currentTarget.value;
    if (petTypeId != '') {
        this.disableInputs(this.$nonPetTypeInputs)
            .showThrobber()
            .getBreedForPetType(petTypeId)
            .then(
                this.onGetBreedsForPetTypeSuccessHandler,
                this.onGetBreedsForPetTypeFailedHandler
            );
    } else {
        this.hideThrobber()
            .emptyBreedSelects()
            .appendDefaultBreedValue()
            .enableInputs(this.$nonPetTypeInputs);
        // Set a short timeout to not immediately flash a failed message
        var self = this;
        setTimeout(function() {
            self.eventBus.trigger('inputPetSaveFailed');
        }, 100);
    }
};

/**
 * On successful get of pet breeds, appends the values to appropriate select lists and reenables input.
 * Also begins the editing and creation flows for the pet.
 *
 * @method onChangeInputPetType
 * @param breedsForPetTypeJSON returned from the server
 * @private
 */
proto.onGetBreedsForPetTypeSuccess = function(breedCollection) {
    this.emptyBreedSelects()
        .appendDefaultBreedValue()
        .appendBreedValues(breedCollection)
        .buildPetModel()
        .showPetSavingSectionIndicator();
    if (this.isPetCreated) {
        this.editPet()
            .then(this.onEditPetSuccessHandler, this.onEditPetFailedHandler)
            .always(this.onUpdatePetCompleteHandler);
    } else {
        this.createPet()
            .then(this.onCreatePetSuccessHandler, this.onCreatePetFailedHandler)
            .always(this.onUpdatePetCompleteHandler);
    }
};

/**
 * On a change of an input thats not the pet type, the pet is edited
 *
 * @method onChangeNonInputPetType
 * @private
 */
proto.onChangeNonInputPetType = function onChangeNonInputPetType() {
    if (!this.doesOnlyBirthMonthHaveValue()) {
        this.showPetSavingSectionIndicator()
            .disableInputs(this.$inputs)
            .buildPetModel()
            .editPet()
            .then(this.onEditPetSuccessHandler, this.onEditPetFailedHandler)
            .always(this.onUpdatePetCompleteHandler);
    } else {
        this.onUpdatePetFailed();
    }
};

/**
 * On click of the delete button, the pet is deleted from the database.
 * If the pet has not been created yet, just wipe out the block of inputs
 *
 * @method onClickBtnDelete
 * @param event Javascript
 * @private
 */
proto.onClickBtnDelete = function onClickBtnDelete(event) {
    // TODO:
    // because the element is removed if the pet hasnt been created, the ensighten class is not able
    // to fire this event.  Fix by refactoring to listen for the event on the event bus.
    event.preventDefault();
    if (window.confirm('Are you sure you would like to remove this pet?')) {
        if (window.purinaTrackEvent !== undefined) {
            window.purinaTrackEvent({
                eventCategory: 'about me',
                eventAction: 'remove a pet',
            });
        }
        if (this.isPetCreated) {
            this.disableInputs(this.$inputs)
                .showThrobber()
                .deletePet()
                .then(
                    this.onDeletePetSuccessHandler,
                    this.onDeletePetFailedHandler
                )
                .always(this.onUpdatePetCompleteHandler);
        } else {
            this.$element.remove();
            this.eventBus.trigger('petInputBlockDeleted');
        }
    }
};

/**
 * On a successful edit pet response, inputs are reenabled and successful messages dispatched
 *
 * @method onEditPetSuccess
 * @private
 */
proto.onEditPetSuccess = function onEditPetSuccess() {
    this.eventBus.trigger('inputPetSaveSuccess');
};

/**
 * On a failed edit pet response, messages are dispatched to the form
 *
 * @method onEditPetFailed
 * @private
 */
proto.onEditPetFailed = function onEditPetFailed(jqXHR) {
    var responseText = JSON.parse(jqXHR['responseText']);

    this.eventBus.trigger('inputPetSaveFailed');
};

/**
 * On a successful creation of the pet, enable inputs and trigger success events
 *
 * @method onEditPetFailed
 * @private
 */
proto.onCreatePetSuccess = function onCreatePetSuccess(json) {
    this.petId = json.id;
    this.isPetCreated = true;
    this.eventBus.trigger('inputPetSaveSuccess');
};

/**
 * On failed creation of the pet, trigger unsuccessful events
 *
 * @method onCreatePetFailed
 * @private
 */
proto.onCreatePetFailed = function onCreatePetFailed() {
    this.eventBus.trigger('inputPetSaveFailed');
};

/**
 * On successful deletion, remove the input block
 *
 * @method onCreatePetFailed
 * @private
 */
proto.onDeletePetSuccess = function onDeletePetSuccess() {
    this.$element.remove();
    this.eventBus.trigger('petInputBlockDeleted');
};

/**
 * On failed deletion, show and error message and trigger form events
 *
 * @method onCreatePetFailed
 * @private
 */
proto.onDeletePetFailed = function onDeletePetFailed() {
    this.eventBus.trigger('inputPetDeleteFailed');
    this.$element.html(I18n['user.add_a_pet_block.deletion_error_message']);
};

/**
 * On a failed get for breeds, emits event to let form handle next steps.
 *
 * @method onGetBreedsForPetTypeFailed
 * @private
 * @chainable
 */
proto.onGetBreedsForPetTypeFailed = function() {
    this.eventBus.trigger('getPetBreedsForTypeFailed');
};

/**
 * On any update completion, hide the loader
 *
 * @method onUpdatePetComplete
 * @private
 */
proto.onUpdatePetComplete = function onUpdatePetComplete() {
    this.enableInputs(this.$inputs).hideThrobber();
};

export default PetInputBlockAutoSave;
