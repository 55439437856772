import { trackEvent } from '../../analytics';

/**
 * About Me Form Analytic tracking component.
 *
 * @class BaseEnsightenAnalytics
 * @constructor
 */
var BaseEnsightenAnalytics = function($element, eventBus) {};

var proto = BaseEnsightenAnalytics.prototype;

var CONST = {
    TRIGGER_ATTEMPTS: 20,
    TRIGGER_REATTEMPT_INTERVAL: 1000,
};

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    /**
     * An HTMLElement wrapped in jQuery that acts as the main element for this view instance.
     *
     * @property $element
     * @type {jQuery}
     */
    this.$element = $element;

    /**
     * Event Bus tracking events throughout components in the app.
     *
     * @property eventBus
     * @type {Object}
     */
    this.eventBus = eventBus;

    /**
     * Counter tracking how many attempts to fire the purinaTrackEvent method.
     *
     * @property eventBus
     * @type {Object}
     */
    this.attempts = 0;

    return this.setupEnableHandler().beforeEnable();
};

/**
 * Binds the scope of the handler listening for the component enabling event
 *
 * @method setupEnableHandler
 * @private
 * @chainable
 */
proto.setupEnableHandler = function setupEnableHandler() {
    this.onEnableHandler = this.onEnable.bind(this);
    return this;
};

/**
 * Method to execute before enabling the component.
 * Listens for the enable component event.
 *
 * @method setupEnableHandler
 * @private
 * @chainable
 */
proto.beforeEnable = function beforeEnable() {
    this.eventBus.on('enableComponents', this.onEnableHandler);
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    if (this.isEnabled) {
        return;
    }
    this.isEnabled = true;
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    return this;
};

/**
 * Destroys the view
 *
 * @method destroy
 * @public
 \     */
proto.destroy = function destroy() {
    this.disable();
    for (var key in this) {
        if (this.hasOwnProperty(key)) {
            this[key] = null;
        }
    }
};

/**
 * Fires the purina track event method.
 * If its not available (script hasnt loaded), gives it 6 tries over 3 seconds and destroys in
 * doesn't becone available.
 *
 * @method triggerEvent
 * @private
 */
proto.triggerEvent = function triggerEvent(components) {
    trackEvent(components);
};

/**
 * Event Handler for the enable component event
 * Runs the enable method
 *
 * @method onEnable
 * @private
 */
proto.onEnable = function onEnable() {
    if (this.isEnabled) {
        return;
    }
    this.enable();
    return this;
};

export default BaseEnsightenAnalytics;
