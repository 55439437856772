import BaseComponent from '../../components/BaseComponent/BaseComponent';
const I18nJSON = require('../../data/i18nJSON.json');

var RequiredCheckboxValidator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
RequiredCheckboxValidator.prototype = new BaseComponent();
var proto = RequiredCheckboxValidator.prototype;
proto.constructor = RequiredCheckboxValidator;
proto._super = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

RequiredCheckboxValidator.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
RequiredCheckboxValidator.SELECTORS.ELEMENT = '.js-validatorRequiredCheckbox';

RequiredCheckboxValidator.SELECTORS.FIELD = '.js-validatorRequiredCheckbox-field';

RequiredCheckboxValidator.SELECTORS.ERROR = '.js-validatorRequiredCheckbox-error';

RequiredCheckboxValidator.SELECTORS.LABEL = '.js-validatorRequiredCheckbox-label';

RequiredCheckboxValidator.SELECTORS.ERROR_MESSAGE_KEY = 'user.form.field_required_error_message';


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

proto.init = function init($element, eventBus, id) {
    this._super.init.call(this, $element, eventBus);

    /* @todo Add as translation
    this.errorMessage = I18nJSON[RequiredCheckboxValidator.SELECTORS.ERROR_MESSAGE_KEY];
    */
    this.errorMessage = 'This option may not be left unchecked.';

    this.hasBeenInteractedWith = false;

    this.setupHandlers()
        .createChildren();

    this.id = this.$input.attr('id');
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onBlurHandler = this.onBlur.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    this.onCheckValidatorValidationHandler = this.onCheckValidatorValidation.bind(this);
    return this;
};

/**
 * Create the children for the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$label = this.$element.find(RequiredCheckboxValidator.SELECTORS.LABEL);
    this.$input = this.$element.find(RequiredCheckboxValidator.SELECTORS.FIELD);
    this.$error = this.$element.find(RequiredCheckboxValidator.SELECTORS.ERROR);
    return this;
};

/**
 * Enables the view
 * PerLoginForms any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$input.on('blur change', this.onBlurHandler);
    this.eventBus.on('submit', this.onSubmitHandler)
        .on('checkValidatorValidation', this.onCheckValidatorValidationHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);

    this.onBlurHandler = null;

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.setInputInvalidStyles = function setFieldInvalidStyles() {
    if (this.hasBeenInteractedWith) {
        this.$input.addClass('m-field_error');
    }
    return this;
};

proto.clearInputInvalidStyles = function clearInputInvalidStyles() {
    this.$input.removeClass('m-field_error');
    return this;
};

proto.setLabelInvalidStyles = function setLabelInvalidStyles() {
    if (this.hasBeenInteractedWith) {
        this.$label.addClass('label_error');
    }
    return this;
};

proto.clearLabelInvalidStyles = function clearLabelInvalidStyles() {
    this.$label.removeClass('label_error');
    return this;
};

proto.showErrorMessage = function showErrorMessage() {
    if (this.hasBeenInteractedWith) {
        this.$error.empty()
            .html(this.errorMessage);
    }
    return this;
};

proto.hideErrorMessage = function hideErrorMessage() {
    this.$error.empty();
    return this;
};

proto.inputHasValue = function inputHasValue() {
    return this.$input.is(':checked');
};

proto.dispatchSuccessfulValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('successfulValidatorValidation', this);
    return this;
};

proto.dispatchFailedValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('failedValidatorValidation', this);
    return this;
};

proto.checkFieldValidity = function checkFieldValidity() {
    if (this.inputHasValue()) {
        this.dispatchSuccessfulValidatorValidationEvents()
            .hideErrorMessage()
            .clearInputInvalidStyles()
            .clearLabelInvalidStyles();
    } else {
        this.dispatchFailedValidatorValidationEvents()
            .showErrorMessage()
            .setInputInvalidStyles()
            .setLabelInvalidStyles();
    }
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Event handler for the country select box changing
 */
proto.onBlur = function(event) {
    event.preventDefault();
    this.hasBeenInteractedWith = true;
    setTimeout(() => {
        this.checkFieldValidity();
    }, 0);
};

proto.onSubmit = function() {
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

proto.onCheckValidatorValidation = function onCheckValidatorValidation(event, validatorElementId) {
    if (validatorElementId == this.id) {
        this.hasBeenInteractedWith = true;
        this.checkFieldValidity();
    }
};

export default RequiredCheckboxValidator;
