import initHypernovaRenderReact from '../utils/hypernova-render-react';

import CopyToClipboard from '../../react-components/specifics/CopyToClipboard';
import ShowCopySecret from '../../react-components/specifics/ShowCopySecret';
import ForDevelopersHeader from '../../react-components/specifics/ForDevelopersHeader';
import SocialSignInButton from '../../react-components/specifics/SocialLogin/index';
import HappyTailsModuleOrgPage from '../../react-components/specifics/HappyTailsModuleOrgPage';

export default function initReactServerComponents() {
    const components = {
        CopyToClipboard,
        ShowCopySecret,
        ForDevelopersHeader,
        SocialSignInButton,
        HappyTailsModuleOrgPage,
    };

    initHypernovaRenderReact(components);
}
