import BaseComponent from '../../components/BaseComponent/BaseComponent';
const I18nJSON = require('../../data/i18nJSON.json');

var RequiredDateValidator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
RequiredDateValidator.prototype = new BaseComponent();
var proto = RequiredDateValidator.prototype;
proto.constructor = RequiredDateValidator;
proto._super = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

RequiredDateValidator.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
RequiredDateValidator.SELECTORS.ELEMENT = '.js-validatorDateRequired';

RequiredDateValidator.SELECTORS.FIELD = '.js-validatorDateRequired-field';

RequiredDateValidator.SELECTORS.ERROR = '.js-validatorDateRequired-error';

RequiredDateValidator.SELECTORS.LABEL = '.js-validatorDateRequired-label';

RequiredDateValidator.SELECTORS.ERROR_MESSAGE_KEY = 'user.form.field_required_error_message';


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

proto.init = function init($element, eventBus, id) {
    this._super.init.call(this, $element, eventBus);

    this.errorMessage = I18nJSON[RequiredDateValidator.SELECTORS.ERROR_MESSAGE_KEY];

    this.setupHandlers()
        .createChildren();

    this.hasBeenInteractedWithArray = [];
    this.idArray = [];

    for (var i = 0; i < this.$input.length; i++) {
        this.hasBeenInteractedWithArray.push(false);
        this.idArray.push(this.$input[i].getAttribute('id'));
    }
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onBlurHandler = this.onBlur.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    this.onCheckValidatorValidationHandler = this.onCheckValidatorValidation.bind(this);
    return this;
};

/**
 * Create the children for the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$label = this.$element.find(RequiredDateValidator.SELECTORS.LABEL);
    this.$input = this.$element.find(RequiredDateValidator.SELECTORS.FIELD);
    this.$error = this.$element.find(RequiredDateValidator.SELECTORS.ERROR);
    return this;
};

/**
 * Enables the view
 * PerLoginForms any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$input.on('blur change', this.onBlurHandler);
    this.eventBus.on('submit', this.onSubmitHandler)
        .on('checkValidatorValidation', this.onCheckValidatorValidationHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);

    this.onBlurHandler = null;

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.setInputInvalidStyles = function setInputInvalidStyles($input) {
    var targetIndex = this.$input.index($input[0]);
    if (this.hasBeenInteractedWithArray[targetIndex]) {
        $input.addClass('m-field_error');
        this.$label.addClass('label_error');
        this.$error.empty()
            .html(this.errorMessage);
    }
    return this;
};

proto.clearInputInvalidStyles = function clearInputInvalidStyles($input) {
    $input.removeClass('m-field_error');
    return this;
};

proto.setLabelInvalidStyles = function setLabelInvalidStyles() {
    if (this.hasBeenInteractedWith()) {
        this.$label.addClass('label_error');
    }
    return this;
};

proto.clearLabelInvalidStyles = function clearLabelInvalidStyles() {
    this.$label.removeClass('label_error');
    return this;
};

proto.showErrorMessage = function showErrorMessage() {
    if (this.hasBeenInteractedWith()) {
        this.$error.empty()
            .html(this.errorMessage);
    }
    return this;
};

proto.hideErrorMessage = function hideErrorMessage() {
    this.$error.empty();
    return this;
};

proto.inputHasValue = function inputHasValue($input) {
    return $input.val() != '';
};

proto.dispatchSuccessfulValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('successfulValidatorValidation', this);
    return this;
};

proto.dispatchFailedValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('failedValidatorValidation', this);
    return this;
};

proto.hasBeenInteractedWith = function hasBeenInteractedWith() {
    var hasBeenInteractedWith = true;
    for (var i = 0; i < this.hasBeenInteractedWithArray.length; i++) {
        if (!this.hasBeenInteractedWithArray[i]) {
            hasBeenInteractedWith = false;
        }
    }
    return hasBeenInteractedWith;
};

proto.checkFieldValidity = function checkFieldValidity($currentInput) {
    if ($currentInput) {
        if (this.inputHasValue($currentInput)) {
            this.clearInputInvalidStyles($currentInput)
                .clearLabelInvalidStyles()
                .hideErrorMessage();
        } else {
            this.setInputInvalidStyles($currentInput);
        }
    } else {
        for (var i = 0; i < this.$input.length; i++) {
            var $input = $(this.$input[i]);
            if (this.inputHasValue($input)) {
                this.clearInputInvalidStyles($input);
            } else {
                this.setInputInvalidStyles($input);
            }
        }
    }

    var groupIsValid = true;

    for (var i = 0; i < this.$input.length; i++) {
        var $input = $(this.$input[i]);
        if (!this.inputHasValue($input)) {
            groupIsValid = false;
        }
    }

    if (groupIsValid) {
        this.dispatchSuccessfulValidatorValidationEvents()
            .hideErrorMessage()
            .clearLabelInvalidStyles();
    } else {
        this.dispatchFailedValidatorValidationEvents()
            .showErrorMessage()
            .setLabelInvalidStyles();
    }
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Event handler for the country select box changing
 */
proto.onBlur = function(event) {
    var targetIndex = this.$input.index(event.currentTarget);
    event.preventDefault();
    this.hasBeenInteractedWithArray[targetIndex] = true;
    this.checkFieldValidity($(event.currentTarget));
};

proto.onSubmit = function() {
    for (var i = 0; i < this.hasBeenInteractedWithArray.length; i++) {
        this.hasBeenInteractedWithArray[i] = true;
    };
    this.checkFieldValidity();
};

proto.onCheckValidatorValidation = function onCheckValidatorValidation(event, validatorElementId) {
    for (var i = 0; i < this.$input.length; i++) {
        if (validatorElementId == this.idArray[i]) {
            this.hasBeenInteractedWithArray[i] = true;
            this.checkFieldValidity($(this.$input[i]));
            break;
        }
    }
};

export default RequiredDateValidator;
