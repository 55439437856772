/**
 * @fileOverview BreedColllection File
 * @author Noah Blon nblon@nerdery.com
 */
import BaseCollection from './BaseCollection';
import BreedModel from '../models/BreedModel';

/**
 * Breed Collection holds a collection of breed models
 *
 * @class BreedCollection
 * @extends {BaseCollection}
 * @constructor
 */
var BreedCollection = function BreedCollection() {
    this.init();
};

BreedCollection.prototype = Object.create(BaseCollection.prototype);
BreedCollection.prototype.constructor = BreedCollection;

var proto = BreedCollection.prototype;
var _super = BaseCollection.prototype;

/**
 * Initializes the Collection
 * Instantiates object property defaults.
 *
 * @private
 * @method init
 */
proto.init = function init() {
    _super.init.call(this);

    /**
     * Array to hold breed models.
     *
     * @for BreedCollection
     * @property breeds
     * @type {Array}
     */
    this.breeds = [];
};

/**
 * Creates new Breed collection when a set of breeds has been retrieved from the server.
 *
 * @method fromJSON
 * @param json response from server containing a list of breeds
 */
proto.fromJSON = function fromJSON(json) {
    this.breeds = [];
    for (var i = 0; i < json.breeds.length; i++) {
        var breed = new BreedModel();
        breed.fromJSON(json.breeds[i]);

        this.breeds.push(breed);
    }
};

export default BreedCollection;
