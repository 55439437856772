import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { asyncCopyTextToClipboard } from '../../../util/dom';

import Tooltip from '../Tooltip';

/**
 * Renders a trigger that when clicked copies text to clipboard and shows a tooltip
 * @class CopyToClipboard
 * @extends Component
 */
class CopyToClipboard extends Component {
    /**
     * @type {Object}
     */
    state = {
        tooltipText: this.props.tooltipTextSuccess,
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        children: PropTypes.func.isRequired,
        copyText: PropTypes.string.isRequired,
        tooltipTextSuccess: PropTypes.string,
        tooltipTextFailure: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        tooltipTextSuccess: 'Copied',
        tooltipTextFailure: 'An error occured. Please refresh the page.',
    };

    /**
     * @type {Object}
     */
    tooltipTimer = null;

    /**
     * @param {function} openTooltip
     * @param {function} closeTooltip
     * @private
     */
    flashTooltip(openTooltip, closeTooltip) {
        openTooltip();
        window.clearTimeout(this.tooltipTimer);
        this.tooltipTimer = setTimeout(() => {
            closeTooltip();
        }, 3000);
    }

    /**
     * @param {function} openTooltip
     * @param {function} closeTooltip
     * @private
     * @returns {function}
     */
    buildHandlers(openTooltip, closeTooltip) {
        return {
            handleClick: ev => {
                this.setState(async prevState => {
                    try {
                        await asyncCopyTextToClipboard(this.props.copyText);
                        return {
                            tooltipText: this.props.tooltipTextSuccess,
                        };
                    } catch (error) {
                        return {
                            tooltipText: this.props.tooltipTextFailure,
                        };
                    }
                }, this.flashTooltip(openTooltip, closeTooltip));
            },
        };
    }

    render() {
        return (
            <Tooltip renderBody={this.state.tooltipText}>
                {({ handleOpen, handleClose }) =>
                    this.props.children({
                        ...this.buildHandlers(handleOpen, handleClose),
                    })
                }
            </Tooltip>
        );
    }
}

export default CopyToClipboard;
