// TODO: Use alias when refactor other themes to not require this template
import { html } from '../../../core/scripts/util/util';

export default function passwordPopupTemplate(vm) {
    return html`
        <div class="password-popup ${vm.selector}-popup">
            <div class="password-rules ${vm.selector}-ruleContainer">
                <div class="password-rule ${vm.selector}-indicatorLength">
                    <span class="password-indicator"></span>
                    <span class="password-txt">${vm.lang.criteriaLength}</span>
                </div>
                <div class="strong">${vm.lang.helpText}}</div>
                <div class="password-rule ${vm.selector}-ruleCaps">
                    <span class="password-indicator"></span>
                    <span class="pwValidation-txt">${vm.lang.criteriaUppercase}</span>
                </div>
                <div class="password-rule ${vm.selector}-ruleLowercase">
                    <span class="password-indicator"></span>
                    ${vm.lang.criteriaLowercase}
                </div>
                <div class="password-rule ${vm.selector}-ruleSpecialChars">
                    <span class="password-indicator"></span>
                    ${vm.lang.criteriaSpecialChars}
                </div>
                <div class="password-rule ${vm.selector}-ruleNumbers">
                    <span class="password-indicator"></span>
                    ${vm.lang.criteriaNumeric}
                </div>
            </div>
            <div class="password-validMsg {{selector}}-validMessage">
                <div class="password-indicator password-indicator_validMsg"></div>
                ${vm.lang.validMessage}
            </div>
        </div>
    `;
}
