export const buildPinterest = (location, image, description) => {
    return `http://www.pinterest.com/pin/create/button/?url=${location}${
        image ? `&media=${image}` : ''
    }${description ? `&description=${description}` : ''}`;
};

// NOTE: location or page metadata could be fetched from 'window.location.href' or meta tags,
// but referencing window or document here will break server-side rendering
export const buildSocialLinks = (location, image, description) => {
    const encodedLocation = encodeURI(location);
    const encodedDescription = description ? encodeURIComponent(description) : null;
    const encodedImage = image ? encodeURI(image) : null;
    return {
        facebook: `http://www.facebook.com/sharer/sharer.php?u=${encodedLocation}`,
        twitter: `http://www.twitter.com/intent/tweet?url=${encodedLocation}`,
        pinterest: buildPinterest(encodedLocation, encodedImage, encodedDescription),
    };
};
