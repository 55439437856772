import { html } from '../../../core/scripts/util/util';

function completeAutosaveIndicator(vm) {
    return html`
        <span class="autosave-msg-icon"></span>
        <span>${vm['user.saving_indicator.saved']}</span>
    `;
}

export default completeAutosaveIndicator;
