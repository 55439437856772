import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
const I18n = require('../../../../core/scripts/data/i18nJSON.json');
import completeAutosaveIndicator from '../../templates/completeAutosaveIndicator.html';
import ContextContainer from '../../../../core/scripts/context';
var AutosaveIndicator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
AutosaveIndicator.prototype = new BaseComponent();
var proto = AutosaveIndicator.prototype;
proto.constructor = AutosaveIndicator;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
AutosaveIndicator.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
AutosaveIndicator.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
AutosaveIndicator.SELECTORS.ELEMENT = '.js-autosaveIndicator';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    this.id = this.$element.attr('id');

    this.animationEngine = ContextContainer.animationEngine;

    this.completeSaveTemplate = completeAutosaveIndicator(I18n);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onSaveInitiatedHandler = this.onSaveInitiated.bind(this);
    this.onSaveCompleteHandler = this.onSaveComplete.bind(this);
    this.onSaveFailedHandler = this.onSaveFailed.bind(this);

    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$saveIndicator = this.$element.find('.js-autosaveIndicator-indicator');
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.eventBus.on('saveInitiated', this.onSaveInitiatedHandler);
    this.eventBus.on('saveComplete', this.onSaveCompleteHandler);
    this.eventBus.on('saveFailed', this.onSaveFailedHandler);

    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    return this;
};

proto.showSaveInitiatedIndicator = function showSaveInitiatedIndicator() {
    this.$saveIndicator.html('' + I18n['user.saving_indicator.saving'] + '');

    this.animationEngine.defineAnimation({
        type: 'fadeIn',
        element: this.$saveIndicator,
        playOnDefine: true,
    });
};

proto.showSaveCompleteIndicator = function hideSaveCompleteIndicator() {
    this.$saveIndicator.html(this.completeSaveTemplate);

    this.animationEngine.defineAnimation({
        type: 'fadeOut',
        element: this.$saveIndicator,
        opts: {
            duration: 1500,
        },
        playOnDefine: true,
    });
};

proto.showSaveFailedIndicator = function howSaveFailedIndicator() {
    this.$saveIndicator.html('Save Failed!');
    this.animationEngine.defineAnimation({
        type: 'fadeOut',
        element: this.$saveIndicator,
        opts: {
            duration: 1500,
        },
        playOnDefine: true,
    });
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onSaveInitiated = function onSaveInitiated(event, id) {
    if (id == this.id) {
        this.showSaveInitiatedIndicator();
    }
};

proto.onSaveComplete = function onSaveComplete(event, id) {
    if (id == this.id) {
        this.showSaveCompleteIndicator();
    }
};

proto.onSaveFailed = function onSaveFailed(event, id) {
    if (id == this.id) {
        this.showSaveFailedIndicator();
    }
};

export default AutosaveIndicator;
