// TODO: move to library
const debounce = function debounce(fn, debouncePeriod, execNow) {
    let timeout;
    let result;

    function debounced(...args) {
        const context = this;

        function fnToCall() {
            if (!execNow) {
                result = fn.apply(context, args);
            }
            timeout = null;
        }

        if (timeout) {
            clearTimeout(timeout);
        } else if (execNow) {
            result = fn.apply(context, args);
        }

        timeout = setTimeout(fnToCall, debouncePeriod);
        return result;
    }

    debounced.cancel = function() {
        clearTimeout(timeout);
    };

    return debounced;
};

/**
 * Scrolls content (vehicle) within a container (track) along with page position.
 *
 * @class ScrollerTrack
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 */
var ScrollerTrack = function ScrollerTrack($element) {
    this.init($element);
};

var proto = ScrollerTrack.prototype;

/**
 * Object to hold selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}h
 */
ScrollerTrack.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
ScrollerTrack.SELECTORS.ELEMENT = '.js-scrollerTrack';

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @private
 * @chainable
 */
proto.init = function init($element) {
    this.$element = $element;

    this.toggleClassName = 'aifInquiryScroller-vehicle_isFixed';

    return this.setupHandlers()
        .createChildren()
        .enable();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onScrollHandler = this.onScroll.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$car = this.$element.find('.js-scrollerTrack-vehicle');
    this.$track = this.$element.find('.js-scrollerTrack-track');
    return this;
};

/**
 * Enables the view
 * Kicks off listener setup
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this.setupListeners();
    return this;
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    // $(window).on('scroll', this.onScrollHandler);
    $(window).on('scroll', debounce(this.onScrollHandler, 15));
};

/**
 * Get the current window position
 *
 * @method getWindowPosition
 * @returns {Number} scroll position of the window
 * @private
 */
proto.getWindowPosition = function getWindowPosition() {
    return $(window).scrollTop();
};

/**
 * Get the top position of the track
 *
 * @method getTrackOffset
 * @returns {Number} top position of the track
 * @private
 */
proto.getTrackOffset = function getTrackOffset() {
    return this.$track.offset().top;
};

/**
 * Check if window scroll position is above track top position
 *
 * @method isCarAboveTrack
 * @returns {Boolean} testing is window postion is less than the track top position
 * @private
 */
proto.isCarAboveTrack = function isCarAboveTrack() {
    return this.getWindowPosition() < this.getTrackOffset();
};

/**
 * Check if window scroll position is within track top position and
 * track bottom position minus the car height
 *
 * @method isCarWithinTrack
 * @returns {Boolean} testing is the car is inside the top and bottom of the track
 * @private
 */
proto.isCarWithinTrack = function isCarWithinTrack() {
    return (
        this.getWindowPosition() <=
        this.getTrackOffset() +
            this.$track.height() -
            this.$car.outerHeight(true)
    );
};

/**
 * Set the css top property to the yPos passed into the method
 *
 * @method positionCar
 * @param {String} yPos position of the top of the car or an empty string
 * reset the car to it's original position
 * @private
 */
proto.positionCar = function positionCar(yPos) {
    this.$car.css('top', yPos);
};

/**
 * Event handler to watch for window scroll and position the car within
 * the track
 *
 * @method onScroll
 * @private
 */
proto.onScroll = function onScroll() {
    // Scroll position is above the track
    if (this.isCarAboveTrack()) {
        if (this.scrollingWithinTrack === false) {
            return;
        }

        this.scrollingWithinTrack = false;
        this.positionCar('');

        this.$car.removeClass(this.toggleClassName);
    }
    // Scroll position is within the track
    else if (this.isCarWithinTrack()) {
        this.scrollingWithinTrack = true;
        this.positionCar('');
        this.$car.addClass(this.toggleClassName);
    }
    // Scroll position is below the track
    else {
        // regardless of positioning mode, we need to set the vehicle to this position
        this.positionCar(this.$track.height() - this.$car.outerHeight(true));
        this.$car.removeClass(this.toggleClassName);
    }
};

export default ScrollerTrack;
