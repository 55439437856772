import $ from 'jquery';
import BaseForm from '../../../../core/scripts/components/BaseForm/BaseForm';
import BootstrapUtility from '../../../../core/scripts/util/BootstrapUtility';

import CountryStateRelatedInputs from '../../../../core/scripts/components/CountryStateRelatedInputs/CountryStateRelatedInputs';
import PetRelatedInputs from '../PetRelatedInputs/PetRelatedInputs';
import PetInputBlocks from '../PetInputBlocks/PetInputBlocks';

var DidYouAdoptForm = function($element, eventBus, routeModel, i18nModel) {
    this.init($element, eventBus, routeModel, i18nModel);
};

// Inheritance
DidYouAdoptForm.prototype = new BaseForm();
var proto = DidYouAdoptForm.prototype;
proto.constructor = DidYouAdoptForm;
proto._super = BaseForm.prototype;

var didYouAdoptFormComponentList = {
    CountryStateRelatedInputs,
    PetRelatedInputs,
    PetInputBlockCollection: PetInputBlocks,
};

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
DidYouAdoptForm.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
DidYouAdoptForm.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
DidYouAdoptForm.SELECTORS.ELEMENT = '.js-didYouAdoptForm';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus, routeModel, i18nModel) {
    this._super.init.call(this, $element, eventBus, routeModel, i18nModel);
    $.extend(this.componentList, didYouAdoptFormComponentList);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this._super.setupHandlers.call(this);
    this.onAddMarkupHandler = this.onAddMarkup.bind(this);
    this.onChangeAcquisitionMethodHandler = this.onChangeAcquisitionMethod.bind(
        this
    );
    this.onChangeFoodPreferenceHandler = this.onChangeFoodPreference.bind(this);
    this.onChangeNewPetTypeHandler = this.onChangeNewPetType.bind(this);
    this.onChangeDiscoveryMethodHandler = this.onChangeDiscoveryMethod.bind(
        this
    );

    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this._super.createChildren.call(this);
    this.$inputAcquisitionMethod = this.$element.find(
        '.js-aboutMeForm-inputAcquisitionMethod'
    );
    this.$inputFoodPreference = this.$element.find(
        '.js-aboutMeForm-inputFoodPreference'
    );
    this.$inputNewPetType = this.$element.find(
        '.js-aboutMeForm-inputNewPetType'
    );
    this.$inputDiscoveryMethod = this.$element.find(
        '.js-aboutMeForm-inputDiscoveryMethod'
    );

    return this;
};

proto.setupListeners = function setupListeners() {
    this._super.setupListeners.call(this);
    this.$inputAcquisitionMethod.on(
        'change',
        this.onChangeAcquisitionMethodHandler
    );
    this.$inputFoodPreference.on('change', this.onChangeFoodPreferenceHandler);
    this.$inputNewPetType.on('change', this.onChangeNewPetTypeHandler);
    this.$inputDiscoveryMethod.on(
        'change',
        this.onChangeDiscoveryMethodHandler
    );

    this.formEventBus.on('addPetBlock', this.onAddMarkupHandler);
    return this;
};

/**
 * Enable validation on the Other Food Preference select
 *
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.onChangeNewPetType();
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onAddMarkup = function onAddMarkup(event, $element) {
    BootstrapUtility.bootstrap(this.componentList, $element, this.formEventBus);
    this.enableComponents();
    this.eventBus.trigger('enableComponentsInDOMSubtree', $element);
};

proto.onChangeAcquisitionMethod = function onChangeAcquisitionMethod(event) {
    var value = event.currentTarget.value;
    if (value == 'lists.petAcquisitionMethods.shelter') {
        this.formEventBus.trigger(
            'unlockConditionalValidator',
            'user_did_you_adopt_application_newPet_organizationName'
        );
    } else {
        this.formEventBus.trigger(
            'lockConditionalValidator',
            'user_did_you_adopt_application_newPet_organizationName'
        );
    }
};

proto.onChangeFoodPreference = function onChangeFoodPreference(event) {
    var value = event.currentTarget.value;
    if (
        value == 'lists.petFoods.other.other' ||
        value == 'lists.petFoods.cat.other' ||
        value == 'lists.petFoods.dog.other'
    ) {
        this.formEventBus.trigger(
            'unlockConditionalValidator',
            'user_did_you_adopt_application_newPet_foodPreferenceOtherSpecify'
        );
    } else {
        this.formEventBus.trigger(
            'lockConditionalValidator',
            'user_did_you_adopt_application_newPet_foodPreferenceOtherSpecify'
        );
    }
};

proto.onChangeNewPetType = function onChangeNewPetType(event) {
    var value = this.$inputNewPetType.val();
    if (value != '2' && value != '3') {
        this.formEventBus.trigger(
            'unlockConditionalValidator',
            'user_did_you_adopt_application_newPet_foodPreferenceOther'
        );
        this.formEventBus.trigger(
            'lockConditionalValidator',
            'user_did_you_adopt_application_newPet_foodPreferenceList'
        );
    } else {
        this.formEventBus.trigger(
            'lockConditionalValidator',
            'user_did_you_adopt_application_newPet_foodPreferenceOther'
        );
        this.formEventBus.trigger(
            'unlockConditionalValidator',
            'user_did_you_adopt_application_newPet_foodPreferenceList'
        );
    }
};

proto.onChangeDiscoveryMethod = function onChangeDiscoveryMethod(event) {
    var value = event.currentTarget.value;
    if (value == 'lists.petDiscoveryMethods.socialMedia') {
        this.formEventBus.trigger(
            'unlockConditionalValidator',
            'user_did_you_adopt_application_newPet_discoveryMethodDetails'
        );
    } else {
        this.formEventBus.trigger(
            'lockConditionalValidator',
            'user_did_you_adopt_application_newPet_discoveryMethodDetails'
        );
    }
};

export default DidYouAdoptForm;
