/**
 * @fileOverview OrganizationRepository
 * @author Noah Blon nblon@nerdery.com
 */
import BaseRepository from './BaseRepository';
import OrganizationCollection from '../collections/OrganizationCollection';

import $ from 'jquery';

/**
 * Organization Repository
 * Handles data posting and getting related to the Did You Adopt coupon promotion
 *
 * @class OrganizationRepository
 * @constructor
 */
var OrganizationRepository = function OrganizationRepository() {
    this.init();
};

OrganizationRepository.prototype = Object.create(BaseRepository.prototype);
OrganizationRepository.prototype.constructor = OrganizationRepository;

var proto = OrganizationRepository.prototype;
var _super = BaseRepository.prototype;

/**
 * Initializes the Repository
 * Caches configuration to object properties.
 *
 * @private
 * @method init
 */
proto.init = function init() {
    _super.init.call(this);

    /**
     * Endpoint route to grab organizations
     *
     * @for OrganizationRepository
     * @property path
     * @type {String}
     */
    // this.path = this.routes.api['organizationsAutocomplete'];
    this.path = '/v2/search/organizations/';
};

/**
 * Gets a list of organizations (pet shelters)
 *
 * @public
 * @method getOrganizations
 * @returns {$.Deferred}
 */
proto.getOrganizations = function getOrganizations(query, limit, page) {
    var deferred = $.Deferred();
    var params = {
        'name_substring': query,
        limit,
        page,
    };
    this.get(this.path, params)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));

    return deferred.promise();
};

/**
 * When get is finished successfully, build a collections of Organization Models.
 *
 * @private
 * @param deferred object to resolve when collection is done building and resume program flow
 * @param json returned from API
 * @method onAJAXDone
 */
proto.onAJAXDone = function onAJAXDone(deferred, json) {
    var collection = new OrganizationCollection();
    collection.fromJSON(json);
    deferred.resolve(collection);
};


export default OrganizationRepository;
