/**
 * @fileOverview Breed Model File
 * @author Noah Blon nblon@nerdery.com
 */
import BaseModel from './LegacyBaseModel';

/**
 * PetModel
 * The model for a breed of pet type.
 *
 * @class PetModel
 * @constructor
 *
 */
var PetModel = function () { };

// Inheritance
PetModel.prototype = new BaseModel();
var proto = PetModel.prototype;
proto.constructor = PetModel;
proto.super = BaseModel.prototype;

proto.init = function () {
    this.super.init.call(this);

    /**
     * Pet's unique Id
     *
     * @for PetModel
     * @property id
     * @type {Number}
     * @default 0
     */
    this.id = 0;

    /**
     * Pet's name
     *
     * @for PetModel
     * @property name
     * @type {String}
     * @default ''
     */
    this.name = '';

    /**
     * Pet's pet type id
     *
     * @for PetModel
     * @property typeId
     * @type {Number}
     * @default 0
     */
    this.typeId = 0;

    /**
     * Pet's primary breed id
     *
     * @for PetModel
     * @property primaryBreedId
     * @type {Number}
     * @default 0
     */
    this.primaryBreedId = 0;

    /**
     * Pet's secondary breed id
     *
     * @for PetModel
     * @property secondaryBreedId
     * @type {Number}
     * @default 0
     */
    this.secondaryBreedId = 0;

    /**
     * Pet's approximate birthdate
     *
     * @for PetModel
     * @property birthDate
     * @type {String}
     * @default ''
     */
    this.birthDate = '';

    /**
     * Pet's acquisition method (shelter, rescue, etc.)
     *
     * @for PetModel
     * @property acquisitionMethod
     * @type {String}
     * @default ''
     */
    this.acquisitionMethod = '';

    /**
     * Pet's organization ID
     *
     * @for PetModel
     * @property organizationId
     * @type {Number}
     * @default 0
     */
    this.organizationId = 0;

    /**
     * Pet's size ID
     *
     * @for PetModel
     * @property sizeId
     * @type {Number}
     * @default 0
     */
    this.sizeId = 0;

    /**
     * Pet's adoption date
     *
     * @for PetModel
     * @property adoptionDate
     * @type {String}
     * @default ''
     */
    this.adoptionDate = '';

    /**
     * Pet's food preference
     *
     * @for PetModel
     * @property foodPreference
     * @type {String}
     * @default ''
     */
    this.foodPreference = '';

    /**
     * Pet's discovery method
     *
     * @for PetModel
     * @property discoveryMethod
     * @type {String}
     * @default ''
     */
    this.discoveryMethod = '';

    /**
     * Pet's adoption date
     *
     * @for PetModel
     * @property discoveryMethodDetails
     * @type {String}
     * @default ''
     */
    this.discoveryMethodDetails = '';

    return this;
};

/**
 * Parses json response from server into model.
 * If API parameters are added/removed/changed, reflect those changes here.
 *
 * @for PetModel
 * @method fromJSON
 * @public
 */
proto.fromJSON = function (json) {
    this.id = json['id'];
    this.name = json['name'];
    this.type_id = json['type_id'];
    this.primaryBreedId = json['primary_breed_id'];
    this.secondaryBreedId = json['secondary_breed_id'];
    this.birthDate = json['birth_date'];
    this.acquisitionMethod = json['acquisition_method'];
    this.organizationId = json['organization_id'];
    this.sizeId = json['size_id'];
    this.adoptionDate = json['adoption_date'];
    this.foodPreference = json['food_preference'];
    this.discoveryMethod = json['discovery_method'];
    this.discoveryMethodDetails = json['discovery_method_details'];
};

export default PetModel;
