var BootstrapUtility = {

    bootstrap(componentList, $element, eventBus, routeModel, i18nModel) {
        var components = {};
        var componentId = 0;
        for (var componentName in componentList) {
            var Component = componentList[componentName];

            // TODO: proper error
            if (typeof Component.SELECTORS.ELEMENT === 'undefined') { }

            var $componentElements = $element.find(Component.SELECTORS.ELEMENT)
                .addBack(Component.SELECTORS.ELEMENT);

            if (!components.hasOwnProperty(componentName)) {
                components[componentName] = [];
            }

            $componentElements.each(function(index) {
                var $componentElement = $componentElements.eq(index);
                components[componentName].push(new Component($componentElement, eventBus, routeModel, i18nModel));
            });
        }

        return components;
    },
};

export default BootstrapUtility;
