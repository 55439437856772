/**
 * @fileOverview StateRepository
 * @author Noah Blon nblon@nerdery.com
 */
import BaseRepository from './BaseRepository';
import StateCollection from '../collections/StateCollection';
import $ from 'jquery';

/**
 * State Repository
 * Performs AJAX requests related to a country's states
 *
 * @class StateRepository
 * @constructor
 */
var StateRepository = function StateRepository() {
    this.init();
};

StateRepository.prototype = Object.create(BaseRepository.prototype);
StateRepository.prototype.constructor = StateRepository;

var proto = StateRepository.prototype;
var _super = BaseRepository.prototype;

/**
 * Initializes the Repository
 * Caches configuration to object properties.
 *
 * @private
 * @method init
 */
proto.init = function init() {
    _super.init.call(this);

    /**
     * Endpoint route from which to grab the states for a country
     *
     * @for LegacyOrganizationModel
     * @property path
     * @type {String}
     */
    this.path = this.routes.api['getStatesForCountry'];
};

/**
 * Gets a list of states for a given country ID
 *
 * @public
 * @method getBreedsByPetType
 * @returns {$.Deferred}
 */
proto.getStatesByCountry = function getStatesByCountry(countryId) {
    var deferred = $.Deferred();
    var token = /\{country\}/gi;

    // replace type token in endpoint with passed in typeId
    var path = this.path.replace(token, countryId);

    this.get(path)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));

    return deferred.promise();
};

/**
 * When get is finished successfully, build a collection of states
 *
 * @private
 * @param deferred object to resolve when collection is done building and resume program flow
 * @param json returned from API
 * @method onAJAXDone
 */
proto.onAJAXDone = function onAJAXDone(deferred, json) {
    var collection = new StateCollection();
    collection.fromJSON(json);
    deferred.resolve(collection);
};

export default StateRepository;
