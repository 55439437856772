import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/languages/hljs/javascript';
import { irBlack, github } from 'react-syntax-highlighter/dist/styles/hljs';

import CopyToClipboard from '../../../../../core/scripts/react-components/specifics/CopyToClipboard';
import _noop from 'lodash/noop';

SyntaxHighlighter.registerLanguage('javascript', js);

/**
 * Github themed version of the CopySnippet component
 *
 * @export
 * @param {*} props
 * @returns {ReactElement}
 */
export function GithubCopySnippet(props) {
    return <CopySnippet {...props} style={github} themeClass="github" />;
}

class CopySnippet extends Component {
    static defaultProps = {
        style: irBlack,
        themeClass: 'irBlack',
        onCopyClick: _noop,
    };

    static propTypes = {
        content: PropTypes.string,
        style: PropTypes.object,
        themeClass: PropTypes.string,
        onCopyClick: PropTypes.func,
    };

    render() {
        return (
            <pre
                className={`snippetBlock snippetBlock_${this.props.themeClass}`}
            >
                <code className="snippetBlock-bd">
                    <SyntaxHighlighter
                        style={this.props.style}
                        wrapLines={true}
                        tabIndex="0"
                        PreTag="div"
                        CodeTag="span"
                    >
                        {this.props.content}
                    </SyntaxHighlighter>
                </code>
                <CopyToClipboard
                    copyText={this.props.content}
                    tooltipTextSuccess="Snippet copied"
                >
                    {({ handleClick }) => (
                        <button
                            type="button"
                            className="snippetBlock-btn"
                            onClick={() => {
                                handleClick();
                                this.props.onCopyClick();
                            }}
                        >
                            copy
                        </button>
                    )}
                </CopyToClipboard>
            </pre>
        );
    }
}

export default CopySnippet;
