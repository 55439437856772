import { trackEvent } from '../../../core/scripts/analytics';
import _lowerCase from 'lodash/lowerCase';

import {
    analyticsConsumer016,
    analyticsConsumer017,
    analyticsConsumer279,
} from './dotcom';

/**
 * @public
 * @event publicApi002
 * @event publicApi003
 * @event publicApi004
 * @param {object} params
 */
export function analyticsPublicApi002(params) {
    const { link } = params;

    trackEvent({
        category: 'developer site',
        action: 'global nav',
        label: _lowerCase(link.label),
    });
}

/**
 * @public
 * @event publicApi005
 * @param {object} params
 */
export function analyticsPublicApi005(params) {
    trackEvent({
        category: 'sign in',
        action: 'go to login or registration modal widow',
    });
}

/**
 * @public
 * @event analyticsPublicApiUserNavLinkClick
 * @param {object} params
 */
export function analyticsPublicApiUserNavLinkClick(params) {
    const { key } = params;

    const userNavKeyToFunctionMap = {
        /* eslint-disable camelcase */
        developer_settings: analyticsPublicApi008,
        search_alerts: analyticsConsumer279,
        profile: analyticsConsumer016,
        logout: analyticsConsumer017,
        /* eslint-enable */
    };

    try {
        userNavKeyToFunctionMap[key]();
    } catch (error) {
        console.error('public analytics user navigation click error:', error);
    }
}

/**
 * @public
 * @event publicApi008
 */
export function analyticsPublicApi008() {
    trackEvent({
        category: 'my petfinder',
        action: 'for developers',
    });
}
