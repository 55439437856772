import BaseModel from './BaseModel';
import HappyTailAnimalModel from './HappyTailAnimalModel';
import UploadModel from './UploadModel';
import OrganizationModel from './OrganizationModel';
import UserModel from './UserModel';
import { buildSocialLinks } from '../util/socialLinks';
import _endsWith from 'lodash/endsWith';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import { distanceInWordsToNow, parse, format } from 'date-fns';

const FALLBACK_IMAGE =
    '/assets/www/media/images/no-image-available-placeholder.jpg';

export default class HappyTailModel extends BaseModel {
    get defaultData() {
        return {
            adoptionTimestamp: '',
            animal: new HappyTailAnimalModel(),
            author: new UserModel(),
            createdAt: '',
            firstViewedAt: '',
            firstViewedBy: null,
            foundOnPetfinder: false,
            id: null,
            image: new UploadModel({
                url: FALLBACK_IMAGE,
                path: FALLBACK_IMAGE,
            }),
            isPublic: false,
            organization: new OrganizationModel(),
            socialMedia: {},
            moderatedAt: '',
            moderator: new UserModel(),
            status: '',
            story: '',
            updatedAt: '',
            url: '',
        };
    }

    /**
     * fromServer
     * API:
     * [GET] /happy-tails/{id}
     * @static
     * @param {object} happyTailData Server Data
     *
     * @return {HappyTailModel}
     */
    static fromServer(happyTailData) {
        const model = new HappyTailModel(
            this._mapServerDataToClientside(happyTailData)
        );
        return model;
    }

    /**
     * _mapServerDataToClientside - helper to translate server data to object
     * @static
     * @param {object} happyTailData
     *
     * @return {object}
     */
    static _mapServerDataToClientside(happyTailData) {
        return {
            animal: new HappyTailAnimalModel({
                adoptionTimestamp: _get(happyTailData, 'adoption_date', ''),
                name: _get(happyTailData, 'name', ''),
                primaryBreed: _get(happyTailData, 'breeds.primary', ''),
                secondaryBreed: _get(happyTailData, 'breeds.secondary', ''),
                species: _get(happyTailData, 'species', ''),
                type: _get(happyTailData, 'type', ''),
            }),
            adoptionTimestamp: _get(happyTailData, 'adoption_date', ''),
            author: UserModel.fromServer({
                ..._get(happyTailData, '_embedded.author', {}),
                ['first_name']: _get(happyTailData, 'author_name', ''),
            }),
            createdAt: _get(happyTailData, 'created_at', ''),
            firstViewedAt: _get(happyTailData, 'first_viewed_at', ''),
            firstViewedBy: _get(happyTailData, 'first_viewed_by', null),
            foundOnPetfinder: _get(happyTailData, 'found_on_petfinder', ''),
            id: _get(happyTailData, 'id', null),
            image: UploadModel.fromServer({
                id: _get(happyTailData, 'photo', null),
                ..._get(happyTailData, '_embedded.photo', {
                    path: FALLBACK_IMAGE,
                    url: FALLBACK_IMAGE,
                }),
            }),
            isPublic: _get(happyTailData, 'is_public', false),
            moderatedAt: _get(happyTailData, 'moderated_at', ''),
            moderator: UserModel.fromServer({
                ..._get(happyTailData, '_embedded.moderator', {}),
            }),
            organization: OrganizationModel.fromServer(
                _get(happyTailData, '_embedded.organization', {})
            ),
            socialMedia: _get(happyTailData, 'social_media', {}),
            status: _get(happyTailData, 'status', ''),
            story: _get(happyTailData, 'story', ''),
            updatedAt: _get(happyTailData, 'updated_at', ''),
            url: _get(happyTailData, 'url', ''),
        };
    }

    static toServer(happyTailModel) {
        return {
            ['happy_tail']: {
                name: happyTailModel.animal.name,
                type: happyTailModel.animal.type,
                species: happyTailModel.animal.species,
                ['adoption_date']: happyTailModel.adoptionTimestamp,
                breeds: {
                    primary: happyTailModel.animal.primaryBreed,
                    secondary: happyTailModel.animal.secondaryBreed,
                },
                ['found_on_petfinder']: happyTailModel.foundOnPetfinder,
                organization: happyTailModel.organization.id,
                story: happyTailModel.story,
                ['is_public']: happyTailModel.isPublic,
                status: happyTailModel.status,
                photo: happyTailModel.image.id,
            },
        };
    }

    /**
     * adoptionDate - adoptionTimestamp into 'Month Year'
     *
     * @return {string} July YYYY
     */
    get adoptionDate() {
        if (!this.adoptionTimestamp) {
            return '';
        }
        return format(parse(this.adoptionTimestamp), 'MMMM YYYY');
    }

    /**
     * adoptionNumericalDate - adoptionTimestamp into 'MM/YYYY'
     *
     * @return {string} MM/YYYY
     */
    get adoptionNumericalDate() {
        if (!this.adoptionTimestamp) {
            return '';
        }
        return format(parse(this.adoptionTimestamp), 'MM/YYYY');
    }

    /**
     * adoptionTimestamp
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get adoptionTimestamp() {
        return this._data.adoptionTimestamp;
    }

    /**
     * animal - HappyTailAnimalModel
     *
     * @return {HappyTailAnimalModel}
     */
    get animal() {
        return this._data.animal;
    }

    /**
     * author - UserModel
     *
     * @return {UserModel}
     */
    get author() {
        return this._data.author;
    }

    /**
     * calculatedStatus - staff tools property
     *
     * @return {string}
     */
    get calculatedStatus() {
        if (this.firstViewedBy) {
            return this.status;
        }

        return 'new';
    }

    /**
     * createdAt - Happy Tail creation timestamp
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get createdAt() {
        return this._data.createdAt;
    }

    /**
     * firstViewedAt - staff tools viewed at timestamp
     *
     * @return {null|string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get firstViewedAt() {
        return this._data.firstViewedAt;
    }

    /**
     * firstViewedBy
     *
     * @return {null|number}
     */
    get firstViewedBy() {
        return this._data.firstViewedBy;
    }

    /**
     * foundOnPetfinder
     *
     * @return {boolean}
     */
    get foundOnPetfinder() {
        return this._data.foundOnPetfinder;
    }

    /**
     * id - Happy Tail ID
     *
     * @return {null|number}
     */
    get id() {
        return this._data.id;
    }

    /**
     * isPublic
     *
     * @return {boolean}
     */
    get isPublic() {
        return this._data.isPublic;
    }

    /**
     * image - UploadModel
     *
     * @return {UploadModel}
     */
    get image() {
        return this._data.image;
    }

    /**
     * imageSource
     *
     * @return {string}
     */
    get imageSource() {
        return this.image.url || this.image.path;
    }

    /**
     * Organization - OrganizationModel
     *
     * @return {OrganizationModel}
     */
    get organization() {
        return this._data.organization;
    }

    /**
     * socialMedia
     *
     * @return {object} Fallback: { ...buildSocialLinks(), email: 'STRING' }
     */
    get socialMedia() {
        if (_isEmpty(this._data.socialMedia)) {
            // Note: lodash/startCase has a bug where it pulls out special chars using replace instead
            // Note: Legacy users may not have a first name associated with their account
            const firstName = this.author.firstName
                ? this.author.firstName.toLowerCase().replace(/\w+/g, _capitalize)
                : '';
            const animalName = this.animal.name.toLowerCase().replace(/\w+/g, _capitalize);
            const socialLinks = buildSocialLinks(this.url, this.imageSource, this.metaDescription);

            if (firstName) {
                return {
                    ...socialLinks,
                    email: `mailto:?subject=Check out ${firstName} and ${animalName}'${
                        _endsWith(animalName.toLowerCase(), 's') ? '' : 's'
                    } Happy Tail adoption story on Petfinder!&body=Ah, the joys of pet adoption! Read the story of how ${animalName} found a forever home with ${firstName}: ${encodeURI(
                        this.url
                    )}`,
                };
            } else {
                return {
                    ...socialLinks,
                    email: `mailto:?subject=Check out ${animalName}'${
                        _endsWith(animalName.toLowerCase(), 's') ? '' : 's'
                    } Happy Tail adoption story on Petfinder!&body=Ah, the joys of pet adoption! Read the story of how ${animalName} found their forever home: ${encodeURI(
                        this.url
                    )}`,
                };
            }
        }

        return this._data.socialMedia;
    }

    /**
     * metaDescription - shortens the story to a max of 100+3 characters
     *
     * @return {string} This is my story 'bout my hound and how our lives flipped upside down...
     */
    get metaDescription() {
        if (this.story.length < 100) {
            return this.story;
        }
        return `${this.story.substr(0, 100)}...`;
    }

    /**
     * modificationSlug - staff tool listing slug
     *
     * @return {string} [xx days] old, last updated [by STAFF USER] [updatedAt]
     */
    get modificationSlug() {
        let moderatorDetails = '';
        if (this.moderatedAt && this.moderator.firstName) {
            moderatorDetails = `, last updated by ${this.moderator.firstName} ${
                this.moderator.lastName
            } ${distanceInWordsToNow(parse(this.moderatedAt))} ago`;
        }
        return `${distanceInWordsToNow(
            parse(this.createdAt)
        )} old${moderatorDetails}`;
    }

    /**
     * moderatedAt
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get moderatedAt() {
        return this._data.moderatedAt;
    }

    /**
     * moderator - UserModel
     *
     * @return {UserModel}
     */
    get moderator() {
        return this._data.moderator;
    }

    /**
     * status
     *
     * @return {string}
     */
    get status() {
        return this._data.status;
    }

    /**
     * @param {string} status
     */
    set status(status) {
        this._data.status = status;
    }

    /**
     * story
     *
     * @return {string}
     */
    get story() {
        return this._data.story;
    }

    /**
     * @param {string} story
     */
    set story(story) {
        this._data.story = story;
    }

    /**
     * title
     *
     * @return {string} Bob & Fido
     */
    get title() {
        // Note: lodash/startCase has a bug where it pulls out special chars using replace instead
        // Note: Legacy users may not have a first name associated with their account
        const firstName = this.author.firstName ? this.author.firstName.toLowerCase().replace(/\w+/g, _capitalize) : '';
        const animalName = this.animal.name.toLowerCase().replace(/\w+/g, _capitalize);

        if (firstName) {
            return `${firstName} & ${animalName}`;
        } else {
            return `${animalName}'${_endsWith(animalName.toLowerCase(), 's') ? '' : 's'} Story`;
        }
    }

    /**
     * updatedAt
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get updatedAt() {
        return this._data.updatedAt;
    }

    /**
     * url
     *
     * @return {string}
     */
    get url() {
        return this._data.url;
    }
}
