/**
 * @fileOverview Breed Model File
 * @author Noah Blon nblon@nerdery.com
 */
import BaseModel from './LegacyBaseModel';

/**
 * BreedModel
 * Model for a breed of pet.
 *
 * @class BreedModel
 * @constructor
 */
var BreedModel = function BreedModel() {
    this.init();
};

// Inheritance
BreedModel.prototype = new BaseModel();
var proto = BreedModel.prototype;
proto.constructor = BreedModel;
proto.super = BaseModel.prototype;

/**
 * Initializes the Breed Model
 * Sets up the local instance variables and defaults
 *
 * @for BreedModel
 * @method init
 * @chainable
 * @returns {BreedModel.prototype}
 * @private
 */
proto.init = function init() {
    this.super.init.call(this);

    /**
     * Unique breed identifier
     *
     * @for BreedModel
     * @property id
     * @type {number}
     * @default 0
     */
    this.breedId = 0;

    /**
     * Breed name
     *
     * @for BreedModel
     * @property name
     * @type {string}
     * @default ''
     */
    this.name = '';

    return this;
};

/**
 * Parses json response from server into model.
 * If API parameters are added/removed/changed, reflect those changes here.
 *
 * @for BreedModel
 * @method fromJSON
 * @public
 */
proto.fromJSON = function fromJSON(json) {
    this.breedId = json['id'];
    this.name = json['name'];
};

export default BreedModel;
