import t from 'tcomb-form';
import { ERROR_REQUIRED_FIELD } from '../constants/errors';

/**
 * @type {number}
 */
const STORY_MAX_LENGTH = 1200;

/**
 * @type {Object}
 */
const STATUS_KEY_ENUM = {
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    DENIED: 'denied',
    NEW: 'new',
    TODO: 'todo',
    DONE: 'done',
};

/**
 * @type {Object}
 */
const STATUS_TO_LABEL_ENUM = {
    [STATUS_KEY_ENUM.PUBLISHED]: 'Published',
    [STATUS_KEY_ENUM.UNPUBLISHED]: 'Unpublished',
    [STATUS_KEY_ENUM.DENIED]: 'Denied',
    [STATUS_KEY_ENUM.NEW]: 'New',
    [STATUS_KEY_ENUM.TODO]: 'To-Do',
    [STATUS_KEY_ENUM.DONE]: 'Done',
};

/**
 * @type {Object}
 */
const EDITABLE_STATUSES_ENUM = {
    [STATUS_KEY_ENUM.PUBLISHED]:
        STATUS_TO_LABEL_ENUM[STATUS_KEY_ENUM.PUBLISHED],
    [STATUS_KEY_ENUM.UNPUBLISHED]:
        STATUS_TO_LABEL_ENUM[STATUS_KEY_ENUM.UNPUBLISHED],
    [STATUS_KEY_ENUM.DENIED]: STATUS_TO_LABEL_ENUM[STATUS_KEY_ENUM.DENIED],
};

/**
 * @type {Object}
 */
const Statuses = t.enums(EDITABLE_STATUSES_ENUM);

Statuses.getValidationErrorMessage = (value, path, context) => {
    return ERROR_REQUIRED_FIELD;
};

/**
 * @type {Object}
 */
const StatusForm = t.struct({
    status: Statuses,
});

/**
 * @type {Object}
 */
const StoryForm = t.struct({
    story: t.String,
});

export default {
    STATUS_KEY_ENUM,
    STATUS_TO_LABEL_ENUM,
    STORY_MAX_LENGTH,
    StatusForm,
    StoryForm,
};
