import React, { Component } from 'react';

import ShowHide from '../../../../../core/scripts/react-components/specifics/ShowHide';

/**
 * Handles the revealing of the api secret key.
 *
 * @class RevealSecret
 * @extends {Component}
 */
class RevealSecret extends Component {
    /**
     * Creates the container for the api secret key.
     *
     * @returns {ReactElement} markup
     * @memberof RevealSecret
     */
    renderSecretContainer() {
        return context => (
            <button
                className="obfuscate u-hr10x"
                onClick={context.handleTriggerClicked}
            >
                <span className="txt m-txt_colorPrimaryS1 m-txt_inlineBlock">
                    Show Secret
                </span>
            </button>
        );
    }

    /**
     * Obtains the actual api secret key from web-front
     *
     * @returns {ReactElement} markup
     * @memberof RevealSecret
     */
    renderSecret() {
        return () => (
            <div className="obfuscate obfuscate_isVisible u-hr10x u-breakWord">
                {this.props.secret}
            </div>
        );
    }

    render() {
        return (
            <ShowHide
                hiddenContent={this.renderSecretContainer()}
                visibleContent={this.renderSecret()}
            />
        );
    }
}

export default RevealSecret;
