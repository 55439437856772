import $ from 'jquery';
import EventBus from '../../../../core/scripts/vendor/EventBus/dist/EventBus';

const I18n = require('../../data/i18nJSON.json');
import BootstrapUtility from '../../util/BootstrapUtility';

// TODO: fix this -- core depending on dotcom template. native js template
import formErrorContentTemplate from
    './../../../../dotcom/scripts/templates/formErrorContent.html.js';

// TODO: fix this -- core depending on dotcom
import AutosaveIndicator from '../../../../dotcom/scripts/components/AutosaveIndicator/AutosaveIndicator';
import RequiredValidator from '../../validators/RequiredValidator/RequiredValidator';
import RequiredCheckboxValidator from '../../validators/RequiredValidator/RequiredCheckboxValidator';
import RequiredDateValidator from '../../validators/RequiredDateValidator/RequiredDateValidator';
import ConditionalRequiredValidator from '../../validators/ConditionalRequiredValidator/ConditionalRequiredValidator';
import PasswordValidator from '../../validators/PasswordValidator/PasswordValidator';
import PasswordValidatorNotRequired from '../../validators/PasswordValidator/PasswordValidatorNotRequired';
import PasswordConfirmValidator from '../../validators/PasswordConfirmValidator/PasswordConfirmValidator';
import CountryPostalCodeValidator from '../../validators/CountryPostalCodeValidator/CountryPostalCodeValidator';
import PrimarySecondaryBreedsValidator from '../../validators/PrimarySecondaryBreedsValidator/PrimarySecondaryBreedsValidator';

import PetInputBlockCollection from '../../../../dotcom/scripts/components/PetInputBlocks/PetInputBlocks';
import CountryOptinRelatedInputs from '../../../../dotcom/scripts/components/CountryOptinRelatedInputs/CountryOptinRelatedInputs';

const componentList = {
    AutosaveIndicator,
    ConditionalRequiredValidator,
    RequiredDateValidator,
    RequiredValidator,
    RequiredCheckboxValidator,
    PasswordValidator,
    PasswordValidatorNotRequired,
    PasswordConfirmValidator,
    CountryPostalCodeValidator,
    PetInputBlockCollection,
    // ShelterAutocomplete: ShelterAutocomplete,
    CountryOptinRelatedInputs,
    PrimarySecondaryBreedsValidator,
};

/**
 * The login form contained in a modal which uses AJAX to login a user.
 *
 * @class BaseForm
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus shared between components in the app
 * @param {Object} routes data object from backend
 * @param {Object} i18nModel template strings and values
 *
 * Bus Event Bus shared between components in app
 */
const BaseForm = function($element, eventBus, routes, i18nModel) {
    if ($element == null) {
        return this;
    }
    if (!$element instanceof $) {
        throw new TypeError('Unable to instantiate View, expected jQuery Object');
    }

    this.init($element, eventBus, routes, i18nModel);
};

// Alias the prototype for convenience
const proto = BaseForm.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Property values should not contain the class notation to play well
 * with jQuery hasClass, toggleClass etc.
 *
 * @property CLASS
 * @static
 * @final
 * @type {Object}
 */
BaseForm.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
BaseForm.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
BaseForm.SELECTORS.ELEMENT = '.js-form';

/**
 * Selector for the submit button
 *
 * @property SELECTORS.SUBMIT_BTN
 * @static
 * @final
 * @type {string}
 */
BaseForm.SELECTORS.SUBMIT = 'button[type="submit"]';

/**
 * From level error class.
 *
 * @property SELECTORS.FORM_ERROR
 * @static
 * @final
 * @type {string}
 */
BaseForm.SELECTORS.FORM_ERROR = '.js-form-serverError';

/**
 * State class to add to inputs in an error state.
 *
 * @property CLASSES.FIELD_ERROR
 * @static
 * @final
 * @type {string}
 */
BaseForm.CLASSES.FIELD_ERROR = 'm-field_error';

/**
 * Data attribute to indicate if form should display top-level validation
 * error on failed form submit.
 *
 * @property DATA_SUPER_VALIDATION
 * @static
 * @final
 * @type {string}
 */
BaseForm.DATA_SUPER_VALIDATION = 'super-validation';

proto.CONST = {};


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Adds and initializes variables as properties of the Component.
 * Kicks off setting up the enable handler listening for the enable event of the
 * event bus. Sets up the enable handler and listeners.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @param {Object} routes data object from backend
 * @param {Object} i18nModel template strings and values
 * @private
 * @chainable
 * @returns {Object} this
 */
proto.init = function init($element, eventBus, routes, i18nModel) {
    /**
     * Flag to track whether the module has been enabled
     *
     * @property isEnabled
     * @type {boolean}
     * @default false
     */
    this.isEnabled = false;

    /**
     * An HTMLElement wrapped in jQuery that acts as the main element for this view instance.
     *
     * @property $element
     * @type {jQuery}
     */
    this.$element = $element;

    /**
     * Event Bus tracking events throughout components in the app.
     *
     * @property eventBus
     * @type {Object}
     */
    this.eventBus = eventBus;

    /**
     * List of validators to be boostrapped in the form
     *
     * @property componentList
     * @type {Object}
     */
    this.componentList = componentList;

    /**
     * An event bus specific to the form for listen and respond to events
     * coming from the validators.
     *
     * @property formEventBus
     * @type {Object}
     */
    this.formEventBus = new EventBus();

    /**
     * An flag to track if the form is disabled
     *
     * @property componentList
     * @type {boolean}
     */
    this.formIsDisabled = true;

    /**
     * An array holding references to invalid validators
     *
     * @property componentList
     * @type {Object}
     */
    this.invalidValidator = [];

    this.hasBeenInteractedWith = false;
    this.i18nModel = i18nModel;
    this.routeModel = routes;
    return this.setupEnableHandler()
        .setupEnableListener();
};

/**
 * Binds the scope of the handler listening for the component enabling event
 *
 * @method setupEnableHandler
 * @private
 * @chainable
 * @returns {Object} this
 */
proto.setupEnableHandler = function setupEnableHandler() {
    this.onEnableHandler = this.onEnable.bind(this);
    return this;
};

/**
 * Sets up listening for the event bus component enable event
 *
 * @method setupEnableHandler
 * @returns {Object} this
 * @private
 * @chainable
 */
proto.setupEnableListener = function setupEnableListener() {
    this.eventBus.on('enableComponents', this.onEnableHandler);

    /*
     * Proxying events through the BaseForm to the Conditional validator component to allow
     * enabling/disabling the conditional validator from the custom elements system.
     */
    this.eventBus.on('legacyConditionalUnlock', (event, element) => {
        this.formEventBus.trigger(
            'unlockConditionalValidator',
            element.getAttribute('dynamic-error-target-id'));
    });

    this.eventBus.on('legacyConditionalLock', (event, element) => {
        this.formEventBus.trigger(
            'lockConditionalValidator',
            element.getAttribute('dynamic-error-target-id'));
    });

    return this;
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @returns {Object} this
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onFailedValidatorValidationHandler = this.onFailedValidatorValidation.bind(this);
    this.onSuccessfulValidatorValidationHandler = this.onSuccessfulValidatorValidation.bind(this);
    this.onCheckFormValidationHandler = this.onCheckFormValidation.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    return this;
};

/**
 * Create the children for the component
 *
 * @method createChildren
 * @returns {Object} this
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$submitBtn = this.$element.find(BaseForm.SELECTORS.SUBMIT);
    return this;
};

/**
 * Enables the view
 * PerBaseForms any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 * @returns {Object} this
 */
proto.enable = function enable() {
    if (this.isEnabled) { return this; }
    this.setupHandlers()
        .createChildren()
        .bootstrapComponents()
        .setupListeners()
        .enableComponents();

    this.isEnabled = true;
    return this;
};

/**
 *
 */
proto.setupListeners = function setupListeners() {
    this.formEventBus.on('failedValidatorValidation', this.onFailedValidatorValidationHandler)
        .on('successfulValidatorValidation', this.onSuccessfulValidatorValidationHandler)
        .on('checkFormValidation', this.onCheckFormValidationHandler);

    this.$element.on('submit', this.onSubmitHandler);
    return this;
};

proto.enableComponents = function enableComponents() {
    this.formEventBus.trigger('enableComponents');
    return this;
};

/**
 * Disables the view
 * Tears down any event binding to handlers
 * Exits early if it is already disabled
 *
 * @method enable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    if (!this.isEnabled) { return this; }
    this.isEnabled = false;

    this.onEnableHandler = null;
    this.onFailedValidatorValidationHandler = null;
    this.onSuccessfulValidatorValidationHandler = null;
    return this;
};

/**
 * Destroys the view
 * Nulls out all values to allow it to be garbage collected
 *
 * @method destroy
 * @public
 */
proto.destroy = function destroy() {
    this.disable();
    for (const key in this) {
        if (this.hasOwnProperty(key)) {
            this[key] = null;
        }
    }
};


/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Runs the onBoostrapValidators method, which initialized the validators in the
 * validators list.  Uses template method to allow overriding in a child view
 * if necessary.
 *
 * @method bootstrapValidators
 * @private
 * @chainable
 */
proto.bootstrapComponents = function bootstrapComponents() {
    this.components = BootstrapUtility.bootstrap(this.componentList, this.$element, this.formEventBus, this.routeModel, this.i18nModel);
    return this;
};

/**
 * Sets form submission state based on whether there are invalid validators.
 * Called whenever a new validator state event is received.
 *
 * @method disable
 * @private
 * @chainable
 */
proto.isFormValid = function isFormValid() {
    return this.invalidValidator.length === 0;
};

/**
 * Checks if the validator is registered as invalid.
 * Runs every time a new validation event is received.
 *
 * @method isValidatorRegisteredAsInvalid
 * @private
 */
proto.isValidatorRegisteredAsInvalid = function isValidatorRegisteredAsInvalid(validatorId) {
    return this.invalidValidator.indexOf(validatorId) !== -1;
};

/**
 * Pushes an invalid validator to the invalid Validator array if it is not already in there.
 * Runs when an invalid event is received from a validator.
 *
 * @method registerValidatorAsInvalid
 * @private
 */
proto.registerValidatorAsInvalid = function registerValidatorAsInvalid(validatorId) {
    if (!this.isValidatorRegisteredAsInvalid(validatorId)) {
        this.invalidValidator.push(validatorId);
    }
};

/**
 * Checks if the validator is registered as invalid.
 * Runs every time a new validation event is received.
 *
 * @method deregisterValidatorAsInvalid
 * @private
 */
proto.deregisterValidatorAsInvalid = function deregisterValidatorAsInvalid(validatorId) {
    if (this.isValidatorRegisteredAsInvalid(validatorId)) {
        const index = this.invalidValidator.indexOf(validatorId);
        if (index !== -1) {
            this.invalidValidator.splice(index, 1);
        }
    }
};

/**
 * Checks server error for form exists. If not, creates client-side error from template
 * and scrolls to top of page.
 *
 * @method displayFormLevelError
 * @private
 */
proto.displayFormLevelError = function displayFormLevelError(validatorId) {
    // check if form should display top-level error
    const displaySuper = this.$element.data(BaseForm.DATA_SUPER_VALIDATION) == true;

    // cache server side error if it exists
    const $serverError = $(BaseForm.SELECTORS.FORM_ERROR);

    // kick off form level error
    if (displaySuper) {
        // if there isn't already a server side error, create one from the template
        if (!$serverError.length && !this.errorHasBeenDisplayed) {
            const errorTemplate =
                `<div class="alert alert_error">${I18n['user.form.form_level_error']}</div>`;
            const errorHtml = $(errorTemplate)
                .attr('js-form-clientError', '')
                .attr('tabindex', -1);
            this.$element.prepend(errorHtml);
            this.errorHasBeenDisplayed = true;
        }
        // find top position of either error
        if ($serverError.length) {
            var position = $serverError.position();
        } else {
            var position = this.$element.position();
        }
        // scroll a little way above the error message
        window.scrollTo(0, position.top - 120);
        // focus the error message
        this.$element.find('[js-form-clientError]').focus();
    }
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Event Handler for the enable component event
 * Runs the enable method
 *
 * @method onEnable
 * @private
 */
proto.onEnable = function onEnable() {
    if (this.isEnabled) { return; }
    this.enable();
    return this;
};

/**
 * Checks if the validator is registered as invalid.
 * Runs every time a new validation event is received.
 *
 * @method onFailedValidatorValidation
 * @private
 */
proto.onFailedValidatorValidation = function onFailedValidatorValidation(event, validator) {
    this.registerValidatorAsInvalid(validator);
    if (this.hasBeenInteractedWith) {
        this.displayErrorMessage(validator);
    }
};

/**
 * Checks if the validator is registered as invalid.
 * Runs every time a new validation event is received.
 *
 * @method deregisterValidatorAsInvalid
 * @private
 */
proto.onSuccessfulValidatorValidation = function onSuccessfulValidatorValidation(event, validator) {
    this.deregisterValidatorAsInvalid(validator);
};

proto.onSubmit = function onSubmit(event) {
    this.formEventBus.trigger('submit');
    if (!this.isFormValid()) {
        event.preventDefault();
        this.displayFormLevelError();
    }
};

proto.onCheckFormValidation = function onCheckFormValidation(event, validatorElementId) {
    this.formEventBus.trigger('checkValidatorValidation', validatorElementId);
};


export default BaseForm;
