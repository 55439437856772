/**
 * @fileOverview Legacy Organization Model File
 * @author Noah Blon nblon@nerdery.com
 */
import BaseModel from './LegacyBaseModel';

/**
 * LegacyOrganizationModel
 *
 * @class LegacyOrganizationModel
 * @constructor
 *
 */
var LegacyOrganizationModel = function() {
    this.init();
};

// Inheritance
LegacyOrganizationModel.prototype = new BaseModel();
var proto = LegacyOrganizationModel.prototype;
proto.constructor = LegacyOrganizationModel;
proto.super = BaseModel.prototype;

/**
 * Initializes the Legacy Organization Model
 * Sets up the local instance variables and defaults
 *
 * @for BreedModel
 * @method init
 * @chainable
 * @returns {BreedModel.prototype}
 * @private
 */
proto.init = function init() {
    this.super.init.call(this);

    /**
     * Organization id, such as CA123
     *
     * @for LegacyOrganizationModel
     * @property id
     * @type {String}
     * @default ''
     */
    this.id = '';

    /**
     * Organization name
     *
     * @for LegacyOrganizationModel
     * @property name
     * @type {String}
     * @default ''
     */
    this.name = '';

    /**
     * Organization email
     *
     * @for LegacyOrganizationModel
     * @property email
     * @type {String}
     * @default ''
     */
    this.email = '';

    /**
     * Organization website url
     *
     * @for LegacyOrganizationModel
     * @property url
     * @type {String}
     * @default ''
     */
    this.url = '';

    /**
     * Organization phone number
     *
     * @for LegacyOrganizationModel
     * @property phone
     * @type {Number}
     * @default 0
     */
    this.phone = 0;

    /**
     * Organization email
     *
     * @for LegacyOrganizationModel
     * @property email
     * @type {String}
     * @default ''
     */
    this.fax = null;

    /**
     * Object holding organization address properties
     *
     * @for LegacyOrganizationModel
     * @property address
     * @type {Object}
     * @default {}
     */
    this.address = {};

    /**
     * Organization street address line 1
     *
     * @for LegacyOrganizationModel
     * @property address.address1
     * @type {String}
     * @default ''
     */
    this.address.address1 = '';

    /**
     * Organization street address line 2
     *
     * @for LegacyOrganizationModel
     * @property address.address2
     * @type {String}
     * @default ''
     */
    this.address.address2 = '';

    /**
     * Organization city
     *
     * @for LegacyOrganizationModel
     * @property address.city
     * @type {String}
     * @default ''
     */
    this.address.city = '';

    /**
     * Organization state abbreviation
     *
     * @for LegacyOrganizationModel
     * @property address.state
     * @type {String}
     * @default ''
     */
    this.address.state = '';

    /**
     * Organization city
     *
     * @for LegacyOrganizationModel
     * @property address.postalCode
     * @type {Number}
     * @default 0
     */
    this.address.postalCode = 0;

    /**
     * Organization country
     *
     * @for LegacyOrganizationModel
     * @property address.country
     * @type {String}
     * @default ''
     */
    this.address.country = '';

    return this;
};

/**
 * Parses json response from server into model.
 * If API parameters are added/removed/changed, reflect those changes here.
 *
 * @for LegacyOrganizationModel
 * @method fromJSON
 * @public
 */
proto.fromJSON = function(json) {
    this.id = json['id'];
    this.name = json['name'];
    this.email = json['email'];
    this.url = json['url'];
    this.phone = json['phone'];
    this.fax = json['fax'];
    this.address.address1 = json.location.address['address1'];
    this.address.address2 = json.location.address['address2'];
    this.address.city = json.location.address['city'];
    this.address.state = json.location.address['state'];
    this.address.postalCode = json.location.address['postal_code'];
    this.address.country = json.location.address['country'];
};

export default LegacyOrganizationModel;
