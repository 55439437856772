/**
 * @type {Object}
 */
export const STATE = {
    IDLE: 'idle',
    LOGIN_MODAL: 'loginModal',
    REGISTRATION_MODAL: 'registrationModal',
    MOBILE_MENU_MODAL: 'mobileMenuModal',
    AUTHENTICATION: 'authenticationModal',
    SAVE_SEARCH: 'saveSearch',
    OPEN_IDLE: 'openIdle',
    OPEN_NO_CLOSE: 'openNoClose',
    CONTENT: 'content',
    CONTROLS: 'controls',
};

/**
 * @type {Object}
 */
export const EVENT = {
    CLOSE: 'close',
    LOGIN_REGISTER_CLICK: 'loginRegisterClick',
    MOBILE_MENU_CLICK: 'mobileMenuClick',
    LOGIN_SUCCESS: 'loginSuccess',
    LOGIN_ERROR: 'loginError',
    REGISTER_CLICK: 'registerClick',
    REGISTRATION_SUCCESS: 'registrationSuccess',
    REGISTRATION_ERROR: 'registrationError',
    AUTHENTICATION_CLICK: 'authenticationClick',
    TOGGLE_CLOSE: 'toggleClose',
};

/**
 * @type {Object}
 */
export const ACTION = {
    REFOCUS_TRIGGER: 'refocusTrigger',
    REGISTER_CLICK: 'registerClick',
};

/**
 * @type {Object}
 */
export const statechart = {
    parallel: true,
    states: {
        [STATE.CONTENT]: {
            initial: STATE.IDLE,
            states: {
                [STATE.IDLE]: {
                    on: {
                        [EVENT.LOGIN_REGISTER_CLICK]: STATE.AUTHENTICATION,
                        [EVENT.AUTHENTICATION_CLICK]: STATE.AUTHENTICATION,
                        [EVENT.MOBILE_MENU_CLICK]: STATE.MOBILE_MENU_MODAL,
                    },
                },
                [STATE.AUTHENTICATION]: {
                    on: {
                        [EVENT.CLOSE]: {
                            [STATE.IDLE]: {},
                        },
                    },
                },
                [STATE.SAVE_SEARCH]: {
                    on: {
                        [EVENT.CLOSE]: {
                            [STATE.IDLE]: {},
                        },
                    },
                },
                [STATE.MOBILE_MENU_MODAL]: {
                    on: {
                        [EVENT.CLOSE]: {
                            [STATE.IDLE]: {},
                        },
                        [EVENT.LOGIN_REGISTER_CLICK]: STATE.AUTHENTICATION,
                        [EVENT.AUTHENTICATION_CLICK]: STATE.AUTHENTICATION,
                    },
                },
            },
        },
        [STATE.CONTROLS]: {
            initial: STATE.IDLE,
            states: {
                [STATE.IDLE]: {
                    on: {
                        [EVENT.LOGIN_REGISTER_CLICK]: STATE.OPEN_IDLE,
                        [EVENT.AUTHENTICATION_CLICK]: STATE.OPEN_IDLE,
                    },
                },
                [STATE.OPEN_IDLE]: {
                    on: {
                        [EVENT.CLOSE]: {
                            [STATE.IDLE]: {
                                actions: [ACTION.REFOCUS_TRIGGER],
                            },
                        },
                        [EVENT.TOGGLE_CLOSE]: STATE.OPEN_NO_CLOSE,
                    },
                },
                [STATE.OPEN_NO_CLOSE]: {
                    on: {
                        [EVENT.CLOSE]: {
                            [STATE.IDLE]: {
                                actions: [ACTION.REFOCUS_TRIGGER],
                            },
                        },
                        [EVENT.TOGGLE_CLOSE]: STATE.OPEN_IDLE,
                    },
                },
            },
        },
    },
};
