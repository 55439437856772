import BaseModel from './BaseModel';
import AddressModel from './AddressModel';
import _get from 'lodash/get';

export default class OrganizationModel extends BaseModel {
    get defaultData() {
        return {
            address: new AddressModel(),
            adoption: {
                policy: '',
                url: '',
            },
            distance: 0,
            email: '',
            hours: {
                sunday: '',
                monday: '',
                tuesday: '',
                wednesday: '',
                thursday: '',
                friday: '',
                saturday: '',
            },
            id: '',
            missionStatement: '',
            name: '',
            phone: '',
            photos: [],
            socialMedia: {
                facebook: '',
                instagram: '',
                twitter: '',
                pinterest: '',
                youtube: '',
            },
            url: '',
            website: '',
        };
    }

    /**
     * fromServer
     * API:
     * [GET] /organizations/{id}
     * [GET] /happy-tails/{id} (organization)
     * @static
     * @param {object} organizationData Server Data
     *
     * @return {OrganizationModel}
     */
    static fromServer(organizationData) {
        const model = new OrganizationModel(
            this._mapServerDataToClientside(organizationData)
        );
        return model;
    }

    /**
     * _mapServerDataToClientside - helper to translate server data to object
     * @static
     * @param {object} organizationData
     *
     * @return {object}
     */
    static _mapServerDataToClientside(organizationData) {
        return {
            address: AddressModel.fromServer(
                _get(organizationData, 'address', {})
            ),
            adoption: _get(organizationData, 'adoption', {
                policy: '',
                url: '',
            }),
            distance: _get(organizationData, 'distance', 0),
            email: _get(organizationData, 'email', ''),
            hours: _get(organizationData, 'hours', {
                sunday: '',
                monday: '',
                tuesday: '',
                wednesday: '',
                thursday: '',
                friday: '',
                saturday: '',
            }),
            id: _get(organizationData, 'id', ''),
            missionStatement: _get(organizationData, 'mission_statement', ''),
            name: _get(organizationData, 'name', ''),
            phone: _get(organizationData, 'phone', ''),
            photos: _get(organizationData, 'social_media', []),
            socialMedia: _get(organizationData, 'social_media', {
                facebook: '',
                instagram: '',
                twitter: '',
                pinterest: '',
                youtube: '',
            }),
            url: _get(organizationData, 'url', ''),
            website: _get(organizationData, 'website', ''),
        };
    }

    /**
     * address - AddressModel
     *
     * @return {AddressModel}
     */
    get address() {
        return this._data.address;
    }

    /**
     * adoption
     *
     * @return {object} {policy: '', url: ''}
     */
    get adoption() {
        return this._data.adoption;
    }

    /**
     * distance
     *
     * @return {number} Fallback: 0
     */
    get distance() {
        return this._data.distance;
    }

    /**
     * email
     *
     * @return {string}
     */
    get email() {
        return this._data.email;
    }

    /**
     * hours
     *
     * @return {object} Fallback: {DAY: '', ...}
     */
    get hours() {
        return this._data.hours;
    }

    /**
     * id - Organization ID
     *
     * @return {string} NJ322
     */
    get id() {
        return this._data.id;
    }

    /**
     * missionStatement
     *
     * @return {string}
     */
    get missionStatement() {
        return this._data.missionStatement;
    }

    /**
     * name
     *
     * @return {string}
     */
    get name() {
        return this._data.name;
    }

    /**
     * phone
     *
     * @return {string}
     */
    get phone() {
        return this._data.phone;
    }

    /**
     * photos
     *
     * @return {array} [{SIZE: '', ...}, {SIZE: '', ...}]
     */
    get photos() {
        return this._data.photos;
    }

    /**
     * socialMedia
     *
     * @return {object} {SOCIAL PLATFORM: '', ...}
     */
    get socialMedia() {
        return this._data.socialMedia;
    }

    /**
     * url
     *
     * @return {string}
     */
    get url() {
        return this._data.url;
    }

    /**
     * website
     *
     * @return {string}
     */
    get website() {
        return this._data.website;
    }
}
