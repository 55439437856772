import BaseComponent from '../../components/BaseComponent/BaseComponent';
const I18nJSON = require('../../data/i18nJSON.json');

var ConditionalRequiredValidator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
ConditionalRequiredValidator.prototype = new BaseComponent();
var proto = ConditionalRequiredValidator.prototype;
proto.constructor = ConditionalRequiredValidator;
proto._super = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

ConditionalRequiredValidator.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
ConditionalRequiredValidator.SELECTORS.ELEMENT = '.js-conditionalValidatorRequired';

ConditionalRequiredValidator.SELECTORS.FIELD = ConditionalRequiredValidator.SELECTORS.ELEMENT + '-field';

ConditionalRequiredValidator.SELECTORS.ERROR = ConditionalRequiredValidator.SELECTORS.ELEMENT + '-error';

ConditionalRequiredValidator.SELECTORS.LABEL = ConditionalRequiredValidator.SELECTORS.ELEMENT + '-label';

ConditionalRequiredValidator.SELECTORS.ERROR_MESSAGE_KEY = 'user.form.field_required_error_message';


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

proto.init = function init($element, eventBus, id) {
    this._super.init.call(this, $element, eventBus);

    this.errorMessage = I18nJSON[ConditionalRequiredValidator.SELECTORS.ERROR_MESSAGE_KEY];

    this.hasBeenInteractedWith = false;

    this.isLocked = true;

    this.setupHandlers()
        .createChildren();

    this.id = this.$input.attr('id');
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onBlurHandler = this.onBlur.bind(this);
    this.onChangeHandler = this.onChange.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    this.onUnlockConditionalValidatorHandler = this.onUnlockConditionalValidator.bind(this);
    this.onLockConditionalValidatorHandler = this.onLockConditionalValidator.bind(this);
    this.onCheckValidatorValidationHandler = this.onCheckValidatorValidation.bind(this);
    return this;
};

/**
 * Create the children for the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$label = this.$element.find(ConditionalRequiredValidator.SELECTORS.LABEL);
    this.$input = this.$element.find(ConditionalRequiredValidator.SELECTORS.FIELD);
    this.$error = this.$element.find(ConditionalRequiredValidator.SELECTORS.ERROR);
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners()
        .checkFieldValidity();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$input.on('blur change', this.onBlurHandler);
    this.eventBus.on('submit', this.onSubmitHandler);
    this.eventBus.on('unlockConditionalValidator', this.onUnlockConditionalValidatorHandler)
        .on('lockConditionalValidator', this.onLockConditionalValidatorHandler)
        .on('checkValidatorValidation', this.onCheckValidatorValidationHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);

    this.onBlurHandler = null;

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.setInputInvalidStyles = function setFieldInvalidStyles() {
    if (this.hasBeenInteractedWith) {
        this.$input.addClass('m-field_error');
    }
    return this;
};

proto.clearInputInvalidStyles = function clearInputInvalidStyles() {
    this.$input.removeClass('m-field_error');
    return this;
};

proto.setLabelInvalidStyles = function setLabelInvalidStyles() {
    if (this.hasBeenInteractedWith) {
        this.$label.addClass('label_error');
    }
    return this;
};

proto.clearLabelInvalidStyles = function clearLabelInvalidStyles() {
    this.$label.removeClass('label_error');
    return this;
};

proto.showErrorMessage = function showErrorMessage() {
    if (this.hasBeenInteractedWith) {
        this.$error.empty()
            .html(this.errorMessage);
    }
    return this;
};

proto.hideErrorMessage = function hideErrorMessage() {
    this.$error.empty();
    return this;
};

proto.inputHasValue = function inputHasValue() {
    return this.$input.val() != '';
};

proto.dispatchSuccessfulValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('successfulValidatorValidation', this);
    return this;
};

proto.dispatchFailedValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('failedValidatorValidation', this);
    return this;
};

proto.checkFieldValidity = function checkFieldValidity() {
    if (!this.isLocked) {
        if (this.inputHasValue()) {
            this.dispatchSuccessfulValidatorValidationEvents()
                .hideErrorMessage()
                .clearInputInvalidStyles()
                .clearLabelInvalidStyles();
        } else {
            this.dispatchFailedValidatorValidationEvents()
                .showErrorMessage()
                .setInputInvalidStyles()
                .setLabelInvalidStyles();
        }
    }
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Event handler for the country select box changing
 */
proto.onBlur = function onBlur(event) {
    event.preventDefault();
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

proto.onSubmit = function onSubmit() {
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

proto.onChange = function onChange() { };

/*
 * Enable the validator when hearing the unlock event.  This will be triggered based on conditions
 * from a source component, such as selecting a country now requiring a postal code.
 *
 * @method onLockConditionalValidator
 * @param {Object} event coming through formEventBus
 * @param {String} ID to parse target of event to this component
 */
proto.onUnlockConditionalValidator = function onUnlockConditionalValidator(event, ID) {
    if (ID === this.id) {
        this.isLocked = false;
    }

    this.checkFieldValidity();
};

/*
 * Disable the validator when hearing the lock event.  This will be triggered based on conditions
 * from a source component, such as selecting a country now NOT requiring a postal code.
 *
 * @method onLockConditionalValidator
 * @param {Object} event coming through formEventBus
 * @param {String} ID to parse target of event to this component
 */
proto.onLockConditionalValidator = function onLockConditionalValidator(event, ID) {
    if (ID == this.id) {
        this.isLocked = true;
        this.dispatchSuccessfulValidatorValidationEvents()
            .hideErrorMessage()
            .clearInputInvalidStyles()
            .clearLabelInvalidStyles();
    }
};

proto.onCheckValidatorValidation = function onCheckValidatorValidation(event, validatorElementId) {
    if (validatorElementId == this.id) {
        this.checkFieldValidity();
    }
};

export default ConditionalRequiredValidator;
