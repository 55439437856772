import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import CouponRepository from '../../../../core/scripts/repositories/CouponRepository';

var RetailerRelatedInputs = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
RetailerRelatedInputs.prototype = new BaseComponent();
var proto = RetailerRelatedInputs.prototype;
proto.constructor = RetailerRelatedInputs;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
RetailerRelatedInputs.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
RetailerRelatedInputs.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
RetailerRelatedInputs.SELECTORS.ELEMENT = '.js-retailerType';

/**
 * Selector for the retailer type select list. Changing this loads in the breeds for the retailer type.
 *
 * @property SELECTORS.INPUT_RETAILER_TYPE
 * @static
 * @final
 * @type {String}
 */
RetailerRelatedInputs.SELECTORS.INPUT_RETAILER_TYPE =
    RetailerRelatedInputs.SELECTORS.ELEMENT + '-inputRetailerType';

/**
 * Selector for the retailers select list.
 *
 * @property SELECTORS.INPUT_RETAILERS
 * @static
 * @final
 * @type {String}
 */
RetailerRelatedInputs.SELECTORS.INPUT_RETAILERS =
    RetailerRelatedInputs.SELECTORS.ELEMENT + '-inputRetailers';

/**
 * Selector for the retailers group.
 *
 * @property SELECTORS.GROUP_RETAILERS
 * @static
 * @final
 * @type {String}
 */
RetailerRelatedInputs.SELECTORS.GROUP_RETAILERS =
    RetailerRelatedInputs.SELECTORS.ELEMENT + '-groupRetailers';

/**
 * Breeds for retailer type endpoint.
 *
 * @property ENDPOINT_RETAILER
 * @static
 * @final
 * @type {String}
 */
RetailerRelatedInputs.ENDPOINT_RETAILER = '/user/api/pet-food-retailers';

/**
 * Retailer type parameter for retailer type endpoint.
 *
 * @property ENDPOINT_TYPE_PARAMETER
 * @static
 * @final
 * @type {String}
 */
RetailerRelatedInputs.ENDPOINT_TYPE_PARAMETER = '?retailerType=';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    this.couponRepository = new CouponRepository();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangeInputRetailerTypeHandler = this.onChangeInputRetailerType.bind(
        this
    );
    this.onGetRetailersForRetailerTypeSuccessHandler = this.onGetRetailersForRetailerTypeSuccess.bind(
        this
    );
    this.onGetRetailersForRetailerTypeFailedHandler = this.onGetRetailersForRetailerTypeFailed.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputRetailerType = this.$element.find(
        RetailerRelatedInputs.SELECTORS.INPUT_RETAILER_TYPE
    );
    this.$groupRetailers = this.$element.find(
        RetailerRelatedInputs.SELECTORS.GROUP_RETAILERS
    );
    this.$inputRetailers = this.$element.find(
        RetailerRelatedInputs.SELECTORS.INPUT_RETAILERS
    );
    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners().onLoadRetailerCheck();
    return this;
};

/**
 * Setsup event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$inputRetailerType.on('change', this.onChangeInputRetailerTypeHandler);
    return this;
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$inputRetailerType.off(
        'change',
        this.onChangeInputRetailerTypeHandler
    );
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On load check to see if the retailer input should be disabled
 *
 * @method onLoadRetailerCheck
 * @private
 * @chainable
 */
proto.onLoadRetailerCheck = function onLoadRetailerCheck() {
    var retailerTypeId = this.$inputRetailerType.val();
    var optionLength = this.$inputRetailers.children('option').length;
    if (!retailerTypeId.length || optionLength <= 1) {
        this.emptyRetailerSelects()
            .disableRetailerInput()
            .toggleRetailersGroup(false);
    } else {
        this.toggleRetailersGroup(true);
    }

    return this;
};

/**
 * kicks off request for retailers according to
 * that retailer type and population of the retailers inputs.
 *
 * @method checkInputRetailerType
 * @private
 * @chainable
 */
proto.checkInputRetailerType = function checkInputRetailerType() {
    var retailerTypeId = this.$inputRetailerType.val();
    if (retailerTypeId.length) {
        this.disableTypeInput()
            .disableRetailerInput()
            .getRetailersForRetailerType(retailerTypeId)
            .then(
                this.onGetRetailersForRetailerTypeSuccessHandler,
                this.onGetRetailersForRetailerTypeFailedHandler
            );
    } else {
        this.emptyRetailerSelects()
            .disableRetailerInput()
            .toggleRetailersGroup(false);
    }

    return this;
};

/**
 * AJAX request for retailers according to retailer types.
 * Run when the retailer types input is changed.
 *
 * @method getRetailersForRetailerType
 * @param retailerTypeId corresponding to the pet type
 * @private
 */
proto.getRetailersForRetailerType = function getRetailersForRetailerType(
    retailerTypeId
) {
    return this.couponRepository.getRetailersForType(retailerTypeId);
};

/**
 * Appends a default select value to the retailers list.
 *
 * @method appendDefaultRetailerValue
 * @private
 * @chainable
 */
proto.appendDefaultRetailerValue = function appendDefaultRetailerValue() {
    // TODO I18n
    this.$inputRetailers.append('<option>Select A Retailer</option>');
    return this;
};

/**
 * Appends the retailers values for the selected retail type to the select list.
 * Run on a successful get for retailers according to retail type.
 *
 * @method appendRetailerValues
 * @param retailersJSON for pet type returned from server
 * @private
 * @chainable
 */
proto.appendRetailerValues = function appendRetailerValues(retailersJSON) {
    for (var retailerKey in retailersJSON.options) {
        var retailer = retailersJSON.options[retailerKey];
        this.$inputRetailers.append(
            '<option value="' + retailerKey + '">' + retailer + '</option>'
        );
    }
    return this;
};

/**
 * Empties the retailers select inputs.
 * Run on a successful get for retailers according to retailer type to reset the field.
 *
 * @method emptyRetailerSelects
 * @private
 * @chainable
 */
proto.emptyRetailerSelects = function emptyRetailerSelects() {
    this.$inputRetailers.empty();
    return this;
};

/**
 * Disables the retailer type input.
 * Run while the get for retailers is being processed to prevent additional user input.
 *
 * @method disableTypeInput
 * @private
 * @chainable
 */
proto.disableTypeInput = function disableTypeInput() {
    this.$inputRetailerType.attr('disabled', true);
    return this;
};

/**
 * Enables the retailer type input.
 * Run after a successful get for retailers to reenable user input.
 *
 * @method enableTypeInput
 * @private
 * @chainable
 */
proto.enableTypeInput = function enableTypeInput() {
    this.$inputRetailerType.attr('disabled', false);
    return this;
};

/**
 * Disables the retailers input.
 * Run while the get for retailers is being processed to prevent additional user input.
 *
 * @method disableRetailerInput
 * @private
 * @chainable
 */
proto.disableRetailerInput = function disableRetailerInput() {
    this.$inputRetailers.attr('disabled', true);
    return this;
};

/**
 * Enables the retailers input.
 * Run after a successful get for retailers to reenable user input.
 *
 * @method enableRetailerInput
 * @private
 * @chainable
 */
proto.enableRetailerInput = function enableRetailerInput() {
    this.$inputRetailers.attr('disabled', false);
    return this;
};

/**
 * Enables the retailers input.
 * Run after a successful get for retailers to reenable user input.
 *
 * @method toggleRetailersGroup
 * @param {Boolean}
 * @private
 * @chainable
 */
proto.toggleRetailersGroup = function toggleRetailersGroup(show) {
    this.$groupRetailers.toggleClass('u-isHidden', !show);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the retailer type input, kicks off request for retailers.
 *
 * @method onChangeInputRetailerType
 * @private
 */
proto.onChangeInputRetailerType = function onChangeInputRetailerType() {
    this.checkInputRetailerType();
};

/**
 * On successful get of retailers, appends the values to appropriate select lists and reenables input.
 *
 * @method onChangeInputRetailerType
 * @param retailersJSON returned from the server
 * @private
 */
proto.onGetRetailersForRetailerTypeSuccess = function onGetRetailersForRetailerTypeSuccess(
    retailersJSON
) {
    this.enableTypeInput();

    // check if we get a not-empty object back from request
    if (
        retailersJSON.options !== null &&
        typeof retailersJSON.options === 'object' &&
        !jQuery.isEmptyObject(retailersJSON.options)
    ) {
        this.emptyRetailerSelects()
            .appendDefaultRetailerValue()
            .appendRetailerValues(retailersJSON)
            .enableRetailerInput()
            .toggleRetailersGroup(true);
    } else {
        this.emptyRetailerSelects()
            .disableRetailerInput()
            .toggleRetailersGroup(false);
    }
};

/**
 * On a failed get for breeds, emits event to let form handle next steps.
 *
 * @method onGetRetailersForRetailerTypeFailed
 * @private
 */
proto.onGetRetailersForRetailerTypeFailed = function onGetRetailersForRetailerTypeFailed() {
    this.eventBus.trigger('getRetailersForRetailerFailed');
};

export default RetailerRelatedInputs;
