const statechart = {
    initial: 'isHiddenState',
    states: {
        isHiddenState: {
            on: {
                triggerClickedEvent: 'isVisibleState',
            },
        },
        isVisibleState: {
            on: {
                triggerClickedEvent: 'isHiddenState',
            },
        },
    },
};

export default statechart;
