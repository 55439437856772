import React, { Component, Fragment } from 'react';

import RevealSecret from '../RevealSecret';
import CopyToClipboard from '../CopyToClipboard';

/**
 * Container that holds the RevealSecret and CopyToClipboard components
 * that comprise the show and copy secret functionality.
 */
class ShowCopySecret extends Component {
    render() {
        return (
            <Fragment>
                <RevealSecret secret={this.props.textToCopy} />
                <CopyToClipboard textToCopy={this.props.textToCopy} />
            </Fragment>
        );
    }
}

export default ShowCopySecret;
