/**
 * @fileOverview UserPetRepository
 * @author Noah Blon nblon@nerdery.com
 */
import BaseRepository from './BaseRepository';
import PetModel from '../models/PetModel';
import $ from 'jquery';

/**
 * UserPetRepository
 * Handles API interaction related to a user's pets
 *
 * @class UserPetRepository
 * @constructor
 */
var UserPetRepository = function UserPetRepository() {
    this.init();
};

UserPetRepository.prototype = Object.create(BaseRepository.prototype);
UserPetRepository.prototype.constructor = UserPetRepository;

var proto = UserPetRepository.prototype;
var _super = BaseRepository.prototype;

/**
 * Initializes the Repository
 * Caches configuration to object properties.
 *
 * @private
 * @method init
 */
proto.init = function init() {
    _super.init.call(this);

    /**
     * Endpoint to post pets to
     *
     * @for UserPetRepository
     * @property pathCreatePet
     * @type {String}
     */
    this.pathCreatePet = this.routes.api['createUserPet'];

    /**
     * Endpoint to post edit pet data to
     *
     * @for UserPetRepository
     * @property pathEditPet
     * @type {String}
     */
    this.pathEditPet = this.routes.api['updateUserPet'];

    /**
     * Endpoint to request deletion of a pet
     *
     * @for UserPetRepository
     * @property pathDeletePet
     * @type {String}
     */
    this.pathDeletePet = this.routes.api['deleteUserPet'];
};

/**
 * Create a new user pet
 *
 * @public
 * @method createPet
 * @param data pet details
 */
proto.createPet = function createPet(data) {
    var deferred = $.Deferred();
    this.post('/user/api/user-pet/', data)
        .then(this.onCreatePetDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));
    return deferred.promise();
};

/**
 * Edit a user's existing pet
 *
 * @public
 * @method editPet
 * @param petId corresponding to the existing pet
 * @param data pet details
 */
proto.editPet = function editPet(petId, data) {
    var deferred = $.Deferred();
    var token = /\{pet\}/gi;
    var path = this.pathEditPet.replace(token, petId);
    this.put(path, data)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));
    return deferred.promise();
};

/**
 * Delete a user's existing pet
 *
 * @public
 * @method deletePet
 * @param petId corresponding to the existing pet
 * @param tokenCSRF to verify authenticity of request
 */
proto.deletePet = function deletePet(petId, tokenCSRF) {
    var deferred = $.Deferred();
    var token = /\{pet\}/gi;
    var path = this.pathDeletePet.replace(token, petId) + '?token=' + tokenCSRF + '';
    this.remove(path)
        .done(deferred.resolve())
        .fail(this.onAJAXFail.bind(this, deferred));
    return deferred.promise();
};

/**
 * Create a new pet model when creation has finished successfully.
 *
 * @private
 * @method onCreatePetDone
 * @param deferred to resolve after collection is built
 * @param json pet details
 */
proto.onCreatePetDone = function onCreatePetDone(deferred, json) {
    var petModel = new PetModel();
    petModel.fromJSON(json);
    deferred.resolve(petModel);
};


export default UserPetRepository;
