/**
 * @fileOverview Base Collection File
 * @author Noah Blon nblon@nerdery.com
 */

/**
 * Abstract model object tp inherit from
 * Stores data about the Game
 *
 * @class BaseCollection
 * @constructor
 *
 */
var BaseCollection = function() { };

var proto = BaseCollection.prototype;

/**
 * Initializes the Collection
 *
 * @private
 * @method init
 */
proto.init = function init() { };

/**
 * Destroys the model
 * Should be called when the object should be left unused
 *
 * @for BaseCollection
 * @method destroy
 * @chainable
 * @returns {BaseCollection.prototype}
 */
proto.destroy = function() {
    for (var key in this) {
        if (this.hasOwnProperty(key)) {
            this[key] = null;
        }
    }

    return this;
};

export default BaseCollection;
