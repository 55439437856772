import $ from 'jquery';
import BaseEnsightenAnalytics from '../../../../core/scripts/analytics/ensighten/BaseEnsightenAnalytics';

/**
 * About Me Form Analytic tracking component.
 *
 * @class AboutMeEnsighten
 * @constructor
 */
const AboutMeEnsighten = function($element, eventBus) {
    BaseEnsightenAnalytics.call(this, $element, eventBus);
    this.init($element, eventBus);
};

// Inheritance
AboutMeEnsighten.prototype = Object.create(BaseEnsightenAnalytics.prototype);
AboutMeEnsighten.prototype.constructor = AboutMeEnsighten;

const proto = AboutMeEnsighten.prototype;
const _super = BaseEnsightenAnalytics.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @extends {BaseForm.setupHandlers}
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onInputNameBlurHandler = this.onInputNameBlur.bind(this);
    this.onInputPhoneBlurHandler = this.onInputPhoneBlur.bind(this);
    this.onInputAddress1BlurHandler = this.onInputAddress1Blur.bind(this);
    this.onInputPetTypeChangeHandler = this.onInputPetTypeChange.bind(this);
    this.onBtnAddPetClickHandler = this.onBtnAddPetClick.bind(this);

    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @extends {BaseForm.createChildren}
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputFirstName = this.$element.find(
        AboutMeEnsighten.SELECTORS.INPUT_FIRST_NAME
    );
    this.$inputLastName = this.$element.find(
        AboutMeEnsighten.SELECTORS.INPUT_LAST_NAME
    );
    this.$inputPhone = this.$element.find(
        AboutMeEnsighten.SELECTORS.INPUT_PHONE
    );
    this.$inputAddress1 = this.$element.find(
        AboutMeEnsighten.SELECTORS.INPUT_ADDRESS_1
    );

    this.$inputPetType = this.$element.find(
        AboutMeEnsighten.SELECTORS.INPUT_PET_TYPE
    );
    this.$btnAddPet = this.$element.find(
        AboutMeEnsighten.SELECTORS.BTN_ADD_PET
    );
    this.$petSection = this.$element.find(
        AboutMeEnsighten.SELECTORS.SECTION_PETS
    );
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @extends {BaseForm.enable}
 * @private
 * @chainable
 */
proto.enable = function enable() {
    _super.enable.call(this);
    this.setupListeners();
    return this;
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @extends {BaseForm.setupListeners}
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    this.$inputFirstName.on('blur', this.onInputNameBlurHandler);
    this.$inputLastName.on('blur', this.onInputNameBlurHandler);

    this.$inputPhone.on('blur', this.onInputPhoneBlurHandler);
    this.$inputAddress1.on('blur', this.onInputAddress1BlurHandler);
    this.$btnAddPet.on('click', this.onBtnAddPetClickHandler);
    // listening for events within the pet section as blocks can be added and deleted containing the pet type input
    this.$petSection.on(
        'change',
        AboutMeEnsighten.SELECTORS.INPUT_PET_TYPE,
        this.onInputPetTypeChangeHandler
    );
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    _super.disable.call(this);
    this.$inputPhone.off('blur', this.onInputPhoneBlurHandler);
    this.$inputAddress1.off('blur', this.onInputAddress1BlurHandler);
    this.$btnAddPet.off('click', this.onBtnAddPetClickHandler);
    this.$petSection.off(
        'change',
        AboutMeEnsighten.SELECTORS.INPUT_PET_TYPE,
        this.onInputPetTypeChangeHandler
    );
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method onInputNameBlur
 * @private
 */
proto.onInputNameBlur = function onInputNameBlur() {
    if (this.$inputFirstName.val() !== '' && this.$inputLastName.val() !== '') {
        this.triggerEvent({
            category: 'about me',
            action: 'enter customer name',
        });
    }
};

/**
 * @method onInputPhoneBlur
 * @private
 */
proto.onInputPhoneBlur = function onInputPhoneBlur() {
    this.triggerEvent({
        category: 'about me',
        action: 'enter phone number',
    });
};

/**
 * @method onInputAddress1Blur
 * @private
 */
proto.onInputAddress1Blur = function onInputAddress1Blur() {
    this.triggerEvent({
        category: 'about me',
        action: 'enter address',
    });
};

/**
 * @method onInputPetTypeChange
 * @param {Event} e
 * @private
 */
proto.onInputPetTypeChange = function onInputPetTypeChange(e) {
    const $currentTarget = $(e.currentTarget);
    const value = $currentTarget.val();
    const selectedOptionLabel = $currentTarget.find(':selected').text();
    // index starts at 0
    const nonZeroIndex = Number($currentTarget.attr('data-petindex')) + 1;
    if (value !== undefined && value !== '') {
        this.triggerEvent({
            category: 'about me',
            action: `pet ${nonZeroIndex} type`,
            label: String(selectedOptionLabel),
        });
    }
};

proto.onBtnAddPetClick = function onBtnAddPetClick() {
    this.triggerEvent({
        category: 'about me',
        action: 'add a pet',
    });
};

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
AboutMeEnsighten.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
AboutMeEnsighten.SELECTORS.ELEMENT = '.js-aboutMeEnsightenAnalytics';

AboutMeEnsighten.SELECTORS.INPUT_FIRST_NAME =
    '#user_extended_profile_profile_firstName';
AboutMeEnsighten.SELECTORS.INPUT_LAST_NAME =
    '#user_extended_profile_profile_lastName';
AboutMeEnsighten.SELECTORS.INPUT_PHONE = `${
    AboutMeEnsighten.SELECTORS.ELEMENT
}-inputPhone`;
AboutMeEnsighten.SELECTORS.INPUT_ADDRESS_1 = `${
    AboutMeEnsighten.SELECTORS.ELEMENT
}-inputAddress1`;
AboutMeEnsighten.SELECTORS.INPUT_PET_TYPE = `${
    AboutMeEnsighten.SELECTORS.ELEMENT
}-inputPetType`;

AboutMeEnsighten.SELECTORS.BTN_ADD_PET = `${
    AboutMeEnsighten.SELECTORS.ELEMENT
}-btnAddPet`;
AboutMeEnsighten.SELECTORS.SECTION_PETS = `${
    AboutMeEnsighten.SELECTORS.ELEMENT
}-sectionPets`;

export default AboutMeEnsighten;
