import BaseComponent from '../../components/BaseComponent/BaseComponent';
const I18nJSON = require('../../data/i18nJSON.json');

// TODO:
// var Templates     = require('helpers/Templates');

// TODO: fix this -- core depending on dotcom template
import passwordPopupHtml from './../../../../dotcom/scripts/templates/passwordPopup.html';

/**
 * Validator for a password field.
 * Display a popup tooltip which shows the validation status of the password as it is being typed.
 *
 * @class PasswordValidatorNotRequired
 * @constructor
 * @author Noah Blon nblon@nerdery.com
 * @extends {BaseComponent}
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus Event Bus shared between components in app
 */
var PasswordValidatorNotRequired = function($element, eventBus, routes, i18nModel) {
    this.init($element, eventBus, routes, i18nModel);
};

// Inheritance
PasswordValidatorNotRequired.prototype = new BaseComponent();
var proto = PasswordValidatorNotRequired.prototype;
proto.constructor = PasswordValidatorNotRequired;
proto._super = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Property values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASS
 * @static
 * @final
 * @type {Object}
 */
PasswordValidatorNotRequired.CLASSES = {};

/**
 * Object to hold selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PasswordValidatorNotRequired.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.ELEMENT = '.js-validatorPasswordNotRequired';

/**
 * Selector for the password field
 *
 * @property SELECTORS.FIELD
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.FIELD = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-field';

/**
 * Selector for the password field
 *
 * @property SELECTORS.FIELD
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.LABEL = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-label';

/**
 * Selector for the validation popup
 *
 * @property SELECTORS.POPUP
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.POPUP = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-popup';

/**
 * Selector for the minimum length indicator
 *
 * @property SELECTORS.MINIMUM_LENGTH_INDICATOR
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.MINIMUM_LENGTH_INDICATOR = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-indicatorLength';

/**
 * Selector for the box containing the rule indicators
 *
 * @property SELECTORS.RULES
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.RULES = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-ruleContainer';

/**
 * Selector for the password is valid message
 *
 * @property SELECTORS.MINIMUM_LENGTH_INDICATOR
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.SELECTORS.VALID_MSG = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-validMessage';

/**
 * Class to add to validity indicator when valid criteria has been met
 *
 * @property CLASSES.RULE_VALID
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.CLASSES.RULE_VALID = 'password-rule_success';

/**
 * Class to add to field if invalid to provide visual feedback of invalid state
 *
 * @property CLASSES.FIELD_ERROR
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.CLASSES.FIELD_ERROR = 'm-field_error';

PasswordValidatorNotRequired.SELECTORS.ERROR = PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-error';


/**
 * Class to add to label if invalid to provide visual feedback of invalid state
 *
 * @property CLASSES.LABEL_ERROR
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.CLASSES.LABEL_ERROR = 'label_error';

/**
 * Class to force popup to collapse at a wider viewport
 *
 * @property CLASSES.COLLAPSE
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.CLASSES.COLLAPSE = 'password-popup_collapseEarly';

/**
 * Data attribute to flag if popup should be collapsed
 *
 * @property DATA_COLLAPSE
 * @static
 * @final
 * @type {String}
 */
PasswordValidatorNotRequired.DATA_COLLAPSE = 'collapse-early';

/**
 * Holds validation rules and the associated selector for element which displays validation status
 *
 * @property VALIDATION_RULES
 * @static
 * @final
 * @type {Object}
 */
PasswordValidatorNotRequired.VALIDATION_RULES = {
    digit: {
        regex: /.*\d/,
        elementSelector: PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-ruleNumbers',
        element: null,
    },
    lowercase: {
        regex: /.*[a-z]/,
        elementSelector: PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-ruleLowercase',
        element: null,
    },
    uppercase: {
        regex: /.*[A-Z]/,
        elementSelector: PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-ruleCaps',
        element: null,
    },
    specialChar: {
        regex: /.*[-`~!@#$%^&*()_=+{}\[\]|\;:'\",.<>\\/?]/,
        elementSelector: PasswordValidatorNotRequired.SELECTORS.ELEMENT + '-ruleSpecialChars',
        element: null,
    },
};

/**
 * Minimum length a password can be
 *
 * @property MINIMUM_LENGTH
 * @static
 * @final
 * @type {Object}
 */
PasswordValidatorNotRequired.MINIMUM_LENGTH = 8;

/**
 * Translation key for password mismatch error
 *
 * @property ERROR_MESSAGE_KEY_MISMATCH
 * @static
 * @final
 * @type {string}
 */
PasswordValidatorNotRequired.ERROR_MESSAGE_KEY_INVALID_PASSWORD = 'user.password.invalid';


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and createPopup
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus, routes, i18nModel) {
    this._super.init.call(this, $element, eventBus, routes, i18nModel);

    /**
     * State variable tracking whether the password entered into the input field is valid
     *
     * @property passwordIsValid
     * @type {Boolean}
     * @default false
     */
    this.passwordIsValid = false;

    /**
     * Property to access the password value in any method
     *
     * @property passwordValue
     * @type {String}
     * @default ''
     */
    this.passwordValue = '';
    this.i18nModel = i18nModel;

    var data = {
        selector: 'js-validatorPasswordNotRequired',
        lang: i18nModel.passwordPopup,
    };
    this.popupTemplate = passwordPopupTemplate(data);
    this.invalidRules = [];
    this.hasBeenInteractedWith = false;


    return this.setupHandlers()
        .createChildren()
        .layoutPopup()
        .createPopupChildren()
        .extendPopup();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onFocusPasswordHandler = this.onFocusPassword.bind(this);
    this.onKeyupPasswordHandler = this.onKeyupPassword.bind(this);
    this.onBlurPasswordHandler = this.onBlurPassword.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);

    return this;
};

/**
 * Create any references to DOM elements necessary.
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$passwordField = this.$element.find(PasswordValidatorNotRequired.SELECTORS.FIELD);
    this.$passwordLabel = this.$element.find(PasswordValidatorNotRequired.SELECTORS.LABEL);
    this.$error = this.$element.find(PasswordValidatorNotRequired.SELECTORS.ERROR);
    return this;
};

proto.layoutPopup = function layoutPopup() {
    this.$passwordField.after(this.popupTemplate);
    return this;
};


proto.createPopupChildren = function createPopupChildren() {
    this.$passwordPopup = this.$element.find(PasswordValidatorNotRequired.SELECTORS.POPUP);
    this.$minimumLengthIndicator = this.$element.find(PasswordValidatorNotRequired.SELECTORS.MINIMUM_LENGTH_INDICATOR);
    this.$validMessage = this.$element.find(PasswordValidatorNotRequired.SELECTORS.VALID_MSG);
    this.$passwordCriteria = this.$element.find(PasswordValidatorNotRequired.SELECTORS.RULES);

    for (var criteria in PasswordValidatorNotRequired.VALIDATION_RULES) {
        PasswordValidatorNotRequired.VALIDATION_RULES[criteria].element =
            this.$element.find(PasswordValidatorNotRequired.VALIDATION_RULES[criteria].elementSelector);
    }
    return this;
};
proto.extendPopup = function extendPopup() {
    if (typeof this.$element.data(PasswordValidatorNotRequired.DATA_COLLAPSE) !== 'undefined') {
        this.$passwordPopup.addClass(PasswordValidatorNotRequired.CLASSES.COLLAPSE);
    }
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);

    this.setupListeners()
        .checkFieldValidity();

    return this;
};


proto.setupListeners = function setupListeners() {
    this.$passwordField.on('focus', this.onFocusPasswordHandler)
        .on('keyup', this.onKeyupPasswordHandler)
        .on('blur', this.onBlurPasswordHandler);
    this.eventBus.on('submit', this.onSubmitHandler);

    return this;
};



/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////


proto.dispatchSuccessfulValidationEvents = function dispatchSuccessfulValidationEvents() {
    this.eventBus.trigger('successfulValidatorValidation', this);
    return this;
};

proto.dispatchFailedValidationEvents = function dispatchFailedValidationEvents() {
    this.eventBus.trigger('failedValidatorValidation', this);
    return this;
};


proto.addInputInvalidStyles = function setFieldInvalidStyles() {
    if (this.hasBeenInteractedWith) {
        this.$passwordLabel.addClass(PasswordValidatorNotRequired.CLASSES.LABEL_ERROR);
        this.$passwordField.addClass(PasswordValidatorNotRequired.CLASSES.FIELD_ERROR);
    }
    return this;
};

proto.removeInputInvalidStyles = function removeInputInvalidStyles() {
    this.$passwordLabel.removeClass(PasswordValidatorNotRequired.CLASSES.LABEL_ERROR);
    this.$passwordField.removeClass(PasswordValidatorNotRequired.CLASSES.FIELD_ERROR);
    return this;
};

proto.showErrorMessage = function showErrorMessage(msg) {
    if (this.hasBeenInteractedWith) {
        this.$error.empty()
            .html(msg);
    }
    return this;
};

proto.hideErrorMessage = function hideErrorMessage() {
    this.$error.empty();
    return this;
};

/**
 * The validation popup shows the validity states of the password criteria
 * as the user types.
 * Shows the popup when the field is focused.
 *
 * @method showPopup
 * @private
 */
proto.showPopup = function showPopup() {
    this.$passwordPopup.show();
};

/**
 * The validation popup shows the validity states of the password criteria
 * as the user types.
 * Hides the popup when the field is blurred.
 *
 * @method hidePopup
 * @private
 */
proto.hidePopup = function hidePopup() {
    this.$passwordPopup.hide();
};

/**
 * An indicator shows whether a certain criteria has been met.
 * Sets the class that to display the indicator's valid
 * state.
 *
 * @method setRuleValid
 * @param {jQuery} $element to add the success state to
 * @private
 */
proto.setRuleIndicatorValid = function setRuleValid($element) {
    $element.addClass(PasswordValidatorNotRequired.CLASSES.RULE_VALID);
};

/**
 * An indicator shows whether a certain criteria has been met.
 * Sets the class that to display the indicator's invalid
 * state.
 *
 * @method setRuleInvalid
 * @param {jQuery} $element to remove the success state from
 * @private
 */
proto.setRuleIndicatorInvalid = function setRuleValid($element) {
    $element.removeClass(PasswordValidatorNotRequired.CLASSES.RULE_VALID);
};

/**
 * When the password is valid, the popup switches to show a message
 * that it is valid.
 * This method is triggered when the password has met the validation
 * requirements
 *
 * @method showPasswordIsValidMsg
 * @private
 */
proto.showPasswordIsValidMsg = function showPasswordIsValidMsg() {
    this.isValidShowing = true;
    this.$validMessage.show();
    this.$passwordCriteria.hide();
    return this;
};

/**
 * When the password is invalid, the popup shows the validation criteria
 * indicators. The password is valid message is toggled when the password
 * goes from valid to invalid.
 *
 * @method hidePasswordIsValidMsg
 * @private
 */
proto.hidePasswordIsValidMsg = function hidePasswordIsValidMsg() {
    this.isValidShowing = false;
    this.$validMessage.hide();
    this.$passwordCriteria.show();
    return this;
};

/**
 * Currently invalid password criteria is registered in an array
 *
 * @method registerInvalidCriteria
 * @param {String} $criteria string identifier of the currently invalid criteria
 * @private
 */
proto.registerInvalidCriteria = function registerInvalidCriteria(criteria) {
    if (this.invalidRules.indexOf(criteria) === -1) {
        this.invalidRules.push(criteria);
    }
};

/**
 * Removes previously invalid criteria from the array if it is now valid
 *
 * @method unregisterInvalidCriteria
 * @param {String} $criteria string identifier of the currently valid criteria
 * @private
 */
proto.unregisterInvalidCriteria = function unregisterInvalidCriteria(criteria) {
    if (this.invalidRules.indexOf(criteria) !== -1) {
        const index = this.invalidRules.indexOf(criteria);
        if (index !== -1) {
            this.invalidRules.splice(index, 1);
        }
    }
};

/**
 * Returns whether the value meets the minimum length requirement.
 *
 * @method isLengthValid
 * @private
 * @returns {Boolean}
 */
proto.isLengthValid = function isLengthValid() {
    return this.passwordValue.length >= PasswordValidatorNotRequired.MINIMUM_LENGTH;
};

/**
 * Returns whether the password is valid against a criteria
 * All criteria are checked when the value is tested for validity
 *
 * @method isCriteriaValid
 * @private
 * @returns {Boolean}
 */
proto.isCriteriaValid = function isCriteriaValid(criteria) {
    return PasswordValidatorNotRequired.VALIDATION_RULES[criteria].regex.exec(this.passwordValue) !== null;
};

/**
 * Returns whether the value is valid against the length and set of criteria.
 * Sets rule indicator states depending on criteria's validity
 * Called whenever the field is checked for validity
 *
 * @method isValueValid
 * @private
 * @returns {Boolean}
 */
proto.isValueValid = function isValueValid() {
    this.passwordValue = this.$passwordField.val();

    if (this.passwordValue === '') {
        for (var criteria in PasswordValidatorNotRequired.VALIDATION_RULES) {
            this.unregisterInvalidCriteria(criteria);
        }
        return true;
    }

    // check against length criteria
    if (this.isLengthValid()) {
        this.setRuleIndicatorValid(this.$minimumLengthIndicator);
    } else {
        this.setRuleIndicatorInvalid(this.$minimumLengthIndicator);
    }

    // check against regex criteria
    for (var criteria in PasswordValidatorNotRequired.VALIDATION_RULES) {
        if (this.isCriteriaValid(criteria)) {
            this.unregisterInvalidCriteria(criteria);
            this.setRuleIndicatorValid(PasswordValidatorNotRequired.VALIDATION_RULES[criteria].element);
        } else {
            this.registerInvalidCriteria(criteria);
            this.setRuleIndicatorInvalid(PasswordValidatorNotRequired.VALIDATION_RULES[criteria].element);
        }
    }
    return this.invalidRules.length <= 1 && this.isLengthValid();
};

proto.doesInputHaveValue = function() {
    return this.$passwordField.val() != '';
};

/**
 * Checks the field validity and messages the form based on the current state.
 * Triggers displaying of feedback based on the validity of the password.
 *
 * @method checkFieldValidity
 * @private
 */
proto.checkFieldValidity = function checkFieldValidity() {
    if (this.isValueValid()) {
        this.dispatchSuccessfulValidationEvents();
        if (this.hasBeenInteractedWith) {
            this.hideErrorMessage()
                .removeInputInvalidStyles()
                .showPasswordIsValidMsg();
        }
    } else {
        this.dispatchFailedValidationEvents()
            .showErrorMessage(this.i18nModel.form.errorPasswordInvalid)
            .addInputInvalidStyles()
            .hidePasswordIsValidMsg();
    }
    return this;
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * onFocusPassword Handler
 * Shows the password validation popup tooltip to assist the user in entering
 * the correct password
 *
 * @method onFocusPassword
 * @param {Object} event
 * @private
 */
proto.onFocusPassword = function onFocusPassword(event) {
    if (this.doesInputHaveValue()) {
        this.showPopup();
    }
};

/**
 * onBlurPassword Handler
 * Hides the validation popup and sets or removes error styles according to the
 * validity of the password value
 *
 * @method onBlurPassword
 * @param {Object} event
 * @private
 */
proto.onBlurPassword = function onBlurPassword(event) {
    this.hidePopup();
    this.hasBeenInteractedWith = true;
    this.eventBus.trigger('confirmPasswordMatch', this);
    this.checkFieldValidity();
};

/**
 * onKeyupPassword Handler
 * On keyup, the password is checked for validity and criteria indicators are updated
 *
 * @method onKeyupPassword
 * @param {Object} event
 * @private
 */
proto.onKeyupPassword = function onKeyupPassword() {
    this.checkFieldValidity();
    if (!this.doesInputHaveValue()) {
        this.hidePopup();
    } else {
        this.showPopup();
    }
};

proto.onSubmit = function() {
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

export default PasswordValidatorNotRequired;
