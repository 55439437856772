import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import CouponRepository from '../../../../core/scripts/repositories/CouponRepository';

var PetDiscoveryRelatedInputs = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PetDiscoveryRelatedInputs.prototype = new BaseComponent();
var proto = PetDiscoveryRelatedInputs.prototype;
proto.constructor = PetDiscoveryRelatedInputs;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
PetDiscoveryRelatedInputs.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PetDiscoveryRelatedInputs.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PetDiscoveryRelatedInputs.SELECTORS.ELEMENT = '.js-petDiscovery';

/**
 * Selector for the pet discovery input.
 *
 * @property SELECTORS.INPUT_METHOD
 * @static
 * @final
 * @type {String}
 */
PetDiscoveryRelatedInputs.SELECTORS.INPUT_METHOD =
    PetDiscoveryRelatedInputs.SELECTORS.ELEMENT + '-methodInput';

/**
 * Selector for the specific pet discovery method.
 *
 * @property SELECTORS.SPECIFY
 * @static
 * @final
 * @type {String}
 */
PetDiscoveryRelatedInputs.SELECTORS.SPECIFY =
    PetDiscoveryRelatedInputs.SELECTORS.ELEMENT + '-specify';

/**
 * Web-front pet discovery method endpoint.
 *
 * @property ENDPOINT_DISCOVERY_METHOD
 * @static
 * @final
 * @type {String}
 */
PetDiscoveryRelatedInputs.ENDPOINT_DISCOVERY_METHOD =
    '/user/api/discovery-method-options';

/**
 * Web-front pet discovery method endpoint parameter.
 *
 * @property ENDPOINT_DISCOVERY_PARAMETER
 * @static
 * @final
 * @type {String}
 */
PetDiscoveryRelatedInputs.ENDPOINT_DISCOVERY_PARAMETER = '?discoveryMethod=';

/**
 * Value of social media discovery method option.
 *
 * @property ENDPOINT_SOCIAL_VALUE
 * @static
 * @final
 * @type {String}
 */
PetDiscoveryRelatedInputs.ENDPOINT_SOCIAL_VALUE =
    'lists.petDiscoveryMethods.socialMedia';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    this.couponRepository = new CouponRepository();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangeMethodInputTypeHandler = this.onChangeMethodInputType.bind(
        this
    );
    this.onGetSpecifyForDiscoveryMethodSuccessHandler = this.onGetSpecifyForDiscoveryMethodSuccess.bind(
        this
    );
    this.onGetSpecifyForDiscoveryMethodFailedHandler = this.onGetSpecifyForDiscoveryMethodFailed.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$methodInput = this.$element.find(
        PetDiscoveryRelatedInputs.SELECTORS.INPUT_METHOD
    );
    this.$specifyGroup = this.$element.find(
        PetDiscoveryRelatedInputs.SELECTORS.SPECIFY
    );
    this.$specifyInput = this.$specifyGroup.find('select');
    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    this.checkDiscoveryMethodValue(true);
    return this;
};

/**
 * Sets up event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$methodInput.on('change', this.onChangeMethodInputTypeHandler);
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$methodInput.off('change', this.onChangeMethodInputTypeHandler);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Check if the discovery method select field is set to social media,
 * and carry out appropriate methods depending on value.
 * Run on Enable and on discovery method input change.
 *
 * @method checkDiscoveryMethodValue
 * @param onEnable boolean if method was called from onEnable
 * @private
 */
proto.checkDiscoveryMethodValue = function checkDiscoveryMethodValue(onEnable) {
    var methodTypeId = this.$methodInput.val();
    if (methodTypeId == PetDiscoveryRelatedInputs.ENDPOINT_SOCIAL_VALUE) {
        if (onEnable) {
            this.toggleSpecifyGroup(true);
        } else {
            this.disableInputs()
                .getSpecificsForDiscoveryMethod(methodTypeId)
                .then(
                    this.onGetSpecifyForDiscoveryMethodSuccessHandler,
                    this.onGetSpecifyForDiscoveryMethodFailedHandler
                );
        }
    } else {
        this.toggleSpecifyGroup(false);
    }
};

/**
 * AJAX request for discovery method specifics according to discovery method.
 * Run when the discovery method input is changed.
 *
 * @method getSpecificsForDiscoveryMethod
 * @param methodId corresponding to the discovery method
 * @private
 */
proto.getSpecificsForDiscoveryMethod = function getSpecificsForDiscoveryMethod(
    methodId
) {
    return this.couponRepository.getDiscoveryMethods(methodId);
};

/**
 * Appends a default select value to the discovery method specifics list.
 *
 * @method appendDefaultSpecificsValue
 * @private
 * @chainable
 */
proto.appendDefaultSpecificsValue = function appendDefaultSpecificsValue() {
    // TODO I18n
    this.$specifyInput.append('<option value="">Please specify</option>');
    return this;
};

/**
 * Appends the discovery method specifics for the selected discovery method to the select list.
 * Run on a successful get for discovery method specifics according to discovery method.
 *
 * @method appendSpecificMethodValues
 * @param discoveryMethodResponseJSON for pet type returned from server
 * @private
 * @chainable
 */
proto.appendSpecificMethodValues = function appendSpecificMethodValues(
    discoveryMethodResponseJSON
) {
    for (var methodKey in discoveryMethodResponseJSON.options) {
        var specificMethod = discoveryMethodResponseJSON.options[methodKey];

        this.$specifyInput.append(
            '<option value="' + methodKey + '">' + specificMethod + '</option>'
        );
    }

    return this;
};

/**
 * Empties the discovery method specifics select inputs.
 * Run on a successful get for discovery method specifics according to discovery method.
 *
 * @method emptySpecifySelectOptions
 * @private
 * @chainable
 */
proto.emptySpecifySelectOptions = function emptySpecifySelectOptions() {
    this.$specifyInput.empty();
    return this;
};

/**
 * Disables the discovery method specifics and discovery method inputs.
 * Run while the get for discovery method specifics is being processed to prevent additional user input.
 *
 * @method disableInputs
 * @private
 * @chainable
 */
proto.disableInputs = function disableInputs() {
    this.$methodInput.attr('disabled', true);
    this.$specifyInput.attr('disabled', true);
    return this;
};

/**
 * Enables the discovery method specifics and discovery method inputs.
 * Run after a successful get for discovery method specifics according to discovery method.
 *
 * @method enableInputs
 * @private
 * @chainable
 */
proto.enableInputs = function enableInputs() {
    this.$methodInput.attr('disabled', false);
    this.$specifyInput.attr('disabled', false);
    return this;
};

/**
 * Toggles the visibility of the discovery method specifics group.
 * Shows with true param and hides with false.
 *
 * @method toggleSpecifyGroup
 * @param show {Boolean}
 * @private
 * @chainable
 */
proto.toggleSpecifyGroup = function toggleSpecifyGroup(show) {
    this.$specifyGroup.toggleClass('u-isHidden', !show);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the discovery method input, disables inputs and kicks off request for discovery method specifics
 * if selection is social media.
 *
 * @method onChangeMethodInputType
 * @param event to grab the input's value
 * @private
 * @chainable
 */
proto.onChangeMethodInputType = function onChangeMethodInputType(event) {
    this.checkDiscoveryMethodValue();
};

/**
 * On successful get of discovery method specifics, appends the values to appropriate select lists and reenables input.
 *
 * @method onChangeMethodInputType
 * @param retailersForRetailerTypeJSON returned from the server
 * @private
 * @chainable
 */
proto.onGetSpecifyForDiscoveryMethodSuccess = function(
    retailersForRetailerTypeJSON
) {
    this.emptySpecifySelectOptions()
        .toggleSpecifyGroup(true)
        .appendDefaultSpecificsValue()
        .appendSpecificMethodValues(retailersForRetailerTypeJSON)
        .enableInputs();
};

/**
 * On a failed get for discovery method specifics, emits event to let form handle next steps.
 *
 * @method onGetSpecifyForDiscoveryMethodFailed
 * @private
 * @chainable
 */
proto.onGetSpecifyForDiscoveryMethodFailed = function() {
    this.eventBus.trigger('getRetailersForRetailerFailed');
};

export default PetDiscoveryRelatedInputs;
