const statechart = {
    initial: 'isIdle',
    states: {
        isIdle: {
            on: {
                TRIGGER_CLICK: 'isPending',
            },
        },
        isPending: {
            onEntry: ['handleDelay'],
            on: {
                DELAY_COMPLETE: 'isVisible',
            },
        },
        isVisible: {
            onEntry: ['showTooltip'],
            on: {
                ANOTHER_TRIGGER_CLICKED: 'isIdle',
                CLOSE_BUTTON_CLICK: {
                    isIdle: {
                        actions: ['focusTrigger'],
                    },
                },
                DOCUMENT_CLICK_OUTSIDE: 'isIdle',
                KEYBOARD_CLOSE_KEY: 'isIdle',
                SHOW_DURATION_COMPLETE: 'isIdle',
                TRIGGER_CLICK: 'isIdle',
                TRIGGER_BLUR: 'isIdle',
            },
            onExit: ['hideTooltip'],
        },
    },
};

export default statechart;
