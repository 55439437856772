import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HappyTailCollection from '../../../../../core/scripts/models/HappyTailCollection';
import HappyTailsContentCardCollection from '../HappyTailsContentCardCollection';
import HappyTailsSubmissionCard from '../HappyTailsSubmissionCard';
import HappyTailsSubmissionBanner from '../HappyTailsSubmissionBanner';
import happyTailAPIService from '../../../../../core/scripts/services/happyTailAPIService';
import { analyticsHappyTails442, analyticsHappyTails443 } from '../../../analytics/happy-tails';
import withLazyLoad from '../../hoc/withLazyLoad';

class HappyTailsModuleOrgPage extends Component {
    state = {
        happyTailCollection: new HappyTailCollection(),
    };

    static propTypes = {
        observed: PropTypes.bool, // value come from lazy load HOC
        orgName: PropTypes.string.isRequired,
        orgId: PropTypes.string.isRequired,
    };

    static defaultProps = {
        observed: true,
    };

    componentDidMount() {
        this._getHappyTailCollection();
    }

    async _getHappyTailCollection() {
        const searchParams = {
            status: 'published',
            limit: '3',
            page: '1',
            include: ['author', 'photo'],
            query: this.props.orgId,
        };
        try {
            const list = await happyTailAPIService.getList(searchParams);
            this.setState({
                happyTailCollection: list.happyTails,
            });
        } catch (error) {}
    }

    get submission() {
        return {
            buttonPrimary: 'Tell Us Your Story',
            headline: 'Submit Your Happy Tail',
            linkPrimary: '/happy-tails/your-pet-adoption-story/submission-form/',
            text:
                'Tell us the story of how you met your furry best friend and help other pet lovers discover the joys of pet adoption!',
        };
    }

    /**
     * _onContentCardClick
     * @param {string} title
     * @param {string} cta
     * @param {string} link
     * @fires PetfinderConsumer443
     */
    _onContentCardClick = (title, cta, link) => {
        analyticsHappyTails443(link, 'organization page');
    };

    /**
     * _onSubmissionPrimaryClick
     * @param {string} label
     * @fires PetfinderConsumer442
     */
    _onSubmissionPrimaryClick = label => {
        analyticsHappyTails442('organization page');
    };

    render() {
        const showCollection = this.state.happyTailCollection.size > 0;
        const showSubmissionCard = this.state.happyTailCollection.size < 2;
        const showSubmissionBanner = this.state.happyTailCollection.size !== 1;
        return (
            <Fragment>
                {showCollection && (
                    <div className="tier tier_full m-tier_bgPrimaryS1 m-tier_coverFixedBottom">
                        <div className="tier-inner">
                            <h2 className="txt txt_h1 m-txt_tight m-txt_colorWhite m-txt_alignCenter u-vr8x u-vrTop6x">
                                {this.props.orgName} Happy Tails
                            </h2>
                            <div className="grid grid_gutterLg m-grid_stretch m-grid_centerJustify u-vr8x">
                                <HappyTailsContentCardCollection
                                    collection={this.state.happyTailCollection}
                                    onCardClick={this._onContentCardClick}
                                    showSubmissionCard={showSubmissionCard}
                                    onPrimaryButtonClick={this._onSubmissionPrimaryClick}
                                    submission={this.submission}
                                >
                                    {showSubmissionCard && (
                                        <div className="grid-col grid-col_2/3@minLg">
                                            <HappyTailsSubmissionCard
                                                submission={this.submission}
                                                onPrimaryButtonClick={this._onSubmissionPrimaryClick}
                                                observed={this.props.observed}
                                            />
                                        </div>
                                    )}
                                </HappyTailsContentCardCollection>
                            </div>
                        </div>
                    </div>
                )}
                {showSubmissionBanner && (
                    <HappyTailsSubmissionBanner
                        submission={this.submission}
                        onPrimaryButtonClick={this._onSubmissionPrimaryClick}
                    />
                )}
            </Fragment>
        );
    }
}

export default withLazyLoad(HappyTailsModuleOrgPage);
