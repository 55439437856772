import APIService from './APIService';
import APIResponse from './APIResponse';
import HappyTailModel from '../models/HappyTailModel';
import HappyTailCollection from '../models/HappyTailCollection';
import PaginationModel from '../models/PaginationModel';
import HappyTail from '../types/HappyTail';

import _get from 'lodash/get';

class HappyTailAPIService extends APIService {
    static url = '/v2/happy-tails';

    successUrl = '/happy-tails/your-pet-adoption-story/success';

    async createHappyTail(data) {
        const options = {
            data,
            method: 'POST',
            lateResponseCancelling: true,
        };
        try {
            const apiResponse = await this.queryAndProcess(options);
            // TODO: move error throw to API response?
            if (apiResponse.error) {
                throw apiResponse;
            }
            return HappyTailModel.fromServer(apiResponse.data.happy_tail);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get list of happy tail objects and pagination
     * @param {Object} params
     * @throws {APIResponse}
     * @returns {Object}
     */
    async getList(params = {}) {
        const defaultParams = {
            status: HappyTail.STATUS_KEY_ENUM.PUBLISHED,
            limit: '20',
            page: '1',
            include: ['organization', 'photo'],
        };
        const options = {
            params: {
                ...defaultParams,
                ...params,
            },
        };

        try {
            const apiResponse = await this.queryAndProcess(options);

            // TODO: move error throw to API response?
            if (apiResponse.error) {
                throw apiResponse;
            }

            return {
                statusKey: options.params.status,
                happyTails: HappyTailCollection.fromServer(
                    apiResponse.data.happy_tails
                ),
                pagination: PaginationModel.fromServer(
                    apiResponse.data.pagination
                ),
                isRandomBreed: _get(apiResponse.data, 'metadata.randomResults', false),
            };
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get list of happy tail objects and exclude by id
     * @param {Number} id
     * @param {Object} limit
     * @param {Array} include
     * @returns {(Object|APIResponse)}
     */
    async getListAndExcludeId(
        id,
        limit = '3',
        include = ['organization', 'photo']
    ) {
        const limitInt = parseInt(limit, 10);
        const newLimit = (limitInt + 1).toString();
        const list = await this.getList({ limit: newLimit, include });
        let collection = list.happyTails.collection;
        const originalCount = collection.length;
        collection = collection.filter(obj => {
            return obj.id !== id;
        });

        if (
            collection.length === originalCount &&
            collection.length >= limitInt
        ) {
            collection.pop();
        }

        return {
            statusKey: list.statusKey,
            happyTails: new HappyTailCollection(collection),
        };
    }

    /**
     * Get counts for each list calculated status i.e. "todo", "done", etc
     * @returns {(Object|APIResponse)}
     */
    async getCountsForList() {
        const listStatusKeys = Object.values(HappyTail.STATUS_KEY_ENUM);
        const counts = {};
        try {
            await Promise.all(
                listStatusKeys.map(key =>
                    this.getList({
                        status: key,
                        limit: '1',
                        include: [],
                    })
                )
            ).then(responses =>
                responses.forEach(response => {
                    counts[response.statusKey] = response.pagination.totalCount;
                })
            );

            return counts;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Update a happy tail record via put endpoint
     * @param {string} id
     * @param {HappyTailModel} model
     * @param {array<string>} include extra query params for embedded resources
     * @throws {APIResponse}
     * @returns {Object}
     */
    async update(id, model, include = ['organization', 'author', 'photo']) {
        const options = {
            method: 'PUT',
            urlAppend: `${id}`,
            params: { include },
            data: HappyTailModel.toServer(model),
        };

        try {
            const apiResponse = await this.queryAndProcess(options);

            // TODO: move error throw to API response?
            if (apiResponse.error) {
                throw apiResponse;
            }

            return HappyTailModel.fromServer(apiResponse.data.happy_tail);
        } catch (error) {
            throw error;
        }
    }
}

const happyTailAPIService = new HappyTailAPIService();
export default happyTailAPIService;
