import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withStateMachine } from 'react-automata';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _noop from 'lodash/noop';

import {
    KEY_ESC,
    KEY_ESCAPE,
} from '../../../../../../core/scripts/constants/keyboard';
import {
    addListeners,
    getNumPixelsScrolledFromTop,
    removeListeners,
} from '../../../../../../core/scripts/util/dom';

import {
    PADDING_FROM_SIDES,
    POINTER_TOP_OFFSET,
    TOOLTIP_TOP_OFFSET,
    TOOLTIP_TYPE_TO_CLASS_MAP,
    TOOLTIP_POSITIONS,
    TOOLTIP_POSITION_TO_CLASS_MAP,
    HIDE_TOOLTIP_OFFSCREEN_PX,
} from './constants';

import statechart from './statechart';

const TOOLTIP_CONTAINER_ID = '#Tooltip_Container';

/**
 * Cache tooltip container element
 */
let tooltipContainer = null;

/**
 * Creates tooltip and and handles repositioning it around the screen based on the trigger
 * data attributes.
 * @class Tooltip
 * @extends React.Component
 */
class BreedTooltip extends Component {
    /**
     * @type {Object}
     */
    state = {
        position: '',
        pointerPos: { left: HIDE_TOOLTIP_OFFSCREEN_PX },
        tooltipPos: { left: HIDE_TOOLTIP_OFFSCREEN_PX },
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        attribute: PropTypes.string,
        delay: PropTypes.number,
        duration: PropTypes.number,
        events: PropTypes.object,
        noPortal: PropTypes.bool,
        persist: PropTypes.bool,
        trigger: PropTypes.object,
        triggerData: PropTypes.object,
        preventEventPropagation: PropTypes.bool,
        onRenderContent: PropTypes.func,
        onShow: PropTypes.func,
        onHide: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        attribute: 'breedTooltip',
        delay: 0,
        duration: 0,
        events: {
            click: true,
            keydown: true,
            blur: true,
            // focus: true,
            // mouseenter: true,
            // mouseleave: true,
        },
        noPortal: false,
        persist: false,
        trigger: {},
        triggerData: {},
        preventEventPropagation: false,
        onRenderContent: _noop,
        onShow: _noop,
        onHide: _noop,
    };

    /**
     * @type {Object}
     */
    tooltip = React.createRef();

    /**
     * @type {Object}
     */
    pointer = React.createRef();

    // Timers references
    delayTimer = null;
    hideTimer = null;

    constructor(props) {
        super(props);

        /**
         * Setup default event handlers
         * @type {Object}
         */
        this.eventHandlerMap = {
            blur: this.handleTriggerBlur,
            click: this.handleTriggerClick,
            keydown: this.handleTriggerKeyDown,
        };
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.removeHandlers();

        if (this.delayTimer) {
            window.clearTimeout(this.delayTimer);
        }

        if (this.hideTimer) {
            window.clearTimeout(this.hideTimer);
        }
    }

    /**
     * @method isTooltipVisible
     * @private
     * @returns {boolean}
     */
    get isTooltipVisible() {
        return ['isVisible'].includes(this.props.machineState.value);
    }

    /**
     * Build classnames for tooltip
     * @method getClassNames
     * @private
     * @returns {string}
     */
    getClassNames() {
        const { type, triggerData } = this.props;
        const position = this.state.position || triggerData.tooltipPosition;

        return buildClassNames({
            ['breedTooltip']: true,
            ['breedTooltip_visible']: this.isTooltipVisible,
            [`${TOOLTIP_TYPE_TO_CLASS_MAP[type]}`]: Object.keys(
                TOOLTIP_TYPE_TO_CLASS_MAP
            ).includes(type),
            [`${TOOLTIP_POSITION_TO_CLASS_MAP[position]}`]: Object.keys(
                TOOLTIP_POSITION_TO_CLASS_MAP
            ).includes(position),
        });
    }

    /**
     * @method getTooltipContainer
     * @private
     * @returns {Element}
     */
    getTooltipContainer() {
        if (!tooltipContainer) {
            tooltipContainer =
                typeof document !== 'undefined'
                    ? document.querySelector(TOOLTIP_CONTAINER_ID)
                    : null;
        }
        return tooltipContainer;
    }

    /**
     * Handles finding all of the triggers for this tooltip
     * @method init
     * @public
     */
    init = () => {
        if (document) {
            // Wait until end of event loop
            setTimeout(() => {
                this.triggers = Array.from(
                    document.querySelectorAll(`[data-${this.props.attribute}]`)
                );

                this.triggers.forEach(trigger =>
                    trigger.setAttribute('aria-pressed', 'false')
                );

                this.setupHandlers();
            }, 0);
        }
    };

    /**
     * Handles setting up our events for the triggers
     * @method setupHandlers
     * @private
     */
    setupHandlers() {
        const { events } = this.props;
        addListeners(events, this.triggers, this.eventHandlerMap);
        document.addEventListener('click', this.handleDocumentClick);
    }

    /**
     * @method removeHandlers
     * @private
     */
    removeHandlers() {
        const { events } = this.props;
        removeListeners(events, this.triggers, this.eventHandlerMap);
        document.removeEventListener('click', this.handleDocumentClick);
    }

    /**
     * Calculates the positioning of the tooltip based on the trigger and window
     * @param {node} trigger
     * @returns {Object}
     */
    getPositions(trigger) {
        const triggerEl = trigger;
        const tooltipEl = this.tooltip.current;
        const pointerEl = this.pointer.current;
        const triggerOffsets = triggerEl.getBoundingClientRect();
        const newTooltipStyle = {};
        const newPointerStyle = {};

        let desiredTooltipPosition = triggerEl.dataset.tooltipPosition;

        // Calculate left positions
        const pointerLeftPos =
            triggerOffsets.left -
            (pointerEl.offsetWidth / 2 - triggerOffsets.width / 2);
        const tooltipLeftPos =
            triggerOffsets.left -
            (tooltipEl.offsetWidth / 2 - triggerOffsets.width / 2);

        // Calculate top positions
        const docScrollTop = this.props.noPortal
            ? trigger.offsetParent.scrollTop
            : getNumPixelsScrolledFromTop();

        const fromDocTop = this.props.noPortal
            ? triggerOffsets.top + trigger.offsetParent.scrollTop
            : triggerOffsets.top + docScrollTop;

        let pointerTopPos;
        let tooltipTopPos;

        if (desiredTooltipPosition === TOOLTIP_POSITIONS.TOP) {
            // Show above trigger
            pointerTopPos =
                fromDocTop - (pointerEl.offsetHeight / 2 + POINTER_TOP_OFFSET);
            tooltipTopPos =
                fromDocTop - (tooltipEl.offsetHeight + TOOLTIP_TOP_OFFSET);
        }

        if (!desiredTooltipPosition || tooltipTopPos < docScrollTop) {
            // Show below trigger
            pointerTopPos =
                fromDocTop + (triggerOffsets.height + POINTER_TOP_OFFSET);
            tooltipTopPos =
                fromDocTop + (triggerOffsets.height + TOOLTIP_TOP_OFFSET);

            desiredTooltipPosition = TOOLTIP_POSITIONS.BOTTOM;
        }

        newPointerStyle.top = `${pointerTopPos}px`;
        newTooltipStyle.top = `${tooltipTopPos}px`;
        newPointerStyle.left = `${pointerLeftPos}px`;

        // Calculate if we need to adjust the tooltip to stay in view
        if (tooltipLeftPos < 0) {
            newTooltipStyle.left = `${PADDING_FROM_SIDES}px`;
        } else if (tooltipLeftPos + tooltipEl.offsetWidth > window.innerWidth) {
            newTooltipStyle.left = null;
            newTooltipStyle.right = `${PADDING_FROM_SIDES}px`;
        } else {
            newTooltipStyle.right = null;
            newTooltipStyle.left = `${tooltipLeftPos}px`;
        }

        return {
            position: desiredTooltipPosition,
            pointerPos: newPointerStyle,
            tooltipPos: newTooltipStyle,
        };
    }

    /**
     * @method showTooltip
     * @private
     */
    showTooltip() {
        const { trigger } = this.props;

        this.setState(
            {
                ...this.getPositions(trigger),
            },
            () => {
                this.props.onShow(this.props.trigger, this.props.triggerData);
            }
        );
    }

    /**
     * @method hideTooltip
     * @private
     */
    hideTooltip() {
        const { trigger } = this.props;
        trigger.setAttribute('aria-pressed', 'false');

        this.setState(
            {
                pointerPos: { left: HIDE_TOOLTIP_OFFSCREEN_PX },
                tooltipPos: { left: HIDE_TOOLTIP_OFFSCREEN_PX },
            },
            () => {
                this.props.onHide(this.props.trigger, this.props.triggerData);
            }
        );
    }

    /**
     * @method focusTrigger
     * @private
     */
    focusTrigger() {
        if (this.props.trigger) {
            this.props.trigger.focus();
        }
    }

    /**
     * @method handleDelay
     * @private
     */
    handleDelay() {
        const { delay, duration, persist } = this.props;

        this.delayTimer = window.setTimeout(() => {
            this.props.transition('DELAY_COMPLETE');
        }, delay);

        if (!persist && duration) {
            this.hideTimer = window.setTimeout(() => {
                this.props.transition('SHOW_DURATION_COMPLETE');
            }, duration);
        }
    }

    /**
     * @private
     * @param {Event} ev
     */
    handleTriggerClick = ev => {
        const prevTrigger = this.props.trigger;
        const trigger = ev.currentTarget;

        // Focus the trigger quick because flickity was causing issues
        trigger.focus();
        trigger.setAttribute('aria-pressed', 'true');

        // So other things don't get triggered...
        if (this.props.preventEventPropagation) {
            ev.preventDefault();
            ev.stopImmediatePropagation();
        }

        if (this.isTooltipVisible && trigger !== prevTrigger) {
            this.props.transition('ANOTHER_TRIGGER_CLICKED');
        }

        this.props.transition('TRIGGER_CLICK', {
            trigger,
            triggerData: JSON.parse(JSON.stringify(trigger.dataset)),
        });
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleTriggerBlur = ev => {
        // Delay to allow other transitions to occur first
        setTimeout(() => {
            this.props.transition('TRIGGER_BLUR');
        }, 250);
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleTriggerKeyDown = ev => {
        const closeOnKeyDown = [KEY_ESC, KEY_ESCAPE];

        if (closeOnKeyDown.includes(ev.key)) {
            this.props.transition('KEYBOARD_CLOSE_KEY');
        }
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleCloseButtonClick = ev => {
        this.props.transition('CLOSE_BUTTON_CLICK');
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleDocumentClick = ev => {
        if (this.tooltip.current.contains(ev.target)) {
            return;
        }

        this.props.transition('DOCUMENT_CLICK_OUTSIDE');
    };

    /**
     * This handles populating the tooltip content, you can pass in a render function
     * you are also responsible for any kind of a close button as this function passes
     * as the last param a function to handle the close button click event
     * @method renderContent
     * @private
     * @returns {string}
     */
    renderContent() {
        return this.props.onRenderContent(
            this.props.trigger,
            this.props.triggerData,
            this.handleCloseButtonClick
        );
    }

    render() {
        const { pointerPos, tooltipPos } = this.state;
        const { noPortal } = this.props;

        const tooltip = (
            <div className={this.getClassNames()}>
                <div
                    ref={this.tooltip}
                    className="breedTooltip-body"
                    style={tooltipPos}
                >
                    {this.isTooltipVisible ? this.renderContent() : ''}
                </div>
                <div
                    ref={this.pointer}
                    className="breedTooltip-pointer"
                    style={pointerPos}
                />
            </div>
        );

        if (noPortal) {
            return tooltip;
        }

        if (this.getTooltipContainer()) {
            return ReactDOM.createPortal(tooltip, this.getTooltipContainer());
        } else {
            return tooltip;
        }
    }
}

export default withStateMachine(statechart)(BreedTooltip);
