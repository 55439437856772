import $ from 'jquery';
'';
/**
 * @fileOverview BaseRepository
 * @author Noah Blon nblon@nerdery.com
 */
import RouteModel from '../models/RouteModel';

/**
 * BaseRepository
 * Class for new repository Classes to inherit from.
 * Not meant for initialization on its own.
 *
 * @class BaseRepository
 * @constructor
 */
var BaseRepository = function BaseRepository() { };

var proto = BaseRepository.prototype;

proto.init = function init() {
    /**
     * Route Model containing API routes to use as configuration
     *
     * @property routes
     * @for BaseRepository
     * @static
     * @final
     * @type {Object}
     */
    this.routes = new RouteModel();
};

/**
 * Gets data from server
 *
 * @public
 * @method get
 * @param path url to request data from
 * @param params url parameters
 * @returns {$.Deferred} jQuery AJAX promise object
 */
proto.get = function get(path, params) {
    return $.ajax({
        type: 'GET',
        url: path,
        data: params,
        cache: false,
    });
};

/**
 * Posts data to server
 *
 * @public
 * @method post
 * @param path url to request data from
 * @param params url parameters
 * @returns {$.Deferred} jQuery AJAX promise object
 */
proto.post = function post(path, params) {
    return $.ajax({
        type: 'POST',
        url: path,
        data: params,
    });
};

/**
 * Posts data to server
 *
 * @public
 * @method post
 * @param path url to request data from
 * @param params url parameters
 * @returns {$.Deferred} jQuery AJAX promise object
 */
proto.patch = function patch(path, params) {
    return $.ajax({
        type: 'PATCH',
        url: path,
        data: params,
    });
};

/**
 * Puts data to server
 *
 * @public
 * @method put
 * @param path url to request data from
 * @param params url parameters
 * @returns {$.Deferred} jQuery AJAX promise object
 */
proto.put = function put(path, params) {
    return $.ajax({
        type: 'PUT',
        url: path,
        data: params,
    });
};

/**
 * Puts data to server
 *
 * @public
 * @method put
 * @param path url to request data from
 * @param params url parameters
 * @returns {$.Deferred} jQuery AJAX promise object
 */
proto.remove = function remove(path, params) {
    return $.ajax({
        type: 'DELETE',
        url: path,
        data: params,
    });
};

/**
 * A generic callback on an AJAX get success to resolve with the data
 *
 * @private
 * @method onAJAXDone
 * @param deferred object
 * @param data response returned from the server
 */
proto.onAJAXDone = function onAJAXDone(deferred, data) {
    deferred.resolve(data);
};

/**
 * A generic callback on an AJAX fail to reject the promise.
 * Can be replaced by another method if something
 *
 * @private
 * @method onAJAXFail
 * @param deferred object
 * @param jqXHR jQuery XMLHttpRequest object
 */
proto.onAJAXFail = function onAJAXFail(deferred, jqXHR) {
    deferred.reject(jqXHR);
};


export default BaseRepository;
