import { html } from '../../../core/scripts/util/util';

/**
 * @function compileFormErrorContent
 * @returns {String}
 */
export default function compileGlobalFormErrorTemplate(vm) {
    return html`
        <div class="alert alert_error">
            <div class="media m-media_gutterSm">
                <div class="media-img">
                    <span class="icon m-icon_block m-icon_colorError">
                        <svg role="img">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-circleAlert"></use>
                        </svg>
                    </span>
                </div>
                <div class="media-bd">
                    <a href="#" class="txt txt_error u-overrideFocusRing" pfdc-dya-form-global-form-error-message>${vm.message}</a>
                </div>
            </div>
        </div>
    `;
}
