import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import format from 'date-fns/format';
import parseDate from 'date-fns/parse';
import Config from '../../../../core/scripts/lib/Config';
import WpSidebarEventsTemplate from '../../templates/wpSidebarEvents.html';
import WpSidebarEventsNotFoundTemplate from '../../templates/wpSidebarEventsNotFound.html';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-wp-events';

const ELEMENT_SELECTORS = {
    CONTAINER: `[${ELEMENT_TAG_NAME}-inner]`,
};

const SEARCH_DISTANCE_DEFAULT = '50';

export class PFDCWpEventsElement extends PFElement {
    get long() {
        return this.userLong ? this.userLong : '';
    }

    get lat() {
        return this.userLat ? this.userLat : '';
    }

    get date() {
        const today = new Date();
        return `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}`;
    }

    get limit() {
        if (!this.hasAttribute('limit')) {
            throw new Error('The number of events to display is required');
        }
        return this.getAttribute('limit');
    }

    get searchDistance() {
        return SEARCH_DISTANCE_DEFAULT;
    }

    /**
     * Initialize this component
     */
    onInit() {
        this.element = this.querySelector(ELEMENT_SELECTORS.CONTAINER);
        this.templateFound = WpSidebarEventsTemplate;
        this.templateNotFound = WpSidebarEventsNotFoundTemplate;
        this.initializeEvents();
    }

    /**
     *  Load try loading the events
     */
    async initializeEvents() {
        try {
            this.userLat = await Config.usersLatitude;
            this.userLong = await Config.usersLongitude;
            const json = await this.eventsRepository.getEvents(
                this.buildParams()
            );
            this.handleSuccess(json);
        } catch (error) {
            this.handleError(error);
        } finally {
        }
    }

    /**
     * Build the URL parameters
     * @returns {object}
     */
    buildParams() {
        return {
            ['limit']: this.limit,
            ['start_date']: this.date,
            ['output']: 'json',
            ['lat']: this.lat,
            ['lon']: this.long,
            ['search_distance']: this.searchDistance,
        };
    }

    /**
     * Adds date values to the return data to be used in templates
     * @param {object} json
     * @returns {object}
     */
    addEventParams(json) {
        const eventParams = json;

        for (let i = 0; i < json.events.length; i++) {
            const event = eventParams.events[i];
            const eventStartDateObject = parseDate(event.start_date_time);

            eventParams.events[i]['start_date_day_shortname'] = format(
                eventStartDateObject,
                'ddd'
            );
            eventParams.events[i]['start_date_month'] = format(
                eventStartDateObject,
                'MMM'
            );
            eventParams.events[i]['start_date_day'] = format(
                eventStartDateObject,
                'D'
            );
            eventParams.events[i]['show_event_duration'] =
                event.event_start_time !== '12:00 AM' ||
                event.event_end_time !== '12:00 AM';
        }

        return eventParams;
    }

    /**
     * Handle a successfull reponse and compile the template
     * @param {object} json
     */
    handleSuccess(json) {
        const vm = this.addEventParams(json);
        this.element.innerHTML = this.templateFound(vm);
    }

    /**
     * Handle an error and compile the not found template
     * @param {object} error
     */
    handleError(error) {
        const vm = error;
        this.element.innerHTML = this.templateNotFound(vm);
    }
}

export default PFDCWpEventsElement;
