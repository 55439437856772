// import { PFDCFormElement } from '../pfdc-form/element';
// import recaptchaFormMixin from '../../mixins/form/recaptchaForm';

import withStatechart from '../../mixins/withStatechartMixin'; // TODO: move to mixins
import statechart from './statechart';
import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import globalFormErrorTemplate from '../../templates/globalFormError.html';

// TODO: to utils
function getSelectValue(select) {
    return select.options[select.selectedIndex].value;
}

// TODO: validators to mixin
export class PFDCDyaFormElement extends withStatechart(PFElement, statechart) {
    validators = new Set();
    get isRecaptchaEnabled() {
        // TODO: refactor
        return this.hasAttribute('recaptcha-enabled');
    }
    onInit() {
        this.cacheChildren();
        this.setupListeners();
        // TODO: refactor to be part of component
        const countrySelect = this.querySelector('[pf-address-country]');
        const value = getSelectValue(countrySelect);
        this.dispatchAction('dya-address.country.change', {
            countryCode: value.toUpperCase(),
        });

        // TODO: refactor cleaner
        const passwordInput = this.querySelector(
            '[pf-password-validator-input]'
        );
        // logged in form doesn't have password input
        if (passwordInput) {
            passwordInput.addEventListener('blur', () => {
                if (passwordInput.value === '') {
                    this.dispatchAction('dyaForm.password.empty');
                } else {
                    this.dispatchAction('dyaForm.password.filled');
                }
            });
        }
    }
    cacheChildren() {
        this.form = this.querySelector('form');
        // TODO: refactor this behavior to a component
        this.globalFormError = document.querySelector(
            '[pfdc-dya-form-global-form-error]'
        );
        this.submitBtn = this.querySelector('button[type="submit"]');
    }
    setupListeners() {
        this.addEventListener('submit', this.onSubmit.bind(this));
    }
    submitForm() {
        // TODO: how to abstract to the recaptcha mixin
        this.form.submit();
    }
    disableSubmitBtn() {
        this.submitBtn.setAttribute('disabled', '');
    }
    enableSubmitBtn() {
        this.submitBtn.removeAttribute('disabled');
    }
    // RECAPTCHA
    executeRecaptcha() {
        // loadReCaptchaScript();
        this.dispatchAction('recaptcha.execute', this);
    }
    onSubmit(event) {
        event.preventDefault();
        this.transition({
            type: 'SUBMITTED',
            isRecaptchaEnabled: this.isRecaptchaEnabled,
        });
    }
    dispatchValidatorValidateAction() {
        this.dispatchAction('validators.validate', this);
    }
    renderGlobalFormError() {
        this.globalFormError.innerHTML = globalFormErrorTemplate({
            message:
                'Your form is invalid.  Please correct the fields highlighted below.',
        });
    }
    clearGlobalFormError() {
        this.globalFormError.innerHTML = '';
    }
    moveFocusToGlobalFormError() {
        document
            .querySelector('[pfdc-dya-form-global-form-error-message]')
            .focus();
    }
    onUpdated(event) {
        // TODO: potentially refactor to single action
        // TODO: Filter to form id
        // TODO: abstract
        if (event.detail.type === 'validator.invalid') {
            this.validators.add(event.detail.trigger);
        }
        if (event.detail.type === 'validator.valid') {
            this.validators.delete(event.detail.trigger);
        }
        if (this.validators.size) {
            this.transition({ type: 'INVALIDATED' });
        } else {
            this.transition({ type: 'VALIDATED' });
        }
        if (event.detail.type === 'recaptcha.validation') {
            this.transition({ type: 'RECAPTCHA_VALIDATED' });
        }
        if (event.detail.type === 'recaptcha.reset') {
            this.transition({ type: 'RECAPTCHA_RESET' });
        }
    }
}
