import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { shelterSearchTable } from './templates/shelterSearchTable.html';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';
import { CLASS_IS_LOADING } from '../../constants/classes';
import Config from '../../../../core/scripts/lib/Config';

import {
    EV_SHELTERSEARCH_NEXTPAGE,
    EV_SHELTERSEARCH_PREVPAGE,
    EV_SHELTERSEARCH_RENDERED,
} from '../../constants/events';

import queryString from 'query-string';

// Private Symbols
const addUserDataToComponentState = Symbol('addUserDataToQuery');
const parseUrlQueryToComponentState = Symbol('parseUrlQueryToComponentState');
const loadOrganizations = Symbol('loadOrganizations');
const componentState = Symbol('componentState');
const render = Symbol('render');
const changePage = Symbol('changePage');

const MESSAGES = {
    ERROR_GENERAL: 'There was an error performing your search.',
    ERROR_NO_RESULTS: 'No results found.',
    ACCESSIBILITY_ORG_LOADING: 'Loading Organizations',
    ACCESSIBILITY_ORG_LOADED: 'Organizations Loaded',
};

const ENDPOINT = '/v2/search/organizations';

// Map to map url query paramp to api params
/* eslint-disable quote-props*/
const urlQueryParamToApiParamMap = {
    location: 'location',
    shelter_name: 'name_substring',
    page_number: 'page',
};
/* eslint-enable */

/**
 * Displays shelter search results, maintaining legacy feature by connecting to new API.
 * @extends PFElement
 * @module PFDCShelterSearchElement
 */
export class PFDCShelterSearchElement extends PFElement {
    /**
     * @constructor
     */
    constructor() {
        super();
        /*
         * Component state object, used to make requests or hold any other relevant component state.
         */
        this[componentState] = {
            requestParams: {
                page: 1,
                name_substring: '', // eslint-disable-line camelcase
                location: '',
                latitude: null,
                longitude: null,
            },
        };
    }

    /**
     * Called after element inserted into DOM and contents have been parsed.
     * @method onInit
     * @async
     */
    async onInit() {
        this[parseUrlQueryToComponentState]();
        await this[addUserDataToComponentState]();
        this[loadOrganizations]();
    }

    /**
     * Called after element inserted into DOM and contents have been parsed.
     * @method onInit
     * @public
     * @async
     */
    async [addUserDataToComponentState]() {
        this[componentState].requestParams.latitude =
            (await Config.usersLatitude) || -44;
        this[componentState].requestParams.longitude =
            (await Config.usersLongitude) || 44;
    }

    /**
     * Maps relevant keys in the url query string to this compoenent's state for use making
     * AJAX requests
     * @method parseUrlQueryToComponentState
     * @private
     */
    [parseUrlQueryToComponentState]() {
        const parsedHash = queryString.parse(location.search);
        if (!parsedHash) {
            return;
        }
        Object.keys(parsedHash).forEach(key => {
            if (parsedHash[key] !== '' && urlQueryParamToApiParamMap[key]) {
                const sanitizedValue = parsedHash[key].replace(/[\u2018\u2019]/g, "'");
                this[componentState].requestParams[
                    urlQueryParamToApiParamMap[key]
                ] = sanitizedValue;
            }
        });
    }

    /**
     * Loads orgs based on query and passes data to render or error handling functions.  Called
     * on load and hydrated with query string params or dynamically on interactions with UI controls
     * @method loadOrganizations
     * @private
     * @async
     */
    async [loadOrganizations]() {
        try {
            const params = this[componentState].requestParams;
            Object.keys(params).forEach(key => {
                if (!params[key] || params[key] === '') {
                    delete params[key];
                }
            });
            const stringifiedQuery = queryString.stringify(params);
            this.dispatchAction('', {
                statusText: MESSAGES.ACCESSIBILITY_ORG_LOADING,
            });
            const shelters = await fetch(`${ENDPOINT}?${stringifiedQuery}`, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'same-origin',
            });
            const data = await shelters.json();
            this.dispatchAction('', {
                statusText: MESSAGES.ACCESSIBILITY_ORG_LOADED,
            });
            if (!data.organizations.length) {
                this.innerHTML = `<h2>${MESSAGES.ERROR_NO_RESULTS}</h2>`;
            } else {
                this[render](data);
            }
        } catch (error) {
            this.innerHTML = MESSAGES.ERROR_GENERAL;
        }
    }

    /**
     * Loads orgs based on query and passes data to render or error handling functions.  Called
     * on load and hydrated with query string params or dynamically on interactions with UI controls
     * @method render
     * @private
     * @param {Object} data
     */
    [render](data) {
        data.startSpan =
            (data.pagination.current_page - 1) *
                data.pagination.count_per_page +
            1;
        const fullPageEndItemNum =
            data.startSpan + data.pagination.count_per_page - 1;
        data.endSpan =
            fullPageEndItemNum > data.pagination.total_count
                ? data.pagination.total_count
                : fullPageEndItemNum;
        this.innerHTML = shelterSearchTable(data);
        scheduleMicrotask(() => this.dispatchAction(EV_SHELTERSEARCH_RENDERED));
        this.classList.remove(CLASS_IS_LOADING);
    }

    /**
     * @method changePage
     * @private
     * @param {number} i increment to add to page
     */
    [changePage](i) {
        this[componentState].requestParams.page =
            this[componentState].requestParams.page + i;
        this.classList.add(CLASS_IS_LOADING);
        this[loadOrganizations]();
    }

    /**
     * @method onUpdated
     * @public
     * @param {Object} event Update Events passed down on Event Bus
     */
    onUpdated(event) {
        const { detail } = event;
        const { type } = detail;
        switch (type) {
            case EV_SHELTERSEARCH_NEXTPAGE:
                this[changePage](+1);
                break;
            case EV_SHELTERSEARCH_PREVPAGE:
                this[changePage](-1);
                break;
            default:
                break;
        }
    }
}

export default PFDCShelterSearchElement;
