import { PFElement } from '../pf-element/element';
import { EV_UI_DATE_RANGE_CHANGE } from '../../constants/events';
import { CLASS_IS_OPEN } from '../../constants/classes';
import { KEY_ESCAPE, KEY_ENTER } from '../../constants/keyboard';
import { parseJsonAttribute } from '../../util/dom';
import { maybeProp, uid } from '../../util/util';
import mapValues from 'lodash/mapValues';

/**
 * Date picker input
 * plugin API: http://daterangepicker.com/#config
 *
 * This is being used to wrap date range inputs in two ways:
 *   1.) [input 'start - end']
 *       (daterangepicker 'default')
 *
 *   2.) [input 'start']             [input 'end']
 *       (daterangepicker 'single')  (daterangepicker 'single')
 *
 *      - In single mode, just 'start date' is used by the plugin.
 *
 * <pf-date-picker
 *      start-date="Date"
 *      end-date="Date"
 *      max-date="Date"
 *      single-date-picker="Bool"
 *      input-empty-on-init="Bool"
 * >
 *     <input pf-date-picker-input>
 * </pf-date-picker>
 *
 * @class PFDatePickerElement
 * @extends HTMLElement
 */
export class PFDatePickerElement extends PFElement {
    /**
     * ## Lifecycle
     */

    /**
     * The constructor is invoked then the element is created.
     * @constructor
     */
    constructor() {
        super();

        /**
         * Reference to the input element
         *
         * @default null
         * @property $input
         * @type {JQuery}
         */
        this.$input = null;

        /**
         * Reference to the daterangepicker instance
         *
         * @default null
         * @property $picker
         * @type {JQuery}
         */
        this.$picker = null;

        /**
         * Configures the initial start date
         *
         * @default null
         * @property startDate
         * @type {Object}
         */
        this.startDate = null;

        /**
         * Configures the initial end date
         *
         * @default null
         * @property endDate
         * @type {Object}
         */
        this.endDate = null;

        /**
         * Configures the last date a user can select
         *
         * @default null
         * @property endDate
         * @type {Object}
         */
        this.maxDate = null;

        /**
         * If true, use single date picker mode
         *
         * @default null
         * @property singleDatePicker
         * @type {Bool}
         */
        this.singleDatePicker = null;

        /**
         * If true, start with no input value
         *
         * @default null
         * @property inputEmptyOnInit
         * @type {Bool}
         */
        this.inputEmptyOnInit = null;

        /**
         * Makes the calendar dropdown a child of this element.
         * This value is nulled if singleDatePicker is false.
         *
         * @default null
         * @property parentEl
         * @type {string} Selector
         */
        this.parentEl = null;

        /**
         * Standard config options to always use.
         *
         * @property baseConfig
         * @type {Object}
         */
        this.baseConfig = {
            dateLimit: { days: 364 }, // was { years: 1 }
            linkedCalendars: true,
        };

        /**
         * Keep track of manually entered values,
         * so we can persist an emptied input instead of coercing it to a date.
         *
         * @default null
         * @property manualInputState
         * @type {Object}
         * @private
         */
        this.manualInputState = null;

        /**
         * The date which we will use to make sure that the input date range is
         * NOT before. Used for the date range picker.
         *
         * @property defaultMinDate
         * @type {string}
         */
        this.defaultMinDate = '1940-01-01';

        /**
         * How the separator should be formatted in the range input.
         *
         * @property validRangeSeparator
         * @type {string}
         */
        this.validRangeSeparator = ' - ';
    }

    get inputValue() {
        return this.$input.val();
    }

    get hasValidRangeSeparator() {
        return this.inputValue.includes(this.validRangeSeparator);
    }

    async loadDependencies() {
        return Promise.all([
            import(/* webpackChunkName: "manual-chunk-moment" */
            'moment' /* webpackChunkName: "manual-chunk-datepicker" */),
            import('daterangepicker'),
        ]);
    }
    /**
     * The constructor is invoked when the element is attached to the DOM.
     *
     * @callback
     * @method connectedCallback
     */
    async connectedCallback() {
        await super.connectedCallback();
        const libReferences = await this.loadDependencies();
        window.moment = libReferences[0].default;
        this.startDate = window
            .moment(this.getAttribute('start-date'))
            .isValid()
            ? window
                  .moment(this.getAttribute('start-date'))
                  .format('MM/DD/YYYY')
            : window.moment().format('MM/DD/YYYY');

        this.endDate = window.moment(this.getAttribute('end-date')).isValid()
            ? window.moment(this.getAttribute('end-date')).format('MM/DD/YYYY')
            : window.moment().format('MM/DD/YYYY');

        this.maxDate = this.getAttribute('max-date');
        this.minDate = this.getAttribute('min-date') || this.defaultMinDate;
        this.singleDatePicker = this.hasAttribute('single-date-picker');
        this.inputEmptyOnInit =
            parseJsonAttribute(this, 'input-empty-on-init') || false;
        this.id = this.id || uid();
        this.parentEl = this.singleDatePicker ? `#${this.id}` : null;

        // Auto apply date ranges selected in the calendar if this is a range picker
        if (!this.singleDatePicker) {
            this.autoApply = true;
        }

        $(document).ready(this.onDocumentReady.bind(this));
    }

    /**
     * @callback
     * @method onDocumentReady
     */
    onDocumentReady() {
        this.$input = $(this).find('[pf-date-picker-input]');
        this.createDatePicker();
    }

    /**
     * ## Methods
     */

    /**
     * Create the date picker after jquery has loaded
     *
     * @method createDatePicker
     */
    createDatePicker() {
        const config = Object.assign(
            {},
            this.baseConfig,
            mapValues(maybeProp(this, 'startDate'), d => window.moment(d)),
            mapValues(maybeProp(this, 'endDate'), d => window.moment(d)),
            mapValues(maybeProp(this, 'maxDate'), d => window.moment(d)),
            maybeProp(this, 'autoApply'),
            maybeProp(this, 'singleDatePicker'),
            maybeProp(this, 'parentEl'),
            {
                autoUpdateInput: false,
                minDate: moment(this.minDate),
            }
        );

        const initialValue = this.$input.val();

        /*  create picker  */
        this.$input.daterangepicker(config);
        this.$picker = this.$input.data('daterangepicker');
        this.$picker.container.attr('aria-hidden', true);

        this.pickerShowHandler = this.onPickerShow.bind(this);
        this.pickerHideHandler = this.onPickerHide.bind(this);
        this.pickerApplyHandler = this.reformatInput.bind(this);
        this.pickerCancelHandler = this.reformatInput.bind(this);
        this.inputInputHandler = this.onInput.bind(this);
        this.inputFocusHandler = this.onInputFocus.bind(this);
        this.inputBlurHandler = this.onInputBlurred.bind(this);

        this.$input.on('show.daterangepicker', this.pickerShowHandler);
        this.$input.on('hide.daterangepicker', this.pickerHideHandler);
        this.$input.on('apply.daterangepicker', this.pickerApplyHandler);
        this.$input.on('cancel.daterangepicker', this.pickerCancelHandler);
        this.$input.on('focus', this.inputFocusHandler);
        this.$input.on('blur', this.inputBlurHandler);
        this.addEventListener('keydown', this.onKeyDowned);

        if (this.inputEmptyOnInit && initialValue === '') {
            this.$input.val('');
        } else if (!moment(initialValue).isValid()) {
            this.$input.val(initialValue);
        } else {
            this.$input.val(this.startDate);
        }

        this.isPickerCreated = true;

        // Apply the start and end date selected if this is a range picker.
        if (!this.singleDatePicker) {
            this.isInputDateRangeValid = true;
            this.$picker.clickApply();
        }
    }

    /**
     * Check if the date range is valid
     *
     * @method checkDateRangeValidity
     * @param {string} value of input
     */
    checkDateRangeValidity(value) {
        this.isInputDateRangeValid = false;
        this.validInputDateRangeDates = [];

        const inputValue = value.split('-');
        if (inputValue.length !== 2) {
            return;
        }

        const trimmedDates = inputValue.map(item => window.moment(item.trim()));
        const validDates = trimmedDates.every(item => item.isValid());

        // Various checks for validity...
        if (validDates) {
            if (trimmedDates[1].isBefore(trimmedDates[0])) {
                return;
            }
            if (trimmedDates[0].isAfter(trimmedDates[1])) {
                return;
            }
            if (
                trimmedDates[0].isAfter(this.maxDate) ||
                trimmedDates[1].isAfter(this.maxDate)
            ) {
                return;
            }

            // Are the years before our acceptable years?
            if (
                trimmedDates[0].isBefore(this.defaultMinDate) ||
                trimmedDates[1].isBefore(this.defaultMinDate)
            ) {
                return;
            }
        }

        this.isInputDateRangeValid = validDates;
        this.validInputDateRangeDates = trimmedDates.map(item =>
            item.format('MM/DD/YYYY')
        );
    }

    /**
     * Handle input events on the input element
     *
     * @method onInput
     * @param {Object} ev
     */
    onInput(ev) {
        if (ev.target === this.$input[0] && !this.singleDatePicker) {
            this.checkDateRangeValidity(ev.currentTarget.value);
        }
    }

    /**
     * Input focus handler
     *
     * @method onInputFocus
     */
    onInputFocus() {
        this.$input.on('input', this.inputInputHandler);
    }

    /**
     * Handles blur events.
     *
     * @method onInputBlurred
     * @param {Object} ev
     */
    onInputBlurred(ev) {
        if (ev.target === this.$input[0] && this.singleDatePicker) {
            const dateInputValue = window.moment(ev.target.value);
            if (dateInputValue.isValid()) {
                this.$input.val(dateInputValue.format('MM/DD/YYYY'));
                this.$picker.setStartDate(dateInputValue.format('MM/DD/YYYY'));
            }
        }
        if (ev.target === this.$input[0] && !this.singleDatePicker) {
            ev.stopImmediatePropagation();
            if (!this.isInputDateRangeValid) {
                this.$picker.clickCancel();
            }
        }
        this.$input.off('input', this.inputInputHandler);
    }

    /**
     * Handles apply and cancel event from the datepicker, formats the date and populates the input
     *
     * @method reformatInput
     * @param {Object} ev
     * @param {Object} picker
     */
    reformatInput(ev, picker) {
        if (this.singleDatePicker) {
            // If a user clicks outside the input without selecting a value, don't change the input value
            if (!ev.hasOwnProperty('target')) {
                return;
            } else {
                this.$input.val(picker.startDate.format('MM/DD/YYYY'));
            }
        } else {
            if (
                picker.startDate.isSame(picker.endDate) ||
                picker.startDate.isSame(window.moment(this.defaultMinDate))
            ) {
                // use old date range data to reformat input because for some reason the
                // calendar plugin is saying the date should be the same day or start at our
                // beginning of time...
                const oldStartDate = `${picker.oldStartDate.format(
                    'MM/DD/YYYY'
                )}`;
                const oldEndDate = `${picker.oldEndDate.format('MM/DD/YYYY')}`;
                const val = `${oldStartDate} - ${oldEndDate}`;
                this.$input.val(val);
                return;
            }

            if (picker.startDate) {
                const startDate = `${picker.startDate.format('MM/DD/YYYY')}`;
                const endDate = `${picker.endDate.format('MM/DD/YYYY')}`;
                this.$input.val(`${startDate} - ${endDate}`);
            }
        }
        this.isInputDateRangeValid = true;
    }

    /**
     * On date panel opened
     *
     * @method onPickerShow
     * @param {Object} ev
     * @param {Object} picker
     */
    onPickerShow(ev, picker) {
        picker.container.addClass(CLASS_IS_OPEN);
    }

    /**
     * On date panel closed
     *
     * @method onPickerHide
     * @param {Object} ev
     * @param {Object} picker
     */
    onPickerHide(ev, picker) {
        picker.container.removeClass(CLASS_IS_OPEN);
        if (!this.singleDatePicker && !this.hasValidRangeSeparator) {
            this.setStateOnPicker(picker.oldStartDate, picker.oldEndDate);
            this.reformatInput({}, picker);
            return;
        }
        if (
            picker.startDate.isSame(picker.endDate) ||
            picker.startDate.isSame(window.moment(this.defaultMinDate))
        ) {
            // go to old date range because for some reason the calendar plugin is saying the date
            // should be the same day or start at our beginning of time...
            this.updateChildren(picker.oldStartDate, picker.oldEndDate);
            this.reformatInput({}, picker);
            return;
        }
        this.updateChildren(picker.startDate, picker.endDate);
        this.reformatInput({}, picker);
    }

    /**
     * Update the children that subscribe to these date pickers
     *
     * @method updateChildren
     * @param {Date} startDate
     * @param {Date} endDate
     */
    updateChildren(startDate, endDate) {
        this.dispatchAction(EV_UI_DATE_RANGE_CHANGE, {
            startDate: window.moment(new Date(startDate)).toISOString(),
            endDate: window.moment(new Date(endDate)).toISOString(),
            trigger: this,
        });

        // sometimes causes deprecation warning?
        const event = new Event('change', { bubbles: true });
        this.$input[0].dispatchEvent(event);
    }

    /**
     * Set state on the daterangepicker plugin, it will update the input val.
     *
     * @method setStateOnPicker
     * @param {Date} startDate
     * @param {Date} endDate
     */
    setStateOnPicker(startDate, endDate) {
        if (startDate) {
            this.$picker.setStartDate(startDate);
        }
        if (endDate) {
            this.$picker.setEndDate(endDate);
        }
    }

    /**
     * Called on updates this component is subscribed to
     * via the `change-on` attribute.
     *
     * @method change
     * @param {Event} ev
     */
    change(ev) {
        console.log('core');
        if (!this.singleDatePicker) {
            this.updateEndDateIfExceededByStartDate(ev.detail.trigger);
        }
    }

    /**
     * For single endDate pickers.
     * Call this on changes of a sibbling startDate picker.
     *
     * @method updateEndDateIfExceededByStartDate
     * @param {Event} trigger
     */
    updateEndDateIfExceededByStartDate(trigger) {
        const sibblingStartDateInputVal = window.moment(
            trigger.$input.val(),
            'MM/DD/YYYY'
        );
        const thisEndDateInputVal = window.moment(
            this.$input.val(),
            'MM/DD/YYYY'
        );

        if (sibblingStartDateInputVal.isAfter(thisEndDateInputVal)) {
            // This 'end' picker gets overwritten by sibbling 'start'.
            this.setStateOnPicker(sibblingStartDateInputVal);

            // Not sure if this is a needed use case.
            // This is for single datepickers, so start/end are the same.
            this.updateChildren(
                sibblingStartDateInputVal,
                sibblingStartDateInputVal
            );
        }
    }

    /**
     * Handles all keydown events
     *
     * @method onKeyDowned
     * @param {Event} ev
     */
    onKeyDowned(ev) {
        if (ev.key === KEY_ESCAPE) {
            this.escapeKeyHandler();
        }
        if (ev.key === KEY_ENTER) {
            if (ev.target === this.$input[0] && !this.singleDatePicker) {
                ev.preventDefault();
                ev.stopImmediatePropagation(); // Stop blur from getting to the datepicker plugin...
                if (!this.isInputDateRangeValid) {
                    this.$picker.clickCancel();
                }
            }
        }
    }

    /**
     * Handles escape key events
     *
     * @method escapeKeyHandler
     */
    escapeKeyHandler() {
        this.$picker.hide();
    }
}
