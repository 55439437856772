import BaseEnsightenAnalytics from '../../../../core/scripts/analytics/ensighten/BaseEnsightenAnalytics';
import $ from 'jquery';
import UserLoginRegistrationEventModel from '../../../../core/scripts/analytics/models/user-login-registration/UserLoginRegistrationEventModel';
import UserInfoEventModel from '../../../../core/scripts/analytics/models/user-info/UserInfoEventModel';
import AnimalEventModel from '../../../../core/scripts/analytics/models/animal/AnimalEventModel';

/**
 * About Me Form Analytic tracking component.
 *
 * @class GuestCouponApplicationEnsightenAnalytics
 * @constructor
 */
const GuestCouponApplicationEnsightenAnalytics = function($element, eventBus) {
    BaseEnsightenAnalytics.call(this, $element, eventBus);
    this.init($element, eventBus);
};

// Inheritance
GuestCouponApplicationEnsightenAnalytics.prototype = Object.create(
    BaseEnsightenAnalytics.prototype
);
GuestCouponApplicationEnsightenAnalytics.prototype.constructor = GuestCouponApplicationEnsightenAnalytics;

const proto = GuestCouponApplicationEnsightenAnalytics.prototype;
const _super = BaseEnsightenAnalytics.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);
    this.theLastDateSet = '';
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @extends {BaseForm.setupHandlers}
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onInputNameBlurHandler = this.onInputNameBlur.bind(this);
    this.onInputAddress1BlurHandler = this.onInputAddress1Blur.bind(this);
    this.onInputEmailBlurHandler = this.onInputEmailBlur.bind(this);
    this.onInputPetNameBlurHandler = this.onInputPetNameBlur.bind(this);
    this.onInputPetTypeChangeHandler = this.onInputPetTypeChange.bind(this);
    this.onInputAdoptionSourceChangeHandler = this.onInputAdoptionSourceChange.bind(
        this
    );
    this.onInputAdoptionDateChangeHandler = this.onInputAdoptionDateChange.bind(
        this
    );
    this.onInputSubmitClickHandler = this.onSubmit.bind(this);
    this.onInputLoginSubmitClickHandler = this.onLoginSubmit.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);

    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @extends {BaseForm.createChildren}
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputFirstName = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_FIRST_NAME
    );
    this.$inputLastName = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_LAST_NAME
    );
    this.$inputAddress1 = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_1
    );
    this.$inputAddress2 = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_2
    );
    this.$inputCity = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_CITY
    );
    this.$inputState = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_STATE
    );
    this.$inputPostalCode = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_POSTAL_CODE
    );
    this.$inputCountry = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_COUNTRY
    );
    this.$inputEmail = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_EMAIL
    );
    this.$inputPetName = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_NAME
    );
    this.$inputPetType = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_TYPE
    );
    this.$inputAdoptionSource = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_SOURCE
    );
    this.$inputAdoptionDate = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_DATE
    );
    this.$inputSubmit = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_SUBMIT
    );
    this.$inputPassword = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PASSWORD
    );
    this.$inputPasswordConfirm = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS
            .INPUT_PASSWORD_CONFIRM
    );

    this.$inputPurinaOptin = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PURINA_OPTIN
    );
    this.$inputPetfinderOptinOther = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS
            .INPUT_PETFINDER_OPTIN_OTHER
    );
    this.$inputPetfinderOptinUs = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS
            .INPUT_PETFINDER_OPTIN_US
    );

    this.$inputLoginSubmit = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_LOGIN_SUBMIT
    );
    this.$form = this.$element.find(
        GuestCouponApplicationEnsightenAnalytics.SELECTORS.FORM
    );
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @extends {BaseForm.enable}
 * @private
 * @chainable
 */
proto.enable = function enable() {
    _super.enable.call(this);
    this.setupListeners();
    return this;
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @extends {BaseForm.setupListeners}
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    this.$inputFirstName.on('blur', this.onInputNameBlurHandler);
    this.$inputLastName.on('blur', this.onInputNameBlurHandler);
    this.$inputAddress1.on('blur', this.onInputAddress1BlurHandler);
    this.$inputEmail.on('blur', this.onInputEmailBlurHandler);
    this.$inputPetName.on('blur', this.onInputPetNameBlurHandler);
    this.$inputPetType.on('change', this.onInputPetTypeChangeHandler);
    this.$inputAdoptionSource.on(
        'change',
        this.onInputAdoptionSourceChangeHandler
    );
    this.$inputAdoptionDate.on('change', this.onInputAdoptionDateChangeHandler);
    this.$inputLoginSubmit.on('click', this.onInputLoginSubmitClickHandler);
    this.$form.on('submit', this.onSubmitHandler);
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    _super.disable.call(this);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.doBothNameInputsHaveAValue = function doBothNameInputsHaveAValue() {
    return this.$inputFirstName.val() != '' && this.$inputLastName.val() != '';
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onInputNameBlur = function onInputNameBlur() {
    if (this.doBothNameInputsHaveAValue()) {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter name',
            label: 'guest',
        });
    }
};

proto.onInputAddress1Blur = function onInputAddress1Blur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter address',
        label: 'guest',
    });
};

proto.onInputEmailBlur = function onInputEmailBlur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter email',
        label: 'guest',
    });
};

proto.onInputPetNameBlur = function onInputPetNameBlur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter new pets name',
        label: 'guest',
    });
};

proto.onInputPetTypeChange = function onInputPetTypeChange(e) {
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'select type of animal',
            label: $(e.currentTarget)
                .find('option:selected')
                .text()
                .toLowerCase(),
        });
    }
};

proto.onInputAdoptionSourceChange = function onInputAdoptionSourceChange(e) {
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'select adoption source',
            label: $(e.currentTarget)
                .find('option:selected')
                .text()
                .toLowerCase(),
        });
    }
};

proto.onInputAdoptionDateChange = function onInputAdoptionDateChange(e) {
    const theDateInput = e.target;
    setTimeout(() => {
        const theDateInputValue = theDateInput.value;

        if (this.theLastDateSet) {
            if (theDateInputValue === this.theLastDateSet) {
                return;
            }
        }

        this.theLastDateSet = theDateInputValue;

        if (theDateInputValue !== '') {
            const dateArray = theDateInputValue.split('/');
            const dateString = dateArray[2] + dateArray[0] + dateArray[1];
            this.triggerEvent({
                category: 'adopter registration',
                action: 'select adoption date',
                label: dateString,
            });
        }
    }, 0);
};

proto.onSubmit = function onSubmit() {
    // TODO: Add form validator and trigger event on success
    const userInfoAnalytics = UserInfoEventModel.prepareEvent({
        city: this.$inputCity ? this.$inputCity.val() : null,
        state: this.$inputState ? this.$inputState.val() : null,
        country: this.$inputCountry ? this.$inputCountry.val() : null,
        postalCode: this.$inputPostalCode ? this.$inputPostalCode.val() : null,
        petType:
            this.$inputPetType.find('option:selected') &&
            this.$inputPetType.find('option:selected').text() !== 'Choose type'
                ? this.$inputPetType.find('option:selected').text()
                : null,
    });

    const animalAnalytics = AnimalEventModel.prepareEvent({
        name: this.$inputPetName ? this.$inputPetName.val() : '',
    });

    const dimensions = {
        ...userInfoAnalytics.dimensions,
        ...animalAnalytics.dimensions,
    };

    const additional = {
        ...userInfoAnalytics.dataLayer,
        ...animalAnalytics.dataLayer,
    };

    this.triggerEvent({
        category: 'adopter registration',
        action: 'submit',
        label: 'guest user',
        dimensions,
        additional,
    });

    // TODO: The logic below is inaccurate/possibly broken in that the error fields remain after the first submission.  Need a way to check if current form values are correct.
    if (this.$element.find('.m-field_error').length !== 0) {
        return;
    }

    if (this.$inputPurinaOptin.is(':checked')) {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'crm opt in',
            label: 'Purina',
        });
    }

    if (
        this.$inputPetfinderOptinOther.is(':checked') ||
        this.$inputCountry.val() == 'US' ||
        this.$inputCountry.val() == 'us'
    ) {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'crm opt in',
            label: 'Petfinder',
        });
    }

    // if password fields valid
    if (
        this.$inputPassword.val() !== '' &&
        this.$inputPasswordConfirm.val() !== '' &&
        !this.$inputPassword.hasClass('.m-field-error') &&
        !this.$inputPasswordConfirm.hasClass('.m-field-error')
    ) {
        const analytics = UserLoginRegistrationEventModel.prepareEvent({
            authenticationStatus: 'registered',
            loginProvider: 'petfinder',
            initiatingSite: 'petfinder',
            initiatingUI: 'did you adopt guest registration',
            currentScreen: 'did you adopt form',
        });

        // Dimensional data was omitted by request of client
        this.triggerEvent({
            category: 'adopter registration',
            action: 'guest create account',
            // dimensions: analytics.dimensions,
            additional: analytics.dataLayer,
        });
    }
};

proto.onLoginSubmit = function onLoginSubmit() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'account login click',
    });
};

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.ELEMENT =
    '.js-guestCouponApplicationEnsightenAnalytics';

/**
 * @property SELECTORS.INPUT_FIRST_NAME
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_FIRST_NAME =
    '.js-guestCouponApplicationEnsightenAnalytics-inputFirstName';

/**
 * @property SELECTORS.INPUT_LAST_NAME
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_LAST_NAME =
    '.js-guestCouponApplicationEnsightenAnalytics-inputLastName';

/**
 * @property SELECTORS.INPUT_ADDRESS_1
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_1 =
    '.js-guestCouponApplicationEnsightenAnalytics-inputAddress1';

/**
 * @property SELECTORS.INPUT_ADDRESS_2
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_1 =
    '.js-guestCouponApplicationEnsightenAnalytics-inputAddress2';

/**
 * @property SELECTORS.INPUT_CITY
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_CITY =
    '.js-guestCouponApplicationEnsightenAnalytics-inputCity';

/**
 * @property SELECTORS.INPUT_STATE
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_STATE =
    '.js-relatedInputs-countryState-stateSelect';

/**
 * @property SELECTORS.INPUT_COUNTRY
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_COUNTRY =
    '.js-countryOptinRelatedInputs-inputCountry';

/**
 * @property SELECTORS.INPUT_POSTAL_CODE
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_POSTAL_CODE =
    '.js-guestCouponApplicationEnsightenAnalytics-inputPostalCode';

/**
 * @property SELECTORS.EMAIL
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_EMAIL =
    '.js-guestCouponApplicationEnsightenAnalytics-inputEmail';

/**
 * @property SELECTORS.INPUT_PET_NAME
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_NAME =
    '.js-guestCouponApplicationEnsightenAnalytics-inputPetName';

/**
 * @property SELECTORS.INPUT_PET_TYPE
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_TYPE =
    '.js-guestCouponApplicationEnsightenAnalytics-inputPetType';

/**
 * @property SELECTORS.INPUT_ADOPTION_SOURCE
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_SOURCE =
    '.js-guestCouponApplicationEnsightenAnalytics-inputAdoptionSource';

/**
 * @property SELECTORS.INPUT_ADOPTION_DATE
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_DATE =
    '.js-guestCouponApplicationEnsightenAnalytics-inputAdoptionDate';

/**
 * @property SELECTORS.INPUT_SUBMIT
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_SUBMIT =
    '.js-guestCouponApplicationEnsightenAnalytics-inputSubmit';

/**
 * @property SELECTORS.INPUT_PASSWORD
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PASSWORD =
    '.js-guestCouponApplicationEnsightenAnalytics-inputPassword';

/**
 * @property SELECTORS.INPUT_PASSWORD_CONFIRM
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PASSWORD_CONFIRM =
    '.js-guestCouponApplicationEnsightenAnalytics-inputPasswordConfirm';

/**
 * @property SELECTORS.INPUT_PURINA_OPTIN
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PURINA_OPTIN =
    '.js-guestCouponApplicationEnsightenAnalytics-purinaOptin';

/**
 * @property SELECTORS.INPUT_PETFINDER_OPTIN_OTHER
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PETFINDER_OPTIN_OTHER =
    '.js-guestCouponApplicationEnsightenAnalytics-petfinderOptinOther';

/**
 * @property SELECTORS.INPUT_PETFINDER_OPTIN_US
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PETFINDER_OPTIN_US =
    '.js-guestCouponApplicationEnsightenAnalytics-petfinderOptinUs';

/**
 *
 * @property SELECTORS.FORM
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.FORM =
    '.js-guestCouponApplicationEnsightenAnalytics-form';

/**
 * Grabbing by ID due to complexities with passing in JS specific selector
 *
 * @property SELECTORS.INPUT_LOGIN_SUBMIT
 * @static
 * @final
 * @type {string}
 */
GuestCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_LOGIN_SUBMIT =
    '.js-form #Login_Page_submit';

export default GuestCouponApplicationEnsightenAnalytics;
