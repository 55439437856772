/*
    <a class="txt txt_link" href="rel:555-555-5555">
        555-555-5555
    </a>
*/
import { html } from '../../../../../core/scripts/util/util.js';
export const phoneLink = vm => html`
   ${vm.phone && html`
        <a class="txt txt_link m-txt_underline" href="tel:${vm.phone}">
            $${vm.phone}
        </a>
    `}
`;
