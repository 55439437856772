import BaseEnsightenAnalytics from '../../../../core/scripts/analytics/ensighten/BaseEnsightenAnalytics';
import UserInfoEventModel from '../../../../core/scripts/analytics/models/user-info/UserInfoEventModel';
import $ from 'jquery';

/**
 * About Me Form Analytic tracking component.
 *
 * @class CouponApplicationSuccessEnsightenAnalytics
 * @constructor
 */
const CouponApplicationSuccessEnsightenAnalytics = function(
    $element,
    eventBus
) {
    BaseEnsightenAnalytics.call(this, $element, eventBus);
    this.init($element, eventBus);
};

// Inheritance
CouponApplicationSuccessEnsightenAnalytics.prototype = Object.create(
    BaseEnsightenAnalytics.prototype
);
CouponApplicationSuccessEnsightenAnalytics.prototype.constructor = CouponApplicationSuccessEnsightenAnalytics;

const proto = CouponApplicationSuccessEnsightenAnalytics.prototype;
const _super = BaseEnsightenAnalytics.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);

    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @extends {BaseForm.setupHandlers}
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onPrimaryBreedChangeHandler = this.onPrimaryBreedChange.bind(this);
    this.onSecondaryBreedChangeHandler = this.onSecondaryBreedChange.bind(this);
    this.onBirthDateChangeHandler = this.onBirthDateChange.bind(this);
    this.onFoodPreferenceChangeHandler = this.onFoodPreferenceChange.bind(this);
    this.onDiscoveryMethodChangeHandler = this.onDiscoveryMethodChange.bind(
        this
    );
    this.onInterestTopicHandler = this.onInterestTopicChange.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    return this;
};

proto.onPrimaryBreedChange = function onPrimaryBreedChange(e) {
    if (e.target.value !== '') {
        const text = e.target.options[e.target.selectedIndex].text;
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter new pets primary breed',
            label: text,
        });
    }
};

proto.onSecondaryBreedChange = function onSecondaryBreedChange(e) {
    if (e.target.value !== '') {
        const text = e.target.options[e.target.selectedIndex].text;
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter new pets secondary breed',
            label: text,
        });
    }
};

proto.onBirthDateChange = function onBirthDateMonthChange() {
    if (this.$birthDateMonth.val() === '' || this.$birthDateYear.val() === '') {
        return;
    }
    let month;
    if (parseInt(this.$birthDateMonth.val(), 10) < 10) {
        month = `0${this.$birthDateMonth.val()}`;
    } else {
        month = this.$birthDateMonth.val();
    }
    const val = this.$birthDateYear.val() + month;
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter new pets birth date',
        label: val,
    });
};

proto.onFoodPreferenceChange = function onFoodPreferenceChange(e) {
    const $currentTarget = $(e.currentTarget);
    const val = $currentTarget.find('option:selected').text();
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter new pets food preference',
            label: val,
        });
    }
};

proto.onDiscoveryMethodChange = function onDiscoveryMethodChange(e) {
    const selectedLabel =
        this.$discoveryMethod.find('option:selected') &&
        this.$discoveryMethod.find('option:selected').text() !== '-'
            ? this.$discoveryMethod.find('option:selected').text()
            : '(not set)';
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter how you found out about new pet',
            label: selectedLabel,
        });
    }
};

proto.onInterestTopicChange = function onInterestTopicChange(e) {
    const val = e.currentTarget.dataset.ensightenEventName.toLowerCase();
    this.triggerEvent({
        category: 'adopter registration',
        action: 'select topic of interest',
        label: val,
    });
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @extends {BaseForm.createChildren}
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$primaryBreed = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-primaryBreed'
    );
    this.$secondaryBreed = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-secondaryBreed'
    );
    this.$birthDateMonth = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-birthDateMonth'
    );
    this.$birthDateYear = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-birthDateYear'
    );
    this.$foodPreference = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-foodPreference'
    );
    this.$discoveryMethod = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-discoveryMethod'
    );
    this.$interestTopic = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-interestTopic'
    );
    this.$form = this.$element.find(
        '.js-couponApplicationSuccessEnsightenAnalytics-form'
    );
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @extends {BaseForm.enable}
 * @private
 * @chainable
 */
proto.enable = function enable() {
    _super.enable.call(this);

    this.setupListeners();
    this.triggerAnalytics();

    return this;
};

proto.triggerAnalytics = function triggerAnalytics() {
    if (this.$element.data('is-authed')) {
        if (this.$element.data('is-qualified')) {
            this.triggerEvent({
                category: 'adopter registration',
                action: 'logged-in user submit coupon application form',
                label: 'qualifies',
            });
        } else {
            this.triggerEvent({
                category: 'adopter registration',
                action: 'logged-in user submit coupon application form',
                label: 'does not qualify for offer',
            });
        }
    } else {
        if (this.$element.data('is-qualified')) {
            this.triggerEvent({
                category: 'adopter registration',
                action: 'guest submit coupon registration form',
                label: 'qualifies',
            });
        } else {
            this.triggerEvent({
                category: 'adopter registration',
                action: 'guest submit coupon registration form',
                label: 'does not qualify for offer',
            });
        }
    }
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @extends {BaseForm.setupListeners}
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    this.$primaryBreed.on('change', this.onPrimaryBreedChangeHandler);
    this.$secondaryBreed.on('change', this.onSecondaryBreedChangeHandler);
    this.$birthDateYear.on('change', this.onBirthDateChangeHandler);
    this.$birthDateMonth.on('change', this.onBirthDateChangeHandler);
    this.$foodPreference.on('change', this.onFoodPreferenceChangeHandler);
    this.$discoveryMethod.on('change', this.onDiscoveryMethodChangeHandler);
    this.$interestTopic.on('change', this.onInterestTopicHandler);
    this.$form.on('submit', this.onSubmitHandler);
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    _super.disable.call(this);
    this.$primaryBreed.off('change', this.onPrimaryBreedChangeHandler);
    this.$secondaryBreed.off('change', this.onSecondaryBreedChangeHandler);
    this.$birthDateYear.off('change', this.onBirthDateChangeHandler);
    this.$birthDateMonth.off('change', this.onBirthDateChangeHandler);
    this.$foodPreference.off('change', this.onFoodPreferenceChangeHandler);
    this.$discoveryMethod.off('change', this.onDiscoveryMethodChangeHandler);
    this.$interestTopic.off('change', this.onInterestTopicHandler);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method doBothNameInputsHaveAValue
 * @private
 * @returns {boolean}
 */
proto.doBothNameInputsHaveAValue = function doBothNameInputsHaveAValue() {};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method onInputNameBlur
 * @private
 */
proto.onInputNameBlur = function onInputNameBlur() {
    if (this.doBothNameInputsHaveAValue()) {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter name',
            label: 'logged-in user',
        });
    }
};

/**
 * @method onInputAddressBlur
 * @private
 */
proto.onInputAddress1Blur = function onInputAddress1Blur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter address',
        label: 'logged-in user',
    });
};

/**
 * @method onInputEmailBlur
 * @private
 */
proto.onInputEmailBlur = function onInputEmailBlur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter email',
        label: 'logged-in user',
    });
};

/**
 * @method onInputPetNameBlur
 * @private
 */
proto.onInputPetNameBlur = function onInputPetNameBlur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter new pets name',
        label: 'logged-in user',
    });
};

/**
 * @method onInputPetTypeChange
 * @param {Event} e
 * @private
 */
proto.onInputPetTypeChange = function onInputPetTypeChange(e) {
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'select type of animal',
            label: $(e.currentTarget)
                .find('option:selected')
                .text()
                .toLowerCase(),
        });
    }
};

/**
 * @method onInputAdoptionSourceChange
 * @param {Event} e
 * @private
 */
proto.onInputAdoptionSourceChange = function onInputAdoptionSourceChange(e) {
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'select adoption source',
            label: $(e.currentTarget)
                .find('option:selected')
                .text()
                .toLowerCase(),
        });
    }
};

/**
 * @method onInputAdoptionDateBlur
 * @param {Event} e
 * @private
 */
proto.onInputAdoptionDateBlur = function onInputAdoptionDateBlur(e) {
    // plugin causes double blur event
    if (!this.dateEventTriggered) {
        if (e.currentTarget.value !== '') {
            const dateArray = e.currentTarget.value.split('/');
            const dateString = dateArray[2] + dateArray[0] + dateArray[1];
            this.triggerEvent({
                category: 'adopter registration',
                action: 'select adoption date',
                label: dateString,
            });
        }
        this.dateEventTriggered = true;
    }
};

/**
 * Coupon App form, so tracking submission of that $element.  If no fields are in error,
 * should be good to track the event.
 * @method onSubmit
 * @private
 */
proto.onSubmit = function onSubmit() {
    // TODO: Add form validator and trigger event on success
    let age = false;
    if (
        this.$birthDateMonth &&
        this.$birthDateMonth.val() &&
        this.$birthDateYear &&
        this.$birthDateYear.val()
    ) {
        const dob = new Date(
            this.$birthDateYear.val(),
            this.$birthDateMonth.val(),
            1
        );
        const diff = new Date(Date.now() - dob.getTime());
        age = Math.abs(diff.getUTCFullYear() - 1970);
    }

    const analytics = UserInfoEventModel.prepareEvent({
        petBreed:
            this.$primaryBreed.find('option:selected') &&
            this.$primaryBreed.find('option:selected').text() !== 'Choose breed'
                ? this.$primaryBreed.find('option:selected').text()
                : null,
        petAge: age ? age : null,
        petDietaryNeeds:
            this.$foodPreference.find('option:selected') &&
            this.$foodPreference.find('option:selected').text() !== '-'
                ? this.$foodPreference.find('option:selected').text()
                : null,
    });

    this.triggerEvent({
        category: 'adopter registration',
        action: 'submit',
        label: 'logged-in user',
        dimensions: analytics.dimensions,
        additional: analytics.dataLayer,
    });
};
/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
CouponApplicationSuccessEnsightenAnalytics.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
CouponApplicationSuccessEnsightenAnalytics.SELECTORS.ELEMENT =
    '.js-couponApplicationSuccessEnsightenAnalytics';

/**
 * Grabbing by ID due to complexities with passing in JS specific selector
 *
 * @property SELECTORS.FORM
 * @static
 * @final
 * @type {string}
 */
CouponApplicationSuccessEnsightenAnalytics.SELECTORS.FORM =
    '.js-couponApplicationSuccessEnsightenAnalytics-form';

export default CouponApplicationSuccessEnsightenAnalytics;
