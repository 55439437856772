import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

class HappyTailsSubmissionBanner extends Component {
    static propTypes = {
        submission: PropTypes.shape({
            buttonPrimary: PropTypes.string.isRequired,
            buttonSecondary: PropTypes.string,
            headline: PropTypes.string.isRequired,
            linkPrimary: PropTypes.string.isRequired,
            linkSecondary: PropTypes.string,
            text: PropTypes.string.isRequired,
        }).isRequired,
        onPrimaryButtonClick: PropTypes.func,
        onSecondaryButtonClick: PropTypes.func,
    };

    static defaultProps = {
        onPrimaryButtonClick: _noop,
        onSecondaryButtonClick: _noop,
    };

    imgRef = React.createRef();

    componentDidMount() {
        // apply objectFit polyfill to images for IE11
        if (window.objectFitImages) {
            objectFitImages(this.imgRef.current);
        }
    }

    _handlePrimaryButtonClick = label => {
        this.props.onPrimaryButtonClick(label);
    };

    _handleSecondaryButtonClick = label => {
        this.props.onSecondaryButtonClick(label);
    };

    _renderBackgroundImage = () => {
        return (
            <picture>
                <source
                    srcSet="
                    /assets/www/media/images/background/bg-happy-tail-submission-banner-mobile_480.jpg 480w,
                    /assets/www/media/images/background/bg-happy-tail-submission-banner-mobile_560.jpg 560w,
                    /assets/www/media/images/background/bg-happy-tail-submission-banner-mobile_720.jpg 720w"
                    media="(max-width: 1023px)"
                    sizes="100vw"
                />
                <source
                    srcSet="
                    /assets/www/media/images/background/bg-happy-tail-submission-banner-desktop_1024.jpg 1024w,
                    /assets/www/media/images/background/bg-happy-tail-submission-banner-desktop_1240.jpg 1240w,
                    /assets/www/media/images/background/bg-happy-tail-submission-banner-desktop_1440.jpg 1440w"
                    sizes="100vw"
                />
                <img
                    className="banner-background"
                    src="/assets/www/media/images/background/bg-happy-tail-submission-banner-desktop_1024.jpg"
                    alt="woman sitting with her dog"
                    title="get started happy tails"
                    aria-hidden="true"
                    tabIndex="-1"
                    ref={this.imgRef}
                />
            </picture>
        );
    };

    render() {
        const submission = this.props.submission;
        return (
            <div className="banner banner_darkOverlay">
                {this._renderBackgroundImage()}
                <div className="banner-inner">
                    <div className="banner-inner-body">
                        <h2 className="banner-inner-body-hdg">
                            {submission.headline}
                        </h2>
                        <p className="banner-inner-body-text">
                            {submission.text}
                        </p>
                        <a
                            href={submission.linkPrimary}
                            alt={submission.buttonPrimary}
                            className="banner-inner-body-button"
                            onClick={() =>
                                this._handlePrimaryButtonClick(
                                    submission.buttonPrimary
                                )
                            }
                            data-test="Happy_Tails_Submission_Banner_Primary_Button"
                        >
                            {submission.buttonPrimary}
                        </a>
                        {submission.linkSecondary && (
                            <a
                                href={submission.linkSecondary}
                                alt={submission.buttonSecondary}
                                className="banner-inner-body-button banner-inner-body-button_secondary "
                                onClick={() =>
                                    this._handleSecondaryButtonClick(
                                        submission.buttonSecondary
                                    )
                                }
                                data-test="Happy_Tails_Submission_Banner_Secondary_Button"
                            >
                                {submission.buttonSecondary}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default HappyTailsSubmissionBanner;
