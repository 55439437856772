import $ from 'jquery';

/**
 * BaseComponent
 * BaseComponent for new View components to inherit from
 *
 * @class BaseComponent View
 * @constructor
 * @param {jQuery} $element Sets the viewBase.$element property.
 * @param {Object} eventBus Event Bus shared between all app components
 */
function BaseComponent($element, eventBus, routes, i18nModel) {
    if ($element == null) {
        return this;
    }

    if (!$element instanceof $) {
        throw new TypeError('Unable to instantiate View, expected jQuery Object');
    }

    this.init($element, eventBus, routes, i18nModel);
}

// Variable holds reference to prototype for convenience
var proto = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus, routes, i18nModel) {
    /**
     * An HTMLElement wrapped in jQuery that acts as the main element for this view instance.
     *
     * @property $element
     * @type {jQuery}
     */
    this.$element = $element;

    /**
     * Flag to indicate whether the module has been enabled
     *
     * @property isEnabled
     * @type {Boolean}
     * @default false
     */
    this.isEnabled = false;

    /**
     * Event Bus tracking events throughout components in the app.
     *
     * @property eventBus
     * @type {Object}
     */
    this.eventBus = eventBus;

    this.i18n = i18nModel;

    this.routes = routes;
    return this.setupEnableHandler()
        .beforeEnable();
};

/**
 * Binds the scope of the handler listening for the component enabling event
 *
 * @method setupEnableHandler
 * @private
 * @chainable
 */
proto.setupEnableHandler = function setupEnableHandler() {
    this.onEnableHandler = this.onEnable.bind(this);
    return this;
};

/**
 * Method to execute before enabling the component.
 * Listens for the enable component event.
 *
 * @method setupEnableHandler
 * @private
 * @chainable
 */
proto.beforeEnable = function beforeEnable() {
    this.eventBus.on('enableComponents', this.onEnableHandler);
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    if (this.isEnabled) { return; }
    this.isEnabled = true;
    return this;
};

/**
 * Disables the view
 * Tears down any event binding to handlers
 * Exits early if it is already disabled
 *
 * @method enable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    if (!this.isEnabled) { return this; }
    this.isEnabled = false;
    return this;
};

proto.destroy = function destroy() {
    this.disable();
    for (var key in this) {
        if (this.hasOwnProperty(key)) {
            this[key] = null;
        }
    }

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Event Handler for the enable component event
 * Runs the enable method
 *
 * @method onEnable
 * @private
 */
proto.onEnable = function onEnable() {
    if (this.isEnabled) { return; }
    this.enable();
    return this;
};


export default BaseComponent;