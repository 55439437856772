import { Machine } from 'xstate';

const withStatechart = (Base, statechart) =>
    class extends Base {
        machine = Machine(statechart);
        constructor() {
            super();
            this.state = this.machine.initialState; // TODO: safe name?
        }
        transition(event) {
            // TODO: sugar out type
            this.state = this.machine.transition(this.state, event);
            // console.log(`${currentState.value} => ${nextState.value}`);
            // TODO: ensure state is updated after each action with nextState
            this.state.actions.forEach(action => this.command(action, event));
        }
        command(action) {
            this[action]();
        }
    };

export default withStatechart;
