/**
 * @fileOverview State Model File
 * @author Noah Blon nblon@nerdery.com
 */
import BaseModel from './LegacyBaseModel';

    /**
     * StateModel
     * The model for a state
     *
     * @class StateModel
     * @constructor
     *
     */
    var StateModel = function() {
        this.init();
    };

    // Inheritance
    StateModel.prototype = new BaseModel();
    var proto = StateModel.prototype;
    proto.constructor = StateModel;
    proto.super = BaseModel.prototype;

    /**
     * Initializes the State Model
     * Sets up the local instance variables and defaults
     *
     * @for StateModel
     * @method init
     * @chainable
     * @returns {StateModel.prototype}
     * @private
     */
    proto.init = function init () {
        this.super.init.call(this);

        /**
         * State's name
         *
         * @for StateModel
         * @property name
         * @type {String}
         * @default ''
         */
        this.name = '';

        /**
         * State's abbreviation
         *
         * @for BreedModel
         * @property code
         * @type {String}
         * @default ''
         */
        this.code = '';

        return this;
    };

    /**
     * Parses json response from server into model.
     * If API parameters are added/removed/changed, reflect those changes here.
     *
     * @for StateModel
     * @method fromJSON
     * @public
     */
    proto.fromJSON = function (json) {
        this.name = json['name'];
        this.code = json['code'];
    };

export default StateModel;
