import BaseComponent from '../../components/BaseComponent/BaseComponent';
const I18nJSON = require('../../data/i18nJSON.json');
import CountryPostalCodePatternMatcher from '../../regexes/CountryZipPatternMatcher';

var CountryPostalCodeValidator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
CountryPostalCodeValidator.prototype = new BaseComponent();
var proto = CountryPostalCodeValidator.prototype;
proto.constructor = CountryPostalCodeValidator;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
CountryPostalCodeValidator.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
CountryPostalCodeValidator.SELECTORS = {};

/**
 * Data attr name for optional flag
 *
 * @property DATA_OPTIONAL
 * @static
 * @final
 * @type {String}
 */
CountryPostalCodeValidator.DATA_OPTIONAL = 'optional';


/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
CountryPostalCodeValidator.SELECTORS.ELEMENT = '.js-validatorCountryPostalCode';
CountryPostalCodeValidator.CLASSES.IS_REQUIRED = 'js-validatorCountryPostalCode_isRequired';
CountryPostalCodeValidator.SELECTORS.INPUT_COUNTRY = CountryPostalCodeValidator.SELECTORS.ELEMENT + '-inputCountry';
CountryPostalCodeValidator.SELECTORS.INPUT_POSTALCODE = CountryPostalCodeValidator.SELECTORS.ELEMENT + '-inputPostalCode';
CountryPostalCodeValidator.SELECTORS.ERROR_COUNTRY = CountryPostalCodeValidator.SELECTORS.ELEMENT + '-errorCountry';
CountryPostalCodeValidator.SELECTORS.ERROR_POSTALCODE = CountryPostalCodeValidator.SELECTORS.ELEMENT + '-errorPostalCode';
CountryPostalCodeValidator.SELECTORS.LABEL_COUNTRY = CountryPostalCodeValidator.SELECTORS.ELEMENT + '-labelCountry';
CountryPostalCodeValidator.SELECTORS.LABEL_POSTALCODE = CountryPostalCodeValidator.SELECTORS.ELEMENT + '-labelPostalCode';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.countryPostalCodePatternMatcher = new CountryPostalCodePatternMatcher();

    this.postalCodeInputHasBeenInteractedWith = false;
    this.countryInputHasBeenInteractedWith = false;
    this.postalCodeValidationMessageUs = I18nJSON['validator.postal_code_us'];
    this.postalCodeValidationMessageNonUs = I18nJSON['validator.postal_code_non_us'];
    this.emptyErrorMessage = I18nJSON['validator.required_postal_code'];
    this.isRequired = this.$element.hasClass(CountryPostalCodeValidator.CLASSES.IS_REQUIRED);
    return this.setupHandlers()
        .createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onCountryInputChangeHandler = this.onCountryInputChange.bind(this);
    this.onPostalCodeInputBlurHandler = this.onPostalCodeInputBlur.bind(this);
    this.onEventInputHandler = this.onEventInput.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputCountry = this.$element.find(CountryPostalCodeValidator.SELECTORS.INPUT_COUNTRY);
    this.$inputPostalCode = this.$element.find(CountryPostalCodeValidator.SELECTORS.INPUT_POSTALCODE);
    this.$errorCountry = this.$element.find(CountryPostalCodeValidator.SELECTORS.ERROR_COUNTRY);
    this.$errorPostalCode = this.$element.find(CountryPostalCodeValidator.SELECTORS.ERROR_POSTALCODE);

    this.$labelCountry = this.$element.find(CountryPostalCodeValidator.SELECTORS.LABEL_COUNTRY);
    this.$labelPostalCode = this.$element.find(CountryPostalCodeValidator.SELECTORS.LABEL_POSTALCODE);

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.isOptional = this.$element.data(CountryPostalCodeValidator.DATA_OPTIONAL) == true;
    this.setPostalCodeErrorMessage()
        .setupListeners();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$inputCountry.on('change', this.onEventInputHandler);
    this.$inputPostalCode.on('blur', this.onEventInputHandler);
    this.$inputCountry.on('change', this.onCountryInputChangeHandler);
    this.$inputPostalCode.on('blur', this.onPostalCodeInputBlurHandler);
    this.eventBus.on('submit', this.onSubmitHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    return this;
};


/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.resetInputs = function resetInputs() {
    this.$errorCountry.html('');
    this.$errorPostalCode.html('');
    this.$labelCountry.removeClass('label_error');
    this.$labelPostalCode.removeClass('label_error');
    this.$inputCountry.removeClass('m-field_error');
    this.$inputPostalCode.removeClass('m-field_error');
};

proto.setInvalidStyles = function setInvalidStyles($input, $label) {
    $input.addClass('m-field_error');
    $label.addClass('label_error');
    return this;
};

proto.clearInvalidStyles = function clearInvalidStyles() {
    $input.removeClass('m-field_error');
    $label.removeClass('label_error');
    return this;
};

proto.showErrorMessage = function showErrorMessage($element, errorMessage) {
    if (this.hasBeenInteractedWith) {
        $element.empty()
            .html(errorMessage);
    }
    return this;
};

proto.clearErrorMessage = function clearErrorMessage($element) {
    $element.empty();
    return this;
};

proto.isPostalCodeValidForCountry = function isPostalCodeValidForCountry() {
    return this.countryPostalCodePatternMatcher.isPostalCodeValidForCountry(this.countryCode, this.postalCode);
};

proto.clearInputValue = function clearInputValue($element) {
    $element.val('');
    return this;
};

proto.cacheInputValues = function cacheInputValues() {
    this.countryCode = this.$inputCountry.val();
    this.postalCode = this.$inputPostalCode.val();
};

proto.dispatchValidationEvents = function dispatchValidationEvents() {
    if (this.isPostalCodeValidForCountry()) {
        this.eventBus.trigger('successfulValidatorValidation', this);
    } else {
        this.eventBus.trigger('failedValidatorValidation', this);
    }
};

proto.setPostalCodeErrorMessage = function setPostalCodeErrorMessage() {
    if (this.$inputPostalCode.val() == '' && !this.isOptional) {
        this.postalCodeTxt = this.emptyErrorMessage;
    }
    else if (this.$inputCountry.val() == 'US' || this.$inputCountry.val() == 'us') {
        this.postalCodeTxt = this.postalCodeValidationMessageUs;
    } else {
        this.postalCodeTxt = this.postalCodeValidationMessageNonUs;
    }
    return this;
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onEventInput = function onEventInput() {
    this.resetInputs();
};


proto.onCountryInputChange = function onCountryInputChange() {
    this.countryCodeInputHasBeenInteractedWith = true;
    this.hasBeenInteractedWith = true;
    this.cacheInputValues();

    if (this.countryCode == '') {
        if (!this.isOptional) {
            this.setInvalidStyles(this.$inputCountry, this.$labelCountry)
                .showErrorMessage(this.$errorCountry, this.emptyErrorMessage);
        }
    } else {
        if (this.postalCodeInputHasBeenInteractedWith) {
            if (this.postalCode == '') {
            }
            else if (!this.isPostalCodeValidForCountry()) {
                this.clearInputValue(this.$inputPostalCode);
            } else {
            }
        }
    }
    this.dispatchValidationEvents();
};

proto.onPostalCodeInputBlur = function onPostalCodeInputBlur() {
    this.postalCodeInputHasBeenInteractedWith = true;
    this.hasBeenInteractedWith = true;
    this.cacheInputValues();
    if (this.isRequired && this.postalCode === '') {
        if (!this.isPostalCodeValidForCountry()) {
            this.setPostalCodeErrorMessage(this.emptyErrorMessage)
                .showErrorMessage(this.$errorPostalCode, this.postalCodeTxt)
                .setInvalidStyles(this.$inputPostalCode, this.$labelPostalCode);
        }
    }
    if (this.countryCodeInputHasBeenInteractedWith) {
        if (this.postalCode == '') {
            return;
        } else if (this.countryCode == '') {
            if (!this.isOptional) {
                this.showErrorMessage(this.$errorCountry, this.emptyErrorMessage);
            }
        } else {
            if (!this.isPostalCodeValidForCountry()) {
                this.setPostalCodeErrorMessage()
                    .showErrorMessage(this.$errorPostalCode, this.postalCodeTxt)
                    .setInvalidStyles(this.$inputPostalCode, this.$labelPostalCode);
            }
        }
    }
    this.dispatchValidationEvents();
};

proto.onSubmit = function onSubmit() {
    this.cacheInputValues();
    this.hasBeenInteractedWith = true;
    if (this.countryCode == '') {
        if (!this.isOptional) {
            this.showErrorMessage(this.$errorCountry, this.emptyErrorMessage)
                .showErrorMessage(this.$errorPostalCode, this.emptyErrorMessage)
                .setInvalidStyles(this.$inputCountry, this.$labelCountry)
                .setInvalidStyles(this.$inputPostalCode, this.$labelPostalCode);
        }
    } else {
        if (!this.isPostalCodeValidForCountry()) {
            this.setPostalCodeErrorMessage()
                .showErrorMessage(this.$errorPostalCode, this.postalCodeTxt)
                .setInvalidStyles(this.$inputPostalCode, this.$labelPostalCode);
        } else {
        }
    }
    this.dispatchValidationEvents();
};

export default CountryPostalCodeValidator;
