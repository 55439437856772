/**
 * @fileOverview State Model File
 */
import { Config } from '../lib/Config';
const routesJSON = require('../data/routes.json');
const externalRoutesJSON = require('../data/externalRoutes.json');
const wwwUrl = Config.wwwUrl;
const proUrl = Config.proUrl;

/**
 * All routes are exported to a json object in a frontend build step.
 * These routes are consumed here and mapped to object properties and used elsewhere as config.
 *
 * @class RouteModel
 * @constructor
 *
 */
var RouteModel = function() {
    this.api = {};
    // TODO
    this.api['createUserPet'] = routesJSON['create_user_pet'];
    this.api['deleteUserPet'] = routesJSON['delete_user_pet'];
    this.api['getCountryByIp'] = routesJSON['api_get_country_by_ip'];
    this.api['getCountries'] = routesJSON['api_get_countries'];
    this.api['getDiscoveryMethodOptionsByType'] =
        routesJSON['discovery_method_options'];
    this.api['getDyaDetails'] = routesJSON['dya_details'];
    this.api['getPetBreedsByType'] = routesJSON['pet_breeds'];
    this.api['getPetFoodRetailersByType'] = routesJSON['pet_food_retailers'];
    this.api['getPetFoodPreferencesByType'] =
        routesJSON['pet_food_preferences'];
    this.api['getStatesForCountry'] = routesJSON['api_get_states_for_country'];
    this.api['loginCheck'] = routesJSON['login_check'];
    this.api['organizationsAutocomplete'] =
        routesJSON['organizations_autocomplete'];
    this.api['profile'] = routesJSON['profile'];
    this.api['updateUserPet'] = routesJSON['update_user_pet'];
    this.api['sessionTTL'] = BuildRoute(
        externalRoutesJSON['petfinder_session_ttl']
    );
    this.api['register'] = routesJSON['register'];
    this.forgottenPassword = routesJSON['forgotten_password'];

    this.petfinderFoundation = BuildRoute(
        externalRoutesJSON['petfinder_foundation']
    );
    this.petfinderFoundationSponsorAPet = BuildRoute(
        externalRoutesJSON['petfinder_foundation_sponsor_a_pet']
    );
    this.petfinderPrivacyPolicy = BuildRoute(
        externalRoutesJSON['petfinder_privacy_policy']
    );
    this.petfinderProPlanPromotionFAQ = BuildRoute(
        externalRoutesJSON['petfinder_pro_plan_promotion_faq']
    );
    this.petfinderSavedSearch = routesJSON['animal_search_proxy'];
    this.petsmartFromDidYouAdopt = BuildRoute(
        externalRoutesJSON['petsmart_fron_did_you_adopt']
    );
    this.purinaPrivacyPolicy = BuildRoute(
        externalRoutesJSON['purina_privacy_policy']
    );
    this.purinaAboutOurAds = BuildRoute(
        externalRoutesJSON['purina_about_our_ads']
    );
    this.purinaProPlanFromDidYouAdopt = BuildRoute(
        externalRoutesJSON['purina_pro_plan_from_did_you_adopt']
    );
    this.memberRegistrationLanding = BuildRoute(
        externalRoutesJSON['member_registration_landing']
    );
    this.petDetail = '/petdetail/';
};

var BuildRoute = function(url) {
    if (typeof url == 'undefined') {
        return '';
    }
    url = url.replace('{www}', wwwUrl);
    url = url.replace('{pro}', proUrl);

    return url;
};

export default RouteModel;
