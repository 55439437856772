/**
 * @fileOverview UserLoginModel
 * @author Noah Blon nblon@nerdery.com
 */
import BaseModel from './LegacyBaseModel';

/**
 * UserLoginModel
 * Model for response from login check
 *
 * @class UserLoginModel
 * @constructor
 */
var UserLoginModel = function UserLoginModel() {
    this.init();
};

// Inheritance
UserLoginModel.prototype = new BaseModel();
var proto = UserLoginModel.prototype;
proto.constructor = UserLoginModel;
proto.super = BaseModel.prototype;

/**
 * Initializes the Breed Model
 * Sets up the local instance variables and defaults
 *
 * @for UserLoginModel
 * @method init
 * @chainable
 * @returns {UserLoginModel.prototype}
 * @private
 */
proto.init = function init() {
    this.super.init.call(this);

    /**
     * URL to redirect to upon successful login
     *
     * @for UserLoginModel
     * @property redirect
     * @type {String}
     * @default ''
     */
    this.redirect = '';

    /**
     * Boolean indicating whether login was successful
     *
     * @for UserLoginModel
     * @property success
     * @type {Boolean}
     * @default null
     */
    this.success = null;

    return this;
};

/**
 * Parses json response from server into model.
 * If API parameters are added/removed/changed, reflect those changes here.
 *
 * @for UserLoginModel
 * @method fromJSON
 * @public
 */
proto.fromJSON = function fromJSON(json) {
    this.redirect = json['redirect'];
    this.success = json['success'];
};

export default UserLoginModel;
