import React, { Component, Fragment } from 'react';
import { State, withStateMachine } from 'react-automata';
import statechart from './statechart';

class ShowHide extends Component {
    handleTriggerClicked = () => {
        this.props.transition('triggerClickedEvent');
    };

    render() {
        return (
            <Fragment>
                <State is="isHiddenState">
                    {this.props.hiddenContent(this)}
                </State>
                <State is="isVisibleState">
                    {this.props.visibleContent(this)}
                </State>
            </Fragment>
        );
    }
}

export default withStateMachine(statechart)(ShowHide);
