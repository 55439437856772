import { PFElement } from '../../../../core/scripts/elements/pf-element/element';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-video';

/**
 * JWPlayers Key
 * @type {string}
 */
const JW_PLAYER_KEY = 'RnELzbMo0yZzNdJ0eiRC9iyE3/YAO1XPIwAoHg==';

/**
 * Player Config: Height
 * @type {string}
 */
const VIDEO_HEIGHT = '355';

/**
 * Player Config: Width
 * @type {string}
 */
const VIDEO_WIDTH = '100%';

/**
 * Player Config: Aspect Ration
 * @type {string}
 */
const ASPECT_RATIO = '16:9';

/**
 * Cloudfront URL
 * @type {string}
 */
const VIDEO_URL = 'https://d3fenhwk93s16g.cloudfront.net/';

/**
 * Video Formats
 * @type {[string,string,string]}
 */
const FORMATS = ['mp4', 'webm', 'flv'];

/**
 * Div ID
 * @type {string}
 */
const DIV_ID = 'js-video';

export class PFDCVideoElement extends PFElement {
    /**
     * Gets the vidly ID from the element
     */
    get vidlyId() {
        if (!this.hasAttribute('vidlyId')) {
            throw new Error('A vidly ID is required to display a video');
        }
        return this.getAttribute('vidlyId');
    }

    /**
     * Gets the video closed captioning webvtt file path
     * @returns {boolean|string}
     */
    get webvttFile() {
        if (!this.hasAttribute('webvtt')) {
            return false;
        }
        return this.getAttribute('webvtt');
    }

    /**
     * Gets the video ad tag.
     * @returns {boolean}
     */
    get adTag() {
        if (!this.hasAttribute('ad_tag')) {
            return false;
        }
        return this.getAttribute('ad_tag');
    }

    /**
     * Sets up the player config
     * @returns {object}
     */
    get playerConfig() {
        const now = new Date();
        const encodedLocation = encodeURI(window.location.href);

        return {
            width: VIDEO_WIDTH,
            height: VIDEO_HEIGHT,
            aspectratio: ASPECT_RATIO,
            playlist: [
                {
                    image: `//vid.ly/${this.vidlyId}/poster`,
                    sources: this.videoSources,
                    tracks: [
                        {
                            file: this.webvttFile,
                            label: 'English',
                            type: 'captions',
                            default: true,
                        },
                    ],
                },
            ],
            key: JW_PLAYER_KEY,
            advertising: {
                client: 'googima',
                tag: `${
                    this.adTag
                }&url=${encodedLocation}&correlator=${now.getTime()}`,
            },
        };
    }

    /**
     * Compile video sources
     * @returns {Array}
     */
    get videoSources() {
        let i = 0;
        const sources = [];

        for (i; i < FORMATS.length; i++) {
            // e.g. /mp4.mp4 - coordinating with vid.ly naming convention
            const filename = `/${FORMATS[i]}.${FORMATS[i]}`;
            sources.push({
                file: `${VIDEO_URL}${this.vidlyId}${filename}`,
            });
        }

        return sources;
    }

    loadLibrary() {
        return import(
            /* webpackChunkName: "manual-chunk-jw-player" */
            '../../../../core/scripts/vendor/jwplayer/jwplayer'
        );
    }

    async onInit() {
        await this.loadLibrary();

        this.player = window.jwplayer(DIV_ID);
        this.player.setup(this.playerConfig);
        this.playbackBtn = null;

        this.player.on('ready', (setupTime, viewable) => {
            this.playbackBtn = this.querySelector('.jw-icon-playback');
        });

        this.player.on('play', (oldstate, viewable) => {
            // Toggle aria label text
            setTimeout(() => {
                this.playbackBtn.setAttribute('aria-label', 'Pause');
            }, 250);
        });

        this.player.on('pause', (oldstate, viewable) => {
            // Toggle aria label text
            setTimeout(() => {
                this.playbackBtn.setAttribute('aria-label', 'Play');
            }, 250);
        });

        this.setupCompleteListener(this.player);
    }

    /**
     * Sets up an oncomplete listener
     * @param {object} setupPlayerInstance jw player instance after setup method has been run
     */
    setupCompleteListener(setupPlayerInstance) {
        const self = this;

        /**
         * If IE9, hookup to the player autocomplete and resetup the video without ads
         * when the video is complete. This eliminates a bug in which the preroll ad is
         * played twice for IE9 users. Runs after the initial setup and only for IE9.
         */
        if (document.documentElement.classList.contains('ie9')) {
            setupPlayerInstance.onComplete(() => {
                self.isAdEnabled = false;
                setupPlayerInstance.setup(self.playerConfig);
            });
        }
    }
}

export default PFDCVideoElement;
