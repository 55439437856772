import { html } from '../../../core/scripts/util/util';

export default vm => {
    const {
        city,
        id,
        name,
        nameWrap,
        state,
    } = vm;

    return html`
        <li
            class="autocomplete-suggestion js-shelterAutocomplete-result"
            data-sheltername="$${name}"
            data-id="$${id}"
            tabindex="0"
            role="option"
        >
            <span class="js-shelterAutocomplete-result-name">${nameWrap}</span><br/>
            ${city ? html`
                <span class="u-caps">$${city}${state ? html`, $${state}` : ''}</span>
            ` : html`
                ${state ? html`<span class="u-caps">$${state}</span>` : ''}
            `}
        </li>
    `;
};
