import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import $ from 'jquery';
import { trackEvent } from '../../../../core/scripts/analytics';
/**
 * About Me Form Analytic tracking component.
 *
 * @class EmailPreferencesEnsightenAnalytics
 * @constructor
 */
const EmailPreferencesEnsightenAnalytics = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
EmailPreferencesEnsightenAnalytics.prototype = new BaseComponent();
const proto = EmailPreferencesEnsightenAnalytics.prototype;
proto.constructor = EmailPreferencesEnsightenAnalytics;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
EmailPreferencesEnsightenAnalytics.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
EmailPreferencesEnsightenAnalytics.SELECTORS.ELEMENT =
    '.js-emailPreferencesEnsightenAnalytics';

EmailPreferencesEnsightenAnalytics.SELECTORS.INPUT_EMAIL_PREFERENCE =
    '[id^="user_email_preferences_emailPreferences"]';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @extends {BaseForm.setupHandlers}
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onInputEmailPreferenceChangeHandler = this.onInputEmailPreferenceChange.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @extends {BaseForm.createChildren}
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputEmailPreference = this.$element.find(
        EmailPreferencesEnsightenAnalytics.SELECTORS.INPUT_EMAIL_PREFERENCE
    );
    this.$successMessage = this.$element.find('.alert_success');
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @extends {BaseForm.enable}
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    if (this.$successMessage.length > 0) {
        trackEvent({
            category: 'email preferences',
            action: 'update preferences',
        });
    }
    this.setupListeners();
    return this;
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @extends {BaseForm.setupListeners}
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    this.$inputEmailPreference.on(
        'change',
        this.onInputEmailPreferenceChangeHandler
    );
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    return this;
};

proto.doBothNameInputsHaveAValue = function doBothNameInputsHaveAValue() {
    return this.$inputFirstName != '' && this.$inputLastName != '';
};

proto.transformSubscriptionCodeToReadable = function transformSubscriptionCodeToReadble(
    subCode
) {
    if (subCode == 'PF') {
        subCode = 'Petfinder';
    } else if (subCode == 'PU') {
        subCode = 'Purina and its Brands';
    } else if (subCode == 'FN') {
        subCode = 'Petfinder Foundation';
    } else if (subCode == 'OT') {
        subCode = 'Partner Message & Offers';
    }
    return subCode;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onInputEmailPreferenceChange = function onInputEmailPreferenceChange(
    event
) {
    const $currentTarget = $(event.currentTarget);
    let emailPreferencesName = $currentTarget.attr(
        'data-email-preferences-name'
    );
    emailPreferencesName = this.transformSubscriptionCodeToReadable(
        emailPreferencesName
    );
    const checked = $currentTarget.is(':checked');
    const yesOrNo = checked ? 'Yes' : 'No';
    purinaTrackEvent({
        eventCategory: 'email preferences',
        eventAction: emailPreferencesName,
        eventLabel: yesOrNo,
    });
};

export default EmailPreferencesEnsightenAnalytics;
