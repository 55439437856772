import React, { Component, Fragment } from 'react';
import { withStateMachine } from 'react-automata';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';

import { analyticsPublicApi002, analyticsPublicApiUserNavLinkClick } from '../../../analytics/publicapi';

import HeaderModals from '../HeaderModals';

import ForDevelopersMobileMenu from './components/ForDevelopersMobileMenu';
import { statechart, STATE, EVENT, ACTION } from './statechart';

class ForDevelopersHeader extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        page: PropTypes.shape({
            navigation: PropTypes.shape({
                root: PropTypes.string,
                main: PropTypes.objectOf(
                    PropTypes.shape({
                        label: PropTypes.string,
                        href: PropTypes.string,
                    })
                ),
                user: PropTypes.oneOfType([
                    PropTypes.array, // Can be empty array from backend
                    PropTypes.objectOf(
                        PropTypes.shape({
                            label: PropTypes.string,
                            href: PropTypes.string,
                        })
                    ),
                ]),
            }),
            loginForm: PropTypes.object.isRequired,
            registrationForm: PropTypes.object.isRequired,
        }),
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
    };

    get userAccountMenuOpen() {
        return this.props.machineState.value === STATE.USER_ACCOUNT_MENU;
    }

    get userIsAuthed() {
        return !_isEmpty(this.props.user);
    }

    get rootClassNames() {
        return buildClassNames({
            ['header']: true,
            ['header_forDevs']: true,
            ['header_isOpenDropdown']: this.userAccountMenuOpen,
            ...this.props.extensionClassNames,
        });
    }

    /**
     * @private
     */
    [ACTION.REFOCUS_TRIGGER]() {
        if (this.props.refocusTarget) {
            this.props.refocusTarget.focus();
        }
    }

    /**
     * @param {Event} ev
     * @private
     */
    handleUserLoggedInClick = ev => {
        ev.preventDefault();

        if (this.userAccountMenuOpen) {
            this.props.transition(EVENT.CLOSE);
        } else {
            this.props.transition(EVENT.USER_ACCOUNT_CLICK, {
                refocusTarget: ev.target,
            });
        }
    };

    /**
     * @param {Event} ev
     * @private
     */
    handleClose = ev => {
        this.props.transition(EVENT.CLOSE);
    };

    /**
     * @param {Object} link
     * @private
     * @returns {function}
     */
    handleMainNavLinkClick = link => {
        return ev => {
            analyticsPublicApi002(link);
        };
    };

    /**
     * @param {Object} link
     * @private
     * @returns {function}
     */
    handleUserNavLinkClick = link => {
        return ev => {
            analyticsPublicApiUserNavLinkClick(link);
        };
    };

    /**
     * @param {event} ev
     * @private
     * @returns {function}
     */
    handleUserMenuClick = ev => {
        if (this.userIsAuthed) {
            return this.handleUserLoggedInClick(ev);
        }
    };

    renderLogo() {
        return (
            <a
                href={this.props.page.navigation.root}
                className="header-inner-main-logo"
                aria-label="Petfinder For Developers"
            >
                <svg role="img" focusable="false" aria-describedby="Petfinder_Logo_Title">
                    <title id="Petfinder_Logo_Title">{'Petfinder For Developers Logo'}</title>
                    <use xlinkHref="#logo-developers" />
                </svg>
            </a>
        );
    }

    renderSkipToContent() {
        return (
            <a href="#Site_Main" className="skipToContent">
                {'Skip to content'}
            </a>
        );
    }

    renderMainNav() {
        const { page } = this.props;
        const { navigation } = page;
        const { main: mainNav } = navigation;

        return Object.keys(mainNav).map(key => {
            const link = mainNav[key];
            return (
                <a
                    key={key}
                    href={link.href}
                    className="header-inner-main-btn header-inner-main-btn_isHidden@maxLg"
                    onClick={this.handleMainNavLinkClick({ key, link })}
                >
                    {link.label}
                </a>
            );
        });
    }

    renderMobileMainNavBtn(handleMobileMenuClick) {
        return (
            <button className="header-inner-main-resourcesBtn" type="button" onClick={handleMobileMenuClick}>
                <svg role="presentation" focusable="false">
                    <use xlinkHref="#icon-menu" />
                </svg>
                <span className="u-isVisuallyHidden">{'Resources'}</span>
            </button>
        );
    }

    renderProfileBtn = (handleLoginClick, handleSignUpClick) => {
        const { page, user } = this.props;
        const { navigation } = page;
        const { user: userNav } = navigation;

        /* eslint-disable max-len */
        return (
            <div className="header-inner-profile">
                <Fragment>
                    {this.userIsAuthed ? (
                        <button
                            type="button"
                            className="header-inner-profile-accountBtn"
                            onClick={this.handleUserMenuClick}
                            aria-expanded={this.userAccountMenuOpen}
                        >
                            <svg role="presentation" focusable="false" aria-hidden="true">
                                <use xlinkHref="#icon-account" />
                            </svg>
                            <span className="header-inner-profile-accountBtn-title">
                                {user.firstName} {user.lastName}
                            </span>
                        </button>
                    ) : (
                        <button type="button" className="header-inner-profile-btn" onClick={handleSignUpClick}>
                            Sign Up
                        </button>
                    )}

                    {!this.userIsAuthed && (
                        <button type="button" className="header-inner-profile-btn" onClick={handleLoginClick}>
                            Log In
                        </button>
                    )}
                </Fragment>

                <div className="header-inner-profile-wrapper">
                    <ul className="header-inner-profile-dropdown">
                        {Object.keys(userNav).map(key => {
                            const link = userNav[key];
                            return (
                                <li key={key} className="header-inner-profile-dropdown-item">
                                    <a
                                        href={link.href}
                                        onClick={this.handleUserNavLinkClick({
                                            key,
                                            link,
                                        })}
                                    >
                                        {link.label}
                                    </a>
                                </li>
                            );
                        })}
                        <li>
                            <button
                                type="button"
                                onClick={this.handleClose}
                                className="header-inner-profile-dropdown-itemHiddenBtn txt txt_linkWhite m-txt_block u-isVisuallyHiddenUnlessFocused"
                            >
                                {'Close Navigation Menu'}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        );
        /* eslint-enable max-len */
    };

    renderMobileMenuModal = ({ handleLoginClick, handleSignUpClick, handleClose }) => {
        return (
            <ForDevelopersMobileMenu
                navigation={this.props.page.navigation}
                user={this.props.user}
                onClose={handleClose}
                onMainNavLinkClick={this.handleMainNavLinkClick}
                onUserNavLinkClick={this.handleUserNavLinkClick}
                onLoginClick={handleLoginClick}
                onSignUpClick={handleSignUpClick}
                onUserMenuClick={this.handleUserMenuClick}
            />
        );
    };

    render() {
        const { loginForm, registrationForm } = this.props.page;

        return (
            <HeaderModals
                loginForm={loginForm}
                registrationForm={registrationForm}
                renderMobileMenuModal={this.renderMobileMenuModal}
            >
                {({ handleMobileMenuClick, handleLoginClick, handleSignUpClick }) => (
                    <header className={this.rootClassNames}>
                        <div className="header-inner">
                            <nav className="header-inner-main">
                                {this.renderLogo()}
                                {this.renderSkipToContent()}
                                {this.renderMainNav()}
                                {this.renderMobileMainNavBtn(handleMobileMenuClick)}
                            </nav>
                            {!ssoFlagIsActive && this.renderProfileBtn(handleLoginClick, handleSignUpClick)}
                            <div id="pupSso"></div>
                        </div>
                    </header>
                )}
            </HeaderModals>
        );
    }
}

export default withStateMachine(statechart)(ForDevelopersHeader);
