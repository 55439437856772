import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';

var AnchorInLabel = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
AnchorInLabel.prototype = new BaseComponent();
var proto = AnchorInLabel.prototype;
proto.constructor = AnchorInLabel;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
AnchorInLabel.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
AnchorInLabel.SELECTORS.ELEMENT = '.js-anchorInLabel';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.setupHandlers();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onClickHandler = this.onClick.bind(this);

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);

    this.$element.on('click', this.onClickHandler);

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * onClick Handler
 *
 * @method onClick
 * @param {Object} event
 * @private
 */
proto.onClick = function onClick(event) {
    event.preventDefault();
    event.stopPropagation();

    var anchorTarget = event.target.getAttribute('target') || '_self';
    var href = event.target.getAttribute('href');

    window.open(href, anchorTarget);
};

export default AnchorInLabel;
