import $ from 'jquery';
import BaseEnsightenAnalytics from '../../../../core/scripts/analytics/ensighten/BaseEnsightenAnalytics';

/**
 * About Me Form Analytic tracking component.
 *
 * @class AuthCouponApplicationEnsightenAnalytics
 * @constructor
 */
const ChangeEmailFormEnsighten = function($element, eventBus) {
    BaseEnsightenAnalytics.call(this, $element, eventBus);
    this.init($element, eventBus);
};

// Inheritance
ChangeEmailFormEnsighten.prototype = Object.create(
    BaseEnsightenAnalytics.prototype
);
ChangeEmailFormEnsighten.prototype.constructor = ChangeEmailFormEnsighten;

const proto = ChangeEmailFormEnsighten.prototype;
const _super = BaseEnsightenAnalytics.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);
    return this.createChildren();
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @extends {BaseForm.createChildren}
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$formErrors = this.$element.find('.js-form-serverError');
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @extends {BaseForm.enable}
 * @private
 * @chainable
 */
proto.enable = function enable() {
    _super.enable.call(this);
    if (this.$formErrors.length > 0) {
        this.triggerEvent({
            category: 'account settings',
            action: 'change email address',
            label: 'error',
        });
    }
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
ChangeEmailFormEnsighten.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
ChangeEmailFormEnsighten.SELECTORS.ELEMENT =
    '.js-changeEmailEnsightenAnalytics';

export default ChangeEmailFormEnsighten;
