import BaseModel from './BaseModel';
import AddressModel from './AddressModel';
import _get from 'lodash/get';

export default class UserModel extends BaseModel {
    get defaultData() {
        return {
            address: new AddressModel(),
            createdAt: '',
            disabled: true,
            email: '',
            emailConfirmed: false,
            emailValid: false,
            expireDate: '',
            firstName: '',
            hasPassword: false,
            id: null,
            isShelter: false,
            lastName: '',
            lockDate: '',
            passwordExpires: '',
            pendingEmail: true,
            primaryPhone: '',
            secondaryPhone: '',
            unconfirmedEmail: '',
            updatedAt: '',
            username: '',
        };
    }

    /**
     * fromServer
     * API:
     * [GET] /me (user)
     * [GET] /happy-tails/{id} (author)
     * @static
     * @param {object} userData Server Data
     *
     * @return {UserModel}
     */
    static fromServer(userData) {
        const model = new UserModel(this._mapServerDataToClientside(userData));
        return model;
    }

    /**
     * _mapServerDataToClientside - helper to translate server data to object
     * @static
     * @param {object} userData
     *
     * @return {object}
     */
    static _mapServerDataToClientside(userData) {
        return {
            address: AddressModel.fromServer(_get(userData, 'address', {})),
            createdAt: _get(userData, 'created_at', ''),
            disabled: _get(userData, 'disabled', true),
            email: _get(userData, 'email', ''),
            emailConfirmed: _get(userData, 'email_confirmed', false),
            emailValid: _get(userData, 'emailValid', false),
            expireDate: _get(userData, 'expire_date', ''),
            firstName: _get(userData, 'first_name', ''),
            hasPassword: _get(userData, 'has_password', false),
            id: _get(userData, 'id', null),
            isShelter: _get(userData, 'is_shelter', false),
            lastName: _get(userData, 'last_name', ''),
            lockDate: _get(userData, 'lock_date', ''),
            passwordExpires: _get(userData, 'password_expires', ''),
            pendingEmail: _get(userData, 'pending_email', true),
            primaryPhone: _get(userData, 'primary_phone', ''),
            secondaryPhone: _get(userData, 'secondary_phone', ''),
            unconfirmedEmail: _get(userData, 'unconfirmed_email', ''),
            updatedAt: _get(userData, 'updated_at', ''),
            username: _get(userData, 'username', ''),
        };
    }

    /**
     * address
     *
     * @return {AddressModel}
     */
    get address() {
        return this._data.address;
    }

    /**
     * createdAt
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get createdAt() {
        return this._data.createdAt;
    }

    /**
     * disabled
     *
     * @return {boolean}
     */
    get disabled() {
        return this._data.disabled;
    }

    /**
     * email
     *
     * @return {string}
     */
    get email() {
        return this._data.email;
    }

    /**
     * emailConfirmed
     *
     * @return {boolean}
     */
    get emailConfirmed() {
        return this._data.emailConfirmed;
    }

    /**
     * emailValid
     *
     * @return {boolean}
     */
    get emailValid() {
        return this._data.emailValid;
    }

    /**
     * expireDate
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get expireDate() {
        return this._data.expireDate;
    }

    /**
     * firstName
     *
     * @return {string}
     */
    get firstName() {
        return this._data.firstName;
    }

    /**
     * hasPassword
     *
     * @return {boolean}
     */
    get hasPassword() {
        return this._data.hasPassword;
    }

    /**
     * id - User ID
     *
     * @return {null|number}
     */
    get id() {
        return this._data.id;
    }

    /**
     * isShelter
     *
     * @return {boolean}
     */
    get isShelter() {
        return this._data.isShelter;
    }

    /**
     * lastName
     *
     * @return {string}
     */
    get lastName() {
        return this._data.lastName;
    }

    /**
     * lockDate
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get lockDate() {
        return this._data.lockDate;
    }

    /**
     * passwordExpires
     *
     * @return {string} YYYY-MM-DDTHH:MM:SS.SSSZ
     */
    get passwordExpires() {
        return this._data.passwordExpires;
    }

    /**
     * pendingEmail
     *
     * @return {boolean}
     */
    get pendingEmail() {
        return this._data.pendingEmail;
    }

    /**
     * primaryPhone
     *
     * @return {string}
     */
    get primaryPhone() {
        return this._data.primaryPhone;
    }

    /**
     * secondaryPhone
     *
     * @return {string}
     */
    get secondaryPhone() {
        return this._data.secondaryPhone;
    }

    /**
     * unconfirmedEmail
     *
     * @return {string}
     */
    get unconfirmedEmail() {
        return this._data.unconfirmedEmail;
    }

    /**
     * updatedAt
     *
     * @return {string}
     */
    get updatedAt() {
        return this._data.updatedAt;
    }

    /**
     * username
     *
     * @return {string}
     */
    get username() {
        return this._data.username;
    }
}
