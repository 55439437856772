import BaseModel from './BaseModel';
import _get from 'lodash/get';

export default class HappyTailAnimalModel extends BaseModel {
    get defaultData() {
        return {
            adoptionTimeStamp: '',
            name: '',
            primaryBreed: '',
            secondaryBreed: '',
            species: '',
            type: '',
        };
    }

    /**
     * adoptionTimestamp
     *
     * @return {string} 2020-01-10T19:54:28.591Z
     */
    get adoptionTimestamp() {
        return this._data.adoptionTimestamp;
    }

    /**
     * name
     *
     * @return {string}
     */
    get name() {
        return this._data.name;
    }

    /**
     * primaryBreed
     *
     * @return {string}
     */
    get primaryBreed() {
        return this._data.primaryBreed;
    }

    /**
     * secondaryBreed
     *
     * @return {string}
     */
    get secondaryBreed() {
        return this._data.secondaryBreed;
    }

    /**
     * slug - Happy Tail subheading for animal information
     *
     * @return {string} primaryBreed / secondaryBreed
     */
    get slug() {
        let slug = this.primaryBreed;
        if (
            this.species === 'Cat' ||
            this.species === 'Dog' ||
            this.species === 'Rabbit'
        ) {
            slug = this.primaryBreed;

            if (this.secondaryBreed) {
                slug = `${this.primaryBreed} / ${this.secondaryBreed}`;
            }
        } else {
            if (this.species !== this.primaryBreed) {
                slug = `${this.species} / ${this.primaryBreed}`;
            }
        }

        return slug;
    }

    /**
     * species
     *
     * @return  {string}
     */
    get species() {
        return this._data.species;
    }

    /**
     * type
     *
     * @return {string}
     */
    get type() {
        return this._data.type;
    }
}
