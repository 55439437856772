import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { State, withStateMachine } from 'react-automata';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

import Config from '../../../../../core/scripts/lib/Config';

import { analyticsConsumer015B, analyticsConsumer016B } from '../../../analytics/dotcom';
import socialLoginStateController from '../SocialLogin/controller/SocialLoginStateController';
import { statechart, STATE, EVENT, ACTION } from './statechart';
import { SSO_MODAL_ACTIONS, handleSSOModal } from '../../../util/ssoModalHelpers';

class HeaderModals extends Component {
    /**
     * @type {Object}
     */
    state = {
        userMe: {},
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        children: PropTypes.func,
        loginForm: PropTypes.object.isRequired,
        registrationForm: PropTypes.object.isRequired,
        renderMobileMenuModal: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        children: _noop,
        renderMobileMenuModal: _noop,
    };

    async componentDidMount() {
        const userMe = await Config.getUserMe();
        this.setState({ userMe });
    }

    get modalProps() {
        return {
            onClose: this.handleClose,
            onEscapeKeyDown: this.handleClose,
            toggleNoClose: this.toggleNoClose,
        };
    }

    /**
     * @private
     */
    [ACTION.REFOCUS_TRIGGER]() {
        if (this.props.refocusTarget) {
            this.props.refocusTarget.focus();
        }
    }

    /**
     * @private
     * @param {Event} ev
     * @param {Bool} mobile
     */
    handleLoginClick = (ev, mobile) => {
        this.props.transition(EVENT.AUTHENTICATION_CLICK);
        analyticsConsumer016B({
            trigger: {
                dataset: { key: mobile ? 'headerLogInMobile' : 'headerLogIn' },
            },
        });

        if (window.ssoFlagIsActive) {
            handleSSOModal(SSO_MODAL_ACTIONS.open, 'global nav');
        } else {
            // OLD LOGIN MODAL
            socialLoginStateController.openLoginModal({
                refocusTarget: ev.target,
                context: 'generic',
                display: 'login',
                onModalClose: this.handleClose,
            });
        }
    };

    /**
     * @private
     * @param {Event} ev
     * @param {Bool} mobile
     */
    handleSignUpClick = (ev, mobile) => {
        this.props.transition(EVENT.AUTHENTICATION_CLICK);

        analyticsConsumer015B({
            trigger: {
                dataset: {
                    key: mobile ? 'headerSignUpMobile' : 'headerSignUp',
                },
            },
        });

        if (window.ssoFlagIsActive) {
            handleSSOModal(SSO_MODAL_ACTIONS.open, 'global nav');
        } else {
            // OLD Sign In Modal
            socialLoginStateController.openLoginModal({
                refocusTarget: ev.target,
                context: 'generic',
                display: 'signUp',
                onModalClose: this.handleClose,
            });
        }
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleLoginModalRegisterClick = ev => {
        this.props.transition(EVENT.REGISTER_CLICK);
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleMobileMenuClick = ev => {
        this.props.transition(EVENT.MOBILE_MENU_CLICK, {
            refocusTarget: ev.target,
        });
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleClose = ev => {
        this.props.transition(EVENT.CLOSE);
    };

    /**
     * @private
     */
    toggleNoClose = () => {
        this.props.transition(EVENT.TOGGLE_CLOSE);
    };

    render() {
        return (
            <Fragment>
                {this.props.children({
                    handleMobileMenuClick: this.handleMobileMenuClick,
                    handleLoginClick: this.handleLoginClick,
                    handleSignUpClick: this.handleSignUpClick,
                    handleClose: this.handleClose,
                })}

                <State is={`${STATE.CONTENT}.${STATE.MOBILE_MENU_MODAL}`}>
                    {this.props.renderMobileMenuModal({
                        handleLoginClick: this.handleLoginClick,
                        handleSignUpClick: this.handleSignUpClick,
                        handleClose: this.handleClose,
                    })}
                </State>
            </Fragment>
        );
    }
}

export default withStateMachine(statechart)(HeaderModals);
