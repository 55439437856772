import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import $ from 'jquery';

/**
 *
 * @class BreakpointHelper
 * @extends {BaseComponent}
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus Event Bus shared between components in app
 */
var BreakpointHelper = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
BreakpointHelper.prototype = new BaseComponent();
var proto = BreakpointHelper.prototype;
proto.constructor = BreakpointHelper;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
BreakpointHelper.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
BreakpointHelper.SELECTORS.ELEMENT = 'body';

/// ///////////////////////////////////////////////////////////////////////////////
// VIEW LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    return this.setBP()
        .setupHandlers()
        .enable();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function() {
    this.onResizeHandler = this.onResize.bind(this);

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function() {
    this._super.enable.call(this);

    this.currentSize = $(window).width();

    $(window).on('resize', this.onResizeHandler);

    return this;
};

/**
 * Disables the view
 *
 * @method disable
 * @private
 * @chainable
 */
BreakpointHelper.prototype.disable = function() {
    $(window).off('resize', this.onResizeHandler);

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method setBP
 */
proto.setBP = function() {
    var oldBP = this.currentBP;

    this.currentBP = window
        .getComputedStyle(document.getElementsByTagName('body')[0], ':before')
        .getPropertyValue('content');
    this.currentBP = this.currentBP.replace(/"/g, '');

    // if there is a new breakpoint and there was an existing one
    // (this isn't the first run through), trigger change action
    if (oldBP != this.currentBP && oldBP) {
        this.eventBus.trigger('BreakpointHelper.BPChange');
    }

    // if still undefined, send a warning to the console to
    // indicate the current breakpoint has no body:before content
    if (!this.currentBP) {
        // console.warning("BreakpointHelper is being called by a component on a page that has no body:before content for needed breakpoints.")
    }

    return this;
};

/**
 * @method getBP
 *
 * Returns the text of the current breakpoint
 */
BreakpointHelper.prototype.getBP = function() {
    return this.currentBP;
};

/// ///////////////////////////////////////////////////////////////////////////////
// EVENT HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method onResize
 *
 * Handles functionality for when the window is resized.
 */
proto.onResize = function() {
    var thisSize = $(window).width();

    if (thisSize !== this.currentSize) {
        this.currentSize = $(window).width();
        this.eventBus.trigger('BreakpointHelper.WindowChange');
        this.setBP();
    }

    return this;
};

export default BreakpointHelper;
