import BaseModel from './BaseModel';
import _get from 'lodash/get';

export default class AddressModel extends BaseModel {
    get defaultData() {
        return {
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
        };
    }

    /**
     * fromServer
     * @static
     * @param {object} addressData Server Data
     *
     * @return {AddressModel}
     */
    static fromServer(addressData) {
        const model = new AddressModel(
            this._mapServerDataToClientside(addressData)
        );
        return model;
    }

    /**
     * _mapServerDataToClientside - helper to translate server data to object
     * @static
     * @param {object} addressData
     *
     * @return {object}
     */
    static _mapServerDataToClientside(addressData) {
        const postalCode =
            _get(addressData, 'postal_code', '') ||
            _get(addressData, 'postcode', '');

        return {
            address1: _get(addressData, 'address1', ''),
            address2: _get(addressData, 'address2', ''),
            city: _get(addressData, 'city', ''),
            state: _get(addressData, 'state', ''),
            postalCode,
            country: _get(addressData, 'country', ''),
        };
    }

    /**
     * address1
     *
     * @return {string}
     */
    get address1() {
        return this._data.address1;
    }

    /**
     * address2
     *
     * @return {string}
     */
    get address2() {
        return this._data.address2;
    }

    /**
     * city
     *
     * @return {string}
     */
    get city() {
        return this._data.city;
    }

    /**
     * cityAndState
     *
     * @return {string} CITY, STATE
     */
    get cityAndState() {
        return `${this.city}, ${this.state}`;
    }

    /**
     * state
     *
     * @return {string}
     */
    get state() {
        return this._data.state;
    }

    /**
     * postalCode
     *
     * @return {string}
     */
    get postalCode() {
        return this._data.postalCode;
    }

    /**
     * country
     *
     * @return {string}
     */
    get country() {
        return this._data.country;
    }
}
