import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import BreedTooltip from '../../breeds/specifics/BreedTooltip';

class CopyToClipboard extends Component {
    tooltip = React.createRef();
    trigger = React.createRef();

    state = {
        copyIsSuccessful: true,
    };

    static defaultProps = {
        classString: 'txt txt_link',
        copySuccessfulResponse: 'Copied',
        copyErroredResponse: 'An error occured. Please refresh the page.',
        delay: 350,
        duration: 3000,
    };

    static propTypes = {
        classString: PropTypes.string,
        copySuccessfulResponse: PropTypes.string,
        copyErroredResponse: PropTypes.string,
        delay: PropTypes.number,
        duration: PropTypes.number,
        textToCopy: PropTypes.string.isRequired,
    };

    /* *******************************************
     * Lifecycle
     ********************************************/
    /**
     * Will hold a uniqe attr slug for the tooltip so we can use this
     * component in multiple places
     * @type {string}
     */
    copyButtonUniqueAttrSlug = null;

    constructor() {
        super();
        /**
         * Generates a unique identifier for the tooltip and it's trigger.
         *
         * @memberof CopyToClipboard
         */
        this.copyButtonUniqueAttrSlug = `content-copy-tooltip-${Math.round(
            Math.random() * Date.now()
        )}`;
    }

    /* *******************************************
     * Actions (used with x-state)
     ********************************************/
    // Because the Tooltip is expecting an event
    showTooltip() {
        const trigger = {
            currentTarget: this.trigger.current,
        };
        this.tooltip.current.instance.current.handleTriggerClick(trigger);
    }

    /**
     * Copy To Clipboard
     * Creates a temporary textarea, set it's value to the provided string,
     * selects and copies the contents, and deletes ths textarea.
     *
     * @param {String} text The text to copy to the clipboard
     * @memberof CopyToClipboard
     */
    copyTextToClipboard = async text => {
        // Create hidden textarea
        const hiddenInput = document.createElement('textarea');
        hiddenInput.value = text;
        hiddenInput.setAttribute('readonly', '');
        hiddenInput.classList.add('u-isVisuallyHidden');
        document.body.appendChild(hiddenInput);

        function selectText(text) {
            let range = null;
            let selection = null;

            range = document.createRange();
            range.selectNodeContents(hiddenInput);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            hiddenInput.setSelectionRange(0, 999999);
        }

        function copyText() {
            document.execCommand('copy');
            document.body.removeChild(hiddenInput);
        }
        if (window.navigator.clipboard) {
            try {
                await window.navigator.clipboard.writeText(hiddenInput.value);
                this.showTooltip();
                document.body.removeChild(hiddenInput);
            } catch (err) {
                await this.setState({ copyIsSuccessful: true });
                this.showTooltip();
            }
        } else {
            selectText();
            copyText();
            this.showTooltip();
        }
    };

    /**
     * Handles rendering tooltip content
     * @method renderCopyUrlTooltip
     * @private
     * @param {Object} trigger
     * @param {Object} triggerData
     * @returns {string}
     */
    renderTooltipCopy = (trigger, triggerData) => {
        return <div>{triggerData.content}</div>;
    };

    render() {
        const copyButtonUniqueAttr = {
            [`data-${this.copyButtonUniqueAttrSlug}`]: 'true',
        };

        return (
            <Fragment>
                <button
                    type="button"
                    ref={this.trigger}
                    {...copyButtonUniqueAttr}
                    className={this.props.classString}
                    data-content={
                        this.state.copyIsSuccessful
                            ? this.props.copySuccessfulResponse
                            : this.props.copyErroredResponse
                    }
                    onClick={() =>
                        this.copyTextToClipboard(this.props.textToCopy)
                    }
                >
                    copy
                </button>
                <BreedTooltip
                    ref={this.tooltip}
                    attribute={this.copyButtonUniqueAttrSlug}
                    delay={this.props.delay}
                    duration={this.props.duration}
                    events={{}}
                    onRenderContent={this.renderTooltipCopy}
                />
            </Fragment>
        );
    }
}

export default CopyToClipboard;
