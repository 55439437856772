import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import EventBus from '../../../../core/scripts/vendor/EventBus/dist/EventBus';
import BootstrapUtility from '../../../../core/scripts/util/BootstrapUtility';
import FocusManager from '../../../../core/scripts/lib/FocusManager';
import PetInputBlock from '../PetInputBlock/PetInputBlock';
import PetInputBlockAutoSave from '../PetInputBlock/PetInputBlockAutoSave';
import $ from 'jquery';

const focusManager = new FocusManager();

var PetInputBlocks = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PetInputBlocks.prototype = new BaseComponent();
var proto = PetInputBlocks.prototype;
proto.constructor = PetInputBlocks;
proto._super = BaseComponent.prototype;

var componentList = {
    PetInputBlock,
    PetInputBlockAutoSave,
};

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
PetInputBlocks.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PetInputBlocks.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PetInputBlocks.SELECTORS.ELEMENT = '.js-petInputBlocks';

/**
 * Selector for the element that adds another set of pet fields
 *
 * @property ADD_PET_TRIGGER
 * @static
 * @final
 * @type {String}
 */
PetInputBlocks.SELECTORS.ADD_PET_TRIGGER =
    PetInputBlocks.SELECTORS.ELEMENT + '-trigger';

/**
 * Selector for the content area listing pet blocks
 *
 * @property ADD_PET_CONTENT
 * @static
 * @final
 * @type {String}
 */
PetInputBlocks.SELECTORS.PET_BLOCK_INSERTION_POINT =
    PetInputBlocks.SELECTORS.ELEMENT + '-insertionPoint';

/**
 * Selector for the content area listing pet blocks
 *
 * @property ADD_PET_CONTENT
 * @static
 * @final
 * @type {String}
 */
PetInputBlocks.SELECTORS.PET_BLOCK =
    PetInputBlocks.SELECTORS.ELEMENT + '-block';

/**
 * Selector for the content area listing pet blocks
 *
 * @property ADD_PET_CONTENT
 * @static
 * @final
 * @type {String}
 */
PetInputBlocks.SELECTORS.PET_BLOCK_HDG =
    PetInputBlocks.SELECTORS.ELEMENT + '-block-hdg';

PetInputBlocks.SELECTORS.INDEX_REPLACEMENT_TOKEN = '__index__';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.petIndex = 0;
    this.componentList = componentList;
    this.petInputBlockCollectionEventBus = new EventBus();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onClickPetInputBlocksTriggerHandler = this.onClickPetInputBlocksTrigger.bind(
        this
    );
    this.onDeletePetInputBlockHandler = this.onDeletePetInputBlock.bind(this);
    this.onPetSaveSuccessHandler = this.onPetSaveSuccess.bind(this);
    this.onPetSaveFailedHandler = this.onPetSaveFailed.bind(this);
    this.onPetSaveInitiatedHandler = this.onPetSaveInitiated.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$petInputBlocksTrigger = this.$element.find(
        PetInputBlocks.SELECTORS.ADD_PET_TRIGGER
    );
    this.$petInputBlocksContent = this.$element.find(
        PetInputBlocks.SELECTORS.PET_BLOCK_INSERTION_POINT
    );
    this.$petInputBlocksBlock = this.$element.find(
        PetInputBlocks.SELECTORS.PET_BLOCK
    );
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.bootstrapChildComponents(this.$element)
        .enableChildComponents()
        .setupListeners()
        .cacheTemplate()
        .indexPets();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$petInputBlocksTrigger.on(
        'click',
        this.onClickPetInputBlocksTriggerHandler
    );
    this.petInputBlockCollectionEventBus
        .on('petInputBlockDeleted', this.onDeletePetInputBlockHandler)
        .on('inputPetSaveSuccess', this.onPetSaveSuccessHandler)
        .on('inputPetSaveInitiated', this.onPetSaveInitiatedHandler)
        .on('inputPetSaveFailed', this.onPetSaveFailedHandler);

    return this;
};

proto.cacheTemplate = function cacheTemplate() {
    this.template = this.$element.data('prototype');
    return this;
};

proto.indexPets = function indexPets() {
    this.$petInputBlocksBlock.each(function(index, element) {
        $(element)
            .find('.js-petInputBlocks-block-inputPetType')
            .attr('data-petindex', index);
    });
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.isEnabled = null;
    return this;
};

proto.bootstrapChildComponents = function bootstrapChildComponents($element) {
    this.components = BootstrapUtility.bootstrap(
        this.componentList,
        $element,
        this.petInputBlockCollectionEventBus
    );
    return this;
};

proto.enableChildComponents = function enableChildComponents() {
    this.petInputBlockCollectionEventBus.trigger('enableComponents');
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.addPetInputBlock = function addPetInputBlock() {
    this.$petInputBlocksContent.append(this.template);
    this.$lastPetContent = this.$petInputBlocksContent
        .find(PetInputBlocks.SELECTORS.PET_BLOCK)
        .last();

    // Move focus to pet card that was just added to the page
    focusManager.focusFirstFocusable(this.$lastPetContent[0]);

    this.bootstrapChildComponents(this.$lastPetContent).enableChildComponents();
    return this;
};

proto.replaceAttribute = function replaceAttribute($element, attribute, index) {
    var attributeValue = $element.attr(attribute);
    if (
        typeof attributeValue != 'undefined' &&
        attributeValue.indexOf(
            PetInputBlocks.SELECTORS.INDEX_REPLACEMENT_TOKEN
        ) >= 0
    ) {
        var newAttributeValue = attributeValue.replace(
            PetInputBlocks.SELECTORS.INDEX_REPLACEMENT_TOKEN,
            index
        );
        $element.attr(attribute, '' + newAttributeValue + '');
    }
    return this;
};

proto.reindexPetInputBlockHeading = function reindexPetInputBlockHeading(
    $element,
    index
) {
    $element
        .find(PetInputBlocks.SELECTORS.PET_BLOCK_HDG)
        .html('Pet ' + (index + 1) + '');
    return this;
};

proto.reindexPetInputBlockInputs = function reindexPetInputBlockInputs(
    $element,
    index
) {
    var self = this;
    $element.find(':input').each(function() {
        var $this = $(this);

        // index to send to ensighten
        $this.attr('data-petindex', index);
        self.replaceAttribute($this, 'id', index)
            .replaceAttribute($this, 'name', index)
            .replaceAttribute($this, 'for', index);
    });
    return this;
};

proto.reindexPetInputBlock = function reindexPetInputBlock(index, element) {
    var $element = $(element);
    this.reindexPetInputBlockHeading(
        $element,
        index
    ).reindexPetInputBlockInputs($element, index);
};

proto.reindexPetInputBlocks = function reindexPetInputBlocks() {
    this.$petInputBlocksBlock.each(this.reindexPetInputBlock.bind(this));
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onClickPetInputBlocksTrigger = function onClickPetInputBlocksTrigger(
    event
) {
    event.preventDefault();
    this.addPetInputBlock()
        .createChildren()
        .reindexPetInputBlocks();
    this.eventBus.trigger('addPetBlock', this.$lastPetContent);
};

proto.onDeletePetInputBlock = function onDeletePetInputBlock() {
    this.createChildren().reindexPetInputBlocks();
};

proto.onPetSaveSuccess = function onPetSaveSuccess() {
    this.eventBus.trigger('inputPetSaveSuccess');
};

proto.onPetSaveFailed = function onPetSaveFailed() {
    this.eventBus.trigger('inputPetSaveFailed');
};

proto.onPetSaveInitiated = function onPetSaveInitiated() {
    this.eventBus.trigger('inputPetSaveInitiated');
};

export default PetInputBlocks;
