import FocusManager from '../../lib/FocusManager';
import { PFElement } from '../pf-element/element';

/**
 * Focuses something inside of itself on a configurable update through the event bus.
 * @module PFFocusOnUpdateElement
 * @extends PFElement
 */
export class PFFocusOnUpdateElement extends PFElement {

    /**
     * Name of update to listen for, after which focus is moved
     * @return {String}
     */
    get updateToFocusOn() {
        return this.getAttribute('update-to-focus-on');
    }

    /**
     * Boolean to focus first focusable within element instead of element itself
     * @return {Boolean}
     */
    get focusFirstFocusableOnUpdate() {
        return this.hasAttribute('focus-first-focusable-on-update');
    }

    /**
     * ID of element to focus on update instead of element itself
     * @return {string}
     */
    get focusElementByIdOnUpdate() {
        return this.getAttribute('focus-element-by-id-on-update');
    }

    /**
     * @method onUpdated
     * @public
     * @param {Object} event Update Events passed down on Event Bus
     */
    onUpdated(event) {
        const type = event.detail.type;
        if (type === this.updateToFocusOn) {
            if (this.focusFirstFocusableOnUpdate) {
                this.context.focusManager.focusFirstFocusable(this);
            } else if (this.focusElementByIdOnUpdate !== '') {
                this.context.focusManager.focusElement(
                    document.getElementById(this.focusElementByIdOnUpdate)
                );
            } else {
                this.focus();
            }
        }
    }
}

export default PFFocusOnUpdateElement;
