import { html } from '../../../../../core/scripts/util/util.js';
import {
    EV_SHELTERSEARCH_NEXTPAGE,
    EV_SHELTERSEARCH_PREVPAGE,
    EV_SHELTERSEARCH_RENDERED } from '../../../constants/events';
import get from 'lodash/get';
import { phoneWithEmailFallbackLink } from './phoneWithEmailFallbackLink.html';
import { cityStateAndNonUsCountryInline } from './cityStateAndNonUsCountryInline.html';

/* eslint-disable max-len */
export const shelterSearchTable = vm => {
    return html`
    <pf-focus-on-update class="u-displayBlock" update-to-focus-on="${EV_SHELTERSEARCH_RENDERED}" focus-first-focusable-on-update>
        <h3 tabindex="-1">
            Shelter Search Results Page ${vm.pagination.current_page}
        </h3>
    </pf-focus-on-update>
    <div class="shelterSearch u-vr2x">
        <table id="search-results" role="table" class="shelterSearch-table">
            <caption class="u-isVisuallyHidden">
                Shelter Search Results Page ${vm.pagination.current_page}
            </caption>
            <thead>
                <tr role="row" class="shelterSearch-table-hd">
                    <th scope="col" role="columnheader" class="txt m-txt_uppercase m-txt_heavy">
                        Organization
                    </th>
                    <th scope="col" role="columnheader" class="m-shelterSearch-table-petList">
                        <span class="txt m-txt_uppercase m-txt_heavy">Pet List</span>
                    </th>
                    <th scope="col" role="columnheader">
                        <span class="txt m-txt_uppercase m-txt_heavy">City, State/Prov</span>
                    </th>
                    <th scope="col" role="columnheader" class="m-shelterSearch-table-contact">
                        <span class="txt m-txt_uppercase m-txt_heavy">Contact</span>
                    </th>
                </tr>
            </thead>
            <tbody id="js-sheltersSearch-resultsContainer">
                ${vm.organizations.map(organization => html`
                    <tr role="row" class="shelterSearch-table-row">
                        <td role="cell" class="m-shelterSearch-table-organization">
                            <h4><a class="txt txt_link m-txt_underline" href="/shelters/$${organization.display_id}.html">$${organization.name}</a></h4>
                        </td>
                        <td role="cell" class="m-shelterSearch-table-petList">
                            <a href="/pet-search?shelter_id=$${organization.display_id}">
                                <span class="u-isVisuallyHidden">See This Organization's Pets</span>
                                <span class="icon icon_md m-icon_colorDark">
                                    <svg role="img">
                                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-search"></use>
                                    </svg>
                                </span>
                            </a>
                        </td>
                        <td role="cell" class="m-shelterSearch-table-location">
                            ${cityStateAndNonUsCountryInline(get(organization, 'location.address')) || ''}
                        </td>
                        <td role="cell" class="m-shelterSearch-table-contact">
                            ${phoneWithEmailFallbackLink(organization.contact)}
                        </td>
                    </tr>
                `)}
            </tbody>
        </table>
    </div>

    <div class="u-vr2x">
    <div class="hrArray m-hrArray_2x m-hrArray_right">
        ${vm.pagination.current_page !== 1
            ? html`
                <pf-element on-click="${EV_SHELTERSEARCH_PREVPAGE}">
                    <button class="fieldBtn" type="button"
                        aria-label="load page ${vm.pagination.current_page - 1} of the results">
                        <span class="fieldBtn-icon">
                            <span class="icon icon_sm m-icon_colorPrimary">
                                <svg role="img">
                                    <use xlink:href="#icon-chevronLeft"/>
                                </svg>
                            </span>
                        </span>
                        <span class="fieldBtn-label">Prev</span>
                    </button>
                </pf-element>
                `
            : false
        }
        ${vm.pagination.current_page !== vm.pagination.total_pages
            ? html`
                <pf-element on-click="${EV_SHELTERSEARCH_NEXTPAGE}">
                    <button class="fieldBtn" type="button"
                        aria-label="load page ${vm.pagination.current_page + 1} of the results">
                        <span class="fieldBtn-label">Next</span>
                        <span class="fieldBtn-icon">
                            <span class="icon icon_sm m-icon_colorPrimary">
                                <svg role="img">
                                    <use xlink:href="#icon-chevronRight"/>
                                </svg>
                            </span>
                        </span>
                    </button>
                </pf-element>
            `
            : false
        }
    </div>

</div>
    <div class="hrArray m-hrArray_2x m-hrArray_right">
        <div class="pagination-text">
            Page
            <span data-id="currentPage">
                ${vm.pagination.current_page}
            </span> of
            <span data-id="numPages">
                ${vm.pagination.total_pages}
            </span>
        </div>
        <div class="pagination-text">
            Showing <span data-id="startItemNum">
               ${vm.startSpan}
                </span> - <span data-id="endItemNum">${vm.endSpan}</span></div>
        </div>
    </div>
    `;
};
/* eslint-enable max-len */

