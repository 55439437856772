import BaseModel from './BaseModel';
import UploadPartsCollection from './UploadPartsCollection';
import UploadTransformationModel from './UploadTransformationModel';
import _get from 'lodash/get';

export default class UploadModel extends BaseModel {
    static get STATUSES() {
        return {
            INITIALIZED: 'initialized',
            PROCESSING: 'processing',
            UNUSED: 'unused',
            USED: 'used',
            DELETED: 'deleted',
            ERROR: 'error',
        };
    }

    get defaultData() {
        return {
            id: '',
            path: '',
            type: '',
            size: -1,
            checksum: '',
            status: '',
            parts: [],
            url: '',
            parentId: '',
            transformation: null,
        };
    }

    /**
     * fromServer
     * API:
     * [GET] /happy-tails/{id} (photo)
     * @static
     * @param {object} uploadData Server Data
     *
     * @return {UploadModel}
     */
    static fromServer(uploadData) {
        if (!uploadData) {
            return null;
        }

        return new UploadModel(this._mapServerDataToClientside(uploadData));
    }

    static _mapServerDataToClientside(uploadData) {
        return {
            id: _get(uploadData, 'id', ''),
            path: _get(uploadData, 'path', ''),
            type: _get(uploadData, 'type', ''),
            size: _get(uploadData, 'size', -1),
            checksum: _get(uploadData, 'checksum', ''),
            status: _get(uploadData, 'status', ''),
            parts: UploadPartsCollection.fromServer(uploadData.parts),
            url: _get(uploadData, 'url', ''),
            parentId: _get(uploadData, 'parent_id', ''),
            parent: UploadModel.fromServer(
                _get(uploadData, '_embedded.parent', null)
            ),
            transformation: UploadTransformationModel.fromServer(
                uploadData.transformation
            ),
        };
    }

    get id() {
        return this._data.id;
    }

    get path() {
        return this._data.path;
    }

    get type() {
        return this._data.type;
    }

    get size() {
        return this._data.size;
    }

    get checksum() {
        return this._data.checksum;
    }

    get status() {
        return this._data.status;
    }

    get parts() {
        return this._data.parts.raw;
    }

    get url() {
        return this._data.url;
    }

    get parentId() {
        return this._data.parentId;
    }

    get parent() {
        return this._data.parent;
    }

    get transformation() {
        return this._data.transformation;
    }

    get ready() {
        return [
            UploadModel.STATUSES.UNUSED,
            UploadModel.STATUSES.USED,
        ].includes(this.status);
    }
}
