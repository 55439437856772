import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { parseJsonAttribute } from '../../../../core/scripts/util/dom';
import dispatcherMixin from '../pfdc-dispatcher/element';
import { onEvented } from '../pfdc-dispatcher/element';

export class PFDCDispatcherCheckboxElement extends dispatcherMixin(PFElement) {
    events = ['change'];

    get nonValueActions() {
        return parseJsonAttribute(this, 'non-value-actions');
    }

    get dispatchOnChecked() {
        return this.hasAttribute('dispatch-on-checked');
    }

    get dispatchOnUnchecked() {
        return this.hasAttribute('dispatch-on-unchecked');
    }

    get isChecked() {
        return this.querySelector('input[type="checkbox"]').checked;
    }

    onInit() {
        super.onInit();
        if (this.dispatchOnLoad) {
            this.dispatchActions();
        }
    }

    dispatchActions(event) {
        if (this.dispatchOnLoad) {
            if (this.dispatchOnChecked && this.isChecked) {
                this.actions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            } else if (this.dispatchOnUnchecked && !this.isChecked) {
                this.nonValueActions.forEach(action =>
                    this.dispatchAction(action, event)
                );
            }
        }
    }

    [onEvented](event) {
        if (this.isDisabled) {
            return;
        }
        this.dispatchActions(event);
    }
}

export default PFDCDispatcherCheckboxElement;
