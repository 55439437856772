import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';

var countryOptinRelatedInputs = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
countryOptinRelatedInputs.prototype = new BaseComponent();
var proto = countryOptinRelatedInputs.prototype;
proto.constructor = countryOptinRelatedInputs;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
countryOptinRelatedInputs.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
countryOptinRelatedInputs.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
countryOptinRelatedInputs.SELECTORS.ELEMENT = '.js-countryOptinRelatedInputs';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onCountryChangeHandler = this.onCountryChange.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputCountry = this.$element.find(
        '.js-countryOptinRelatedInputs-inputCountry'
    );
    this.$regionUS = this.$element.find(
        '.js-countryOptinRelatedInputs-regionUS'
    );
    this.$regionOther = this.$element.find(
        '.js-countryOptinRelatedInputs-regionOther'
    );
    this.$inputPurinaUS = this.$element.find(
        '.js-countryOptinRelatedInputs-inputPurinaUS'
    );
    this.$inputPetfinderOther = this.$element.find(
        '.js-countryOptinRelatedInputs-inputPetfinderOther'
    );

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    if (this.$inputCountry.length === 0) {
        this.destroy();
        return;
    }

    this._super.enable.call(this);
    this.setupListeners().switchOptins();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$inputCountry.on('change', this.onCountryChangeHandler);
    this.eventBus.on('changeInputCountry', this.onCountryChangeHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    return this;
};

proto.isCountryUS = function isCountryUS() {
    return this.$inputCountry.val() == 'us' || this.$inputCountry.val() == 'US';
};

proto.switchOptins = function switchOptins() {
    if (this.isCountryUS()) {
        this.$regionUS.show();
        this.$regionOther.hide();
        this.$inputPurinaUS.prop('checked', true);
        this.$inputPetfinderOther.prop('checked', true);
    } else {
        this.$regionUS.hide();
        this.$regionOther.show();
        this.$inputPurinaUS.prop('checked', false);
        this.$inputPetfinderOther.prop('checked', false);
    }

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.onCountryChange = function onCountryChange() {
    this.switchOptins();
};

export default countryOptinRelatedInputs;
