import { html } from '../../../core/scripts/util/util';

const eventItem = event => {
    return html`
        <li class="u-vr2x grid-col">
            <div class="eventWidget" itemtype="http://schema.org/Event">
                <div class="eventWidget-md">
                    <time itemprop="startDate" content="$${event.start_date_time}" class="event-date">
                        <div class="txt m-txt_xl m-txt_uppercase m-txt_colorPrimary">$${event.start_date_day_shortname}</div>
                        <div class="txt m-txt_colorPrimaryS2">$${event.start_date_month} $${event.start_date_day}</div>
                    </time>
                </div>
                <div class="eventWidget-bd" itemscope="" itemtype="http://schema.org/Place">
                    <h4 class="txt txt_link m-txt_bold" itemprop="name">
                        <a href="/calendar/displayevent.cgi?event_id=$${event.event_id}">
                            $${event.title}
                        </a>
                    </h4>
                    ${event.show_event_duration ? html`
                    <time itemprop="endDate" content="$${event.start_date_time}" class="txt m-txt_sm u-vr2x">
                        $${event.event_start_time} - $${event.event_end_time}
                    </time>
                    ` : ''}
                    <dl>
                        <dt class="txt m-txt_bold">Location:</dt>
                        <dd class="txt m-txt_sm">
                            <div itemprop="name">$${event.location}</div>
                            <p itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
                                <div itemprop="streetAddress">$${event.street_address}</div>
                                ${event.locality ? html`
                                    <span itemprop="addressLocality">$${event.locality}, </span>
                                ` : ''}
                                <span itemprop="addressRegion">$${event.region}</span>
                                <span itemprop="postalCode">$${event.postal_code}</span>
                            </p>
                            <div>
                                <a href="/calendar/displayevent.cgi?event_id=$${event.event_id}" class="txt txt_link m-txt_heavy m-txt_uppercase">Learn More</a>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </li>
    `;
};

export default function wpSidebarEventsTemplate(vm) {
    const { events } = vm;

    return html`
        <ul class="u-vr4x">
            ${events.map(event => eventItem(event))}
        </ul>
        <div>
            <a class="txt txt_link m-txt_heavy m-txt_uppercase" href="/calendar/">View All Events Near You</a>
        <div>
    `;
}
