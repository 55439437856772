// TODO: direct copy-paste of ComponentBootstrap from lib/legacy

import $ from 'jquery';

import EventBus from '../../../../core/scripts/vendor/EventBus/dist/EventBus';
import I18nModel from '../../../../core/scripts/models/I18nModel';
import RouteModel from '../../../../core/scripts/models/RouteModel';

// Components
import AboutMeForm from '../../components/AboutMeForm/AboutMeForm';
import AnchorInLabel from '../../components/AnchorInLabel/AnchorInLabel';
import BreakpointHelper from '../../components/BreakpointHelper/BreakpointHelper';
import DidYouAdoptForm from '../../components/DidYouAdoptForm/DidYouAdoptForm';
import FoodPreference from '../../components/FoodPreference/FoodPreference';
import PetDiscoveryRelatedInputs from '../../components/PetDiscoveryRelatedInputs/PetDiscoveryRelatedInputs';
import PetRelatedInputs from '../../components/PetRelatedInputs/PetRelatedInputs';
import RetailerRelatedInputs from '../../components/RetailerRelatedInputs/RetailerRelatedInputs';
import ScrollerTrack from '../../components/ScrollerTrack/ScrollerTrack';
import ShelterAutocomplete from '../../components/ShelterAutocomplete/ShelterAutocomplete';
import Toggler from '../../components/Toggler/Toggler';

// Forms
import BaseForm from '../../../../core/scripts/components/BaseForm/BaseForm';

// Ensighten
import GlobalEnsighten from '../../analytics/ensighten/GlobalEnsightenAnalytics';
import AboutMeEnsighten from '../../analytics/ensighten/AboutMeEnsightenAnalytics';
import AuthCouponApplicationEnsighten from '../../analytics/ensighten/AuthCouponApplicationEnsightenAnalytics';
import ChangeEmailFormEnsighten from '../../analytics/ensighten/ChangeEmailFormEnsightenAnalytics';
import CouponApplicationSuccessEnsighten from '../../analytics/ensighten/CouponApplicationSuccessEnsightenAnalytics';
import EmailPreferencesEnsighten from '../../analytics/ensighten/EmailPreferencesEnsightenAnalytics';
import GuestCouponApplicationEnsighten from '../../analytics/ensighten/GuestCouponApplicationEnsightenAnalytics';

/**
 * Bootstraps all components in the app on ready
 *
 * @cass ComponentBootstrap
 * @constructor
 */
// eslint-disable-next-line space-before-function-paren
const ComponentBootstrap = function() {
    this.init();
};

// Variable for conveniently referencing the prototype
const proto = ComponentBootstrap.prototype;

// Manifest of App Components
const componentList = {
    AboutMeEnsighten,
    AnchorInLabel,
    AboutMeForm,
    AuthCouponApplicationEnsighten,
    BaseForm,
    BreakpointHelper,
    ChangeEmailFormEnsighten,
    CouponApplicationSuccessEnsighten,
    EmailPreferencesEnsighten,
    FoodPreference,
    GlobalEnsighten,
    GuestCouponApplicationEnsighten,
    PetDiscoveryRelatedInputs,
    PetRelatedInputs,
    RetailerRelatedInputs,
    ScrollerTrack,
    ShelterAutocomplete,
    Toggler,
};

/**
 * Initializes the component bootstrap.  Runs setupComponents.
 *
 * @method init
 * @private
 * @returns {This}
 */
proto.init = function init() {
    /**
     * An HTMLElement wrapped in jQuery that acts as the root element
     * within which to find components to bootstrap.
     *
     * @property $element
     * @type {jQuery}
     * @default $('html')
     */
    this.$element = $('html');

    /**
     * Holds instantiates components
     *
     * @property components
     * @type {Object}
     * @default {}
     */
    this.components = {};

    /**
     * A top-level event bus to share events for cross-component communication
     *
     * @property eventBus
     * @type {Object}
     * @default new EventBus()
     */
    this.eventBus = new EventBus();

    this.routeModel = new RouteModel();
    this.i18nModel = new I18nModel();

    // TODO
    return this.setupHandlers()
        .setupComponents(this.$element)
        .enable();
};

/**
 * @returns {This}
 */
proto.setupHandlers = function setupHandlers() {
    this.onEnableComponentsInDOMSubtreeHandler = this.onEnableComponentsInDOMSubtree.bind(
        this
    );
    return this;
};

/**
 * Processes all components in the component list.
 * Loops over all components, finds the DOM element selector associated with the component.
 * Instantiates each component with the DOM element and the app-level event bus.
 *
 * @method setupComponents
 * @private
 * @param {jQuery} $element
 * @chainable
 * @returns {This}
 */
proto.setupComponents = function setupComponents($element) {
    const components = {};
    Object.keys(componentList).forEach(componentName => {
        const Component = componentList[componentName];

        if (typeof Component.SELECTORS.ELEMENT === 'undefined') {
            throw new Error(
                'Components require a selector to bind to in order to be initialized.'
            );
        }

        const $componentElements = $element.find(Component.SELECTORS.ELEMENT);

        if (!components.hasOwnProperty(componentName)) {
            components[componentName] = [];
        }

        $componentElements.each(index => {
            const $componentElement = $componentElements.eq(index);
            components[componentName].push(
                new Component(
                    $componentElement,
                    this.eventBus,
                    this.routeModel,
                    this.i18nModel
                )
            );
        });
    });

    this.components = components;

    return this;
};

proto.enable = function enable() {
    this.eventBus.on(
        'enableComponentsInDOMSubtree',
        this.onEnableComponentsInDOMSubtreeHandler
    );
};

/**
 * Trigger the enable component events to bootstrap all of the components
 *
 * @method enableComponents
 * @private
 * @chainable
 * @returns {This}
 */
proto.enableComponents = function enable() {
    this.eventBus.trigger('enableComponents');
    return this;
};

proto.onEnableComponentsInDOMSubtree = function onEnableComponentsInDOMSubtree(
    event,
    $element
) {
    this.setupComponents($element).enableComponents();
};

const componentBootstrap = new ComponentBootstrap();

export default function initLegacyComponents() {
    componentBootstrap.enableComponents();
}
