import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _noop from 'lodash/noop';

import Modal from '../../../../../../../core/scripts/react-components/specifics/Modal';

class ForDevelopersMobileMenu extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        navigation: PropTypes.shape({
            root: PropTypes.string,
            main: PropTypes.objectOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    href: PropTypes.string,
                })
            ),
            user: PropTypes.oneOfType([
                PropTypes.array, // Can be empty array from backend
                PropTypes.objectOf(
                    PropTypes.shape({
                        label: PropTypes.string,
                        href: PropTypes.string,
                    })
                ),
            ]),
        }),
        onUserMenuClick: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onMainNavLinkClick: PropTypes.func,
        onUserNavLinkClick: PropTypes.func,
        onLoginClick: PropTypes.func,
        onSignUpClick: PropTypes.func,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        onMainNavLinkClick: _noop,
        onUserNavLinkClick: _noop,
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleCloseClick = ev => {
        this.props.onClose();
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleUserMenuClick = ev => {
        ev.preventDefault();
        this.props.onUserMenuClick(ev);
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleLoginClick = ev => {
        ev.preventDefault();
        this.props.onLoginClick(ev, true);
    };

    /**
     * @private
     * @param {Event} ev
     */
    handleSignUpClick = ev => {
        ev.preventDefault();
        this.props.onSignUpClick(ev, true);
    };

    render() {
        const { main: mainNav, user: userNav } = this.props.navigation;
        const userLoggedIn = !_isEmpty(this.props.user);

        return (
            <Modal
                extensionClassNames={{ ['modal_mobileMenu']: true }}
                onClose={this.handleCloseClick}
                onEscapeKeyDown={this.handleCloseClick}
            >
                <div className="mobileMenu">
                    <header className="mobileMenu-header">
                        <button
                            className="mobileMenu-header-btn"
                            type="button"
                            aria-label="Close All About Pets Navigation Menu"
                            onClick={this.handleCloseClick}
                        >
                            <svg role="img">
                                <use xlinkHref="#icon-close" />
                            </svg>
                            <span className="u-isVisuallyHidden">{'Close All About Pets Navigation Menu'}</span>
                        </button>
                    </header>
                    <div className="mobileMenu-body">
                        <ul className="mobileMenu-body-nav">
                            {Object.keys(mainNav).map(key => {
                                const link = mainNav[key];
                                return (
                                    <li className="mobileMenu-body-nav-item" key={key}>
                                        <a
                                            href={link.href}
                                            className="mobileMenu-body-nav-item-btn"
                                            onClick={this.props.onMainNavLinkClick({ key, link })}
                                        >
                                            {link.label}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="mobileMenu-footer">
                        <ul className="mobileMenu-footer-nav">
                            {userLoggedIn ? (
                                <Fragment>
                                    <li className="mobileMenu-footer-nav-item">
                                        <span>{this.props.user.firstName}</span>
                                    </li>
                                    {Object.keys(userNav).map(key => {
                                        const link = userNav[key];
                                        return (
                                            <li key={key} className="mobileMenu-footer-nav-item">
                                                <a
                                                    href={link.href}
                                                    onClick={this.props.onUserNavLinkClick({ key, link })}
                                                >
                                                    {link.label}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <li className="mobileMenu-footer-nav-item">
                                        <a href="/user/register" onClick={this.handleSignUpClick}>
                                            {'Sign Up'}
                                        </a>
                                    </li>
                                    <li className="mobileMenu-footer-nav-item">
                                        <a href="/user/login" onClick={this.handleLoginClick}>
                                            {'Log In'}
                                        </a>
                                    </li>
                                </Fragment>
                            )}
                        </ul>
                        <button
                            className="txt txt_linkWhite m-txt_block u-isVisuallyHiddenUnlessFocused u-vrTop7x"
                            type="button"
                            onClick={this.handleCloseClick}
                        >
                            {'Close Main Navigation Menu'}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ForDevelopersMobileMenu;
