import $ from 'jquery';
import BaseEnsightenAnalytics from '../../../../core/scripts/analytics/ensighten/BaseEnsightenAnalytics';
import UserInfoEventModel from '../../../../core/scripts/analytics/models/user-info/UserInfoEventModel';
import AnimalEventModel from '../../../../core/scripts/analytics/models/animal/AnimalEventModel';

/**
 * About Me Form Analytic tracking component.
 *
 * @class AuthCouponApplicationEnsightenAnalytics
 * @constructor
 */
const AuthCouponApplicationEnsightenAnalytics = function($element, eventBus) {
    BaseEnsightenAnalytics.call(this, $element, eventBus);
    this.init($element, eventBus);
};

// Inheritance
AuthCouponApplicationEnsightenAnalytics.prototype = Object.create(
    BaseEnsightenAnalytics.prototype
);
AuthCouponApplicationEnsightenAnalytics.prototype.constructor = AuthCouponApplicationEnsightenAnalytics;

const proto = AuthCouponApplicationEnsightenAnalytics.prototype;
const _super = BaseEnsightenAnalytics.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @extends {BaseForm.setupHandlers}
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onInputNameBlurHandler = this.onInputNameBlur.bind(this);
    this.onInputAddress1BlurHandler = this.onInputAddress1Blur.bind(this);
    this.onInputEmailBlurHandler = this.onInputEmailBlur.bind(this);
    this.onInputPetNameBlurHandler = this.onInputPetNameBlur.bind(this);
    this.onInputPetTypeChangeHandler = this.onInputPetTypeChange.bind(this);
    this.onInputAdoptionSourceChangeHandler = this.onInputAdoptionSourceChange.bind(
        this
    );
    this.onInputAdoptionDateBlurHandler = this.onInputAdoptionDateBlur.bind(
        this
    );
    this.onSubmitHandler = this.onSubmit.bind(this);

    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @extends {BaseForm.createChildren}
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputFirstName = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_FIRST_NAME
    );
    this.$inputLastName = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_LAST_NAME
    );
    this.$inputAddress1 = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_1
    );

    this.$inputAddress2 = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_2
    );

    this.$inputCity = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_CITY
    );

    this.$inputState = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_STATE
    );

    this.$inputCountry = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_COUNTRY
    );

    this.$inputPostalCode = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_POSTAL_CODE
    );

    this.$inputEmail = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_EMAIL
    );
    this.$inputPetName = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_NAME
    );
    this.$inputPetType = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_TYPE
    );
    this.$inputAdoptionSource = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_SOURCE
    );
    this.$inputAdoptionDate = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_DATE
    );
    this.$form = this.$element.find(
        AuthCouponApplicationEnsightenAnalytics.SELECTORS.FORM
    );

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @extends {BaseForm.enable}
 * @private
 * @chainable
 */
proto.enable = function enable() {
    _super.enable.call(this);
    this.setupListeners();
    return this;
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @extends {BaseForm.setupListeners}
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    this.$inputFirstName.on('blur', this.onInputNameBlurHandler);
    this.$inputLastName.on('blur', this.onInputNameBlurHandler);
    this.$inputAddress1.on('blur', this.onInputAddress1BlurHandler);
    this.$inputEmail.on('blur', this.onInputEmailBlurHandler);
    this.$inputPetName.on('blur', this.onInputPetNameBlurHandler);
    this.$inputPetType.on('change', this.onInputPetTypeChangeHandler);
    this.$inputAdoptionSource.on(
        'change',
        this.onInputAdoptionSourceChangeHandler
    );
    this.$inputAdoptionDate.on('blur', this.onInputAdoptionDateBlurHandler);
    this.$form.on('submit', this.onSubmitHandler);
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    _super.disable.call(this);
    this.$inputFirstName.off('blur', this.onInputNameBlurHandler);
    this.$inputLastName.off('blur', this.onInputNameBlurHandler);
    this.$inputAddress1.off('blur', this.onInputAddress1BlurHandler);
    this.$inputEmail.off('blur', this.onInputEmailBlurHandler);
    this.$inputPetName.off('blur', this.onInputPetNameBlurHandler);
    this.$inputPetType.off('change', this.onInputPetTypeChangeHandler);
    this.$inputAdoptionSource.off(
        'change',
        this.onInputAdoptionSourceChangeHandler
    );
    this.$inputAdoptionDate.off('blur', this.onInputAdoptionDateBlurHandler);
    this.$form.off('submit', this.onSubmitHandler);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method doBothNameInputsHaveAValue
 * @private
 * @returns {boolean}
 */
proto.doBothNameInputsHaveAValue = function doBothNameInputsHaveAValue() {
    return this.$inputFirstName.val() != '' && this.$inputLastName.val() != '';
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * @method onInputNameBlur
 * @private
 */
proto.onInputNameBlur = function onInputNameBlur() {
    if (this.doBothNameInputsHaveAValue()) {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'enter name',
            label: 'logged-in user',
        });
    }
};

/**
 * @method onInputAddressBlur
 * @private
 */
proto.onInputAddress1Blur = function onInputAddress1Blur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter address',
        label: 'logged-in user',
    });
};

/**
 * @method onInputEmailBlur
 * @private
 */
proto.onInputEmailBlur = function onInputEmailBlur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter email',
        label: 'logged-in user',
    });
};

/**
 * @method onInputPetNameBlur
 * @private
 */
proto.onInputPetNameBlur = function onInputPetNameBlur() {
    this.triggerEvent({
        category: 'adopter registration',
        action: 'enter new pets name',
        label: 'logged-in user',
    });
};

/**
 * @method onInputPetTypeChange
 * @param {Event} e
 * @private
 */
proto.onInputPetTypeChange = function onInputPetTypeChange(e) {
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'select type of animal',
            label: $(e.currentTarget)
                .find('option:selected')
                .text()
                .toLowerCase(),
        });
    }
};

/**
 * @method onInputAdoptionSourceChange
 * @param {Event} e
 * @private
 */
proto.onInputAdoptionSourceChange = function onInputAdoptionSourceChange(e) {
    if (e.currentTarget.value !== '') {
        this.triggerEvent({
            category: 'adopter registration',
            action: 'select adoption source',
            label: $(e.currentTarget)
                .find('option:selected')
                .text()
                .toLowerCase(),
        });
    }
};

/**
 * @method onInputAdoptionDateBlur
 * @param {Event} e
 * @private
 */
proto.onInputAdoptionDateBlur = function onInputAdoptionDateBlur(e) {
    // plugin causes double blur event
    if (!this.dateEventTriggered) {
        if (e.currentTarget.value !== '') {
            const dateArray = e.currentTarget.value.split('/');
            const dateString = dateArray[2] + dateArray[0] + dateArray[1];
            this.triggerEvent({
                category: 'adopter registration',
                action: 'select adoption date',
                label: dateString,
            });
        }
        this.dateEventTriggered = true;
    }
};

/**
 * Coupon App form, so tracking submission of that $element.  If no fields are in error,
 * should be good to track the event.
 * @method onSubmit
 * @private
 */
proto.onSubmit = function onSubmit() {
    // TODO: Add form validator and trigger event on success
    const userInfoAnalytics = UserInfoEventModel.prepareEvent({
        city: this.$inputCity ? this.$inputCity.val() : null,
        state: this.$inputState ? this.$inputState.val() : null,
        country: this.$inputCountry ? this.$inputCountry.val() : null,
        postalCode: this.$inputPostalCode ? this.$inputPostalCode.val() : null,
        petType:
            this.$inputPetType.find('option:selected') &&
            this.$inputPetType.find('option:selected').text() !== 'Choose type'
                ? this.$inputPetType.find('option:selected').text()
                : null,
    });

    const animalAnalytics = AnimalEventModel.prepareEvent({
        name: this.$inputPetName ? this.$inputPetName.val() : '',
    });

    const dimensions = {
        ...userInfoAnalytics.dimensions,
        ...animalAnalytics.dimensions,
    };

    const additional = {
        ...userInfoAnalytics.dataLayer,
        ...animalAnalytics.dataLayer,
    };

    this.triggerEvent({
        category: 'adopter registration',
        action: 'submit',
        label: 'logged-in user',
        dimensions,
        additional,
    });
};

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.ELEMENT =
    '.js-authCouponApplicationEnsightenAnalytics';

/**
 * @property SELECTORS.INPUT_FIRST_NAME
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_FIRST_NAME =
    '.js-authCouponApplicationEnsightenAnalytics-inputFirstName';

/**
 * @property SELECTORS.INPUT_LAST_NAME
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_LAST_NAME =
    '.js-authCouponApplicationEnsightenAnalytics-inputLastName';

/**
 * @property SELECTORS.INPUT_ADDRESS_1
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_1 =
    '.js-authCouponApplicationEnsightenAnalytics-inputAddress1';

/**
 * @property SELECTORS.INPUT_ADDRESS_2
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADDRESS_2 =
    '.js-authCouponApplicationEnsightenAnalytics-inputAddress2';

/**
 * @property SELECTORS.INPUT_CITY
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_CITY =
    '.js-authCouponApplicationEnsightenAnalytics-inputCity';

/**
 * @property SELECTORS.INPUT_STATE
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_STATE =
    '.js-relatedInputs-countryState-stateSelect';

/**
 * @property SELECTORS.INPUT_COUNTRY
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_COUNTRY =
    '.js-countryOptinRelatedInputs-inputCountry';

/**
 * @property SELECTORS.INPUT_POSTAL_CODE
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_POSTAL_CODE =
    '.js-authCouponApplicationEnsightenAnalytics-inputPostalCode';

/**
 * @property SELECTORS.EMAIL
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_EMAIL =
    '.js-authCouponApplicationEnsightenAnalytics-inputEmail';

/**
 * @property SELECTORS.INPUT_PET_NAME
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_NAME =
    '.js-authCouponApplicationEnsightenAnalytics-inputPetName';

/**
 * @property SELECTORS.INPUT_PET_TYPE
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PET_TYPE =
    '.js-authCouponApplicationEnsightenAnalytics-inputPetType';

/**
 * @property SELECTORS.INPUT_ADOPTION_SOURCE
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_SOURCE =
    '.js-authCouponApplicationEnsightenAnalytics-inputAdoptionSource';

/**
 * @property SELECTORS.INPUT_ADOPTION_DATE
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_ADOPTION_DATE =
    '.js-authCouponApplicationEnsightenAnalytics-inputAdoptionDate';

/**
 * @property SELECTORS.INPUT_SUBMIT
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_SUBMIT =
    '.js-authCouponApplicationEnsightenAnalytics-inputSubmit';

/**
 * @property SELECTORS.INPUT_PURINA_OPTIN
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.INPUT_PURINA_OPTIN =
    '.js-authCouponApplicationEnsightenAnalytics-purinaOptin';

/**
 * Grabbing by ID due to complexities with passing in JS specific selector
 *
 * @property SELECTORS.FORM
 * @static
 * @final
 * @type {string}
 */
AuthCouponApplicationEnsightenAnalytics.SELECTORS.FORM =
    '.js-authCouponApplicationEnsightenAnalytics-form';

export default AuthCouponApplicationEnsightenAnalytics;
