import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withLazyLoad from '../../hoc/withLazyLoad';
import _noop from 'lodash/noop';

class HappyTailsSubmissionCard extends Component {
    static propTypes = {
        submission: PropTypes.shape({
            buttonPrimary: PropTypes.string.isRequired,
            buttonSecondary: PropTypes.string,
            headline: PropTypes.string.isRequired,
            linkPrimary: PropTypes.string.isRequired,
            linkSecondary: PropTypes.string,
            text: PropTypes.string.isRequired,
        }).isRequired,
        onPrimaryButtonClick: PropTypes.func,
        onSecondaryButtonClick: PropTypes.func,
        observed: PropTypes.bool,
    };

    static defaultProps = {
        onPrimaryButtonClick: _noop,
        onSecondaryButtonClick: _noop,
        observed: true,
    };

    componentDidUpdate() {
        // apply objectFit pollyfill to images for IE11
        if (window.objectFitImages) {
            objectFitImages(this.imgRef.current);
        }
    }

    /**
     * Reference to the <img> tag
     */
    imgRef = React.createRef();

    _handlePrimaryButtonClick = label => {
        this.props.onPrimaryButtonClick(label);
    };

    _handleSecondaryButtonClick = label => {
        this.props.onSecondaryButtonClick(label);
    };

    _renderBackgroundImage = () => {
        return (
            <img
                ref={this.imgRef}
                className="card-promo-background u-animationFadeIn"
                src="/assets/www/media/images/background/bg-happy-tail-submission-card_720.jpg"
                alt="woman sitting with her dog"
                title="get started happy tails"
                aria-hidden="true"
                tabIndex="-1"
                srcSet="
                    /assets/www/media/images/background/bg-happy-tail-submission-card_480.jpg 480w,
                    /assets/www/media/images/background/bg-happy-tail-submission-card_560.jpg 560w,
                    /assets/www/media/images/background/bg-happy-tail-submission-card_720.jpg 720w"
                sizes="100vw"
            />
        );
    };

    render() {
        const submission = this.props.submission;
        return (
            <div className="card m-card_noBorder m-card_rounded u-flex">
                <div className="card-promo">
                    {this.props.observed ? this._renderBackgroundImage() : null}
                    <div className="card-promo-inner card-promo-inner_darkOverlay">
                        <div className="card-promo-inner-body">
                            <h2 className="card-promo-inner-body-hdg">
                                {submission.headline}
                            </h2>
                            <p className="card-promo-inner-body-text">
                                {submission.text}
                            </p>
                            <a
                                href={submission.linkPrimary}
                                alt={submission.buttonPrimary}
                                className="card-promo-inner-body-button"
                                onClick={() =>
                                    this._handlePrimaryButtonClick(
                                        submission.buttonPrimary
                                    )
                                }
                                data-test="Happy_Tails_Submission_Card_Primary_Button"
                            >
                                {submission.buttonPrimary}
                            </a>
                            {submission.linkSecondary && (
                                <a
                                    href={submission.linkSecondary}
                                    alt={submission.buttonSecondary}
                                    className="card-promo-inner-body-button card-promo-inner-body-button_secondary"
                                    onClick={() =>
                                        this._handleSecondaryButtonClick(
                                            submission.buttonSecondary
                                        )
                                    }
                                    data-test="Happy_Tails_Submission_Card_Secondary_Button"
                                >
                                    {submission.buttonSecondary}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLazyLoad(HappyTailsSubmissionCard);
