/**
 * @fileOverview State Model File
 * @author Noah Blon nblon@nerdery.com
 */
const json= require('../data/i18nJSON.json');

/**
 * I18nModel
 * The model for a state
 *
 * @class I18nModel
 * @constructor
 *
 */
var I18nModel = function () {

    this.init();

};

I18nModel.prototype.init = function init() {
    this.loginModalSidebarTitle = json['user.login_modal.sidebar.title'];
    this.loginModalSidebarBodyText = json['user.login_modal.sidebar.body_txt'];
    this.loginModalLoginHeading = json['user.login_modal.login_heading'];
    this.loginModalLabelEmail = json['user.login_modal.form.label.email'];
    this.loginModalLabelPassword = json['user.login_modal.form.label.password'];
    this.loginModalForgotPasswordLink = json['user.login_modal.forgot_password_link'];
    this.loginModalSubmitBtnText = json['user.login_modal.submit_button_txt'];
    this.loginModalRegisterHeading = json['user.login_modal.register_heading'];
    this.loginModalRegisterBtnTxt = json['user.login_modal.register_button_txt'];
    this.loginModalErrorMessage = json['user.login_modal.error_message'];

    this.registrationModal = {};
    this.registrationModal.selectCountryPlaceholder = json['user.registration_modal.country_select_default_txt'];

    this.notificationsSuccessfulLogin = json['user.notification.successful_login'];
    this.notificationsSuccessfulRegistration = json['user.notification.successful_registration'];

    this.passwordPopup = {};
    this.passwordPopup.helpText = json['user.form.password_popup.help_text'];
    this.passwordPopup.criteriaLength = json['user.form.password_popup.length_criteria'];
    this.passwordPopup.criteriaUppercase = json['user.form.password_popup.uppercase_criteria'];
    this.passwordPopup.criteriaLowercase = json['user.form.password_popup.lowercase_criteria'];
    this.passwordPopup.criteriaNumeric = json['user.form.password_popup.numeric_criteria'];
    this.passwordPopup.criteriaSpecialChars = json['user.form.password_popup.special_characters_criteria'];
    this.passwordPopup.validMessage = json['user.form.password_popup.valid_message'];

    this.userProfile = {};
    this.userProfile.savingMessage = json['user.saving_indicator.saving'];
    this.userProfile.savedMessage = json['user.saving_indicator.saved'];

    this.petBlock = {};
    this.petBlock.selectBreedPlaceholder = json['user.add_a_pet_block.default_breed_select_txt'];
    this.petBlock.deletionErrorMessage = json['user.add_a_pet_block.deletion_error_message'];

    this.adoptionInquiry = {};
    this.adoptionInquiry.petfinderOptinUs = json['user.adoption_inquiry.petfinder_optin_us'];
    this.adoptionInquiry.petfinderOptinNonUs = json['user.adoption_inquiry.sponsor_optin_us'];
    this.adoptionInquiry.sponsorOptinUs = json['user.adoption_inquiry.petfinder_optin_other'];

    this.form = {};
    this.form.labelAddressState = json['user.form.label.address.state'];
    this.form.labelAddressProvince = json['user.form.label.address.province'];
    this.form.labelAddressStateAndProvince = json['user.form.label.address.state_province'];
    this.form.labelAddressZipCode = json['user.form.label.address.postalCode'];
    this.form.labelAddressZipCodeNonUse = json['user.form.label.address.postalCode_non_us'];

    this.form.placeholderAddressState = json['user.form.label.empty_state'];
    this.form.placeholderAddressProvince = json['user.form.label.empty_province'];

    this.form.errorPasswordMismatch = json['user.password.repeated_mismatch'];
    this.form.errorPasswordInvalid = json['user.password.invalid'];
    this.formErrorZipCode = json['validator.postal_code_us'];
    this.formErrorZipCodeNonUs = json['validator.postal_code_non_us'];

    this.recentlyViewedPetsHeading = json['user.recentlyviewedpets.heading'];
};

export default I18nModel;
