import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import {
    KEY_LEFT,
    KEY_ARROW_LEFT,
    KEY_RIGHT,
    KEY_ARROW_RIGHT,
} from '../../constants/keyboard';
import Flickity from 'flickity';
// import fizzyUiUtilsMOD from '../../../../core/scripts/vendor/flickity-mod/fizzy-ui-utils__mod';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-carousel';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CAROUSEL: `${ELEMENT_TAG_NAME}-body`,
    NAV: `${ELEMENT_TAG_NAME}-nav`,
    PAUSE_PLAY_BTN: `${ELEMENT_TAG_NAME}-pause-play`,
    SLIDE: `${ELEMENT_TAG_NAME}-slide`,
};

/**
 * State classes for CSS
 * @const
 * @type {string}
 */
const CLASSES = {
    PRE_JS: 'u-preJs',
    IS_PLAYING: 'u-isPlaying',
};

/**
 * @class PFDCCarouselElement
 * @extends PFElement
 */
export class PFDCCarouselElement extends PFElement {
    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        /**
         * Flickity instance.
         *
         * @property flickity
         * @type {HTMLElement}
         */
        this.flickity = null;

        /**
         * Carousel container element passed to flickity.
         *
         * @property carousel
         * @type {HTMLElement}
         */
        this.carousel = null;

        /**
         * Carousel paused status
         *
         * @property isPaused
         * @type {boolean}
         */
        this.isPaused = false;
    }

    onInit() {
        this.classList.remove(CLASSES.PRE_JS);

        this.carousel = this.querySelector(`[${ELEMENT_SELECTORS.CAROUSEL}]`);
        this.pausePlayButton = this.querySelector(
            `[${ELEMENT_SELECTORS.PAUSE_PLAY_BTN}]`
        );
        this.slides = Array.from(
            this.querySelectorAll(`[${ELEMENT_SELECTORS.SLIDE}]`)
        );
        this.nav = this.querySelector(`[${ELEMENT_SELECTORS.NAV}]`);

        const options = {
            imagesLoaded: true,
            percentPosition: true,
            wrapAround: true,
            friction: 0.5,
            selectedAttraction: 0.08,
            prevNextButtons: true,
            lazyLoad: 1,
            pageDots: false,
            autoPlay: this.autoPlay,
            adaptiveHeight: this.adaptiveHeight,
            accessibility: false, // because flickity safari arrows keys not working
        };

        this.flickity = new Flickity(this.carousel, options);

        window.f = this.flickity;

        this.createNav();

        if (this.autoPlay) {
            this.classList.add(CLASSES.IS_PLAYING);
        }

        this.flickity.on('select', this.onSelected.bind(this));
        this.flickity.on('settle', this.onSettled.bind(this));

        this.addEventListener('click', this.onClicked);
        this.pausePlayButton.addEventListener(
            'click',
            this.onPauseClicked.bind(this)
        );

        // Pause slider when focus is inside
        this.carousel.addEventListener('focusin', ev => {
            this.flickity.pausePlayer();
        });

        this.carousel.addEventListener('focusout', ev => {
            this.flickity.unpausePlayer();
        });

        // Carousel keydown handler
        this.addEventListener('keydown', ev => {
            const prevKeys = [KEY_ARROW_LEFT, KEY_LEFT];
            const nextKeys = [KEY_ARROW_RIGHT, KEY_RIGHT];
            if (prevKeys.includes(ev.key)) {
                ev.preventDefault();
                this.flickity.previous();
            }
            if (nextKeys.includes(ev.key)) {
                ev.preventDefault();
                this.flickity.next();
            }
        });

        window.addEventListener('focus', ev => {
            this.flickity.resize();
        });

        // Start updateAccessibilityAttrs
        this.updateAccessibilityAttrs();
    }

    /**
     * Hides inactive slides and tabbable inner content from screen readers,
     * makes selected element and inner content visible to screen readers
     */
    updateAccessibilityAttrs() {
        // Update accessibility attributes for inactive slides
        this.flickity.cells.forEach(cell => {
            this.toggleScreenReaderVisibility(cell.element, false);

            const links = Array.from(cell.element.getElementsByTagName('a'));
            links.forEach(link => {
                this.toggleScreenReaderVisibility(link, false);
            });
        });

        // Update accessibility attributes for active slides
        this.toggleScreenReaderVisibility(this.flickity.selectedElement, true);

        const links = Array.from(this.flickity.selectedElement.getElementsByTagName('a'));
        links.forEach(link => {
            this.toggleScreenReaderVisibility(link, true);
        });
    }

    /**
     * Toggle accessibility attributes
     * @param {Object} element - DOM element to modify
     * @param {Boolean} status - true shows element, false hides element
     */
    toggleScreenReaderVisibility(element, status) {
        if (status) {
            element.setAttribute('tabindex', '0');
            element.setAttribute('aria-hidden', 'false');
        } else if (!status) {
            element.setAttribute('tabindex', '-1');
            element.setAttribute('aria-hidden', 'true');
        }
    }

    /**
     * current slide index
     * reduces selectedIndex by increments of true slide length
     * until targetIndex is within range of nav
     *
     * @type {number}
     */
    get actualIndex() {
        let targetIndex = this.flickity.selectedIndex;

        while (targetIndex > this.slides.length - 1) {
            targetIndex = targetIndex - this.slides.length;
        }

        return targetIndex;
    }

    /**
     * Handler for standard click events
     *
     * @method onClicked
     * @param {Event} ev
     */
    onClicked(ev) {
        switch (true) {
            case this.navButtons.includes(ev.target):
                this.flickity.select(
                    this.navButtons.indexOf(ev.target),
                    false,
                    false
                );
                break;
            default:
                break;
        }
    }

    /**
     * Handler for flickity select events
     */
    onSelected() {
        this.updateNav();

        // Start updateAccessibilityAttrs
        this.updateAccessibilityAttrs();
    }

    /**
     * Handler for flickity settled events
     */
    onSettled() {
        if (!this.initialResizeComplete) {
            this.flickity.resize();
            this.initialResizeComplete = true;
        }
    }

    /**
     * handles pausing/starting the player
     */
    onPauseClicked() {
        if (this.isPaused) {
            this.classList.add(CLASSES.IS_PLAYING);
            this.flickity.playPlayer();
            this.isPaused = false;
        } else {
            this.classList.remove(CLASSES.IS_PLAYING);
            this.flickity.stopPlayer();
            this.isPaused = true;
        }
    }

    /**
     * Generates navigation dots
     */
    createNav() {
        let navTemplate = '';

        for (let i = 0; i < this.flickity.slides.length; i++) {
            navTemplate = `${navTemplate}\n<button type="button" aria-label="Go to slide ${i +
                1}"></button>`;
        }

        this.nav.innerHTML = navTemplate;
        this.navButtons = Array.from(this.nav.querySelectorAll('button'));
        this.nav.setAttribute('aria-hidden', 'false');
        this.updateNav();
    }

    /**
     * Updates navigation dots
     */
    updateNav() {
        const targetIndex = this.actualIndex;

        // remove active class
        for (let i = 0; i < this.navButtons.length; i++) {
            this.navButtons[i].classList.remove('isActive');
            this.navButtons[i].removeAttribute('disabled');
            this.navButtons[i].removeAttribute('aria-selected');
        }

        // add active class to correct nav dot
        this.navButtons[targetIndex].classList.add('isActive');
        this.navButtons[targetIndex].setAttribute('disabled', 'true');
        this.navButtons[targetIndex].setAttribute('aria-selected', 'true');
    }

    /**
     * Returns the autoplay value, can return a delay time or  false
     *
     * @returns {boolean|int}
     */
    get autoPlay() {
        if (!this.hasAttribute('autoplay')) {
            return false;
        }

        return parseInt(this.getAttribute('autoplay'), 10);
    }

    /**
     * Returns the adaptiveHeight value to set in the carousel options
     *
     * @returns {boolean}
     */
    get adaptiveHeight() {
        return this.hasAttribute('adaptiveHeight');
    }
}

export default PFDCCarouselElement;
