import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';
import withLazyLoad from '../../hoc/withLazyLoad';

/**
 * Renders Happy Tails CTA Card
 * @param {Object} props
 * @returns {string}
 */
export class HappyTailsContentCard extends Component {
    /**
     * @static
     * @type {Object}
     */
    static propTypes = {
        cta: PropTypes.string,
        extensionClassNames: PropTypes.object,
        happyTailsHref: PropTypes.string.isRequired,
        imageSource: PropTypes.string.isRequired,
        onCardClick: PropTypes.func.isRequired,
        story: PropTypes.string.isRequired,
        dataTestId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        observed: PropTypes.bool,
        renderImg: PropTypes.func,
    };

    /**
     * @static
     * @type {Object}
     */
    static defaultProps = {
        cta: 'Read More',
        observed: true, // default prop if content card is not given this value through lazyLoad
        renderImg: null,
    };

    /**
     * @type {Object}
     */
    imgRef = React.createRef();

    componentDidMount() {
        // apply objectFit polyfill to images for IE11
        if (window.objectFitImages) {
            objectFitImages(this.imgRef.current);
        }
    }

    /**
     * Build classnames for content card
     * @method getClassNames
     * @private
     * @returns {string}
     */
    getClassNames = () => {
        const extensionClasses = this.props.extensionClassNames;

        return buildClassNames({
            ['contentCard']: true,
            ['contentCard_happyTails']: true,
            ...extensionClasses,
        });
    };

    /**
     * On card click handler
     * @private
     */
    _handleCardClick = () => {
        this.props.onCardClick(
            this.props.title,
            this.props.cta,
            this.props.happyTailsHref
        );
    };

    /**
     * Render body fragment
     * @private
     *
     * @return {string}
     */
    _renderBody = () => {
        return (
            <Fragment>
                <h3 className="contentCard-body-hdg">
                    <span className="txt m-txt_xl">
                        {this.props.title}
                    </span>
                </h3>
                <p className="contentCard-body-details contentCard-body-details_clamp txt m-txt_lg ">
                    {this.props.story}
                </p>
            </Fragment>
        );
    };

    /**
     * Render media fragment
     *
     * @return {string}
     */
    _renderMedia = () => {
        const props = {
            alt: `Happy Tail ${this.props.title} together`,
            title: this.props.title,
            className: 'u-animationFadeIn',
            ref: this.imgRef,
        };

        if (typeof this.props.renderImg === 'function') {
            return this.props.renderImg({
                props,
            });
        }

        /* eslint-disable */
        return (
            <img
                srcSet={`
                    ${this.props.imageSource}&width=400 400w,
                    ${this.props.imageSource}&width=600 600w,
                    ${this.props.imageSource}&width=800 800w,
                `}
                sizes="
                    (min-width:1320px) 400px,
                    (min-width:1024px) calc((100vm - 140px) / 3),
                    calc(100vm - 40px),
                "
                src={`${this.props.imageSource}&width=400`}
                {...props}
            />
        );
        /* eslint-enable */
    };

    render() {
        return (
            <div
                className="u-flex u-flex_grow"
                data-test={this.props.dataTestId}
            >
                <a
                    className={this.getClassNames()}
                    onClick={this._handleCardClick}
                    href={this.props.happyTailsHref}
                >
                    <div className="contentCard-wrap">
                        <div className="contentCard-media">
                            {/* lazy load support */}
                            {this.props.observed ? this._renderMedia() : null}
                        </div>
                        <div className="contentCard-body">
                            {this._renderBody()}
                        </div>
                    </div>
                    <div className="contentCard-footer">
                        <div className="contentCard-footer-cta">
                            {this.props.cta}
                        </div>
                    </div>
                </a>
            </div>
        );
    }
}

export default withLazyLoad(HappyTailsContentCard);
