/**
 * # Custom Element Registry
 * Loads polyfills for custom elements.
 * Loads element class definitions.
 * Defines custom elements.
 */

/**
 * Core Element Imports
 */
import PFAccordionElement from '../../../../core/scripts/elements/pf-accordion/element';
import PFAdElement from '../../../../core/scripts/elements/pf-ad/element';
import PFAppElement from '../../../../core/scripts/elements/pf-app/element';
import { PFDatePickerElement } from '../../../../core/scripts/elements/pf-date-picker/element';
import PFDynamicElement from '../../../../core/scripts/elements/pf-dynamic/element';
import PFElement from '../../../../core/scripts/elements/pf-element/element';
import PFEnsightenElement from '../../../../core/scripts/elements/pf-ensighten/element';
import PFFocusOnUpdateElement from '../../../../core/scripts/elements/pf-focus-on-update/element';
import PFTruncate from '../../../../core/scripts/elements/pf-truncate/element';
import { PFRecaptchaElement } from '../../../../core/scripts/elements/pf-recaptcha/element';

/**
 * Dotcom Specific Element Imports
 */
import PFDCAddressElement from '../../elements/pfdc-address/element';
import PFDCTrackingBridge from '../../elements/pfdc-tracking-bridge/element';
import PFDCCarouselElement from '../../../../dotcom/scripts/elements/pfdc-carousel/element';
import PFDCClipboardElement from '../../../../dotcom/scripts/elements/pfdc-clipboard/element';
import PFDCContentToutsElement from '../../../../dotcom/scripts/elements/pfdc-content-touts/element';
import PFDCCountrySelectElement from '../../../../dotcom/scripts/elements/pfdc-country-select/element';

import { PFDCDispatcherSelectElement } from '../../../../dotcom/scripts/elements/pfdc-dispatcher/element';
import { PFDCDyaFormElement } from '../../../../dotcom/scripts/elements/pfdc-dya-form/element';

import PFDCDispatcherCheckboxElement from '../../../../dotcom/scripts/elements/pfdc-dispatcher-checkbox/element';
import PFDCFavoriteBtnElement from '../../elements/pfdc-favorite-btn/element';
import PFDCFooterElement from '../../../../dotcom/scripts/elements/pfdc-footer/element';
import PFDCFormElement from '../../elements/pfdc-form/element';
import PFDCHeaderElement from '../../../../dotcom/scripts/elements/pfdc-header/element';
import PFDCHeaderDropdownElement from '../../../../dotcom/scripts/elements/pfdc-header-dropdown/element';
import PFDCHeaderDropdownResourcesElement from '../../../../dotcom/scripts/elements/pfdc-header-dropdown-resources/element';
import PFDCHelloBarElement from '../../../../dotcom/scripts/elements/pfdc-hello-bar/element';
import PFDCInputElement from '../../../../dotcom/scripts/elements/pfdc-input/element';
import PFDCLazyLoadElement from '../../../../dotcom/scripts/elements/pfdc-lazy-load/element';
import PFDCLoginFormElement from '../../../../dotcom/scripts/elements/pfdc-login-form/element';
import PFDCMobileMenuElement from '../../../../dotcom/scripts/elements/pfdc-mobile-menu/element';
import PFDCModalElement from '../../../../dotcom/scripts/elements/pfdc-modal/element';
import PFDCOptinsElement from '../../../../dotcom/scripts/elements/pfdc-optins/element';
import PFDCPasswordValidatorElement from '../../../../dotcom/scripts/elements/pfdc-password-validator/element';

import PFDCPetCardElement from '../../../../dotcom/scripts/elements/pfdc-pet-card/element';
import PFDCPostalCodeInputElement from '../../../../dotcom/scripts/elements/pfdc-postal-code-input/element';
import PFDCRecommendationsElement from '../../../../dotcom/scripts/elements/pfdc-recommendations/element';
import PFDCRegistrationFormElement from '../../../../dotcom/scripts/elements/pfdc-registration-form/element';
import PFDCSavedSearchListElement from '../../../../dotcom/scripts/elements/pfdc-saved-search-list/element';
import PFDCShelterSearchElement from '../../../../dotcom/scripts/elements/pfdc-shelter-search/element';
import PFDCStickyElement from '../../../../dotcom/scripts/elements/pfdc-sticky/element';
import PFDCStoryGuideElement from '../../../../dotcom/scripts/elements/pfdc-story-guide/element';
import PFDCTooltipControllerElement from '../../../../dotcom/scripts/elements/pfdc-tooltip-controller/element';
import PFDCVideoElement from '../../../../dotcom/scripts/elements/pfdc-video/element';
import PFDCWpEventsElement from '../../../../dotcom/scripts/elements/pfdc-wp-events/element';

import { PFDCPetCarouselElement } from '../../../../dotcom/scripts/elements/pfdc-pet-carousel/element';
import { PFDCFullscreenCarouselElement } from '../../../../dotcom/scripts/elements/pfdc-fullscreen-carousel/element';
import { PFDCCollapsableElement } from '../../../../dotcom/scripts/elements/pfdc-collapsable/element';
import PFDCSelectNav from '../../../../dotcom/scripts/elements/pfdc-select-nav/element';
import PFDCSavedSearchCard from '../../../../dotcom/scripts/elements/pfdc-saved-search-card/element';
import PFDCSavedSearchListController from '../../../../dotcom/scripts/elements/pfdc-saved-search-list-controller/element';
import PFDCSavedSearchCarouselNav from '../../../../dotcom/scripts/elements/pfdc-saved-search-carousel-nav/element';

export default function initCustomElements() {
    // Core
    customElements.define('pf-accordion', PFAccordionElement);
    customElements.define('pf-ad', PFAdElement);
    customElements.define('pf-app', PFAppElement);
    customElements.define('pf-date-picker', PFDatePickerElement);
    customElements.define('pf-dynamic', PFDynamicElement);
    customElements.define('pf-element', PFElement);
    customElements.define('pf-ensighten', PFEnsightenElement);
    customElements.define('pf-focus-on-update', PFFocusOnUpdateElement);
    customElements.define('pf-recaptcha', PFRecaptchaElement);
    customElements.define('pf-truncate', PFTruncate);

    // Dotcom
    customElements.define('pfdc-address', PFDCAddressElement);
    customElements.define('pfdc-tracking-bridge', PFDCTrackingBridge);
    customElements.define('pfdc-carousel', PFDCCarouselElement);
    customElements.define('pfdc-content-touts', PFDCContentToutsElement);
    customElements.define('pfdc-country-select', PFDCCountrySelectElement);
    customElements.define('pfdc-clipboard', PFDCClipboardElement);
    customElements.define('pfdc-dispatcher-select', PFDCDispatcherSelectElement);
    customElements.define('pfdc-dya-form', PFDCDyaFormElement);
    customElements.define('pfdc-favorite-btn', PFDCFavoriteBtnElement);
    customElements.define('pfdc-footer', PFDCFooterElement);
    customElements.define('pfdc-form', PFDCFormElement);
    customElements.define('pfdc-header', PFDCHeaderElement);
    customElements.define('pfdc-header-dropdown', PFDCHeaderDropdownElement);
    customElements.define('pfdc-header-dropdown-resources', PFDCHeaderDropdownResourcesElement);
    customElements.define('pfdc-hello-bar', PFDCHelloBarElement);
    customElements.define('pfdc-input', PFDCInputElement);
    customElements.define('pfdc-lazy-load', PFDCLazyLoadElement);
    customElements.define('pfdc-login-form', PFDCLoginFormElement);
    customElements.define('pfdc-mobile-menu', PFDCMobileMenuElement);
    customElements.define('pfdc-modal', PFDCModalElement);
    customElements.define('pfdc-optins', PFDCOptinsElement);
    customElements.define('pfdc-password-validator', PFDCPasswordValidatorElement);
    customElements.define('pfdc-pet-card', PFDCPetCardElement);
    customElements.define('pfdc-postal-code-input', PFDCPostalCodeInputElement);
    customElements.define('pfdc-recommendations', PFDCRecommendationsElement);
    customElements.define('pfdc-registration-form', PFDCRegistrationFormElement);
    customElements.define('pfdc-saved-search-list', PFDCSavedSearchListElement);
    customElements.define('pfdc-shelter-search', PFDCShelterSearchElement);
    customElements.define('pfdc-sticky', PFDCStickyElement);
    customElements.define('pfdc-story-guide', PFDCStoryGuideElement);
    customElements.define('pfdc-tooltip-controller', PFDCTooltipControllerElement);
    customElements.define('pfdc-video', PFDCVideoElement);
    customElements.define('pfdc-wp-events', PFDCWpEventsElement);

    // TODO: Remove
    customElements.define('pfdc-select-nav', PFDCSelectNav);
    customElements.define('pfdc-saved-search-card', PFDCSavedSearchCard);
    customElements.define('pfdc-saved-search-list-controller', PFDCSavedSearchListController);
    customElements.define('pfdc-saved-search-carousel-nav', PFDCSavedSearchCarouselNav);
    customElements.define('pfdc-dispatcher-checkbox', PFDCDispatcherCheckboxElement);
    customElements.define('pfdc-pet-carousel', PFDCPetCarouselElement);
    customElements.define('pfdc-fullscreen-carousel', PFDCFullscreenCarouselElement);
    customElements.define('pfdc-collapsable', PFDCCollapsableElement);
}
