const statechart = {
    strict: true,
    initial: 'isValid',
    states: {
        isValid: {
            on: {
                INVALIDATED: 'isInvalid',
                RECAPTCHA_VALIDATED: 'isSubmitting',
                SUBMITTED: {
                    isRecaptchaing: {
                        cond: (extState, { isRecaptchaEnabled }) =>
                            isRecaptchaEnabled,
                    },
                    isSubmitting: {
                        cond: (extState, { isRecaptchaEnabled }) =>
                            !isRecaptchaEnabled,
                    },
                },
            },
        },
        isInvalid: {
            on: {
                VALIDATED: 'isValid',
                SUBMITTED: {
                    isInvalid: {
                        actions: [
                            'dispatchValidatorValidateAction',
                            'renderGlobalFormError',
                            'moveFocusToGlobalFormError',
                        ],
                    },
                },
            },
        },
        isRecaptchaing: {
            onEntry: [
                'executeRecaptcha',
                'clearGlobalFormError',
                'disableSubmitBtn',
            ],
            onExit: 'enableSubmitBtn',
            on: {
                RECAPTCHA_VALIDATED: 'isSubmitting',
                RECAPTCHA_RESET: 'isValid',
            },
        },
        isSubmitting: {
            onEntry: 'submitForm',
        },
    },
};

export default statechart;
