/*
    <a class="txt txt_link" href="mailto:asdf@example.com">
        asdf@example.com
    </a>
*/
import { html } from '../../../../../core/scripts/util/util.js';
export const emailLink = vm => html`
   ${vm.email && html`
        <a class="txt txt_link m-txt_underline" href="mailto:${vm.email}">
            $${vm.email}
        </a>
    `}
`;
