import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import OrganizationRepository from '../../../../core/scripts/repositories/OrganizationRepository';

import autocompleteSuggestionNoResultsTemplate from '../../templates/autocompleteSuggestionNoResults.html';
import autocompleteSuggestionTemplate from '../../templates/autocompleteSuggestion.html';
import autocompleteSuggestionsRequestErrorTemplate from '../../templates/autocompleteSuggestionRequestError.html';
import { escapeExpression } from '../../../../core/scripts/util/dom';

import { buildCustomEvent } from '../../util/dom';

import {
    KEY_ARROW_DOWN,
    KEY_DOWN,
    KEY_ARROW_UP,
    KEY_UP,
    KEY_ESCAPE,
    KEY_ESC,
    KEY_ENTER,
    KEY_SPACE,
    KEY_SPACE_BAR,
} from '../../constants/keyboard';

import $ from 'jquery';

// TODO: move to library
const debounce = function debounce(fn, debouncePeriod, execNow) {
    let timeout;
    let result;

    function debounced(...args) {
        const context = this;

        function fnToCall() {
            if (!execNow) {
                result = fn.apply(context, args);
            }
            timeout = null;
        }

        if (timeout) {
            clearTimeout(timeout);
        } else if (execNow) {
            result = fn.apply(context, args);
        }

        timeout = setTimeout(fnToCall, debouncePeriod);
        return result;
    }

    debounced.cancel = function() {
        clearTimeout(timeout);
    };

    return debounced;
};

/**
 * Autocomplete
 *
 * @class ShelterAutocomplete
 * @extends {BaseComponent}
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus Event Bus shared between components in app
 */
var ShelterAutocomplete = function($element, eventBus, routeModel, i18nModel) {
    BaseComponent.call(this, $element, eventBus, routeModel, i18nModel);
    this.init($element, eventBus);
};

// Inheritance
ShelterAutocomplete.prototype = Object.create(BaseComponent.prototype);
ShelterAutocomplete.prototype.constructor = ShelterAutocomplete;

var proto = ShelterAutocomplete.prototype;
var _super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);

    /**
     * Counter which maintains current page of results fetched in order to load more
     *
     * @property ShelterAutocomplete.page
     * @type {Number}
     */
    this.page = 1;

    /**
     * Counter which maintains the most recent AJAX request in order to only resolve the most current
     *
     * @property ShelterAutocomplete.requestIndex
     * @type {Number}
     */
    this.requestIndex = 0;

    /**
     * Have the results been cached.  Lets not request em again.
     *
     * @property ShelterAutocomplete.resultsCached
     * @type {Number}
     */
    this.resultsCached = false;

    /**
     * No results template used when the get for shelters returns no matching results
     *
     * @property ShelterAutocomplete.templateNoResults
     * @type {Object}
     */
    this.templateNoResults = autocompleteSuggestionNoResultsTemplate();

    /**
     * Template containing message to display if there is an error requesting shelters, such as a timeout.
     *
     * @property ShelterAutocomplete.templateRequestError
     * @type {Object}
     */
    this.templateRequestError = autocompleteSuggestionsRequestErrorTemplate();

    /**
     * Repository for handling organization requests and building models.
     *
     * @property ShelterAutocomplete.organizationRepository
     * @type {Object}
     */
    this.organizationRepository = new OrganizationRepository();

    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangeAdoptionMethodHandler = this.onChangeAdoptionMethod.bind(this);
    this.onShelterInputBlurHandler = this.onShelterInputBlur.bind(this);
    this.onShelterInputFocusHandler = this.onShelterInputFocus.bind(this);
    this.onResultsScrollHandler = this.onResultsScroll.bind(this);
    this.onShelterInputKeyupDebouncedHandler = this.onShelterInputKeyupDebounced.bind(
        this
    );
    this.onShelterSelectBlurHandler = this.onShelterSelectBlur.bind(this);
    // this.onShelterInputKeyupHandler = this.onShelterInputKeyup.bind(this);
    this.onShelterInputKeydownHandler = this.onShelterInputKeydown.bind(this);
    this.onResultClickHandler = this.onResultClick.bind(this);
    this.onClickStopPropagationHandler = this.onClickStopPropagation.bind(this);
    this.onToggleClickHandler = this.onToggleClick.bind(this);
    this.onDocumentClickHandler = this.onDocumentClick.bind(this);
    this.onShelterNotFoundBtnClickHandler = this.onShelterNotFoundBtnClick.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$methodInput = this.$element.find(
        ShelterAutocomplete.SELECTORS.INPUT_METHOD
    );
    this.$shelterGroup = this.$element.find(
        ShelterAutocomplete.SELECTORS.SHELTER_GROUP
    );
    this.$shelterInputGroup = this.$element.find(
        '.js-shelterAutocomplete-shelterInputGroup'
    );
    this.$shelterInput = this.$element.find(
        ShelterAutocomplete.SELECTORS.SHELTER_INPUT
    );
    this.$shelterId = this.$element.find(
        ShelterAutocomplete.SELECTORS.SHELTER_ID
    );
    this.$throbber = this.$element.find(ShelterAutocomplete.SELECTORS.THROBBER);
    this.$results = this.$element.find(ShelterAutocomplete.SELECTORS.RESULTS);

    this.$shelterNotFoundBtn = this.$element.find(
        '.js-shelterAutocomplete-shelterNotFoundBtn'
    );
    this.$shelterNotFoundCheckbox = this.$element.find(
        '.js-shelterAutocomplete-shelterNotFoundCheckbox'
    );
    this.$resultsContainer = this.$element.find(
        '.js-shelterAutocomplete-results-container'
    );
    this.$shelterSelect = this.$element.find('.js-shelterAutocomplete-select');
    this.$shelterSelectLabel = this.$element.find(
        '.js-shelterAutocomplete-select-label'
    );

    // Element we use to update num of results to screen readers
    this.$resultsStatus = this.$element.find('.js-shelterAutocomplete-status');

    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    _super.enable.call(this);
    // disable the shelter input until shelters are initially loaded.  By default we'll allow any text here
    // if JS does not load because the shelter not found checkbox will be checked.
    this.$shelterInput.prop('disabled', true);

    // Hide the shelter input group
    this.$shelterInputGroup.hide();

    this.getShelters(this.page, '')
        .done(this.onInitialShelterFetchSuccess.bind(this))
        .fail(this.onInitialShelterFetchFail.bind(this));

    this.checkAdoptionMethodValue();
};

/**
 * Sets up any event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$methodInput.on('change', this.onChangeAdoptionMethodHandler);
    this.$shelterInput.on('focus', this.onShelterInputFocusHandler);
    this.$shelterInput.on('blur', this.onShelterInputBlurHandler);
    // this.$shelterInput.on('keyup', this.onShelterInputKeyupHandler);
    this.$shelterInput.on('keydown', this.onShelterInputKeydownHandler);
    this.$shelterInput.on(
        'keyup',
        debounce(this.onShelterInputKeyupDebouncedHandler, 500)
    );
    this.$shelterInput.on('click', this.onClickStopPropagationHandler);

    this.$results.on('click', this.onClickStopPropagationHandler);
    this.$shelterSelect.on('click', this.onToggleClickHandler);
    this.$shelterSelect.on('blur', this.onShelterSelectBlurHandler);
    this.$shelterNotFoundBtn.on('click', this.onShelterNotFoundBtnClickHandler);

    // Managing isScrolling Events
    var self = this;
    this.isScrolling = true;

    this.$resultsContainer.on('scroll', function() {
        // TODO Why?
        // self.$shelterInput.focus();
        self.isScrolling = true;
    });

    // this.$resultsContainer.on('mouseup', function() {
    //     self.isScrolling = false;
    // });

    this.$resultsContainer.on('focusin', ev => {
        self.isScrolling = true;
    });

    this.$resultsContainer.on('focusout', ev => {
        self.isScrolling = false;
    });

    // Keyboard Events
    this.$results.on('keydown', ev => {
        const key = ev.originalEvent.key;
        const $eventTarget = $(ev.target);

        // Close the results
        if (key === KEY_ESC || key === KEY_ESCAPE) {
            self.hideResults();
            setTimeout(() => {
                self.$shelterInput.focus();
            }, 0);
        }

        if (key === KEY_UP || key === KEY_ARROW_UP) {
            ev.preventDefault();
            // Move focus back to results container when you press up on the not found button...
            if ($eventTarget[0] === self.$shelterNotFoundBtn[0]) {
                const $listItems = this.$resultsContainer.children();
                $listItems[$listItems.length - 1].focus();
                return;
            }
            $eventTarget.prev().focus();
        }

        if (key === KEY_DOWN || key === KEY_ARROW_DOWN) {
            ev.preventDefault();
            if (!$eventTarget.next().length) {
                self.$shelterNotFoundBtn.focus();
                return;
            }
            $eventTarget.next().focus();
        }
    });

    // Key handler for results container
    this.$resultsContainer.on('keydown', ev => {
        const key = ev.originalEvent.key;
        // Click items in the suggestions list
        if (key === KEY_ENTER || key === KEY_SPACE || key === KEY_SPACE_BAR) {
            ev.preventDefault();
            $(ev.target).click();
            setTimeout(() => {
                this.$shelterSelect.focus();
            }, 0);
        }
    });

    $('input, select')
        .not(this.$shelterInput)
        .on('focus', function() {
            self.$shelterInputGroup.hide();
            self.$shelterSelect.show();
            self.$shelterSelectLabel.show();
            self.hideResults();
        });

    return this;
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    _super.disable.call(this);
    this.$methodInput.off('change', this.onChangeAdoptionMethodHandler);
    this.$shelterInput.off('focus', this.onShelterInputFocusHandler);
    this.$shelterInput.off('blur', this.onShelterInputBlurHandler);
    this.$shelterInput.off('keyup', this.onShelterInputKeyupHandler);
    this.$shelterInput.off('keyup', this.onShelterInputKeyupDebouncedHandler);
    this.$shelterSelect.off('blur', this.onShelterSelectBlurHandler);
    // TODO
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Check if the adoption method select field is set to shelter,
 * and carry out appropriate methods depending on value.
 * Run on Enable and on adoption method input change to show shelter autocomplete if the correct value is selected.
 *
 * @method checkAdoptionMethodValue
 * @private
 */
proto.checkAdoptionMethodValue = function checkAdoptionMethodValue() {
    var methodTypeId = this.$methodInput.val();
    if (methodTypeId == ShelterAutocomplete.SHELTER_VALUE) {
        this.$shelterGroup.show();
    } else {
        this.$shelterGroup.hide();
    }
    return this;
};

/**
 * Builds option templates and appends them to the results container.
 * Also caches references to the results so we can track events on them.
 *
 * @method buildOptions
 * @param {Object} orgColl collection of org models
 * @private
 */
proto.buildOptions = function(orgColl) {
    var $template = $();
    var i = 0;
    for (i; i < orgColl.organizations.length; i++) {
        $template = this.buildOptionTemplate(orgColl.organizations[i], '');
        this.$resultsContainer.append($template);
    }
    this.$result = this.$element.find(ShelterAutocomplete.SELECTORS.RESULT);
    this.$result.on('click', this.onResultClickHandler);
};

/**
 * Toggles the visibility of the shelter group.
 * Shows with true param and hides with false.
 *
 * @method toggleShelterGroup
 * @param {Boolean} show
 * @private
 * @chainable
 */
proto.toggleShelterGroup = function toggleShelterGroup(show) {
    this.$shelterGroup.toggleClass('u-isHidden', !show);
    return this;
};

/**
 * Populates the shelter input with a provided set of autocomplete options
 *
 * @method getShelters
 * @private
 * @chainable
 */
proto.getShelters = function getShelters(page, searchQuery) {
    return this.organizationRepository.getOrganizations(
        searchQuery,
        ShelterAutocomplete.SHELTER_LIMIT,
        page
    );
};

/**
 * Check if shelter input value matches the value of any shelters in the currentSuggestions.
 * If there is no match, clear the shelter id value and the shelter input.
 *
 * @method checkIfShelterValueIsValid
 * @private
 * @chainable
 */
proto.checkIfShelterValueIsValid = function checkIfShelterValueIsValid() {
    var shelterMatch = false;
    var shelterInputValue = this.$shelterInput.val();
    if (this.otherShelter) {
        return this;
    }
    if (this.currentSuggestions.organizations) {
        for (var i = 0; i < this.currentSuggestions.organizations.length; i++) {
            if (
                this.currentSuggestions.organizations[i].name ===
                shelterInputValue
            ) {
                shelterMatch = true;
            }
        }
        if (!shelterMatch) {
            this.clearShelterIdData().clearShelterInput();
            this.$shelterSelect.text('Select A Shelter');
        }
    }
    return this;
};

/**
 * Clears the user input in the shelter text field
 *
 * @method clearShelterInput
 * @private
 * @chainable
 */
proto.clearShelterInput = function clearShelterInput() {
    this.$shelterInput.val('');
    return this;
};

/**
 * Clears the data value in the shelter id hidden input
 *
 * @method clearShelterIdData
 * @private
 * @chainable
 */
proto.clearShelterIdData = function clearShelterIdData() {
    this.$shelterId.val('');
    return this;
};

/**
 * Send shelter input id to form validation through event bus
 *
 * @method triggerValidationThroughEventBus
 * @private
 * @chainable
 */
proto.triggerValidationThroughEventBus = function triggerValidationThroughEventBus() {
    this.eventBus.trigger('checkFormValidation', this.shelterInputId);
    return this;
};

/**
 * Show the throbber while the shelter fetch is in flight.
 *
 * @method showThrobber
 * @private
 * @chainable
 */
proto.showThrobber = function showThrobber() {
    this.$throbber.fadeIn(150);
    return this;
};

/**
 * Hide the throbber when the shelter request is finished.
 *
 * @method showThrobber
 * @private
 * @chainable
 */
proto.hideThrobber = function hideThrobber() {
    this.$throbber.fadeOut(150);
    return this;
};

/**
 * Subroutine for requesting the shelters and directing based on result
 *
 * @method doGetSheltersWorkflow
 * @private
 * @chainable
 */
proto.doGetSheltersWorkflow = function doGetSheltersWorkflow(
    requestIndex,
    searchQuery
) {
    this.showThrobber();
    if (searchQuery.length > 0) {
        this.getShelters(this.page, searchQuery)
            .done(
                this.onShelterFetchSuccess.bind(this, requestIndex, searchQuery)
            )
            .fail(this.onShelterFetchFail.bind(this, requestIndex));
    }
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the adoption method input
 *
 * @method onChangeAdoptionMethod
 * @param event to grab the input's value
 * @private
 */
proto.onChangeAdoptionMethod = function onChangeAdoptionMethod(event) {
    this.checkAdoptionMethodValue();
};

proto.showResults = function() {
    this.resultsContainerOpen = true;
    this.$results.show();
    var resultsContainerHeight = this.$resultsContainer.outerHeight();
    this.$shelterNotFoundBtn.css('top', 55 + resultsContainerHeight);

    var self = this;
    setTimeout(function() {
        $('html').on('click', self.onDocumentClickHandler);
    }, 100);
};

proto.hideResults = function() {
    $('html').off('click', this.onDocumentClickHandler);
    this.resultsContainerOpen = false;
    this.$results.hide();
};

proto.canFocusResults = function() {
    return (
        this.resultsContainerOpen &&
        this.$resultsContainer.children().length >= 1
    );
};

/**
 * When the shelter input is focused, if there are some cached results show them
 *
 * @method onShelterInputFocus
 * @private
 */
proto.onShelterInputFocus = function onShelterInputFocus() {
    if (this.resultsCached) {
        this.showResults();
    } else {
        this.doGetSheltersWorkflow(this.requestIndex, '');
    }
    if (this.$shelterInput.val()) {
        this.$shelterInput[0].setSelectionRange(0, 9999);
    }
    if (window.innerWidth <= 480) {
        window.scrollTo(0, this.$shelterInput.offset().top);
    }
};

/**
 * Check if shelter input value is valid onBlur of autocomplete input
 *
 * @method onShelterInputBlur
 * @private
 */
proto.onShelterInputBlur = function onShelterInputBlur(e) {
    var self = this;

    setTimeout(function() {
        if (!self.isScrolling) {
            // TODO is this needed? sometimes makes the component totally break
            // self.$shelterInput.hide();
            // self.$shelterSelect.show();
            // self.hideResults();
            self.checkIfShelterValueIsValid().triggerValidationThroughEventBus();
        }
    }, 50);
};

/**
 * Trigger input blur on shelter select blur (for field validation on tab-through)
 *
 * @method onShelterSelectBlurHandler
 * @private
 */
proto.onShelterSelectBlur = function onShelterSelectBlur(e) {
    var input = this.$shelterInput[0];
    input.dispatchEvent(buildCustomEvent('blur'));
};

/**
 * Load new shelter results on a debounced keyup
 *
 * @method onShelterInputKeyup
 * @param event
 * @private
 */
proto.onShelterInputKeyupDebounced = function onShelterInputKeyupDebounced(
    event
) {
    const key = event.originalEvent.key;

    this.searchQuery = this.$shelterInput.val().trim();
    // If the shelter input has no value or just spaces, make no request
    if (this.searchQuery.length === 0) {
        this.$resultsStatus.text('');
        this.hideResults();
        return;
    }

    if (key === KEY_ARROW_DOWN || key === KEY_DOWN) {
        return;
    }

    // Reset the page index since we know this should be a fresh search
    this.page = 1;
    this.requestIndex++;
    this.hideResults();
    this.isSelected = false;
    this.doGetSheltersWorkflow(this.requestIndex, this.searchQuery);
};

proto.onShelterInputKeyup = function onShelterInputKeyup(event) {
    // this.hideResults();
};

proto.onShelterInputKeydown = function onShelterInputKeydown(event) {
    const key = event.originalEvent.key;

    if (this.canFocusResults()) {
        if (key === KEY_DOWN || key === KEY_ARROW_DOWN) {
            event.preventDefault();
            // down arrow focuses first item in list
            try {
                const firstChild = this.$resultsContainer.find(
                    '.js-shelterAutocomplete-result:first-child'
                );
                setTimeout(() => {
                    firstChild[0].focus();
                }, 0);
            } catch (e) {
                return;
            }
            return;
        }
    }
};

proto.parseSpecialCharacters = function parseSpecialCharacters(name) {
    return name
        .replace(/&quot;/g, '||||&quot;||||')
        .replace(/&amp;/g, '||||&amp;||||')
        .replace(/&lt;/g, '||||&lt;||||')
        .replace(/&gt;/g, '||||&gt;||||')
        .replace(/&ndash;/g, '||||&ndash;||||')
        .replace(/&mdash;;/g, '||||&mdash;||||')
        .replace(/&lsquo;/g, '||||&lsquo;||||')
        .replace(/&rsquo;/g, '||||&rsquo;||||')
        .replace(/&sbquo;/g, '||||&sbquo;||||')
        .replace(/&ldquo;/g, '||||&ldquo;||||')
        .replace(/&rdquo;/g, '||||&rdquo;||||')
        .replace(/&bdquo;/g, '||||&bdquo;||||')
        .split('||||');
};

proto.wrapSearchQuery = function wrapSearchQuery(nameComponent, searchQuery) {};

proto.highlightSearchTermInName = function highlighSearchTermInName(
    nameComponents,
    searchQuery
) {
    if (!searchQuery) {
        return nameComponents.join('');
    }
    var escapedQuery = searchQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    var regex = new RegExp('(' + escapedQuery + ')', 'gi');

    var nameComponent = '';
    var components = [];
    // iterate over all of the name components and if they are not a special character, wrap them in
    // a strong tag to highlight them in the options.  Special character are skipped so they do not break
    // if the search query matches their value. For example, if searching dash, endashed and emdashes would be
    // wrapped in a strong tag, breaking the output.

    var components = nameComponents.map(function(nameComponent) {
        if (
            !(
                nameComponent === '&quot;' ||
                nameComponent === '&amp;' ||
                nameComponent === '&ndash;' ||
                nameComponent === '&mdash;' ||
                nameComponent === '&lsquo;' ||
                nameComponent === '&rsquo;' ||
                nameComponent === '&sbquo;' ||
                nameComponent === '&ldquo;' ||
                nameComponent === '&rdquo;' ||
                nameComponent === ' &bdquo;'
            )
        ) {
            return nameComponent.replace(regex, '<strong>$1</strong>');
        } else {
            return nameComponent;
        }
    });

    return components.join('');
};

proto.buildOptionTemplate = function buildOptionTempate(
    organization,
    searchQuery
) {
    // escape DOM
    var escapedName = escapeExpression(organization.name);
    // parse the special strings returned from the API to their appropriate HTML entity.
    var nameComponents = this.parseSpecialCharacters(escapedName);

    // escape special characters in the search Query and build a new Regexp with the escaped searchQuery
    var nameWithSearchQueryHighlighted = this.highlightSearchTermInName(
        nameComponents,
        searchQuery
    );

    var templateData = {
        name: escapedName,
        nameWrap: nameWithSearchQueryHighlighted,
        id: organization.id,
        city: organization.address.city,
        state: organization.address.state,
    };
    return $(autocompleteSuggestionTemplate(templateData));
};

proto.renderResults = function renderResults(
    organizationCollection,
    searchQuery
) {
    var $template = $();
    var i = 0;

    if (organizationCollection.pagination.currentPage === 1) {
        this.currentSuggestions = organizationCollection;
    } else {
        this.currentSuggestions = this.currentSuggestions.fromJSON(
            organizationCollection.organizations
        );
    }
    for (i; i < organizationCollection.organizations.length; i++) {
        $template = this.buildOptionTemplate(
            organizationCollection.organizations[i],
            searchQuery
        );
        this.$resultsContainer.append($template);
    }
    this.$result = this.$element.find(ShelterAutocomplete.SELECTORS.RESULT);
    this.$result.on('click', this.onResultClickHandler);
};

/**
 * If shelters are loaded successfully, populate the results box and hook up handlers for clicking them
 *
 * @method onShelterFetchSuccess
 * @param requestIndex this request index
 * @param searchQuery search query used as param for this shelter fetch
 * @param data returned from shelter request
 * @private
 */
proto.onShelterFetchSuccess = function onShelterFetchSuccess(
    requestIndex,
    searchQuery,
    data
) {
    // if the index of this request doesn't equal the latest request in flight, exit early
    if (requestIndex !== this.requestIndex) {
        return;
    }
    this.$resultsContainer.off('scroll.results');
    // if no shelters are returned
    if (
        data === null ||
        data.organizations === undefined ||
        data.organizations === null ||
        data.organizations.length === 0
    ) {
        this.$resultsContainer.html(this.templateNoResults);
        this.$resultsStatus.text('No results found.');
    } else {
        if (data.pagination.currentPage === 1) {
            this.$resultsContainer.empty();
        }

        // If we're not on the last page of results hook up the scroll handler for requesting new
        if (data.pagination.currentPage < data.pagination.totalPages) {
            this.$resultsContainer.on(
                'scroll.results',
                debounce(this.onResultsScrollHandler, 100)
            );
        }
        this.renderResults(data, searchQuery);
        this.$resultsStatus.text(
            `${this.$resultsContainer.children().length} results found.`
        );
    }
    this.resultsCached = true;
    this.showResults();
    if (!this.isSelected) {
        if (data.pagination.currentPage === 1) {
            this.$resultsContainer.scrollTop(0);
        }
    }
    this.hideThrobber();
    this.isFetching = false;
};

/**
 * Show an error message if the shelter fetch has failed.
 *
 * @method onShelterFetchFail
 * @private
 */
proto.onShelterFetchFail = function onShelterFetchFail(requestIndex) {
    if (requestIndex !== this.requestIndex) {
        return;
    }
    this.$results.empty();
    this.$results.append(this.templateRequestError);
    this.showResults();
    this.$resultsStatus.text(
        'Your search encountered an error, please try again.'
    );
    this.hideThrobber();
    this.isFetching = false;
};

/**
 * Check if the user has scrolled to the end of the results list.  If so, try to load up some new results.
 *
 * @method onResultsScroll
 * @private
 */
proto.onResultsScroll = function onResultsScroll(e) {
    var $currentTarget = $(e.currentTarget);
    // If we've scrolled to the bottom, get some new results

    if (
        $currentTarget[0].scrollHeight - $currentTarget.scrollTop() <=
        $currentTarget.height() + 40
    ) {
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;
        this.page++;
        this.requestIndex++;
        this.doGetSheltersWorkflow(this.requestIndex, this.searchQuery);
    }
};

proto.onResultClick = function onResultClick(e) {
    e.stopPropagation();
    var $currentTarget = $(e.currentTarget);
    this.$result.removeClass('autocomplete-selected');
    const id = $currentTarget.data('id');
    this.$shelterInput.val(
        this.currentSuggestions.getOrganizationById(id).name
    );
    this.$shelterInput.blur();
    this.$shelterId.val($currentTarget.data('id'));
    $currentTarget.addClass('autocomplete-selected');
    this.isSelected = true;
    this.$throbber.hide();
    this.$results.hide();

    this.$shelterSelect.text(
        this.currentSuggestions.getOrganizationById(id).name
    );
    this.$shelterInputGroup.hide();
    this.$shelterSelect.show();
    this.$shelterSelectLabel.show();
    this.$shelterSelect.focus();
    this.requestIndex++;
};

proto.onDocumentClick = function onDocumentClick(e) {
    e.stopPropagation();
    this.hideResults();
    this.$throbber.hide();
};

proto.onClickStopPropagation = function onClickStopPropagation(e) {
    e.stopPropagation();
};

proto.onToggleClick = function onToggleClick() {
    this.$shelterNotFoundCheckbox.prop('checked', false);
    this.$shelterInputGroup.show();
    this.$shelterSelect.hide();
    this.$shelterSelectLabel.hide();
    this.$shelterInput.focus();
    this.otherShelter = false;
};

/**
 * Sets up any event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */

proto.onShelterNotFoundBtnClick = function onShelterNotFoundBtnClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$shelterNotFoundCheckbox.prop('checked', true);
    this.$throbber.hide();
    this.hideResults();
    this.resultsCache = false;
    // TODO: i18n
    this.$shelterSelect.text('Other Shelter or rescue');
    this.otherShelter = true;
    this.$shelterInputGroup.hide();
    this.$shelterInput.val('');
    this.$resultsContainer.empty();
    this.$shelterSelect.show();
    this.$shelterSelectLabel.show();
};

/**
 * If shelters are gotten successfully, we know we are good to go with the component.
 *
 * @method onInitialShelterFetchSuccess
 * @private
 * @param {Object} organizationCollection of org models
 */
proto.onInitialShelterFetchSuccess = function onInitialShelterFetchSuccess(
    orgColl
) {
    this.currentSuggestions = orgColl;
    // enable the shelter not found checkbox and remove its readonly property.  it should now only programatically
    // be checked if the shelter not found button is clicked in the search results
    this.$shelterNotFoundCheckbox
        .prop('checked', false)
        .prop('readonly', false);
    this.buildOptions(orgColl);
    this.hideThrobber();
    this.setupListeners();
    this.$shelterInput.prop('disabled', false);
    this.resultsCached = true;
};

/**
 * If shelters are gotten successfully, we know we are good to go with the component.
 *
 * @method onInitialShelterFetchSuccess
 * @private
 * @param {Object} organizationCollection of org models
 */
proto.onInitialShelterFetchFail = function onInitialShelterFetchFail() {
    // If we can't get shelters, shut it down.
    this.destroy();
};
/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
ShelterAutocomplete.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */

ShelterAutocomplete.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.ELEMENT = '.js-shelterAutocomplete';

/**
 * Selector for the adoption method input.
 *
 * @property SELECTORS.INPUT_METHOD
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.INPUT_METHOD =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-methodInput';

/**
 * Selector for the shelter input group.
 *
 * @property SELECTORS.SHELTER
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.SHELTER_GROUP =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-shelterGroup';

/**
 * Selector for the shelter input group.
 *
 * @property SELECTORS.SHELTER
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.SHELTER_INPUT =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-shelterInput';

/**
 * Selector for the shelter id hidden input.
 *
 * @property SELECTORS.SHELTER_ID
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.SHELTER_ID =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-shelterId';

/**
 * Selector for the loading throbber displayed during shelter fetch
 *
 * @property SELECTORS.THROBBER
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.THROBBER =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-throbber';

/**
 * Selector for the loading throbber displayed during shelter fetch
 *
 * @property SELECTORS.RESULTS
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.RESULTS =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-results';

/**
 *
 *
 * @property SELECTORS.RESULT
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SELECTORS.RESULT =
    ShelterAutocomplete.SELECTORS.ELEMENT + '-result';

/**
 * Web-front shelter endpoint.
 *
 * @property ENDPOINT_SHELTER
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.ENDPOINT_SHELTER = '/user/api/organizations';

/**
 * Value of shelter option.
 *
 * @property SHELTER_VALUE
 * @static
 * @final
 * @type {String}
 */
ShelterAutocomplete.SHELTER_VALUE = 'lists.petAcquisitionMethods.shelter';

/**
 * Number of shelters to fetch at a time
 *
 * @property SHELTER_LIMIT
 * @static
 * @final
 * @type {Number}
 */
ShelterAutocomplete.SHELTER_LIMIT = 7;

export default ShelterAutocomplete;
