/**
 * @fileOverview BreedRepository
 */
import BaseRepository from './BaseRepository';
import UserLoginModel from '../models/UserLoginModel';
import $ from 'jquery';

// TODO: refactor out jquery; use regular promises instead

/**
 * UserRepository
 * Handles API request for user login
 *
 * @class BreedRepository
 * @constructor
 */
function UserRepository() {
    BaseRepository.call(this);
    this.init();
}

UserRepository.prototype = Object.create(BaseRepository.prototype);
UserRepository.prototype.constructor = UserRepository;

const proto = UserRepository.prototype;
const _super = BaseRepository.prototype;

/**
 * Initializes the Repository
 * Caches configuration to object properties.
 *
 * @private
 * @method init
 */
proto.init = function init() {
    _super.init.call(this);

    /**
     * Endpoint to post username/password for authentication
     *
     * @for UserRepository
     * @property path
     * @type {String}
     */
    this.path = this.routes.apiloginCheck;

    /**
     * Endpoint to post user profile data to
     *
     * @for UserRepository
     * @property path
     * @type {String}
     */
    this.profilePath = this.routes.apiprofile;
};

/**
 * Posts user data to login check and returns success or failure
 * based on data veracity
 *
 * @public
 * @method login
 * @returns {$.Deferred}
 */
proto.login = function login(data) {
    const deferred = $.Deferred();
    this.post(this.path, data)
        .done(this.onLoginDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));
    return deferred.promise();
};

proto.saveData = function saveData(data) {
    const deferred = $.Deferred();
    this.patch(this.profilePath, data)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));
    return deferred.promise();
};

/**
 * When login resolves successfully, builds the UserModel and resumes program flow
 *
 * @private
 * @method onLoginDone
 */
proto.onLoginDone = function onLoginDone(deferred, json) {
    const userLoginModel = new UserLoginModel();
    userLoginModel.fromJSON(json);
    deferred.resolve(userLoginModel);
};


export default UserRepository;
