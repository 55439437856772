import $ from 'jquery';
import BaseComponent from '../BaseComponent/BaseComponent';
import StateRepository from '../../repositories/StateRepository';

/**
 * Loads states from the server based on the selected country.
 *
 * @class CountrySelect
 * @extends {BaseComponent}
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus Event Bus shared between components in app
 */
var CountryStateRelatedInputs = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
CountryStateRelatedInputs.prototype = new BaseComponent();
var proto = CountryStateRelatedInputs.prototype;
proto.constructor = CountryStateRelatedInputs;
proto._super = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandlers and createChildren.
 *
 * @method init
 * @extends BaseForm.init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.stateRepository = new StateRepository();
    return this.setupHandlers()
        .createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onCountryChangeHandler = this.onCountryChange.bind(this);
    this.onFetchStatesSuccessHandler = this.onFetchStatesSuccess.bind(this);
    this.onFetchStatesFailHandler = this.onFetchStatesFail.bind(this);
    return this;
};

/**
 * Create the children for the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$countrySelect = this.$element.find(CountryStateRelatedInputs.SELECTORS.COUNTRY_SELECT);
    this.$stateSelect = this.$element.find(CountryStateRelatedInputs.SELECTORS.STATE_SELECT);
    return this;
};

/**
 * Enables the view
 * PerLoginForms any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.$countrySelect.on('change', this.onCountryChangeHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @method disable
 * @extends {BaseComponent.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$countrySelect.off('change', this.onCountryChangeHandler);
    return this;
};


/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Disable the state select.
 * Used when data is being fetched to prevent the input changing.
 *
 * @method disableStateSelect
 * @private
 * @chainable
 */
proto.disableStateSelect = function disableStateSelect() {
    this.$stateSelect.prop('disabled', true);
    return this;
};

/**
 * Enable the state select.
 * Used when data fetch is complete.
 *
 * @method enableStateSelect
 * @private
 * @chainable
 */
proto.enableStateSelect = function enableStateSelect() {
    this.$stateSelect.prop('disabled', false);
    return this;
};

/**
 * Highlight the state select.
 * Run when data fetch is complete and the state select has been populated with new data.
 *
 * @method highlightStateSelect
 * @private
 * @chainable
 */
proto.highlightStateSelect = function highlightStateSelect() {
    var self = this;
    this.$stateSelect.addClass(CountryStateRelatedInputs.CLASSES.HIGHLIGHT);
    setTimeout(function() {
        self.$stateSelect.removeClass(CountryStateRelatedInputs.CLASSES.HIGHLIGHT);
    }, 300);
    return this;
};

/**
 * Clear the states select box
 *
 * @method clearStates
 * @private
 * @chainable
 */
proto.clearStates = function() {
    this.$stateSelect.empty();
    return this;
};

/**
 * Append the default option to the state select
 * Run when a new list of states has been received.
 *
 * @method appendDefaultStateOption
 * @private
 * @chainable
 */
proto.appendDefaultStateOption = function appendDefaultStateOption() {
    var selectedCountry = this.$countrySelect.val();
    if (selectedCountry == 'US' || selectedCountry == 'us' || selectedCountry == 'MX') {
        this.$stateSelect.append('<option value="">Choose a state</option>');
    } else if (selectedCountry == 'CA' || selectedCountry == 'ca') {
        this.$stateSelect.append('<option value="">Choose a province</option>');
    } else {
        this.$stateSelect.append('<option value="">Choose a State/Province</option>');
    }

    return this;
};

/**
 * Add a state to the states select box
 *
 * @method addState
 * @param state to populate the option
 * @chainable
 */
proto.addState = function addState(state) {
    this.$stateSelect.append('<option value="' + state.code + '">' + state.name + '</option>');
    return this;
};

/**
 * Set the options in the states select box
 *
 * @method setStates
 * @param states list to append to the select as options
 * @chainable
 */
proto.setStates = function setStates(states) {
    states.forEach(this.addState.bind(this));
    return this;
};

/**
 * Fetches the states for the selected country.
 * Run when the country select changes
 *
 * @method setStates
 * @param country to grab states for
 * @chainable
 */
proto.fetchStatesForCountry = function fetchStatesForCountry(country) {
    this.stateRepository.getStatesByCountry(country)
        .then(this.onFetchStatesSuccessHandler, this.onFetchStatesFailHandler);
    return this;
};

/**
 * Set the options in the states select box
 *
 * @method setStates
 * @param fetchedStatesFromServer fetched state list from server
 * @chainable
 */
proto.updateStateSelect = function updateStateSelect(fetchedStatesFromServer) {
    this.clearStates()
        .appendDefaultStateOption()
        .setStates(fetchedStatesFromServer)
        .highlightStateSelect()
        .enableStateSelect();
    return this;
};

/**
 * Kicks off the process of fetching the states for the selected country.
 * Run when the country select changes.
 *
 * @method updateStatesForCountry
 * @chainable
 */
proto.updateStatesForCountry = function updateStatesForCountry() {
    var countrySelectInputValue = this.$countrySelect.val();
    if (countrySelectInputValue) {
        this.disableStateSelect(this.$states)
            .fetchStatesForCountry(countrySelectInputValue);
    } else {
        this.clearStates();
    }
};

proto.dispatchFetchSuccessEvents = function dispatchFetchSuccessEvents() {
    this.eventBus.trigger('statesLoadedForCountry');
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the country select, begins the process of loading the states for the country.
 *
 * @method onCountryChange
 */
proto.onCountryChange = function() {
    this.updateStatesForCountry();
};

/**
 * On a successful request of states for the country
 *
 * @method onCountryChange
 * @param fetchedStatesFromServer
 */
proto.onFetchStatesSuccess = function onFetchStatesSuccess(fetchedStatesFromServer) {
    this.dispatchFetchSuccessEvents()
        .updateStateSelect(fetchedStatesFromServer.states);
};

/**
 * On a failed request for states
 *
 * @method onFetchedStatesForCountryFailed
 * @param jqXHR response object
 */
proto.onFetchStatesFail = function onFetchStatesFail(jqXHR) {};


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
CountryStateRelatedInputs.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
CountryStateRelatedInputs.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
CountryStateRelatedInputs.SELECTORS.ELEMENT = '.js-relatedInputs-countryState';

/**
 * Selector for the state select element.
 *
 * @property SELECTORS.STATE_SELECT
 * @static
 * @final
 * @type {String}
 */
CountryStateRelatedInputs.SELECTORS.STATE_SELECT = CountryStateRelatedInputs.SELECTORS.ELEMENT + '-stateSelect';

/**
 * Selector for the country select element.
 *
 * @property SELECTORS.COUNTRY_SELECT
 * @static
 * @final
 * @type {String}
 */
CountryStateRelatedInputs.SELECTORS.COUNTRY_SELECT = CountryStateRelatedInputs.SELECTORS.ELEMENT + '-countrySelect';

/**
 * Class added to the input field to briefly highlight it, giving the user a cue that the options have changed.
 *
 * @property CLASSES.HIGHLIGHT
 * @static
 * @final
 * @type {String}
 */
CountryStateRelatedInputs.CLASSES.HIGHLIGHT = 'm-input_isChanged';


export default CountryStateRelatedInputs;
