/*
    phoneLink
    --or--
    emailLink
*/
import { html } from '../../../../../core/scripts/util/util.js';
import { phoneLink } from './phoneLink.html';
import { emailLink } from './emailLink.html';
export const phoneWithEmailFallbackLink = vm => html`
   ${vm.phone && phoneLink(vm) ||
     vm.email && emailLink(vm) ||
     ''
    }
`;
