import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import $ from 'jquery';

/**
 * Toggler is a generic class toggler that can accept any number of targets
 * in a data attribute and toggle an assigned class on click
 *
 * @class Toggler
 * @extends {BaseComponent}
 * @constructor
 * @param {jQuery} $element A jQuery wrapped DOM element to which the view is attached.
 * @param {Object} eventBus Event Bus shared between components in app
 */
var Toggler = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
Toggler.prototype = new BaseComponent();
var proto = Toggler.prototype;
proto.constructor = Toggler;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
Toggler.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
Toggler.SELECTORS.ELEMENT = '.js-toggler';

/**
 * Object to hold the names of data attributes
 *
 * @property DATA
 * @static
 * @final
 * @type {Object}
 */
Toggler.DATA = {};

/**
 * Title for the target selector data attributes
 *
 * @property DATA.TARGETS
 * @static
 * @final
 * @type {String}
 */
Toggler.DATA.TARGETS = 'toggler-targets';

/**
 * Title for the target classes data attributes
 *
 * @property DATA.CLASS
 * @static
 * @final
 * @type {String}
 */
Toggler.DATA.CLASS = 'toggler-class';

/**
 * Data to define intended initial state of radio or checkbox
 *
 * @property DATA.CHECKED
 * @static
 * @final
 * @type {String}
 */
Toggler.DATA.CHECKED = 'toggler-checked';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);

    this.setupHandlers()
        .fetchData()
        .createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onClickHandler = this.onClick.bind(this);
    this.onChangeHandler = this.onChange.bind(this);
    this.onRadioChangeHandler = this.onRadioChange.bind(this);

    return this;
};

/**
 * Get $element target and class information from its data attributes
 *
 * @method fetchData
 * @private
 * @chainable
 */
proto.fetchData = function fetchData() {
    /**
     * Array of classes to identify target elements
     *
     * @property targetClasses
     * @type {Array}
     */
    this.targetClasses = this.$element.data(Toggler.DATA.TARGETS).split(' ');

    return this;
};

/**
 * Index DOM references into TARGETS array
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    /**
     * Array of all toggle targets. Each item in array is an object containing
     * the target DOM reference and a string for the class to toggle
     *
     * @property targets
     * @type {Array}
     */
    this.targets = [];

    // if target element defines a class to toggle, add to targets array
    for (var i = 0; i < this.targetClasses.length; i++) {
        var target = {};
        target.$element = $('.' + this.targetClasses[i]);
        target.classToggle = target.$element.data(Toggler.DATA.CLASS);

        if (target.classToggle !== undefined) {
            this.targets.push(target);
        }
    }

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);

    // Show toggler $element on component enable
    this.$element.show();

    this.isChecked = this.$element[0].hasAttribute(
        'data-' + Toggler.DATA.CHECKED
    );

    if (this.$element[0].type == 'checkbox') {
        this.$element.on('change', this.onChangeHandler);

        this.checkIfToggledBeforeLoad();
    } else if (this.$element[0].type == 'radio') {
        // get radio group for event binding
        var radioName = this.$element.attr('name');
        var $radioGroup = $('input:radio').attr('name', radioName);

        /**
         * State tracker for radio
         *
         * @property currentState
         * @type {Boolean}
         */
        this.currentState = this.$element.is(':checked');

        // bind change event to entire radio group
        $radioGroup.on('change', this.onRadioChangeHandler);

        this.checkIfToggledBeforeLoad();
    } else {
        this.$element.on('click', this.onClickHandler);
    }

    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Method for toggling classes
 *
 * @method toggleClasses
 * @private
 */
proto.toggleClasses = function toggleClasses() {
    var i = 0;
    var $el = {};

    for (; i < this.targets.length; i++) {
        $el = this.targets[i].$element;
        $el.toggleClass(this.targets[i].classToggle);
        if ($el.is(':hidden')) {
            $el.attr('aria-hidden', 'true');
        } else {
            $el.attr('aria-hidden', 'false');
        }
    }
};

/**
 * Checks if $element was toggled before JS loaded
 *
 * @method checkIfToggledBeforeLoad
 * @private
 */
proto.checkIfToggledBeforeLoad = function checkIfToggledBeforeLoad() {
    if (this.$element.is(':checked') != this.isChecked) {
        this.toggleClasses();
    }
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * onClick Handler
 *
 * @method onClick
 * @param {Object} event
 * @private
 */
proto.onClick = function onClick(event) {
    event.preventDefault();
    this.toggleClasses();
};

/**
 * onChange Handler for checkboxes
 *
 * @method onChange
 * @param {Object} event
 * @private
 */
proto.onChange = function onChange(event) {
    this.toggleClasses();
};

/**
 * onChange Handler for radio groups
 *
 * @method onRadioChange
 * @param {Object} event
 * @private
 */
proto.onRadioChange = function onRadioChange(event) {
    // toggle classes if element has changed states
    if (this.currentState != this.$element.is(':checked')) {
        this.toggleClasses();

        // update currectState
        this.currentState = this.$element.is(':checked');
    }
};

export default Toggler;
