/*
    St. Louis, MS
    --or--
    MS
    --or--
    Toronto, ON  CA
*/
/* eslint-disable max-len */
import { html } from '../../../../../core/scripts/util/util.js';
export const cityStateAndNonUsCountryInline = (vm) => html`
    $${vm.city || ''}$${vm.city && ','} $${vm.state || ''}  ${vm.country !== 'US' && html`${vm.country}`}
`;
/* eslint-enable max-len */
