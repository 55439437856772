/**
 * @type {Object}
 */
export const STATE = {
    IDLE: 'idle',
    USER_ACCOUNT_MENU: 'userAccountMenu',
};

export const EVENT = {
    CLOSE: 'close',
    USER_ACCOUNT_CLICK: 'userAccountClick',
};

export const ACTION = {
    REFOCUS_TRIGGER: 'refocusTrigger',
};

/**
 * @type {Object}
 */
export const statechart = {
    initial: STATE.IDLE,
    states: {
        [STATE.IDLE]: {
            on: {
                [EVENT.USER_ACCOUNT_CLICK]: STATE.USER_ACCOUNT_MENU,
            },
        },
        [STATE.USER_ACCOUNT_MENU]: {
            on: {
                [EVENT.CLOSE]: {
                    [STATE.IDLE]: {
                        actions: [ACTION.REFOCUS_TRIGGER],
                    },
                },
            },
        },
    },
};
