import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
const I18n = require('../../../../core/scripts/data/i18nJSON.json');
import BreedRepository from '../../../../core/scripts/repositories/BreedRepository';
import $ from 'jquery';

var PetRelatedInputs = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PetRelatedInputs.prototype = new BaseComponent();
var proto = PetRelatedInputs.prototype;
proto.constructor = PetRelatedInputs;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
PetRelatedInputs.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PetRelatedInputs.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PetRelatedInputs.SELECTORS.ELEMENT = '.js-petRelatedInputs';

/**
 * Selector for the pet type select list. Changing this loads in the breeds for the pet type.
 *
 * @property SELECTORS.INPUT_PET_TYPE
 * @static
 * @final
 * @type {String}
 */
PetRelatedInputs.SELECTORS.INPUT_PET_TYPE =
    PetRelatedInputs.SELECTORS.ELEMENT + '-inputPetType';

/**
 * Selector for the breeds select list.
 *
 * @property SELECTORS.INPUT_BREEDS
 * @static
 * @final
 * @type {String}
 */
PetRelatedInputs.SELECTORS.INPUT_BREEDS =
    PetRelatedInputs.SELECTORS.ELEMENT + '-inputBreeds';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    this.breedRepository = new BreedRepository();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangeInputPetTypeHandler = this.onChangeInputPetType.bind(this);
    this.onGetBreedsForPetTypeSuccessHandler = this.onGetBreedsForPetTypeSuccess.bind(
        this
    );
    this.onGetBreedsForPetTypeFailedHandler = this.onGetBreedsForPetTypeFailed.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputPetType = this.$element.find(
        PetRelatedInputs.SELECTORS.INPUT_PET_TYPE
    );
    this.$inputBreeds = this.$element.find(
        PetRelatedInputs.SELECTORS.INPUT_BREEDS
    );
    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainablech
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    return this;
};

/**
 * Setsup event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$inputPetType.on('change', this.onChangeInputPetTypeHandler);
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$inputPetType.off('change', this.onChangeInputPetTypeHandler);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * AJAX request for pets breeds according to pet type.
 * Run when the pet type input is changed to fetch associated primary and secondary breeds.
 *
 * @method getBreedForPetType
 * @param petTypeId corresponding to the pet type
 * @private
 */
proto.getBreedForPetType = function getBreedForPetType(petTypeId) {
    return this.breedRepository.getBreedsByPetType(petTypeId);
};

/**
 * Appends a default select value to the breed list.
 *
 * @method appendDefaultBreedValue
 * @private
 * @chainable
 */
proto.appendDefaultBreedValue = function appendDefaultBreedValue() {
    this.$inputBreeds.append('<option value="">-</option>');
    return this;
};

/**
 * Appends a breed value to the breed select list.
 * Run when a list of breeds is retrieved.
 *
 * @method appendBreedValue
 * @param breed object containing id and name
 * @private
 * @chainable
 */
proto.appendBreedValue = function appendBreedValue(breedModel) {
    this.$inputBreeds.append(
        '<option value="' +
            breedModel.breedId +
            '">' +
            breedModel.name +
            '</option>'
    );
};

/**
 * Appends the breed values for the selected pet type to the select list.
 * Run on a successful get for breeds according to pet type.
 *
 * @method appendBreedValues
 * @param petBreeds for pet type returned from server
 * @private
 * @chainable
 */
proto.appendBreedValues = function appendBreedValues(breedCollection) {
    $.each(breedCollection.breeds, (index, element) => {
        this.appendBreedValue(element);
    });

    return this;
};

/**
 * Empties the breed select inputs.
 * Run on a successful get for breeds according to pet type to reset the field.
 *
 * @method emptyBreedSelects
 * @private
 * @chainable
 */
proto.emptyBreedSelects = function emptyBreedSelects() {
    this.$inputBreeds.empty();
    return this;
};

proto.enableInput = function enableInput($input) {
    $input.attr('disabled', false);
};

proto.disableInput = function disableInput($input) {
    $input.attr('disabled', true);
};

/**
 * Disables the pet type and breed inputs.
 * Run while the get for pet breeds is being processed to prevent additional user input.
 *
 * @method disableInputs
 * @private
 * @chainable
 */
proto.disableInputs = function disableInputs($input) {
    if ($.isArray($input)) {
        $.each($input, (index, element) => {
            this.disableInput(element);
        });
    } else {
        this.disableInput($input);
    }
    return this;
};

/**
 * Enables the pet type and breed inputs.
 * Run after a successful get for pet breeds to reenable user input.
 *
 * @method enableInputs
 * @private
 * @chainable
 */
proto.enableInputs = function enableInputs($input) {
    if ($.isArray($input)) {
        $.each($input, (index, element) => {
            this.enableInput(element);
        });
    } else {
        this.enableInput($input);
    }

    return this;
};

proto.dispatchGettingBreedsEvents = function dispatchGettingBreedsEvents() {
    this.eventBus.trigger('gettingBreedsForPetType');
    return this;
};

proto.dispatchGetBreedsSuccessEvents = function dispatchGetBreedsSuccessEvents() {
    this.eventBus.trigger('getBreedsForPetTypeSuccess');
    return this;
};

proto.dispatchGetBreedsFailedEvents = function dispatchGetBreedsFailedEvents() {
    this.eventBus.trigger('getBreedsForPetTypeFailed');
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the pet type input, disables input and kicks off request for pet breeds according to
 * that pet and population of the pet breed inputs.
 *
 * @method onChangeInputPetType
 * @param event to grab the input's value
 * @private
 * @chainable
 */
proto.onChangeInputPetType = function onChangeInputPetType(event) {
    var petTypeId = event.currentTarget.value;
    if (petTypeId != '') {
        this.dispatchGettingBreedsEvents()
            .disableInputs([this.$inputPetType, this.$inputBreeds])
            .getBreedForPetType(petTypeId)
            .then(
                this.onGetBreedsForPetTypeSuccessHandler,
                this.onGetBreedsForPetTypeFailedHandler
            );
    } else {
        this.emptyBreedSelects()
            .appendDefaultBreedValue()
            .enableInputs(this.$inputPetType)
            .disableInputs(this.$inputBreeds);
    }
};

/**
 * On successful get of pet breeds, appends the values to appropriate select lists and reenables input.
 *
 * @method onChangeInputPetType
 * @param breedsForPetTypeJSON returned from the server
 * @private
 * @chainable
 */
proto.onGetBreedsForPetTypeSuccess = function onGetBreedsForPetTypeSuccess(
    breedCollection
) {
    this.emptyBreedSelects()
        .appendDefaultBreedValue()
        .appendBreedValues(breedCollection)
        .enableInputs([this.$inputPetType, this.$inputBreeds])
        .dispatchGetBreedsSuccessEvents();
};

/**
 * On a failed get for breeds, emits event to let form handle next steps.
 *
 * @method onGetBreedsForPetTypeFailed
 * @private
 * @chainable
 */
proto.onGetBreedsForPetTypeFailed = function onGetBreedsForPetTypeFailed() {
    this.dispatchGetBreedsFailedEvents();
};

export default PetRelatedInputs;
