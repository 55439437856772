import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';
import Flickity from 'flickity';
// import '../../../../core/scripts/vendor/flickity-mod/fizzy-ui-utils__mod';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pf-story-guide';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    CAROUSEL: `[${ELEMENT_TAG_NAME}-carousel]`,
};

/**
 * Sets up story guide specific flickity slider.
 *
 * @extends PFElement
 */
export class PFDCStoryGuideElement extends PFElement {
    /**
     * Initialize this component
     */
    onInit() {
        /**
         * Reference to the carousel initialization element
         * @type {element}
         */
        this.carousel = this.querySelector(ELEMENT_SELECTORS.CAROUSEL);

        /**
         * Flickity options
         * @type {Object}
         */
        const options = {
            dragThreshold: 10,
            cellAlign: 'left',
            adaptiveHeight: true,
            percentPosition: true,
            friction: 0.5,
            selectedAttraction: 0.08,
            pageDots: true,
            prevNextButtons: true,
            wrapAround: true,
            accessibility: false, // because flickity safari arrows keys not working
        };

        // Setup flickity
        this.flickity = new Flickity(this.carousel, options);

        window.f = this.flickity;

        // So the slider is right size
        setTimeout(() => {
            this.flickity.resize();
        }, 250);

        this.flickity.on('select', ev => {
            this.updateAccessibilityAttrs();
        });

        // Start updateAccessibilityAttrs
        this.updateAccessibilityAttrs();
    }

    /**
     * Hides inactive slides and tabbable inner content from screen readers,
     * makes selected element and inner content visible to screen readers
     */
    updateAccessibilityAttrs() {
        // Update accessibility attributes for inactive slides
        this.flickity.cells.forEach(cell => {
            this.toggleScreenReaderVisibility(cell.element, false);

            const links = Array.from(cell.element.getElementsByTagName('a'));
            links.forEach(link => {
                this.toggleScreenReaderVisibility(link, false);
            });
        });

        // Update accessibility attributes for active slides
        this.toggleScreenReaderVisibility(this.flickity.selectedElement, true);

        const links = Array.from(this.flickity.selectedElement.getElementsByTagName('a'));
        links.forEach(link => {
            this.toggleScreenReaderVisibility(link, true);
        });
    }

    /**
     * Toggle accessibility attributes
     * @param {Object} element - DOM element to modify
     * @param {Boolean} isActive - true shows element, false hides element
     */
    toggleScreenReaderVisibility(element, isActive) {
        if (isActive) {
            element.setAttribute('aria-hidden', 'false');

            // tabindex needs to be removed entirely to prevent focus on div.contentCard_storyGuide
            element.removeAttribute('tabindex');
        } else if (!isActive) {
            element.setAttribute('aria-hidden', 'true');
            element.setAttribute('tabindex', '-1');
        }
    }
}

export default PFDCStoryGuideElement;
