import BaseModel from './BaseModel';
import _get from 'lodash/get';

export default class UploadTransformationModel extends BaseModel {
    get defaultData() {
        return {
            x: null,
            y: null,
            width: null,
            height: null,
            rotate: null,
            scaleX: null,
            scaleY: null,
        };
    }

    get x() {
        return this._data.x;
    }

    get y() {
        return this._data.y;
    }

    get width() {
        return this._data.width;
    }

    get height() {
        return this._data.height;
    }

    get rotate() {
        return this._data.rotate;
    }

    get scaleX() {
        return this._data.scaleX;
    }

    get scaleY() {
        return this._data.scaleY;
    }

    get cropped() {
        return [this.width, this.height].every(property => property > 0);
    }

    get hasTransformation() {
        return this.rotate || this.cropped;
    }

    static fromServer(transformationData) {
        if (!transformationData) {
            return new UploadTransformationModel();
        }

        return new UploadTransformationModel(this._mapServerDataToClientside(transformationData));
    }

    static _mapServerDataToClientside(transformationData) {
        return {
            x: _get(transformationData, 'crop.x', null),
            y: _get(transformationData, 'crop.y', null),
            width: _get(transformationData, 'crop.width', null),
            height: _get(transformationData, 'crop.height', null),
            rotate: _get(transformationData, 'rotate', null),
            scaleX: _get(transformationData, 'scale.width', null),
            scaleY: _get(transformationData, 'scale.height', null),
        };
    }

    toServer() {
        const transformations = {
            // Server does not accept 0
            rotate: this.rotate || null,
        };

        if (this.cropped) {
            transformations.crop = {
                x: this.x,
                y: this.y,
                width: this.width,
                height: this.height,
            };
        }

        return { transformations };
    }

    toCropperJSCropBoxData() {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
        };
    }
}
