import React from 'react';
import ReactDOM from 'react-dom';
import ReactApp from '../../ReactApp';

import CopySnippet from '../../react-components/specifics/CopySnippet';

export default function initReactClientComponents() {
    ReactApp.init();

    const snippetRootElements = Array.from(
        document.querySelectorAll('.js-copySnippet')
    );

    snippetRootElements.forEach(snippetRootElement => {
        return ReactDOM.render(
            <CopySnippet content={snippetRootElement.innerText} />,
            snippetRootElement
        );
    });
}
