import BaseComponent from '../../components/BaseComponent/BaseComponent';

var PrimarySecondaryBreedsValidator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PrimarySecondaryBreedsValidator.prototype = new BaseComponent();
var proto = PrimarySecondaryBreedsValidator.prototype;
proto.constructor = PrimarySecondaryBreedsValidator;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
PrimarySecondaryBreedsValidator.CLASSES = {};

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PrimarySecondaryBreedsValidator.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PrimarySecondaryBreedsValidator.SELECTORS.ELEMENT = '.js-primarySecondaryBreedsValidator';

PrimarySecondaryBreedsValidator.SELECTORS.INPUT_BREED = '.js-primarySecondaryBreedsValidator-inputBreed';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    return this.setupHandlers()
        .createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangeBreedHandler = this.onChangeBreed.bind(this);
    this.onChangePetTypeHandler = this.onChangePetType.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputs = this.$element.find('.js-primarySecondaryBreedsValidator-input');
    this.$errors = this.$element.find('.js-primarySecondaryBreedsValidator-error');
    this.$labels = this.$element.find('.js-primarySecondaryBreedsValidator-label');

    this.$inputPetType = this.$element.find('.js-primarySecondaryBreedsValidator-inputPetType');

    this.$inputPrimaryBreed = this.$element.find('.js-primarySecondaryBreedsValidator-inputPrimaryBreed');
    this.$inputSecondaryBreed = this.$element.find('.js-primarySecondaryBreedsValidator-inputSecondaryBreed');

    this.$errorPrimaryBreed = this.$element.find('.js-primarySecondaryBreedsValidator-errorPrimaryBreed');
    this.$errorSecondaryBreed = this.$element.find('.js-primarySecondaryBreedsValidator-errorSecondaryBreed');

    this.$labelPrimaryBreed = this.$element.find('.js-primarySecondaryBreedsValidator-labelPrimaryBreed');
    this.$labelSecondaryBreed = this.$element.find('.js-primarySecondaryBreedsValidator-labelSecondaryBreed');

    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$inputs.on('change', this.onChangeBreedHandler);
    this.$inputPetType.on('change', this.onChangePetTypeHandler);
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    return this;
};

proto.clearInvalidStates = function clearInvalidStates() {
    this.$inputs.removeClass('m-field_error');
    this.$errors.html('');
    this.$labels.removeClass('label_error');
};

proto.setErrorMessage = function showErrorMessage($error, msg) {
    if (this.hasBeenInteractedWith) {
        $error.empty()
            .html(msg);
    }
    return this;
};


proto.setLabelInvalidStyles = function setLabelInvalidStyles($label) {
    if (this.hasBeenInteractedWith) {
        $label.addClass('label_error');
    }
    return this;
};

proto.setInputInvalidStyles = function setFieldInvalidStyles($input) {
    if (this.hasBeenInteractedWith) {
        $input.addClass('m-field_error');
    }
    return this;
};

proto.doesInputHaveValue = function doesInputHaveValue($input) {
    return $input.val() != '';
};

proto.doValuesMatch = function doValuesMatch() {
    return this.$inputPrimaryBreed.val() == this.$inputSecondaryBreed.val();
};

proto.doesPrimaryBreedHaveValue = function doesPrimaryBreedHaveValue() {
    return this.doesInputHaveValue(this.$inputPrimaryBreed);
};

proto.dispatchSuccessfulValidatorValidationEvents = function dispatchSuccessfulValidatorValidationEvents() {
    this.eventBus.trigger('successfulValidatorValidation', this);
    return this;
};

proto.dispatchFailedValidatorValidationEvents = function dispatchFailedlValidatorValidationEvents() {
    this.eventBus.trigger('failedValidatorValidation', this);
    return this;
};


proto.checkFieldValidity = function checkFieldValidity() {
    this.clearInvalidStates();
    if (this.doesInputHaveValue(this.$inputSecondaryBreed) &&
        !this.doesInputHaveValue(this.$inputPrimaryBreed)
    ) {
        this.setLabelInvalidStyles(this.$labelPrimaryBreed)
            .setInputInvalidStyles(this.$inputPrimaryBreed)
            .setErrorMessage(this.$errorPrimaryBreed, 'A primary breed is required.');
        this.dispatchFailedValidatorValidationEvents();
    }

    else if (this.doValuesMatch() &&
        (this.doesInputHaveValue(this.$inputSecondaryBreed) &&
            this.doesInputHaveValue(this.$inputPrimaryBreed))) {
        this.setLabelInvalidStyles(this.$labelPrimaryBreed)
            .setInputInvalidStyles(this.$inputPrimaryBreed)
            .setErrorMessage(this.$errorPrimaryBreed, 'The breeds cannot match.');

        this.setLabelInvalidStyles(this.$labelSecondaryBreed)
            .setInputInvalidStyles(this.$inputSecondaryBreed)
            .setErrorMessage(this.$errorSecondaryBreed, 'The breeds cannot match.');
        this.dispatchFailedValidatorValidationEvents();
    } else {
        this.dispatchSuccessfulValidatorValidationEvents();
    }
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////


proto.onChangeBreed = function onChangeBreed() {
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

proto.onSubmit = function() {
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

proto.onChangePetType = function onChangePetType() {
    this.clearInvalidStates();
    this.eventBus.trigger('successfulValidatorValidation', this);
};

export default PrimarySecondaryBreedsValidator;
