import BaseCollection from './BaseCollection';
import UploadPartModel from './UploadPartModel';

export default class UploadPartsCollection extends BaseCollection {
    static fromServer(partsData) {
        if (!partsData || partsData.length == null) {
            return new UploadPartsCollection();
        }

        return new UploadPartsCollection(
            partsData.map(part => UploadPartModel.fromServer(part)));
    }
}
