import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HappyTailsContentCard from '../HappyTailsContentCard';
import _noop from 'lodash/noop';
import HappyTailCollection from '../../../../../core/scripts/models/HappyTailCollection';

class HappyTailsContentCardCollection extends Component {
    /**
     * @static
     * @type {Object}
     */
    static propTypes = {
        collection: PropTypes.instanceOf(HappyTailCollection).isRequired,
        onCardClick: PropTypes.func,
        observed: PropTypes.bool,
    };

    static defaultProps = {
        onCardClick: _noop,
        observed: true, // default prop if content card collection is not given this value through lazyLoad
    };

    _handleCardClick = (title, cta, link) => {
        this.props.onCardClick(title, cta, link);
    };

    _buildHappyTailCard = (happyTail, index) => {
        const { imageSource, story, title, url } = happyTail;
        return (
            <div className="grid-col grid-col_1/3@minLg" key={index}>
                <HappyTailsContentCard
                    happyTailsHref={url}
                    imageSource={imageSource}
                    onCardClick={this._handleCardClick}
                    story={story}
                    dataTestId="Happy_Tails_Content_Card"
                    title={title}
                    observed={this.props.observed}
                />
            </div>
        );
    };

    _renderCardCollection = () => {
        const collection = this.props.collection.sliceThree;
        return collection.map((happyTail, index) => {
            return this._buildHappyTailCard(happyTail, index);
        });
    };

    render() {
        return (
            <Fragment>
                {this._renderCardCollection()}
                {this.props.children}
            </Fragment>
        );
    }
}

export default HappyTailsContentCardCollection;
