import BaseModel from './BaseModel';
import _get from 'lodash/get';

export default class PaginationModel extends BaseModel {
    get defaultData() {
        return {
            countPerPage: null,
            totalCount: null,
            currentPage: null,
            totalPages: null,
        };
    }

    /**
     * @static
     * @param {object} paginationData
     *
     * @return {PaginationModel}
     */
    static fromServer(paginationData) {
        if (!paginationData) {
            return new PaginationModel();
        }

        return new PaginationModel(this._mapServerDataToClientside(paginationData));
    }

    static _mapServerDataToClientside(paginationData) {
        return {
            countPerPage: _get(paginationData, 'count_per_page', null),
            totalCount: _get(paginationData, 'total_count', null),
            currentPage: _get(paginationData, 'current_page', null),
            totalPages: _get(paginationData, 'total_pages', null),
        };
    }

    get countPerPage() {
        return this._data.countPerPage;
    }

    get totalCount() {
        return this._data.totalCount;
    }

    get currentPage() {
        return this._data.currentPage;
    }

    get totalPages() {
        return this._data.totalPages;
    }
}
