import BaseEnsightenAnalytics from '../../../../core/scripts/analytics/ensighten/BaseEnsightenAnalytics';

/**
 * Ensighten tracking on global elements
 *
 * @class GlobalEnsightenAnalytics
 * @constructor
 */
const GlobalEnsightenAnalytics = function($element, eventBus) {
    BaseEnsightenAnalytics.call(this, $element, eventBus);
    this.init($element, eventBus);
};

GlobalEnsightenAnalytics.prototype = Object.create(
    BaseEnsightenAnalytics.prototype
);
GlobalEnsightenAnalytics.prototype.constructor = GlobalEnsightenAnalytics;

const proto = GlobalEnsightenAnalytics.prototype;
const _super = BaseEnsightenAnalytics.prototype;

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
GlobalEnsightenAnalytics.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {string}
 */
GlobalEnsightenAnalytics.SELECTORS.ELEMENT = '.js-globalEnsighten';

const CONST = {
    SELECTOR: {
        SIGN_OUT_LINK: `${GlobalEnsightenAnalytics.SELECTORS.ELEMENT}-signOut`,
    },
};

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @extends {BaseForm.init}
 */
proto.init = function init($element, eventBus) {
    _super.init.call(this, $element, eventBus);
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onSignOutLinkClickHandler = this.onSignOutLinkClick.bind(this);
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$signOutLink = this.$element.find(CONST.SELECTOR.SIGN_OUT_LINK);
    return this;
};

/**
 * Enables the view
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this.setupListeners();
    return this;
};

/**
 * Sets up Event Listeners
 * Run during enabling of the component
 *
 * @method setupListeners
 * @private
 * @chainable
 */
proto.setupListeners = function setupListeners() {
    this.$signOutLink.on('click', this.onSignOutLinkClickHandler);
    return this;
};

/**
 * Disable any event listeners for the view
 *
 * @method disable
 * @extends {BaseForm.disable}
 * @private
 * @chainable
 */
proto.disable = function disable() {
    _super.disable.call(this);
    this.$signOutLink.off('click', this.onSignOutLinkClickHandler);
    return this;
};

/**
 * Form submit handler
 *
 * @method onFormSubmit
 * @private
 * @chainable
 */
proto.onSignOutLinkClick = function onSignOutLinkClick() {
    this.triggerEvent({
        category: 'sign out',
        action: 'log out of site',
    });
};

export default GlobalEnsightenAnalytics;
