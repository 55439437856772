/**
 * @fileOverview CouponRepository
 * @author Noah Blon nblon@nerdery.com
 */
import BaseRepository from './BaseRepository';
import $ from 'jquery';

/**
 * CouponRepository
 * Handles data posting and getting related to the Did You Adopt coupon promotion
 *
 * @class CouponRepository
 * @constructor
 */
var CouponRepository = function CouponRepository() {
    this.init();
};

CouponRepository.prototype = Object.create(BaseRepository.prototype);
CouponRepository.prototype.constructor = CouponRepository;

var proto = CouponRepository.prototype;
var _super = BaseRepository.prototype;

/**
 * Initializes the Repository
 * Caches configuration to object properties.
 *
 * @private
 * @method init
 */
proto.init = function init() {
    _super.init.call(this);

    /**
     * Endpoint route from which to fetch pet discovery methods
     *
     * @for CouponRepository
     * @property pathDiscoveryMethod
     * @type {String}
     */
    this.pathDiscoveryMethod = this.routes.api['getDiscoveryMethodOptionsByType'];

    /**
     * Endpoint route from which to fetch retailers for a type such as grocery store
     *
     * @for CouponRepository
     * @property path
     * @type {String}
     */
    this.pathRetailers = this.routes.api['getPetFoodRetailersByType'];

    /**
     * Endpoint route from which to fetch pet food preferences
     *
     * @for CouponRepository
     * @property path
     * @type {String}
     */
    this.pathFoodPreferences = this.routes.api['getPetFoodPreferencesByType'];
};

/**
 * Gets a list of discovery method specifics based on a category.
 * For example, social media returns specific social media services.
 *
 * @public
 * @method getDiscoveryMethods
 * @returns {$.Deferred}
 */
proto.getDiscoveryMethods = function getDiscoveryMethods(discoveryMethodKey) {
    var deferred = $.Deferred();
    var params = { discovery_method: discoveryMethodKey };

    this.get(this.pathDiscoveryMethod, params)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));

    return deferred.promise();
};

/**
 * Gets a list of discovery method specifics based on a category.
 * For example, social media returns specific social media services.
 *
 * @public
 * @method getDiscoveryMethods
 * @returns {Function}
 */
proto.getRetailersForType = function getRetailersForType(typeId) {
    var deferred = $.Deferred();
    var params = { retailer_type: typeId };

    this.get(this.pathRetailers, params)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));

    return deferred.promise();
};

/**
 * Gets a list of discovery method specifics based on a category.
 * For example, social media returns specific social media services.
 *
 * @public
 * @method getDiscoveryMethods
 * @returns {Function}
 */
proto.getFoodPreferencesForPetType = function getFoodPreferencesForPetType(typeId) {
    var deferred = $.Deferred();
    var params = { pet_type: typeId };

    this.get(this.pathFoodPreferences, params)
        .done(this.onAJAXDone.bind(this, deferred))
        .fail(this.onAJAXFail.bind(this, deferred));

    return deferred.promise();
};

export default CouponRepository;
