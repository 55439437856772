import BaseCollection from './BaseCollection';
import HappyTailModel from './HappyTailModel';

export default class HappyTailCollection extends BaseCollection {
    /**
     * fromServer
     * API:
     * [GET] /happy-tails/{id}
     * @static
     * @param {Array} happyTailCollectionData Server Data
     *
     * @return {HappyTailCollection}
     */
    static fromServer(happyTailCollectionData) {
        if (
            !happyTailCollectionData ||
            happyTailCollectionData.length == null
        ) {
            return new HappyTailCollection();
        }

        return new HappyTailCollection(
            happyTailCollectionData.map(happyTailData =>
                HappyTailModel.fromServer(happyTailData)
            )
        );
    }

    /**
     * size
     *
     * @return {Number} length of collection
     */
    get size() {
        return this.raw.length;
    }

    /**
     * sliceThree
     *
     * @return {Array} first 3 stories
     */
    get sliceThree() {
        return this.sliceGroup(0, 3);
    }

    /**
     * sliceGroup
     *
     * @param  {number} indexStart
     * @param  {number} indexEnd
     *
     * @return {Array}
     */
    sliceGroup(indexStart, indexEnd) {
        return this._items.slice(indexStart, indexEnd);
    }
}
