import BaseModel from './BaseModel';

export default class UploadPartModel extends BaseModel {
    get defaultData() {
        return {
            part: -1,
            start: -1,
            end: -1,
            url: '',
            method: '',
        };
    }

    static fromServer(partData) {
        if (!partData) {
            return new UploadPartModel();
        }

        return new UploadPartModel(this._mapServerDataToClientside(partData));
    }

    static _mapServerDataToClientside(partData) {
        return {
            part: partData.part,
            start: partData.start,
            end: partData.end,
            url: partData.url,
            method: partData.method,
        };
    }

    get part() {
        return this._data.part;
    }

    get start() {
        return this._data.start;
    }

    get end() {
        return this._data.end;
    }

    get url() {
        return this._data.url;
    }

    get method() {
        return this._data.end;
    }
}
