import BaseComponent from '../../../../core/scripts/components/BaseComponent/BaseComponent';
import CouponRepository from '../../../../core/scripts/repositories/CouponRepository';

var FoodPreference = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
FoodPreference.prototype = new BaseComponent();
var proto = FoodPreference.prototype;
proto.constructor = FoodPreference;
proto._super = BaseComponent.prototype;

/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASSES
 * @static
 * @final
 * @type {Object}
 */
FoodPreference.CLASSES = {};

/**
 * Hidden class for visibility toggling
 *
 * @property CLASSES.HIDDEN
 * @static
 * @final
 * @type {String}
 */
FoodPreference.CLASSES.HIDDEN = 'u-isHidden';

/**
 * Holds selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
FoodPreference.SELECTORS = {};

/**
 * The selector for the DOM element to which the component will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
FoodPreference.SELECTORS.ELEMENT = '.js-foodPreference';

/**
 * Selector for the food preference select input group
 *
 * @property SELECTORS.GROUP_PREFERENCE_SELECT
 * @static
 * @final
 * @type {String}
 */
FoodPreference.SELECTORS.GROUP_PREFERENCE_SELECT =
    FoodPreference.SELECTORS.ELEMENT + '-preferenceSelect';

/**
 * Selector for the food preference text input group
 *
 * @property SELECTORS.GROUP_PREFERENCE_TEXT
 * @static
 * @final
 * @type {String}
 */
FoodPreference.SELECTORS.GROUP_PREFERENCE_TEXT =
    FoodPreference.SELECTORS.ELEMENT + '-preferenceText';

/**
 * Selector for the specify other food preference input group
 *
 * @property SELECTORS.GROUP_SPECIFY
 * @static
 * @final
 * @type {String}
 */
FoodPreference.SELECTORS.GROUP_SPECIFY =
    FoodPreference.SELECTORS.ELEMENT + '-specify';

/**
 * Endpoint path for the webfront api food preferences
 *
 * @property ENDPOINT_FOOD
 * @static
 * @final
 * @type {String}
 */
FoodPreference.ENDPOINT_FOOD = '/user/api/pet-food-preferences';

/**
 * api parameter for pet type
 *
 * @property ENDPOINT_PARAMETER
 * @static
 * @final
 * @type {String}
 */
FoodPreference.ENDPOINT_PARAMETER = '?petType=';

/**
 * Value for the "other" food option for dog
 *
 * @property ENDPOINT_OTHER_VALUE_DOG
 * @static
 * @final
 * @type {String}
 */
FoodPreference.ENDPOINT_OTHER_VALUE_DOG = 'lists.petFoods.dog.other';

/**
 * Value for the "other" food option for cat
 *
 * @property ENDPOINT_OTHER_VALUE_CAT
 * @static
 * @final
 * @type {String}
 */
FoodPreference.ENDPOINT_OTHER_VALUE_CAT = 'lists.petFoods.cat.other';

/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component component
 * Kicks off component lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @private
 * @chainable
 * @overridden BaseComponent.init
 */
proto.init = function init($element, eventBus) {
    this._super.init.call(this, $element, eventBus);
    this.couponRepository = new CouponRepository();
    return this.setupHandlers().createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onChangePetTypeHandler = this.onChangePetType.bind(this);
    this.onChangeFoodPreferenceHandler = this.onChangeFoodPreference.bind(this);
    this.onGetFoodPreferencesSuccessHandler = this.onGetFoodPreferencesSuccess.bind(
        this
    );
    this.onGetFoodPreferencesFailedHandler = this.onGetFoodPreferencesFailed.bind(
        this
    );
    return this;
};

/**
 * Create any child objects or references to DOM elements
 * Should only be run on initialization of the component
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$inputPetType = this.$element.find(
        FoodPreference.SELECTORS.INPUT_PET_TYPE
    );

    this.$groupPreferenceSelect = this.$element.find(
        FoodPreference.SELECTORS.GROUP_PREFERENCE_SELECT
    );
    this.$inputPreferenceSelect = this.$groupPreferenceSelect.find('select');

    this.$groupPreferenceText = this.$element.find(
        FoodPreference.SELECTORS.GROUP_PREFERENCE_TEXT
    );
    this.$inputPreferenceText = this.$groupPreferenceText.find('input');

    this.$groupSpecify = this.$element.find(
        FoodPreference.SELECTORS.GROUP_SPECIFY
    );
    this.$inputSpecify = this.$groupSpecify.find('input');

    return this;
};

/**
 * Enables the component
 * Performs any event binding to handlers
 * Exits early if it is already enabled
 * Kicks off the slogan update lifecycle
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners();
    this.typeName = this.$element.data('typename');
    this.checkPetTypeValue();
    this.checkFoodPreferenceValue();
    return this;
};

/**
 * Setup event listeners necessary for the component
 *
 * @method enable
 * @private
 * @chainable
 * @overridden BaseComponent.enable
 */
proto.setupListeners = function setupListeners() {
    this.$inputPetType.on('change', this.onChangePetTypeHandler);
    this.$inputPreferenceSelect.on(
        'change',
        this.onChangeFoodPreferenceHandler
    );
};

/**
 * Disables the component
 * Tears down any event handlers
 *
 * @method disable
 * @public
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);
    this.$inputPetType.off('change', this.onChangePetTypeHandler);
    this.$inputPreferenceSelect.off(
        'change',
        this.onChangeFoodPreferenceHandler
    );
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Check if the pet type select field is set to shelter,
 * and carry out appropriate methods depending on value.
 * Run on Enable and on pet type input change.
 *
 * @method checkPetTypeValue
 * @param onEnable boolean if method was called from onEnable
 * @private
 */
proto.checkPetTypeValue = function checkPetTypeValue() {
    if (this.typeName === 'Dog' || this.typeName === 'Cat') {
        this.showPreferenceSelectGroup();
    } else {
        this.showPreferenceTextGroup();
    }
    // preserving old logic for loading food prefs
    // var petBreedId = this.$inputPetType.val();
    //
    /// / if dog or cat
    // if (petBreedId == '2' || petBreedId == '3') {
    //    if (onEnable) {
    //        this.showPreferenceSelectGroup();
    //    } else {
    //        this.disableInputs()
    //            .showPreferenceSelectGroup()
    //            .toggleSpecifyGroup(false)
    //            .getFoodPreferenceForPetType(petBreedId)
    //            .then(this.onGetFoodPreferencesSuccessHandler, this.onGetFoodPreferencesFailedHandler);
    //    }
    // } else {
    //    if (onEnable) {
    //        this.showPreferenceTextGroup();
    //    } else {
    //        this.emptyFoodPreferenceText()
    //            .showPreferenceTextGroup()
    //            .toggleSpecifyGroup(false);
    //    }
    // }
};

/**
 * Check if the food preference select field is set to shelter,
 * and carry out appropriate methods depending on value.
 * Run on Enable and on food preference input change.
 *
 * @method checkFoodPreferenceValue
 * @private
 */
proto.checkFoodPreferenceValue = function checkFoodPreferenceValue() {
    var foodPreferenceId = this.$inputPreferenceSelect.val();

    if (
        foodPreferenceId == FoodPreference.ENDPOINT_OTHER_VALUE_DOG ||
        foodPreferenceId == FoodPreference.ENDPOINT_OTHER_VALUE_CAT
    ) {
        this.toggleSpecifyGroup(true);
    } else {
        this.toggleSpecifyGroup(false);
    }
};

/**
 * AJAX request for food preference according to pet type.
 * Run when the pet type input is changed.
 *
 * @method getFoodPreferenceForPetType
 * @param petTypeId corresponding to the pet type
 * @private
 */
proto.getFoodPreferenceForPetType = function getFoodPreferenceForPetType(
    petTypeId
) {
    return this.couponRepository.getFoodPreferencesForPetType(petTypeId);
};

/**
 * Appends a default select value to the food preference list.
 *
 * @method appendDefaultFoodPreferenceValue
 * @private
 * @chainable
 */
proto.appendDefaultFoodPreferenceValue = function appendDefaultFoodPreferenceValue() {
    // TODO I18n
    this.$inputPreferenceSelect.append(
        '<option value="">Select Food Preference</option>'
    );
    return this;
};

/**
 * Appends the food preference choices for the selected pet type to the select list.
 * Run on a successful get for food preferences according to a pet type of dog or cat.
 *
 * @method appendFoodPreferenceChoices
 * @param foodPreferenceJSON for pet type returned from server
 * @private
 * @chainable
 */
proto.appendFoodPreferenceChoices = function appendFoodPreferenceChoices(
    foodPreferenceJSON
) {
    for (var foodKey in foodPreferenceJSON.options) {
        var food = foodPreferenceJSON.options[foodKey];
        this.$inputPreferenceSelect.append(
            '<option value="' + foodKey + '">' + food + '</option>'
        );
    }
    return this;
};

/**
 * Empties the food preference select inputs.
 *
 * @method emptyFoodPreferenceSelect
 * @private
 * @chainable
 */
proto.emptyFoodPreferenceSelect = function emptyFoodPreferenceSelect() {
    this.$inputPreferenceSelect.empty();
    return this;
};

/**
 * Empties the food preference text input.
 *
 * @method emptyFoodPreferenceText
 * @private
 * @chainable
 */
proto.emptyFoodPreferenceText = function emptyFoodPreferenceText() {
    this.$inputPreferenceText.val('');
    return this;
};

/**
 * Empties the specify other input.
 *
 * @method emptySpecifyInput
 * @private
 * @chainable
 */
proto.emptySpecifyInput = function emptySpecifyInput() {
    this.$inputSpecify.val('');
    return this;
};

/**
 * Disables all child inputs.
 * Run while the get for food preferences is being processed to prevent additional user input.
 *
 * @method disableInputs
 * @private
 * @chainable
 */
proto.disableInputs = function disableInputs() {
    this.$inputPetType.attr('disabled', true);
    this.$inputPreferenceSelect.attr('disabled', true);
    this.$inputPreferenceText.attr('disabled', true);
    this.$inputSpecify.attr('disabled', true);
    return this;
};

/**
 * Enables all child inputs.
 * Run after a successful get for pfood preferences to reenable user input.
 *
 * @method enableInputs
 * @private
 * @chainable
 */
proto.enableInputs = function enableInputs() {
    this.$inputPetType.attr('disabled', false);
    this.$inputPreferenceSelect.attr('disabled', false);
    this.$inputPreferenceText.attr('disabled', false);
    this.$inputSpecify.attr('disabled', false);
    return this;
};

/**
 * Adds visibility to the food preference select input group and hides the text input group.
 *
 * @method showPreferenceSelectGroup
 * @private
 * @chainable
 */
proto.showPreferenceSelectGroup = function showPreferenceSelectGroup() {
    this.$groupPreferenceSelect.removeClass(FoodPreference.CLASSES.HIDDEN);
    this.$groupPreferenceText.addClass(FoodPreference.CLASSES.HIDDEN);
    return this;
};

/**
 * Adds visibility to the food preference text input group and hides the select input group.
 *
 * @method showPreferenceTextGroup
 * @private
 * @chainable
 */
proto.showPreferenceTextGroup = function showPreferenceTextGroup() {
    this.$groupPreferenceSelect.addClass(FoodPreference.CLASSES.HIDDEN);
    this.$groupPreferenceText.removeClass(FoodPreference.CLASSES.HIDDEN);
    return this;
};

/**
 * Toggles visibility of "specify other food preference" input group.
 * Clears user text input when hidden.
 *
 * @method toggleSpecifyGroup
 * @param boolean to hide or show input. True will show, false will hide
 * @private
 * @chainable
 */
proto.toggleSpecifyGroup = function toggleSpecifyGroup(show) {
    if (!show) {
        this.emptySpecifyInput();
    }
    this.$groupSpecify.toggleClass(FoodPreference.CLASSES.HIDDEN, !show);
    return this;
};

/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On change of the pet type input, disables inputs and kicks off request for food preference if selection
 * is cat or dog. Populates food preference input on success.
 *
 * @method onChangePetType
 * @param event to grab the input's value
 * @private
 * @chainable
 */
proto.onChangePetType = function onChangePetType(event) {
    this.checkPetTypeValue();
};

/**
 * On change of the food preference input, specify other preference text input is shown if
 * other option is chosen from select list.
 *
 * @method onChangeFoodPreference
 * @param event to grab the input's value
 * @private
 * @chainable
 */
proto.onChangeFoodPreference = function onChangeFoodPreference(event) {
    this.checkFoodPreferenceValue();
};

/**
 * On successful get of pet breeds, appends the values to appropriate select lists and reenables input.
 *
 * @method onChangePetType
 * @param foodPreferencesForPetTypeJSON returned from the server
 * @private
 * @chainable
 */
proto.onGetFoodPreferencesSuccess = function(foodPreferencesForPetTypeJSON) {
    this.emptyFoodPreferenceSelect()
        .appendDefaultFoodPreferenceValue()
        .appendFoodPreferenceChoices(foodPreferencesForPetTypeJSON)
        .enableInputs();
};

/**
 * On a failed get for breeds, emits event to let form handle next steps.
 *
 * @method onGetFoodPreferencesFailed
 * @private
 * @chainable
 */
proto.onGetFoodPreferencesFailed = function() {
    this.eventBus.trigger('GetFoodPreferencesFailed');
};

export default FoodPreference;
