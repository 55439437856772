import BaseComponent from '../../components/BaseComponent/BaseComponent';
const I18nJSON = require('../../data/i18nJSON.json');

var PasswordConfirmValidator = function($element, eventBus) {
    this.init($element, eventBus);
};

// Inheritance
PasswordConfirmValidator.prototype = new BaseComponent();
var proto = PasswordConfirmValidator.prototype;
proto.constructor = PasswordConfirmValidator;
proto._super = BaseComponent.prototype;


/// ///////////////////////////////////////////////////////////////////////////////
// CONSTANTS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Object to hold CSS class names that will be manipulated.
 * Property values should not contain the class notation to play well with jQuery hasClass, toggleClass etc.
 *
 * @property CLASS
 * @static
 * @final
 * @type {Object}
 */
PasswordConfirmValidator.CLASSES = {};

/**
 * Object to hold selectors to grab DOM references.
 * Property values should include the selector notation
 *
 * @property SELECTORS
 * @static
 * @final
 * @type {Object}
 */
PasswordConfirmValidator.SELECTORS = {};

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PasswordConfirmValidator.SELECTORS.ELEMENT = '.js-validator-passwordConfirm';


/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PasswordConfirmValidator.SELECTORS.PASSWORD_FIELD = '.js-validator-passwordConfirm-password';

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PasswordConfirmValidator.SELECTORS.CONFIRM_LABEL = '.js-validator-passwordConfirm-label';

/**
 * The selector for the DOM element to which the view will be bound.
 * Value should include the selector notation.
 * Element must be a form element.
 *
 * @property SELECTORS.ELEMENT
 * @static
 * @final
 * @type {String}
 */
PasswordConfirmValidator.SELECTORS.CONFIRM_FIELD = '.js-validator-passwordConfirm-confirm';

PasswordConfirmValidator.SELECTORS.ERROR = '.js-validator-passwordConfirm-error';

/**
 * Class to add to field if invalid to provide visual feedback of invalid state
 *
 * @property CLASSES.FIELD_ERROR
 * @static
 * @final
 * @type {String}
 */
PasswordConfirmValidator.CLASSES.FIELD_ERROR = 'm-field_error';

/**
 * Class to add to label if invalid to provide visual feedback of invalid state
 *
 * @property CLASSES.LABEL_ERROR
 * @static
 * @final
 * @type {String}
 */
PasswordConfirmValidator.CLASSES.LABEL_ERROR = 'label_error';

/**
 * Translation key for password mismatch error
 *
 * @property ERROR_MESSAGE_KEY_MISMATCH
 * @static
 * @final
 * @type {string}
 */
PasswordConfirmValidator.ERROR_MESSAGE_KEY_MISMATCH = 'user.password.repeated_mismatch';


/// ///////////////////////////////////////////////////////////////////////////////
// LIFECYCLE
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * Initializes the UI Component View
 * Kicks off view lifecycle with setupHandler, createChildren, and enable methods.
 *
 * @method init
 * @param {Object} $element jQuery wrapped element
 * @param {Object} eventBus App level event bus to listen for events
 * @param {Number} id for tracking component instance
 * @private
 * @chainable
 */
proto.init = function init($element, eventBus, id) {
    this._super.init.call(this, $element, eventBus);

    /**
     * Id to track the component instance.  Passed back with the triggered event
     *
     * @property passwordValue
     * @type {String}
     * @default ''
     */
    this.id = id;

    /**
     * Boolean tracks the current validity state of the field
     *
     * @property fieldIsValid
     * @type {Boolean}
     * @default true
     */
    this.fieldIsValid = false;

    this.required = !this.$element.hasClass('js-validator-passwordConfirm_notRequired');

    return this.setupHandlers()
        .createChildren();
};

/**
 * Binds the scope of any handler functions
 *
 * @method setupHandlers
 * @private
 * @chainable
 */
proto.setupHandlers = function setupHandlers() {
    this.onBlurFieldHandler = this.onBlurField.bind(this);
    this.onConfirmPasswordMatchHandler = this.onConfirmPasswordMatch.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    return this;
};

/**
 * Create the children for the view
 *
 * @method createChildren
 * @private
 * @chainable
 */
proto.createChildren = function createChildren() {
    this.$passwordConfirmField = this.$element.find(PasswordConfirmValidator.SELECTORS.CONFIRM_FIELD);
    this.$passwordConfirmLabel = this.$element.find(PasswordConfirmValidator.SELECTORS.CONFIRM_LABEL);
    this.$passwordField = this.$element.find(PasswordConfirmValidator.SELECTORS.PASSWORD_FIELD);
    this.$error = this.$element.find(PasswordConfirmValidator.SELECTORS.ERROR);
    return this;
};

/**
 * Enables the view
 * PerLoginForms any event binding to handlers
 * Exits early if it is already enabled
 *
 * @method enable
 * @private
 * @chainable
 */
proto.enable = function enable() {
    this._super.enable.call(this);
    this.setupListeners()
        .checkFieldValidity();
    return this;
};

proto.setupListeners = function setupListeners() {
    this.$passwordConfirmField.on('blur', this.onBlurFieldHandler);
    this.eventBus.on('confirmPasswordMatch', this.onConfirmPasswordMatchHandler)
        .on('submit', this.onSubmitHandler);
    return this;
};

/**
 * Disable the children for the view
 *
 * @chainable
 */
proto.disable = function disable() {
    this._super.disable.call(this);

    this.onBlurHandler = null;
    return this;
};


/// ///////////////////////////////////////////////////////////////////////////////
// HELPERS
/// ///////////////////////////////////////////////////////////////////////////////

proto.doesInputHaveValue = function() {
    return this.$passwordConfirmField.val() != '';
};

proto.showErrorMessage = function showErrorMessage(message) {
    if (this.hasBeenInteractedWith) {
        this.$error.empty()
            .html(message);
    }
    return this;
};

proto.clearErrorMessage = function clearErrorMessage() {
    this.$error.empty();
    return this;
};

/**
 * Styles are added to the field and label on blur of the field to inform the
 * user the field value is invalid.
 *
 * @method addInvalidFieldStyles
 * @private
 * @chainable
 */
proto.addInvalidFieldStyles = function addInvalidFieldStyles() {
    if (this.hasBeenInteractedWith) {
        this.$passwordConfirmLabel.addClass(PasswordConfirmValidator.CLASSES.LABEL_ERROR);
        this.$passwordConfirmField.addClass(PasswordConfirmValidator.CLASSES.FIELD_ERROR);
    }
    return this;
};

/**
 * Invalid field styles are removed from the field and label on blur when the
 * value is valid.
 *
 * @method removeInvalidFieldStyles
 * @private
 * @chainable
 */
proto.removeInvalidFieldStyles = function removeInvalidFieldStyles() {
    this.$passwordConfirmLabel.removeClass(PasswordConfirmValidator.CLASSES.LABEL_ERROR);
    this.$passwordConfirmField.removeClass(PasswordConfirmValidator.CLASSES.FIELD_ERROR);
    return this;
};

/**
 * Returns whether the value in the password confirm field matches the value in
 * the password field.
 *
 * @method isConfirmValueMatchingPassword
 * @private
 * @returns {Boolean}
 */
proto.isConfirmValueMatchingPassword = function isConfirmValueMatchingPassword() {
    return this.$passwordConfirmField.val() === this.$passwordField.val();
};

/**
 * Checks if the field is valid and dispatches an event to the form for form level
 * handling.
 * Adds or removes error styles depending on validity.
 *
 * @method checkFieldValidity
 * @private
 */
proto.checkFieldValidity = function checkFieldValidity() {
    if (this.required) {
        if (!this.doesInputHaveValue()) {
            this.addInvalidFieldStyles()
                .showErrorMessage('Password confirmation is required.');
        } else if (this.isConfirmValueMatchingPassword()) {
            this.eventBus.trigger('successfulValidatorValidation', this);
            this.removeInvalidFieldStyles()
                .clearErrorMessage();
        } else {
            this.eventBus.trigger('failedValidatorValidation', this);
            this.addInvalidFieldStyles()
                .showErrorMessage(I18nJSON[PasswordConfirmValidator.ERROR_MESSAGE_KEY_MISMATCH]);
        }
    } else {
        if (this.$passwordField.val().length > 0) {
            if (this.isConfirmValueMatchingPassword()) {
                this.eventBus.trigger('successfulValidatorValidation', this);
                this.removeInvalidFieldStyles()
                    .clearErrorMessage();
            } else {
                this.eventBus.trigger('failedValidatorValidation', this);
                this.addInvalidFieldStyles()
                    .showErrorMessage(I18nJSON[PasswordConfirmValidator.ERROR_MESSAGE_KEY_MISMATCH]);
            }
        } else {
            this.eventBus.trigger('successfulValidatorValidation', this);
            this.removeInvalidFieldStyles()
                .clearErrorMessage();
        }
    }
};


/// ///////////////////////////////////////////////////////////////////////////////
// HANDLERS
/// ///////////////////////////////////////////////////////////////////////////////

/**
 * On blurring of the field, the field is checked for validity.
 * Messages are dispatched to the form and error states are set if applicable.

 * @method onBlurField
 * @private
 *
 */
proto.onBlurField = function onBlurField(event) {
    event.preventDefault();
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};

proto.onConfirmPasswordMatch = function onConfirmPasswordMatch() {
    this.checkFieldValidity();
};

proto.onSubmit = function() {
    this.hasBeenInteractedWith = true;
    this.checkFieldValidity();
};


export default PasswordConfirmValidator;
