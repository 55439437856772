import { PFElement } from '../../../../core/scripts/elements/pf-element/element';

import {
    COUNTRY_CHOOSE_LABEL,
    STATE_TYPE_ENUM,
    USE_LABEL_STATE,
    USE_LABEL_PROVINCE,
} from '../../../../core/scripts/constants/geography';
class PFDCAddressElement extends PFElement {
    get eventId() {
        if (
            !this.hasAttribute('event-id') ||
            this.getAttribute('event-id').length <= 0
        ) {
            return false;
        }
        return this.getAttribute('event-id');
    }

    onInit() {
        this.currentState = 'init';

        // TODO: rework with actions
        this.machine = {
            init: {
                COUNTRY_CHANGE: 'loading',
            },
            loading: {
                SUCCESS: 'success',
                FAILURE: 'error',
            },
            success: {
                COUNTRY_CHANGE: 'loading',
            },
            error: {
                COUNTRY_CHANGE: 'loading',
            },
        };

        this.countrySelect = this.querySelector('[pf-address-country]');
        this.stateSelect = this.querySelector('[pf-address-state]');
        this.stateSelectLabel = this.stateSelect.previousElementSibling;
        this.postalCodeInput = this.querySelector('[pf-address-postalcode]');
        this.postalCodeLabel = this.postalCodeInput.previousElementSibling;

        this.addEventListener('change', this.onChanged);
    }

    /**
     * Determine the next state based off the passed action string
     * @param {string} state
     * @param {string} action
     * @returns {string}
     */
    transition(state, action) {
        return this.machine[state][action];
    }

    /**
     * Handle updates to the state of this component
     * @param {string} state
     */
    // TODO: Handle state machine updates
    update(state) {
        if (!state) {
            throw Error(`State '${state}' does not exist`);
        }
        this.currentState = state;
        this.setAttribute('state', this.currentState);
    }

    /**
     * Get states from the api with the given country code
     * @param {string} countryCode
     */
    async getStatesForCountry(countryCode) {
        const formConfig = {
            form: this.closest('form'),
            triggeringComponent: this.countrySelect,
            asyncComponent: this,
        };

        try {
            this.update(this.transition(this.currentState, 'COUNTRY_CHANGE'));

            this.dispatchAction('form.async.component.start', formConfig);

            this.stateSelect.setAttribute('disabled', '');

            const response = await this.countryRepository.getStatesForCountry(
                countryCode
            );

            const states = response.states;
            this.renderStateOptions(states, countryCode);
            this.updateLabels(countryCode);

            this.stateSelect.removeAttribute('disabled');

            this.update(this.transition(this.currentState, 'SUCCESS'));
        } catch (err) {
            this.stateSelect.innerHTML = '';
            this.update(this.transition(this.currentState, 'FAILURE'));
        } finally {
            this.dispatchAction('form.async.component.end', formConfig);
        }
    }

    /**
     * Builds an object for the first option based on the selected country
     * @param {string} countryCode
     * @returns {array}
     */
    getFirstOption(countryCode) {
        const firstOptionObj = {};
        firstOptionObj.code = '';

        if (USE_LABEL_STATE.includes(countryCode)) {
            firstOptionObj.name =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.STATE].stateOptionText;
        } else if (USE_LABEL_PROVINCE.includes(countryCode)) {
            firstOptionObj.name =
                STATE_TYPE_ENUM.values[
                    STATE_TYPE_ENUM.PROVINCE
                ].stateOptionText;
        } else {
            firstOptionObj.name =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.DEFAULT].stateOptionText;
        }

        return [firstOptionObj];
    }

    /**
     * Render the state options into the state select
     * @param {array} states
     * @param {string} countryCode
     */
    renderStateOptions(states, countryCode) {
        if (states.length <= 0) {
            this.stateSelect.innerHTML = '';
            return;
        }

        const firstOption = this.getFirstOption(countryCode);

        const newStateOptions = []
            .concat(firstOption)
            .concat(states)
            .map(
                option =>
                    `<option value="${option.code}">${option.name}</option>`
            );

        this.stateSelect.innerHTML = '';
        this.stateSelect.innerHTML = newStateOptions.join('\n');
    }

    /**
     * Updates the label text for the state field
     * @param {string} countryCode
     */
    updateLabels(countryCode) {
        if (USE_LABEL_STATE.includes(countryCode)) {
            this.stateSelectLabel.innerText =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.STATE].stateLabel;
            this.postalCodeLabel.innerText =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.STATE].postalCodeLabel;
        } else if (USE_LABEL_PROVINCE.includes(countryCode)) {
            this.stateSelectLabel.innerText =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.PROVINCE].stateLabel;
            this.postalCodeLabel.innerText =
                STATE_TYPE_ENUM.values[
                    STATE_TYPE_ENUM.PROVINCE
                ].postalCodeLabel;
        } else {
            this.stateSelectLabel.innerText =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.DEFAULT].stateLabel;
            this.postalCodeLabel.innerText =
                STATE_TYPE_ENUM.values[STATE_TYPE_ENUM.DEFAULT].postalCodeLabel;
        }
    }

    /**
     * Handle component change event
     * @param {object} ev
     */
    onChanged(ev) {
        if (ev.target === this.countrySelect) {
            const countryCode = ev.target.value;

            if (countryCode.length <= 0) {
                this.stateSelect.innerHTML = `<option value="">${COUNTRY_CHOOSE_LABEL}</option>`;
                this.stateSelect.setAttribute('disabled', '');
                return;
            }

            this.getStatesForCountry(countryCode);
            this.onCountrySelectChanged(ev, countryCode);
        }
    }

    /**
     * Handle component specific country select change event
     * @param {object} ev
     * @param {string} countryCode
     */
    onCountrySelectChanged(ev, countryCode) {
        if (this.eventId) {
            this.dispatchAction(`${this.eventId}.country.change`, {
                countryCode,
                srcEvent: ev,
            });
        }
    }
}

export default PFDCAddressElement;
